import {IDocumentData} from "@buildwithflux/models";
import {isEqual} from "lodash";

/**
 * This function is a helper method for the document reducer
 *
 * @param draftState - document state draft
 * @param deletedObjectsUids - array of uids of subjects that we wanna unselect, please note subjects here
 * could be elements/routes/pcbLayoutNodes
 *
 * TODO: Move this to core
 */
export const removeDeletedSubjectsFromSelection = (draftState: IDocumentData, deletedObjectsUids: string[]) => {
    if (draftState.selectedObjectUids) {
        // QUESTION: avoid filter for better memoization?
        draftState.selectedObjectUids = draftState.selectedObjectUids.filter(
            (uid) => !deletedObjectsUids.includes(uid),
        );
    }
};

/**
 * This function is a helper for updating `draftState` selection
 *
 * @param draftState
 * @param selectedObjectUids
 */
export const updateSelectedSubjects = (draftState: IDocumentData, selectedObjectUids: string[]) => {
    if (draftState && draftState.selectedObjectUids?.length !== selectedObjectUids.length) {
        // If length is different, we should definitely update the `selectedObjectUids`
        draftState.selectedObjectUids = selectedObjectUids;
    } else if (draftState && selectedObjectUids && selectedObjectUids.length) {
        // Otherwise, we should do a deep compare. Separating these two cases can
        // avoid this `isEqual` check when it doesn't need to
        // TODO I'm surprised lodash.isEqual doesn't do a length check as one of its
        // first checks! Meaning the savings by doing this is neglible. (Looking at the
        // source, it seems to, but I'm not absolutely certain without some further
        // thought so I'll leave this as-is for now)
        if (!isEqual(draftState.selectedObjectUids, selectedObjectUids)) {
            draftState.selectedObjectUids = selectedObjectUids;
        }
    }
};
