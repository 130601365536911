import {Box2, Box3, Euler, EulerOrder, Matrix4, Object3D, Path, ShapePath, Vector2, Vector3} from "three";

export class ThreeJSHelper {
    public static getObjectSize(object: Object3D) {
        const size = new Vector3();
        const boundingBox = new Box3().setFromObject(object);
        return boundingBox.getSize(size);
    }
    public static getObjectBox(object: Object3D) {
        return new Box3().setFromObject(object);
    }
    public static convertToBox2(box: Box3) {
        return new Box2(vector3To2(box.min), vector3To2(box.max));
    }
    public static convertToBox3(box: Box2) {
        return new Box3(new Vector3(box.min.x, box.min.y, 0), new Vector3(box.max.x, box.max.y, 0));
    }
    public static vector3To2(vec: Vector3) {
        return new Vector2(vec.x, vec.y);
    }
    public static vector2To3(vec: Vector2) {
        return new Vector3(vec.x, vec.y, 0);
    }
    public static polygonsToShapes(polygons: Vector2[][]) {
        const shape = new ShapePath();
        shape.subPaths = polygons.map((polygon) => new Path(polygon));
        return shape.toShapes(true);
    }
    public static vector2FromXY(xy: {x: number; y: number}) {
        return new Vector2(xy.x, xy.y);
    }
    public static vector3FromXYZ(xyz: {x: number; y: number; z: number}) {
        return new Vector3(xyz.x, xyz.y, xyz.z);
    }
    public static eulerFromXYZ(xyz: {x: number; y: number; z: number}, order: EulerOrder = "XYZ") {
        return new Euler(xyz.x, xyz.y, xyz.z, order);
    }
    public static invertEuler(euler: Euler) {
        return new Euler().setFromRotationMatrix(new Matrix4().makeRotationFromEuler(euler).invert());
    }
    public static box2FromMinMax(box: {min: {x: number; y: number}; max: {x: number; y: number}}) {
        return new Box2(vector2FromXY(box.min), vector2FromXY(box.max));
    }
    public static box2ToRBush(box2: Box2) {
        return {minX: box2.min.x, minY: box2.min.y, maxX: box2.max.x, maxY: box2.max.y};
    }
}

// For brevity:
export const box2FromMinMax = ThreeJSHelper.box2FromMinMax;
export const vector3To2 = ThreeJSHelper.vector3To2;
export const vector2To3 = ThreeJSHelper.vector2To3;
export const vector2FromXY = ThreeJSHelper.vector2FromXY;
export const vector3FromXYZ = ThreeJSHelper.vector3FromXYZ;
export const eulerFromXYZ = ThreeJSHelper.eulerFromXYZ;
export const invertEuler = ThreeJSHelper.invertEuler;
export const box2ToRBush = ThreeJSHelper.box2ToRBush;
