import {Vector2, Vector3} from "three";

import {ShapeContainerInfo} from "..";

/**
 * Given a 3D position, computes the projected 2D position on a layer, useful for determining events collisions and such
 * @param position The 3D position to transform
 * @param container From `containerMap` from the PcbShapesStore
 * @returns The transformed 2D position
 */
export function getLocalPositionForLayer(position: Vector3, container: ShapeContainerInfo) {
    const tempVector3 = new Vector3();
    tempVector3.copy(position).applyMatrix4(container.transformInverse);
    return new Vector2(tempVector3.x, tempVector3.y);
}

export function getWorldPositionFromLayer(position: Vector2, container: ShapeContainerInfo) {
    const tempVector3 = new Vector3();
    tempVector3.set(position.x, position.y, 0).applyMatrix4(container.transform);
    return new Vector2(tempVector3.x, tempVector3.y);
}

export function isPolygonDiff(newPolygon: {x: number; y: number}[], oldPolygon: {x: number; y: number}[]) {
    return (
        newPolygon.length !== oldPolygon.length ||
        newPolygon.some((xy, index) => xy.x !== oldPolygon[index]?.x || xy.y !== oldPolygon[index]?.y)
    );
}

export function arePolygonsDiff(newPolygons: {x: number; y: number}[][], oldPolygons: Vector2[][]) {
    return (
        newPolygons.length !== oldPolygons.length ||
        newPolygons.some((newPolygon, index) => {
            const oldPolygon = oldPolygons[index];
            if (!oldPolygon) {
                return true;
            } else {
                return isPolygonDiff(newPolygon, oldPolygon);
            }
        })
    );
}

export function convertToVector2(newPolygon: {x: number; y: number}[]) {
    return newPolygon.map((xy) => new Vector2(xy.x, xy.y));
}
