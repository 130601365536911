import {PcbRulesMap} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const addGlobalRulesFromClipboard = createActionRecordAction(
    "addGlobalRulesFromClipboard",
    (targetObjectUids: string[], nodeLayoutRules: PcbRulesMap) => {
        return {
            payload: {
                targetObjectUids,
                nodeLayoutRules,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // NOTE: assume the worst for baking
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "pasted layout rules",
);
