import {createAction} from "@reduxjs/toolkit";

export const setCurrentUserHasCommentingPermission = createAction(
    "setCurrentUserHasCommentingPermission",
    (hasCommentingPermission: boolean) => {
        return {
            payload: {
                hasCommentingPermission,
            },
        };
    },
);

export const setCurrentUserHasEditPermission = createAction(
    "setCurrentUserHasEditPermission",
    (hasEditPermission: boolean) => {
        return {
            payload: {
                hasEditPermission,
            },
        };
    },
);
