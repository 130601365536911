import {ICommentThreadData} from "@buildwithflux/core";
import {produce} from "immer";
import {useDebouncedCallback} from "use-debounce";
import {create, type StoreApi, UseBoundStore} from "zustand";

import {useFluxServices} from "../../injection/hooks";

type Timer = ReturnType<typeof setTimeout>;

// TODO: the naming is a little misleading here.
type RemoteUserIsTypingState = {
    localUserIsTyping: (threadUid: ICommentThreadData["uid"]) => void;
    setLocalUserIsTypingCallback: (callback: (threadUid: ICommentThreadData["uid"]) => void) => void;
    setRemoteUserIsTyping: (threadUid: ICommentThreadData["uid"]) => void;
    setRemoteUserIsNotTyping: (threadUid: ICommentThreadData["uid"]) => void;
    remoteUserIsTyping: {[threadUid: ICommentThreadData["uid"]]: boolean};
    remoteUserIsTypingTimers: {[threadUid: ICommentThreadData["uid"]]: Timer};
};

export type RemoteUserIsTypingStore = UseBoundStore<StoreApi<RemoteUserIsTypingState>>;

export const createUseRemoteUserIsTypingStateHook = () =>
    create<RemoteUserIsTypingState>()((set, get) => {
        return {
            localUserIsTyping: (_threadUid) => {},
            setLocalUserIsTypingCallback: (callback) => {
                set(
                    // TODO - do we need to take it over?
                    produce((state: RemoteUserIsTypingState) => {
                        state.localUserIsTyping = callback;
                    }),
                );
            },
            setRemoteUserIsNotTyping: (threadUid) => {
                const currentState = get();

                const existingTimer = currentState.remoteUserIsTypingTimers[threadUid];
                if (existingTimer) clearTimeout(existingTimer);

                set(
                    produce((state: RemoteUserIsTypingState) => {
                        state.remoteUserIsTyping[threadUid] = false;
                    }),
                );
            },
            setRemoteUserIsTyping: (threadUid) => {
                const currentState = get();

                const existingTimer = currentState.remoteUserIsTypingTimers[threadUid];
                if (existingTimer) clearTimeout(existingTimer);

                const newTimer = setTimeout(() => currentState.setRemoteUserIsNotTyping(threadUid), 5000);

                set(
                    produce((state: RemoteUserIsTypingState) => {
                        state.remoteUserIsTyping[threadUid] = true;
                        state.remoteUserIsTypingTimers[threadUid] = newTimer;
                    }),
                );
            },
            remoteUserIsTyping: {},
            remoteUserIsTypingTimers: {},
            localUserIsTypingTimers: {},
        };
    });

export function useRemoteTypingIndicators(threadUid: string | undefined) {
    // TODO: implement me
    return useFluxServices().useRemoteUserIsTypingState((state) => {
        if (!threadUid) return false;
        return state.remoteUserIsTyping[threadUid] ?? false;
    });
}

export function useLocalUserIsTyping() {
    const localUserIsTyping = useFluxServices().useRemoteUserIsTypingState((state) => state.localUserIsTyping);

    return useDebouncedCallback(
        (threadUid: string) => {
            localUserIsTyping(threadUid);
        },
        5000,
        {leading: true},
    );
}
