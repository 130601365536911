import {create, type StoreApi, type UseBoundStore} from "zustand";

import {useFluxServices} from "../../../injection/hooks";
import AlgoliaConnector from "../../storage_engine/connectors/AlgoliaConnector";

type AlgoliaSearchClientState = {
    searchClient: AlgoliaConnector["searchClient"];
    subscriptionHandle: () => void;
};

export type UseAlgoliaSearchClientStore = UseBoundStore<StoreApi<AlgoliaSearchClientState>>;

export const createAlgoliaSearchClientStoreHook = (algoliaConnector: AlgoliaConnector): UseAlgoliaSearchClientStore =>
    create<AlgoliaSearchClientState>()((set) => {
        const unsubHandle = algoliaConnector.subscribeToSearchClientChanges((client) => {
            set({searchClient: client});
        });

        return {
            searchClient: algoliaConnector.getCurrentSearchClient(),
            subscriptionHandle: unsubHandle,
        };
    });

export function useAlgoliaSearchClient(): AlgoliaConnector["searchClient"] {
    return useFluxServices().useAlgoliaSearchClientStore((state) => state.searchClient);
}
