import {Vector2} from "three";

import {ShapeType, Shape} from "../types";

export function isConvex(shape: Shape) {
    if (shape.shapeType === ShapeType.ApproxPolygon) {
        return shape.convex;
    }

    if (shape.shapeType === ShapeType.Expansion) {
        // An expansion shape is convex if the underlying shape is convex
        return isConvex(shape.shape);
    }

    if (shape.shapeType === ShapeType.Union || shape.shapeType === ShapeType.Difference) {
        // Not supported for now, assume not convex
        return false;
    }

    // All other default shapes (circle, oblong, rectangle) are convex
    return true;
}

// http://www.sunshine2k.de/coding/java/Polygon/Convex/polygon.htm
export function isConvexFromPoints(polygon: Vector2[]) {
    if (polygon.length < 3) return false;

    let res = 0;
    for (let i = 0; i < polygon.length; i++) {
        const p = polygon[i]!;
        const tmp = polygon[(i + 1) % polygon.length]!;
        const v = new Vector2();
        v.x = tmp.x - p.x;
        v.y = tmp.y - p.y;
        const u = polygon[(i + 2) % polygon.length]!;

        if (i == 0)
            // in first loop direction is unknown, so save it in res
            res = u.x * v.y - u.y * v.x + v.x * p.y - v.y * p.x;
        else {
            const newres = u.x * v.y - u.y * v.x + v.x * p.y - v.y * p.x;
            if ((newres > 0 && res < 0) || (newres < 0 && res > 0)) return false;
        }
    }
    return true;
}
