const connection =
    typeof window !== "undefined"
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          window.navigator && (window.navigator as any).connection
        : null;

export function isSafeDataMode() {
    return connection && !!connection.saveData;
}

export function isSlow2gConnection() {
    return connection && /slow-2g/.test(connection.effectiveType);
}
export function is2gConnection() {
    return connection && /2g/.test(connection.effectiveType);
}

export function is3gConnection() {
    return connection && /3g/.test(connection.effectiveType);
}

export function is4gConnection() {
    return connection && /4g/.test(connection.effectiveType);
}
