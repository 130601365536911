import {AsyncClipperShapeFactory, ClipperShapeFactory} from "@buildwithflux/core";

import {approxAABoundingBox, approxFittingPolygon, mergeApproxBoundingBoxes} from "./math";
import {Shape, ShapeType} from "./types";
import {chainCompoundShapeTransforms} from "./utils";

function toSvgPolyline(shape: Shape, clipperShapeFactory: ClipperShapeFactory): string {
    if (shape.shapeType === ShapeType.Union) {
        const [chainedA, chainedB] = chainCompoundShapeTransforms(shape);
        return toSvgPolyline(chainedA, clipperShapeFactory) + toSvgPolyline(chainedB, clipperShapeFactory);
    }
    if (shape.shapeType === ShapeType.Difference) {
        // TODO difference
        const [chainedA, _chainedB] = chainCompoundShapeTransforms(shape);
        return toSvgPolyline(chainedA, clipperShapeFactory);
    }

    const points = approxFittingPolygon(shape, 128, clipperShapeFactory);
    return `<polyline points="${points.map((p) => [p.x, p.y].join(",")).join(" ")}" fill="red" />`;
}

// Limited, so useful only for debugging for now
export async function shapesToSVG(shapes: Shape[]) {
    let svgString = ``;

    const boundingBox = mergeApproxBoundingBoxes(shapes.map((shape) => approxAABoundingBox(shape)));

    const clipperShapeFactory = await new AsyncClipperShapeFactory().load();

    shapes.forEach((shape) => {
        svgString += toSvgPolyline(shape, clipperShapeFactory);
    });

    const finalSvgString = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="1024" height="1024" viewBox="${
        boundingBox.minX
    } ${boundingBox.minY} ${boundingBox.maxX - boundingBox.minX} ${
        boundingBox.maxY - boundingBox.minY
    }">${svgString}</svg>`;
    return finalSvgString;
}
