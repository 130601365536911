import {useEffect, useMemo} from "react";

import {useFluxServices} from "../../../injection/hooks";
import {EnvironmentSwitcher} from "../../../modules/storage_engine/connectors/EnvironmentSwitcher";
import {
    FeatureFlagConnector,
    isAnonymousContext,
} from "../../../modules/storage_engine/connectors/FeatureFlagConnector";

import {useFeatureFlags} from "./featureFlags/useFeatureFlag";

export function useEnvSwitcher() {
    const {analyticsStorage} = useFluxServices();
    // NOTE: `useEnvSwitcher.initializeEnvironment` depends on the `splitTestingAccess` flag from
    // LaunchDarkly, so it is important that we call `initializeEnvironment` whenever this flag
    // is changed
    const splitTestingAccess = useFeatureFlags((state) => state.splitTestingAccess);
    const envSwitcher = useMemo(() => new EnvironmentSwitcher(analyticsStorage), []);

    useEffect(() => {
        const launchDarklyContext = FeatureFlagConnector?.getContext();
        if (launchDarklyContext && !isAnonymousContext(launchDarklyContext)) {
            envSwitcher.initializeEnvironment();
        }
    }, [splitTestingAccess, envSwitcher]);
}
