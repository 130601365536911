import {PcbRuleSetsMap} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const setGlobalPcbRuleSet = createActionRecordAction(
    "setGlobalPcbRuleSet",
    (setPcbLayoutRules: PcbRuleSetsMap, selectedGlobalPcbLayoutRule?: string) => {
        return {
            payload: {
                setPcbLayoutRules,
                selectedGlobalPcbLayoutRule,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added or updated document pcb layout rules",
);
