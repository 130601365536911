/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
/* eslint-disable no-console */
import {isDevEnv} from "@buildwithflux/shared";
import {shallow} from "zustand/shallow";

export function refEqualityWithLogging(selector: any) {
    return (a: any, b: any) => {
        if (a === b) {
            // Expected
            return true;
        }
        if (isDevEnv()) {
            const shal = shallow(a, b);
            let deep = false;
            try {
                deep = JSON.stringify(a) === JSON.stringify(b);
            } catch (err) {
                if ((err as Error).message.endsWith("Cannot convert undefined or null to object")) {
                    console.info(`FLUX-PERF: selecting bad values from state`, a, b, selector);
                    return false;
                } else {
                    throw err;
                }
            }
            if (shal && deep) {
                console.info(
                    `FLUX-PERF: selector values NOT equal by REF, BUT equal by SHALLOW and equal by DEEP`,
                    selector,
                );
            } else if (shal || deep) {
                console.info(
                    `FLUX-PERF: selector values NOT equal by REF, ${shal ? "BUT" : "NOT"} equal by SHALLOW, ${
                        deep ? "BUT" : "NOT"
                    } equal by DEEP`,
                    selector,
                    // Uncomment if you need to see the data
                    // a,
                    // b,
                );
            }
        }
        return false;
    };
}
