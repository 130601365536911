import type {IApplicationState} from "../../../../state";

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to memoize it
const selectRoles = (state: IApplicationState) => state.document?.roles;

const rolesSelectors = {
    selectRoles,
};

export default rolesSelectors;
