import ipaddr from "ipaddr.js";
import publicIp from "public-ip";

import {blockedIpv4Addresses} from "./BlockedIpAddresses";

export const IP_UNAVAILABLE_IDENTIFIER = "N/A";

function isIpInCIDR(ip: string, cidr: string) {
    const subnet = ipaddr.parseCIDR(cidr);
    const ipAddress = ipaddr.parse(ip);

    return ipAddress.match(subnet);
}

export const getIpAddress = async (): Promise<string> => {
    try {
        return await publicIp.v4({
            // 5000ms is the default, specifying it for clarity
            timeout: 5000,
        });
    } catch (err) {
        // If IP check fails, we don't want to keep retrying
        return IP_UNAVAILABLE_IDENTIFIER;
    }
};

export const isIpAddressBlocked = (ipAddress?: string) => {
    // in case we cannot determine IP
    if (!ipAddress || !ipaddr.isValid(ipAddress)) {
        return false;
    }

    const processedIp = ipaddr.parse(ipAddress);
    // we don't currently have any ipv6 blocked addresses, so no need to handle it yet
    if (processedIp.kind() !== "ipv4") {
        return false;
    }

    return (
        // cidr check is faster and most likely to return true
        blockedIpv4Addresses.cidrRanges.some((cidr) => isIpInCIDR(ipAddress, cidr)) ||
        blockedIpv4Addresses.ipAddresses.some((ip) => ip === ipAddress)
    );
};
