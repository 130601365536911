import {CLIPPER_INT_SPACE_CONVERSION, ClipperShapeFactory} from "@buildwithflux/core";
import {Matrix3Array} from "@buildwithflux/models";
import {clamp, isEqual} from "lodash";
import {Matrix3, Matrix4, Vector2} from "three";

import {CompoundShape, Shape} from "./types";

// prettier-ignore
export const toThreeMatrix3 = (m?: Matrix3Array | null) =>
    m ? new Matrix3().set(
        m[0], m[3], m[6],
        m[1], m[4], m[7],
        m[2], m[5], m[8]
) : new Matrix3().identity();

export function absVector2(v: Vector2): Vector2 {
    v.x = Math.abs(v.x);
    v.y = Math.abs(v.y);
    return v;
}

// NOTE: Those utilities are now included in THREE.js but not in the version we are using, consider removing when updating
export function matrix3MakeTranslation(m3: Matrix3, x: number, y: number) {
    m3.set(1, 0, x, 0, 1, y, 0, 0, 1);
    return m3;
}

export function matrix3MakeRotation(m3: Matrix3, theta: number) {
    // counterclockwise
    const c = Math.cos(theta);
    const s = Math.sin(theta);
    m3.set(c, -s, 0, s, c, 0, 0, 0, 1);
    return m3;
}

export function matrix3MakeScale(m3: Matrix3, x: number, y: number) {
    m3.set(x, 0, 0, 0, y, 0, 0, 0, 1);
    return m3;
}

/** Checks if the matrix is a simple affine transformation with 0,0,1 on the bottom row */
export function matrix3AffineSanityCheck(m3: Matrix3) {
    return m3.elements[2] === 0 && m3.elements[5] === 0 && m3.elements[8] === 1;
}

export function matrix3Decompose(m3: Matrix3, equalScalingThreshold = 0.001) {
    // https://stackoverflow.com/a/45392997
    const xyDot = m3.elements[0]! * m3.elements[3]! + m3.elements[1]! * m3.elements[4]!;
    const rotation = -Math.atan2(m3.elements[3]!, m3.elements[0]!);
    const translationX = m3.elements[6]!;
    const translationY = m3.elements[7]!;
    const scaleX = Math.sqrt(m3.elements[0]! ** 2 + m3.elements[1]! ** 2);
    const scaleY = Math.sqrt(m3.elements[3]! ** 2 + m3.elements[4]! ** 2);
    const scaleEquality = Math.abs(1 - scaleX / scaleY);
    const scaleIsEqual = scaleEquality <= equalScalingThreshold;
    return {rotation, xyDot, translationX, translationY, scaleX, scaleY, scaleEquality, scaleIsEqual};
}
export type DecomposedMatrix3 = ReturnType<typeof matrix3Decompose>;

export function matrix3To4(m3: Matrix3) {
    const m4 = new Matrix4().identity();
    m4.elements[0] = m3.elements[0]!;
    m4.elements[1] = m3.elements[1]!;
    m4.elements[2] = 0;
    m4.elements[3] = m3.elements[2]!;

    m4.elements[4] = m3.elements[3]!;
    m4.elements[5] = m3.elements[4]!;
    m4.elements[6] = 0;
    m4.elements[7] = m3.elements[5]!;

    m4.elements[8] = 0;
    m4.elements[9] = 0;
    m4.elements[10] = 1;
    m4.elements[11] = 0;

    m4.elements[12] = m3.elements[6]!;
    m4.elements[13] = m3.elements[7]!;
    m4.elements[14] = 0;
    m4.elements[15] = m3.elements[8]!;
    return m4;
}

/** Given a compound shape (union/difference), returns the inner shapes transformed with the compound matrix */
export function chainCompoundShapeTransforms(parentShape: CompoundShape): [Shape, Shape] {
    // TODO: Check if order is correct
    const newTransformA = parentShape.transform.clone().multiply(parentShape.shapeA.transform);
    const newTransformB = parentShape.transform.clone().multiply(parentShape.shapeB.transform);

    const newTransformAInverse = parentShape.shapeA.transformInverse.clone().multiply(parentShape.transformInverse);
    const newTransformBInverse = parentShape.shapeB.transformInverse.clone().multiply(parentShape.transformInverse);

    return [
        {
            ...parentShape.shapeA,

            // Role and layer of the children is ignored, the parent wins
            shapeRole: parentShape.shapeRole,
            layerId: parentShape.layerId,

            transform: newTransformA,
            transformInverse: newTransformAInverse,
        },
        {
            ...parentShape.shapeB,

            shapeRole: parentShape.shapeRole,
            layerId: parentShape.layerId,

            transform: newTransformB,
            transformInverse: newTransformBInverse,
        },
    ];
}

// https://stackoverflow.com/questions/6989100/sort-points-in-clockwise-order
function clockwiseCompare(a: Vector2, b: Vector2, center: Vector2) {
    if (a.x - center.x >= 0 && b.x - center.x < 0) return true;
    if (a.x - center.x < 0 && b.x - center.x >= 0) return false;
    if (a.x - center.x === 0 && b.x - center.x === 0) {
        if (a.y - center.y >= 0 || b.y - center.y >= 0) return a.y > b.y;
        return b.y > a.y;
    }

    // compute the cross product of vectors (center -> a) x (center -> b)
    const det = (a.x - center.x) * (b.y - center.y) - (b.x - center.x) * (a.y - center.y);
    if (det < 0) return true;
    if (det > 0) return false;

    // points a and b are on the same line from the center
    // check which point is closer to the center
    const d1 = (a.x - center.x) * (a.x - center.x) + (a.y - center.y) * (a.y - center.y);
    const d2 = (b.x - center.x) * (b.x - center.x) + (b.y - center.y) * (b.y - center.y);
    return d1 > d2;
}

export function sortPolyClockwise(points: Vector2[]) {
    const center = new Vector2(0, 0);
    points.forEach((p) => center.add(p));
    center.divideScalar(points.length);

    return points.sort((a, b) => (clockwiseCompare(a, b, center) ? 1 : -1));
}

function rotateArray<T>(arr: T[], n: number) {
    n = n % arr.length;
    return arr.slice(n, arr.length).concat(arr.slice(0, n));
}

// Quite inefficient, useful for testing
export function isPolygonSorted(points: Vector2[]) {
    const sorted = sortPolyClockwise([...points]);
    for (let i = 0; i < sorted.length; i++) {
        if (isEqual(points, rotateArray(sorted, i))) {
            return true;
        }
    }
    return false;
}

/**
 * Distance between a point and a polygon, also finds the closest point on the polygon
 * https://iquilezles.org/articles/distfunctions2d/
 * https://stackoverflow.com/a/9557244
 * Should work for non-convex as well
 */
export function polygonPointDistance(p: Vector2, v: Vector2[], outPoint = new Vector2()) {
    let minDist = p.clone().sub(v[0]!).dot(p.clone().sub(v[0]!));
    outPoint.copy(v[0]!);
    let sign = 1.0;

    for (let i = 0, j = v.length - 1; i < v.length; j = i, i++) {
        // distance
        const AB = v[j]!.clone().sub(v[i]!);
        const AP = p.clone().sub(v[i]!);
        const magAB = AB.dot(AB);
        const ABAPproduct = AP.dot(AB);
        const distance = ABAPproduct / magAB;
        const a = AB.clone().multiplyScalar(clamp(distance, 0.0, 1.0));
        const b = AP.clone().sub(a);

        const newDist = b.dot(b);
        const newPoint = v[i]!.clone().add(a);
        if (newDist < minDist) {
            minDist = newDist;
            outPoint.copy(newPoint);
        }

        // winding number from http://geomalgorithms.com/a03-_inclusion.htm
        const isInsidePoly =
            (p.y >= v[i]!.y && p.y < v[j]!.y && AB.x * AP.y > AB.y * AP.x) ||
            (!(p.y >= v[i]!.y) && !(p.y < v[j]!.y) && !(AB.x * AP.y > AB.y * AP.x));
        if (isInsidePoly) {
            sign *= -1.0;
        }
    }

    return sign * Math.sqrt(minDist);
}

type PolygonDistance = {
    minDist: number;
    pointA: Vector2;
    pointB: Vector2;
    minA: Vector2;
    minB: Vector2;
    maxA: Vector2;
    maxB: Vector2;
};

// NOTE: This expects a sorted convex polygon, the tests ensure that for the results of approxFittingPolygon
// NOTE: returns null for empty input polygon
export function polygonDistance(bcA: Vector2[], bcB: Vector2[]): PolygonDistance | null {
    // TODO: this could be optimized with binary search

    let minDist = Infinity;
    const allDists: number[] = [];
    const allPointsA: Vector2[] = [];
    const allPointsB: Vector2[] = [];

    // We need to evaluate it twice
    // 1. Points of polygon A vs segments of polygon B
    bcA.forEach((pa) => {
        const resultP = new Vector2();
        const dist = polygonPointDistance(pa, bcB, resultP);
        allDists.push(dist);
        allPointsA.push(pa);
        allPointsB.push(resultP);
        if (dist < minDist) {
            minDist = dist;
        }
    });

    // 2. Points of polygon B vs segments of polygon A
    bcB.forEach((pb) => {
        const resultP = new Vector2();
        const dist = polygonPointDistance(pb, bcA, resultP);
        allDists.push(dist);
        allPointsA.push(resultP);
        allPointsB.push(pb);
        if (dist < minDist) {
            minDist = dist;
        }
    });

    const minimalPointsA = allPointsA.filter((_p, i) => Math.abs(allDists[i]! - minDist) < 0.0000001);
    const minimalPointsB = allPointsB.filter((_p, i) => Math.abs(allDists[i]! - minDist) < 0.0000001);

    // NOTE: preventing the runtime error from an empty polygon input
    if (minimalPointsA.length === 0 || minimalPointsB.length === 0) return null;

    const minA = minimalPointsA[0]!.clone();
    const maxA = minimalPointsA[0]!.clone();
    const minB = minimalPointsB[0]!.clone();
    const maxB = minimalPointsB[0]!.clone();
    minimalPointsA.forEach((p) => {
        minA.min(p);
        maxA.max(p);
    });
    minimalPointsB.forEach((p) => {
        minB.min(p);
        maxB.max(p);
    });
    const pointA = minA.clone().add(maxA).divideScalar(2);
    const pointB = minB.clone().add(maxB).divideScalar(2);

    return {minDist, pointA, pointB, minA, minB, maxA, maxB};
}

export function resizePolygonByExpansion(
    polygon: Vector2[],
    expansion: number,
    clipperShapeFactory: ClipperShapeFactory,
    options?: {
        arcTolerance?: number;
        miterLimit?: number;
        joinType?: "Square" | "Miter" | "Round";
        endType?: "ClosedPolygon" | "ClosedLine" | "OpenButt" | "OpenSquare" | "OpenRound";
    },
): Vector2[] | undefined {
    const resizedShape = clipperShapeFactory
        .shape([polygon], true)
        .offset(expansion * CLIPPER_INT_SPACE_CONVERSION, options);

    const vectors = resizedShape.toPolygons();
    return vectors[0] ?? undefined;
}

export function booleanUnionPolygons(
    polygonA: Vector2[],
    polygonB: Vector2[],
    clipperShapeFactory: ClipperShapeFactory,
): Vector2[] | undefined {
    const shapeBClipper = clipperShapeFactory.shape([polygonB], true);
    const outputShape = clipperShapeFactory.shape([polygonA], true).union(shapeBClipper);

    const vectors = outputShape.toPolygons();
    return vectors[0] ?? undefined;
}

export function booleanDifferencePolygons(
    polygonA: Vector2[],
    polygonB: Vector2[],
    clipperShapeFactory: ClipperShapeFactory,
): Vector2[] | undefined {
    const shapeBClipper = clipperShapeFactory.shape([polygonB], true);
    const outputShape = clipperShapeFactory.shape([polygonA], true).difference(shapeBClipper);

    const vectors = outputShape.toPolygons();
    return vectors[0] ?? undefined;
}
