import RBush from "rbush";
import {Matrix3, Matrix4, Vector2} from "three";

export type PcbQuadTreeNode = ApproxBoundingBox & {
    nodeUid: string;
    shapes: Shape[];
    containerId: string;
};

export type ShapeContainerInfo = {
    transform: Matrix4;
    transformInverse: Matrix4;
    quadTree: RBush<PcbQuadTreeNode>;
};

export type ApproxBoundingBox = {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
};

export type ApproxBoundingCircle = {
    centerX: number;
    centerY: number;
    radius: number;
};

export type BaseShape =
    | CircleShape
    | OblongShape
    | RectangleShape
    | RoundedRectangleShape
    | LineShape
    | ApproxPolygonShape;

export type CompoundShape = UnionShape | DifferenceShape;
export type ModifierShape = ExpansionShape;
export type Shape = BaseShape | CompoundShape | ModifierShape;

export enum ShapeType {
    Circle,
    Oblong,
    Rectangle,
    RoundedRectangle,
    Line,
    Union,
    Difference,
    ApproxPolygon,
    Expansion,
}

export enum ShapeRole {
    silkScreen,
    metaData,
    copper,
    solderPaste,
    solderMaskOpening,
    layoutHole,
    zone,
    other,
}

export type ShapeCommon = {
    // NOTE: those properties are supposed to be copied down to the children, but to be safe consider as valid the outermost ones
    shapeRole: ShapeRole;
    layerId: string | null;

    /** Local transform matrix relative to the layout layer. */
    transform: Matrix3;
    /** Local inverse transform matrix relative to the layout layer */
    transformInverse: Matrix3;
};

// To play around with 2D transforms you can use this tool: https://web.ma.utexas.edu/users/ysulyma/matrix/

export type CircleShape = ShapeCommon & {
    shapeType: ShapeType.Circle;
    radius: number;
};

export type OblongShape = ShapeCommon & {
    shapeType: ShapeType.Oblong;
    /** NOTE: this is not a radius, it's the entire width/height of the thing */
    width: number;
    height: number;
};

export type RectangleShape = ShapeCommon & {
    shapeType: ShapeType.Rectangle;
    /** Relative to the center of the shape */
    width: number;
    height: number;
};

export type RoundedRectangleShape = ShapeCommon & {
    shapeType: ShapeType.RoundedRectangle;
    width: number;
    height: number;
    radiusTopLeft: number;
    radiusTopRight: number;
    radiusBottomLeft: number;
    radiusBottomRight: number;
};

/** Lines differ from rectangles because they have rounded caps (which extend outside the start/end point) */
export type LineShape = ShapeCommon & {
    shapeType: ShapeType.Line;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    thickness: number;
};

/** shapeA + shapeB */
export type UnionShape = ShapeCommon & {
    shapeType: ShapeType.Union;
    shapeA: Shape;
    shapeB: Shape;
};

/** shapeA - shapeB */
export type DifferenceShape = ShapeCommon & {
    shapeType: ShapeType.Difference;
    shapeA: Shape;
    shapeB: Shape;
};

// TODO: it would be better to keep shapes lossless but for now we are forced to make this approximation
/** A shape defined as a closed polygon and its points. This shape is approximated as it doesn't yet support curves but just line segments. */
export type ApproxPolygonShape = ShapeCommon & {
    shapeType: ShapeType.ApproxPolygon;
    points: Vector2[];
    convex: boolean;
};

/** Offsets the shape by a given value */
export type ExpansionShape = ShapeCommon & {
    shapeType: ShapeType.Expansion;
    shape: Shape;
    expansion: number;
};
