import intersects from "intersects";
import {makeCCW, quickDecomp} from "poly-decomp-es";
import {Vector2} from "three";

import {Shape, ShapeType} from "../types";
import {chainCompoundShapeTransforms, matrix3AffineSanityCheck, matrix3Decompose} from "../utils";

import {approxFittingPolygon} from "./approxFittingPolygon";
import {isConvex} from "./isConvex";
import {pointDistance} from "./pointDistance";
import {ClipperShapeFactory} from "@buildwithflux/core";

// TOOD: now difference shapes are supported by approxFittingPolygon, but they are still excluded here
// since it uses Clipper and it could be quite slow, evaluate if it's worth it to include them

function polygonsIntersectConvex(a: Vector2[], b: Vector2[]): boolean {
    const flatPointsA = a.flatMap((p) => [p.x, p.y]);
    const flatPointsB = b.flatMap((p) => [p.x, p.y]);
    return intersects.polygonPolygon(flatPointsA, flatPointsB);
}

function polygonsIntersectPartialConvex(a_convex: Vector2[], b: Vector2[]): boolean {
    const flatPointsA = a_convex.flatMap((p) => [p.x, p.y]);
    const pointsB = b.map<[number, number]>((p) => [p.x, p.y]);
    makeCCW(pointsB);
    const convexPolygonsB = quickDecomp(pointsB);
    return convexPolygonsB.some((polyB) =>
        intersects.polygonPolygon(
            flatPointsA,
            polyB.flatMap((p) => [p[0], p[1]]),
        ),
    );
}

// Much more expensive but necessary for non-convex shapes, evaluate later if too slow
function polygonsIntersectGeneral(a: Vector2[], b: Vector2[]): boolean {
    const pointsA = a.map<[number, number]>((p) => [p.x, p.y]);
    const pointsB = b.map<[number, number]>((p) => [p.x, p.y]);
    makeCCW(pointsA);
    makeCCW(pointsB);
    const convexPolygonsA = quickDecomp(pointsA);
    const convexPolygonsB = quickDecomp(pointsB);
    return convexPolygonsA.some((polyA) =>
        convexPolygonsB.some((polyB) =>
            intersects.polygonPolygon(
                polyA.flatMap((p) => [p[0], p[1]]),
                polyB.flatMap((p) => [p[0], p[1]]),
            ),
        ),
    );
}

// Wrapper for isOverlapping that approximates the result difference shapes, by considering only the outer shape
export function isOverlappingApprox(
    shapeA: Shape,
    shapeB: Shape,
    clipperShapeFactory: ClipperShapeFactory,
    polygonApproxSegments = 64,
): boolean {
    if (shapeA.shapeType === ShapeType.Difference) {
        // We discard the subtracted shape (approximation)
        const [transformedA, _transformedB] = chainCompoundShapeTransforms(shapeA);
        return isOverlappingApprox(transformedA, shapeB, clipperShapeFactory, polygonApproxSegments);
    }
    if (shapeB.shapeType === ShapeType.Difference) {
        // We discard the subtracted shape (approximation)
        const [transformedA, _transformedB] = chainCompoundShapeTransforms(shapeB);
        return isOverlappingApprox(transformedA, shapeA, clipperShapeFactory, polygonApproxSegments);
    }
    return isOverlapping(shapeA, shapeB, clipperShapeFactory, polygonApproxSegments);
}

// Doesn't yet support difference shapes!
export function isOverlapping(
    shapeA: Shape,
    shapeB: Shape,
    clipperShapeFactory: ClipperShapeFactory,
    polygonApproxSegments = 64,
): boolean {
    if (shapeA.shapeType === ShapeType.Difference || shapeB.shapeType === ShapeType.Difference)
        throw new Error("Difference shapes are not yet supported in isOverlapping");

    // Recurses over compound shapes
    if (shapeA.shapeType === ShapeType.Union) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeA);
        const resultA = isOverlapping(transformedA, shapeB, clipperShapeFactory, polygonApproxSegments);
        const resultB = isOverlapping(transformedB, shapeB, clipperShapeFactory, polygonApproxSegments);
        return resultA || resultB;
    }
    if (shapeB.shapeType === ShapeType.Union) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeB);
        const resultA = isOverlapping(transformedA, shapeA, clipperShapeFactory, polygonApproxSegments);
        const resultB = isOverlapping(transformedB, shapeA, clipperShapeFactory, polygonApproxSegments);
        return resultA || resultB;
    }

    const transformDecomposedA = matrix3Decompose(shapeA.transform);
    const transformDecomposedB = matrix3Decompose(shapeB.transform);
    const matrixIsSimpleA =
        matrix3AffineSanityCheck(shapeA.transform) &&
        transformDecomposedA.scaleIsEqual &&
        transformDecomposedA.xyDot === 0;
    const matrixIsSimpleB =
        matrix3AffineSanityCheck(shapeB.transform) &&
        transformDecomposedB.scaleIsEqual &&
        transformDecomposedB.xyDot === 0;

    // Easier case: equal scaling circle
    if (shapeA.shapeType === ShapeType.Circle && matrixIsSimpleA) {
        const distance = pointDistance(
            shapeB,
            transformDecomposedA.translationX,
            transformDecomposedA.translationY,
            new Vector2(),
            clipperShapeFactory,
        );
        const radius = shapeA.radius * transformDecomposedA.scaleX;
        return distance <= radius;
    } else if (shapeB.shapeType === ShapeType.Circle && matrixIsSimpleB) {
        const distance = pointDistance(
            shapeA,
            transformDecomposedB.translationX,
            transformDecomposedB.translationY,
            new Vector2(),
            clipperShapeFactory,
        );
        const radius = shapeB.radius * transformDecomposedB.scaleX;
        return distance <= radius;
    }

    // Worst case: convert to polygons
    const polyA = approxFittingPolygon(shapeA, polygonApproxSegments, clipperShapeFactory);
    const polyB = approxFittingPolygon(shapeB, polygonApproxSegments, clipperShapeFactory);

    if (isConvex(shapeA) && isConvex(shapeB)) {
        return polygonsIntersectConvex(polyA, polyB);
    } else if (isConvex(shapeA)) {
        return polygonsIntersectPartialConvex(polyA, polyB);
    } else if (isConvex(shapeB)) {
        return polygonsIntersectPartialConvex(polyB, polyA);
    } else {
        return polygonsIntersectGeneral(polyA, polyB);
    }
}
