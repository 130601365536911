import {
    Command,
    StaticPartVersionDescriptor,
    PcbNodesCommand,
    AddElementCommand,
    ConnectPinsCommand,
    SetElementPropertyCommand,
    BaseDocumentCommand,
    CommandType,
    EnrichedAddPcbNodesCommand,
    RemoveElementCommand,
    DisconnectPinsCommand,
    IElementData,
    IPartVersionData,
} from "@buildwithflux/models";

import type {FluxServices} from "../../injection/container";

type EnrichedAddElementCommand = {
    type: AddElementCommand["type"];
    element: IElementData;
};

/**
 * Enrich an add element command by adding the element data to it.
 */
async function enrichAddElementOp(op: AddElementCommand, services: FluxServices): Promise<EnrichedAddElementCommand> {
    const partData = await services.partLibrary.get(op.partDescriptor);
    const currentUser = services.currentUserService.getCurrentUser();
    // TODO: clean these errors up
    if (!currentUser) {
        throw new Error("Could not find current user");
    }
    if (!partData) {
        throw new Error(`Could not find part ${op.partDescriptor}`);
    }
    return {
        type: op.type,
        element: {
            uid: op.elementUid,
            owner_uid: currentUser.uid,
            created_at: Date.now(),
            updated_at: Date.now(),
            part_uid: partData.part_uid,
            part_version: partData.version,
            part_version_data_cache: partData,
            diagram_position: {x: 0, y: 0},
            properties: {},
        },
    };
}

/**
 * Enrich a connect pins command by adding portal elements to it.
 */
export type EnrichedConnectPinsCommand = ConnectPinsCommand & {
    /**
     * A "companion" portal for the connected pins to use.
     */
    portal: IPartVersionData;
};

async function enrichConnectPinsOp(
    op: ConnectPinsCommand,
    services: FluxServices,
): Promise<EnrichedConnectPinsCommand> {
    /**
     * This is the net portal that we use for achieving these connections (for now).
     */
    const portalDescriptor: StaticPartVersionDescriptor = {
        partUid: "8324674a-2826-0848-1f78-bd4b4ee0741b",
        partVersion: "f070ac72-4988-478e-a626-f0b79292db6d",
    };
    const portalData = await services.partLibrary.get(portalDescriptor);
    if (!portalData) {
        throw new Error(`Could not find part ${portalDescriptor}`);
    }
    return {
        ...op,
        portal: portalData,
    };
}

export type EnrichedCommand =
    | EnrichedAddElementCommand
    | EnrichedConnectPinsCommand
    | SetElementPropertyCommand
    | BaseDocumentCommand
    | PcbNodesCommand
    | RemoveElementCommand
    | DisconnectPinsCommand
    | EnrichedAddPcbNodesCommand;

/**
 * Enriching an command takes a regular old command as input and then "enriches" it by adding data that is derived by performing
 * i/o.  For example, adding an element to the document requires us to look up the part data for the element that we're adding.
 *
 * For some commands, they are equal to their enrichment.  For example, disconnecting pins does not require any enrichment, or setting
 * property values.
 */
async function enrichCommand(command: Command, services: FluxServices): Promise<EnrichedCommand> {
    switch (command.type) {
        case CommandType.addElementCommand:
            return enrichAddElementOp(command, services);
        case CommandType.connectPinsCommand:
            return enrichConnectPinsOp(command, services);
        case CommandType.setElementPropertyCommand:
            return command; // Does not need enrichment
        default:
            return command; // Does not need enrichment
    }
}

/**
 * "Enrich" an command by adding additional information to it such that it is ready to be applied to the document.
 *
 * @remarks This is necessary for commands because we keep them as small as possible until it's time to apply them to the document.  They maintain
 * only the bare minimum concerns necessary to describe the change to the document.
 */
export async function enrichCommands(commands: Command[], services: FluxServices): Promise<EnrichedCommand[]> {
    const results: EnrichedCommand[] = [];
    for (const command of commands) {
        results.push(await enrichCommand(command, services));
    }
    return results;
}
