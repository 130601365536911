import {IVector2} from "@buildwithflux/models";
import {Vector2} from "three";

export function roundFloat(n: number, p: number) {
    const precision = Math.pow(10, p);
    return Math.round(n * precision) / precision;
}

/** Given a number n, round it to the nearest number representable in the
 * floating-point precision bucket one size larger than the minimum, so
 * that operations with round decimals stay representable as round
 * decimals, while preserving all (or most) significant digits irrespective
 * of the magnitude of the number.
 *
 * So that eg
 * 0.1 + 0.2 === 0.3
 * 10^11+0.1 + 10^11+0.2 === 2x10^11+0.3
 * 92.1+0.1 == 92.2
 *
 * NOTE: This function is very slow due to the `toPrecision` round trip which (among other
 * things) converts the number to a string and back. Also, the purpose of this function is often to
 * compare floats reliably across different magnitudes, and a more common (and probably better, more
 * well-documented) way to compare floats is with a relative tolerance. In many cases, you probably
 * want to use compareFloats() instead!
 */
export function roundFloatError(n: number) {
    return Number(n.toPrecision(15));
}

export function roundVectorFloat(vector: Vector2, p: number) {
    return new Vector2(roundFloat(vector.x, p), roundFloat(vector.y, p));
}

/** NOTE: See comment about float comparison above `roundFloatError`  */
export function roundVectorFloatError(vector: Vector2) {
    return new Vector2(roundFloatError(vector.x), roundFloatError(vector.y));
}

export function roundVec2Float(vector: IVector2, p: number) {
    return {x: roundFloat(vector.x, p), y: roundFloat(vector.y, p)};
}

/** NOTE: See comment about float comparison above `roundFloatError`  */
export function roundVec2FloatError(vector: IVector2) {
    return {x: roundFloatError(vector.x), y: roundFloatError(vector.y)};
}

type FloatCompareOptions = {relTolerance?: number; absTolerance?: number};

/** Compare two floats meaningfully at different magnitudes. Specify relTolerance to compare within
 * a certain number of significant digits (eg the default relTolerance of 1e-9 will compare two
 * numbers to ~9 significant digits). If you also need
 *
 * Note that if you specify an absTolerance of exactly 0, then this function will never compare a
 * number as "close" to zero, no matter how small, because the relTolerance is always smaller than
 * the absolute difference.
 *
 * If you specify absTolerance > relTolerance, then relTolerance is effectively ignored.
 *
 * Read Python's explanation of these parameters for a good understanding of what these parameters
 * do and of the concepts in general:
 * https://peps.python.org/pep-0485/
 *
 * Inspired heavily by the Python sample implementation of math.isclose():
 * https://github.com/PythonCHB/close_pep/blob/master/is_close.py
 */
export function compareFloats(a: number, b: number, opts: FloatCompareOptions = {}) {
    const relTolerance = opts.relTolerance ?? 1e-9;
    const absTolerance = opts.absTolerance ?? 1e-12;
    const diff = Math.abs(a - b);

    // Always apply relative tolerance to the largest of a and b, so this function returns the same
    // result regardless of parameter order. Your code can have weird behaviour if the same numbers
    // give different results depending on eg codepath!
    // See the Python docs linked above for some more detail of approaches here.
    return diff <= Math.max(a, b) * relTolerance || diff <= absTolerance;
}

/** Compare two IVector2s for float-closeness. */
export function compareVec2(a: IVector2, b: IVector2, opts: FloatCompareOptions = {}) {
    return compareFloats(a.x, b.x, opts) && compareFloats(a.y, b.y, opts);
}
