import {ElementHelper, IUserData} from "@buildwithflux/core";
import {IDocumentData, IPartVersionData} from "@buildwithflux/models";
import {Vector2} from "three";

import {WireProjectionSmart} from "../schematic_editor/wiring/WireProjectionSmart";
import Wiring, {WireProjectionVertex} from "../schematic_editor/wiring/Wiring";

import {ConnectionToWire} from "./models";
import {autoWirePointToPoint} from "./point_to_point";
import {portalAutoWireStrategy} from "./two_terminal_portal";

type CostBasedAutoWireStrategyArgs = {
    /**
     * The portal data to use when connecting the terminals.
     */
    portalPartVersion: IPartVersionData;
    /**
     * The list of all connections to be made.
     */
    connectionsToWire: ConnectionToWire[];
    /**
     * Distance that should be created between the element terminals and the new portal terminals.
     */
    elementToPortalDistance: number;
    /**
     * Cost to place a portal.  This is in "units" of wire length
     */
    portalStrategyCost: number;
};

/**
 * This function is a little awkward - we have to get the total length but JS is annoying about the possibility of unchecked index
 * access.
 */
function totalWireLength(projectedWire: WireProjectionVertex[]): number {
    const result = projectedWire.reduce(
        (acc, currentVertex) => {
            if (acc.previousVertex) {
                return {
                    totalLength: acc.totalLength + acc.previousVertex.position.distanceTo(currentVertex.position),
                    previousVertex: currentVertex,
                };
            }
            return {
                totalLength: 0,
                previousVertex: currentVertex,
            };
        },
        {totalLength: 0} as {totalLength: number; previousVertex?: WireProjectionVertex},
    );

    return result.totalLength;
}

/**
 * This module defines an auto-wiring strategy that will connect an arbitrary number of terminals together by considering an overall "configuration cost".
 *
 * NOTE: THIS FUNCTION WILL MUTATE THE DOCUMENT DATA.
 */
export function costBasedAutoWireStrategy(
    document: IDocumentData,
    currentUser: Readonly<IUserData>,
    args: CostBasedAutoWireStrategyArgs,
) {
    const pointToPointWireConnections: ConnectionToWire[] = [];
    for (const connection of args.connectionsToWire) {
        const sourceElement = document.elements[connection.start.elementUid];
        if (!sourceElement) return undefined;
        const targetElement = document.elements[connection.end.elementUid];
        if (!targetElement) return undefined;
        const sourceTerminal = sourceElement.part_version_data_cache.terminals[connection.start.terminalUid];
        if (!sourceTerminal) return undefined;
        const targetTerminal = targetElement.part_version_data_cache.terminals[connection.end.terminalUid];
        if (!targetTerminal) return undefined;
        const sourceTerminalPosition =
            ElementHelper.getAbsoluteTerminalPlacements(sourceElement)[connection.start.terminalUid];
        if (!sourceTerminalPosition) return undefined;
        const targetTerminalPosition =
            ElementHelper.getAbsoluteTerminalPlacements(targetElement)[connection.end.terminalUid];
        if (!targetTerminalPosition) return undefined;
        const routeMgr = new Wiring(document.routeVertices, document.elements);
        const getElementData = (elementUid: string) => document.elements[elementUid];
        const wiringAlgo = new WireProjectionSmart(routeMgr, getElementData);
        const projectedWire = wiringAlgo.project(
            {position: new Vector2(sourceTerminalPosition.position.x, sourceTerminalPosition.position.y)},
            {position: new Vector2(targetTerminalPosition.position.x, targetTerminalPosition.position.y)},
        );
        const wireCost = totalWireLength(projectedWire);
        if (wireCost <= args.portalStrategyCost) {
            pointToPointWireConnections.push(connection);
        } else {
            portalAutoWireStrategy(document, currentUser, {
                portalPartVersion: args.portalPartVersion,
                startPin: connection.start,
                endPin: connection.end,
                elementToPortalDistance: args.elementToPortalDistance,
            });
        }
    }
    autoWirePointToPoint(document, {connectionsToWire: pointToPointWireConnections});
}
