import {MissingArgumentReducerError} from "@buildwithflux/core";
import {IActionRecordsMap} from "@buildwithflux/models";
import {createReducer} from "@reduxjs/toolkit";
import {cloneDeep, pickBy} from "lodash";

import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";

import {
    addChangeHistoryRecords,
    clearChangeHistory,
    setChangeHistoryListItems,
    setCurrentChangeId,
    setLatestSnapshot,
    setRequestedChangeId,
} from "./actions";
import {IChangeHistoryData} from "./index.types";

function findTopAndBottomActionRecordByDateAndIncrementCount(
    actionRecords: IActionRecordsMap,
    changeHistory: IChangeHistoryData,
) {
    const actionRecordCacheMetadata = changeHistory.changeHistoryCacheMetadata;
    // Cheap cloneDeep since it's only 5 scalars
    const result = cloneDeep(actionRecordCacheMetadata);

    for (const record of Object.entries(actionRecords)) {
        if (record[1].actionTimestamp > result.topActionRecordTimestamp) {
            result.topActionRecordTimestamp = record[1].actionTimestamp;
            result.topActionRecordUid = record[1].actionRecordUid;
            result.topActionRecordName = record[1].actionName;
        }
        if (record[1].actionTimestamp < result.bottomActionRecordTimestamp) {
            result.bottomActionRecordTimestamp = record[1].actionTimestamp;
            result.bottomActionRecordUid = record[1].actionRecordUid;
        }
        if (!changeHistory.changeHistoryEntries[record[0]]) {
            ++result.cachedEntriesCount;
        }
    }
    return result;
}

const changeHistoryInitialState: IChangeHistoryData = {
    countOfTotalEntries: 0,
    documentUid: "",
    changeHistoryCacheMetadata: {
        bottomActionRecordUid: "",
        bottomActionRecordTimestamp: Number.MAX_SAFE_INTEGER,
        topActionRecordUid: "",
        topActionRecordName: "",
        topActionRecordTimestamp: 0,
        cachedEntriesCount: 0,
    },
    changeHistoryEntries: {},
    changeHistoryListItems: [],
    currentChangeId: "",
    requestedChangeId: "",
    latestSnapshot: null,
};

const changeHistoryReducer = createReducer(changeHistoryInitialState, (builder) => {
    builder
        .addCase(addChangeHistoryRecords, (draftState, action) => {
            const {actionRecords} = action.payload;

            if (!Object.keys(actionRecords).length) {
                FluxLogger.captureError(
                    new MissingArgumentReducerError(
                        action.type,
                        "Object.keys(action.actionRecords).length",
                        addChangeHistoryRecords,
                    ),
                );
                return draftState;
            }
            // NOTE: key line to prevent permission actions like setRole from
            // being in change history.
            const filtered = pickBy(actionRecords, (record) => record.actionUpdatesDocumentVersion);

            draftState.changeHistoryCacheMetadata = findTopAndBottomActionRecordByDateAndIncrementCount(
                filtered,
                draftState,
            );
            draftState.changeHistoryEntries = {...draftState.changeHistoryEntries, ...filtered};
        })
        .addCase(setLatestSnapshot, (draftState, action) => {
            const {latestSnapshot} = action.payload;

            draftState.latestSnapshot = latestSnapshot;
        })
        .addCase(setChangeHistoryListItems, (draftState, action) => {
            const {items} = action.payload;

            draftState.changeHistoryListItems = items;
        })
        .addCase(setCurrentChangeId, (draftState, action) => {
            const {currentChangeId} = action.payload;

            draftState.currentChangeId = currentChangeId;

            if (currentChangeId === "") {
                draftState.latestSnapshot = null;
            }
        })
        .addCase(setRequestedChangeId, (draftState, action) => {
            const {requestedChangeId} = action.payload;

            // NOTE: see useChangeHistory.ts for useLayoutEffect that is
            // triggered by requestedChangeId
            draftState.requestedChangeId = requestedChangeId;

            if (requestedChangeId === draftState.changeHistoryCacheMetadata.topActionRecordUid) {
                draftState.latestSnapshot = null;
            }
        })
        .addCase(clearChangeHistory, (_draftState, _action) => {
            return changeHistoryInitialState;
        });
});

export default changeHistoryReducer;
