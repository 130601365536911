import type {IApplicationState} from "../../state";

const selectHasCommentingPermission = (state: IApplicationState) => state.auth.currentUserHasCommentingPermission;
const selectHasEditPermission = (state: IApplicationState) => state.auth.currentUserHasEditPermission;

// TODO: Move to store?
const authSelectors = {
    selectHasCommentingPermission,
    selectHasEditPermission,
};

export default authSelectors;
