import {hasSomeLayoutRuleConfig, RuleKey} from "@buildwithflux/models";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const removeNodeRules = createActionRecordAction(
    "removeNodeRules",
    (nodeId: string, ruleKeys: RuleKey[]) => {
        const hasInheritedRules = hasSomeLayoutRuleConfig(ruleKeys, "allChildNodesWillInheritThisRule");
        const hasInheritedCalculations = hasSomeLayoutRuleConfig(ruleKeys, "affectsDescendantCalculations");
        const requiresDescendantRebake = hasSomeLayoutRuleConfig(ruleKeys, "requiresDescendantRebake");

        return {
            payload: {
                nodeId,
                ruleKeys,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,

                hasInheritedRules,
                hasInheritedCalculations,
                requiresDescendantRebake,
            },
        };
    },
    "remove object-specific pcb layout rules",
);
