// We need to refactor those out to avoid circular imports

export enum AppStatus {
    noDocumentData,
    loadingDocument,
    documentReady,
}

export enum DocumentStatusStates {
    none,
    loaded,
    loading,
    syncing,
    synced,
    error,
    syncNeeded,
}
