import {maxBy} from "lodash";
import {Matrix3, Vector2} from "three";

import {
    ApproxBoundingCircle,
    ApproxPolygonShape,
    BaseShape,
    CircleShape,
    LineShape,
    OblongShape,
    RectangleShape,
    RoundedRectangleShape,
    ShapeType,
} from "../types";
import {DecomposedMatrix3} from "../utils";

/** No shear supported! Please check if it's present beforehand */
export function approxBoundingCircle(shape: BaseShape, transformDecomposed: DecomposedMatrix3): ApproxBoundingCircle {
    switch (shape.shapeType) {
        case ShapeType.Circle:
            return circleBoundingCircle(shape, transformDecomposed);
        case ShapeType.Oblong:
            return oblongBoundingCircle(shape, transformDecomposed);
        case ShapeType.Rectangle:
            return rectangleBoundingCircle(shape, transformDecomposed);
        case ShapeType.RoundedRectangle:
            return roundedRectangleBoundingCircle(shape, transformDecomposed);
        case ShapeType.Line:
            return lineBoundingCircle(shape, transformDecomposed);
        case ShapeType.ApproxPolygon:
            return approxPolygonBoundingCircle(shape, transformDecomposed);
    }
}

function circleBoundingCircle(shape: CircleShape, transformDecomposed: DecomposedMatrix3): ApproxBoundingCircle {
    return transformBoundingCircle(
        {radius: shape.radius, centerX: 0, centerY: 0},
        shape.transform,
        transformDecomposed,
    );
}

function oblongBoundingCircle(shape: OblongShape, transformDecomposed: DecomposedMatrix3): ApproxBoundingCircle {
    return transformBoundingCircle(
        {radius: Math.max(shape.width / 2, shape.height / 2), centerX: 0, centerY: 0},
        shape.transform,
        transformDecomposed,
    );
}

function rectangleBoundingCircle(shape: RectangleShape, transformDecomposed: DecomposedMatrix3): ApproxBoundingCircle {
    const radius = Math.sqrt((shape.width / 2) ** 2 + (shape.height / 2) ** 2);
    return transformBoundingCircle({radius, centerX: 0, centerY: 0}, shape.transform, transformDecomposed);
}

function roundedRectangleBoundingCircle(
    shape: RoundedRectangleShape,
    transformDecomposed: DecomposedMatrix3,
): ApproxBoundingCircle {
    const rectRadius = Math.sqrt((shape.width / 2) ** 2 + (shape.height / 2) ** 2);
    const smallestRadius = Math.min(
        shape.radiusBottomLeft,
        shape.radiusBottomRight,
        shape.radiusTopLeft,
        shape.radiusTopRight,
    );
    const radiusRectDiff = Math.sqrt(smallestRadius ** 2 * 2) - smallestRadius;
    const radius = rectRadius - radiusRectDiff;
    return transformBoundingCircle({radius, centerX: 0, centerY: 0}, shape.transform, transformDecomposed);
}

function lineBoundingCircle(shape: LineShape, transformDecomposed: DecomposedMatrix3): ApproxBoundingCircle {
    const minPointX = Math.min(shape.startX, shape.endX);
    const minPointY = Math.min(shape.startY, shape.endY);
    const maxPointX = Math.max(shape.startX, shape.endX);
    const maxPointY = Math.max(shape.startY, shape.endY);
    const centerX = (minPointX + maxPointX) / 2;
    const centerY = (minPointY + maxPointY) / 2;
    const radius = Math.sqrt((maxPointX - centerX) ** 2 + (maxPointY - centerY) ** 2) + shape.thickness / 2;
    return transformBoundingCircle({radius, centerX, centerY}, shape.transform, transformDecomposed);
}

function approxPolygonBoundingCircle(
    shape: ApproxPolygonShape,
    transformDecomposed: DecomposedMatrix3,
): ApproxBoundingCircle {
    if (shape.points.length === 0) {
        // Is this the correct behavior?
        return transformBoundingCircle({radius: 0, centerX: 0, centerY: 0}, shape.transform, transformDecomposed);
    }

    const centerPoint = shape.points[0]!.clone();
    shape.points.forEach((p) => {
        centerPoint.add(p);
    });
    centerPoint.divideScalar(shape.points.length);
    const radius = Math.sqrt(
        maxBy(shape.points, (p) => p.distanceToSquared(centerPoint))!.distanceToSquared(centerPoint),
    );

    return transformBoundingCircle({radius, centerX: 0, centerY: 0}, shape.transform, transformDecomposed);
}

const tempVector = new Vector2();
function transformBoundingCircle(
    origCircle: ApproxBoundingCircle,
    transformMat: Matrix3,
    // The same as transformMat but decomposed
    transformMatDecomposed: DecomposedMatrix3,
): ApproxBoundingCircle {
    tempVector.set(origCircle.centerX, origCircle.centerY).applyMatrix3(transformMat);
    const centerX = tempVector.x;
    const centerY = tempVector.y;

    // APPROX: To avoid the ellipse situation, uses the biggest one of the two scales (to remain a circle)
    const radius = origCircle.radius * Math.max(transformMatDecomposed.scaleX, transformMatDecomposed.scaleY);

    // Shear is ignored!

    return {centerX, centerY, radius};
}

export function boundingCircleDistance(bcA: ApproxBoundingCircle, bcB: ApproxBoundingCircle) {
    return Math.sqrt((bcA.centerX - bcB.centerX) ** 2 + (bcA.centerY - bcB.centerY) ** 2) - bcA.radius - bcB.radius;
}
