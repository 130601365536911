import {AnyPcbBakedNode, CameraMode, IPcbBoardLayerCopper, PcbNodesMap, PcbNodeTypes} from "@buildwithflux/core";
import {IVector2, IVector3} from "@buildwithflux/models";
import {Group, Vector2, Vector3} from "three";

import {R3FStateEvents} from "../../r3f_events";

export enum LayerViewState {
    opened = "open",
    closed = "close",
}

export type ControlsState = {
    offset: IVector3;
    position: IVector3;
    target: IVector3;
    zoom: number;
};
type InteractionType =
    | "DEFAULT"
    | "DRAGGING"
    | "PANNING"
    | "MEASURING"
    // It's possible to pan while routing using middle click or space-drag.
    // So you end up in a state in which you pause routing to pan, and routing mode has to be restored after pan is finished.
    | "PANNING_PAUSEDROUTING"
    | "PANNING_PAUSEDMEASURING"
    | "ROUTING"
    | "DISABLED";

export type Interaction =
    | "START_DRAGGING"
    | "START_PANNING"
    | "START_MEASURING"
    | "END_INTERACTION"
    | "DISABLE"
    | "ABORT_INTERACTION";

export type PlaceholderSegments = {
    layer: IPcbBoardLayerCopper;
    uid: string;
    start: Vector3;
    end: Vector3;
}[];

export type PcbEditorUiTransientState = {
    interactionAborted: boolean;
    interactionState: {state: InteractionType};
    routingState: RoutingState | undefined;
    multiRoutingState: MultiRoutingState;
    multiRoutingCache: MultiRoutingCache | undefined;
    multiRoutingHover: boolean;
    disableMultiRouting: boolean;
    threeDSceneContentGroupMesh: Group | null;
    projectedHumanizedTraceWidth: string | undefined;
    selectedHumanizedTraceWidth: string | undefined;
    activeTouchpointContext: ActiveTouchpointContext | null;
    measuringStartPoint: Vector2;
    pcbEditorR3FStore: R3FStateEvents | null;
    previewSegments: PreviewSegment[];
    placeholderSegments: PlaceholderSegments | undefined;
    // Cache to store selected via configurations for specified connected layers and via options for smart vias
    smartViaConfigsCache: Record<string, string[]>;
};
export type PcbEditorUiPersistentState = {
    cameraMode: CameraMode;
    flippedCamera: boolean;
    layerViewState: LayerViewState;
    pcbEnabledSnapping: boolean;
    pcbEnabledMeasurements: boolean;
    pcbThreeDModelsVisibility: boolean;
};
export type RoutingState = {
    netId: string;
    position: IVector2;
    /** @deprecated This field is not reliable anymore, do not use to determine what is connected */
    lastVertexId: string;
    lastNodeId: string;
    layer: IPcbBoardLayerCopper;
    flip: boolean;
    freeRoutingMode: boolean;
    snapToSegment: SnapToSegment | null;
    routeFromSegment: SnapToSegment | null;
    snappedVertexUid: string | null;
    snappedPos: IVector3 | null;
};
export type SnapToSegment = {
    start: IVector2;
    end: IVector2;
    /** @deprecated This field is not reliable anymore, do not use to determine what is connected */
    startId: string;
    /** @deprecated This field is not reliable anymore, do not use to determine what is connected */
    endId: string;
    id: string;
};
export type ActiveTouchpointContext = {
    uid: string;
    parentUid: string;
    nodeType: PcbNodeTypes;
    position: IVector2;
    netId?: string;
    routeFromSegment?: SnapToSegment;
    edge?: "start" | "end" | "midpoint";
};

export type MultiRoutingCache = {
    /** For each net, is that net connected or does it have airwires? */
    netConnected?: Record<string, boolean>;
    /** For each group, all the absolute vertex positions (traces+pads+vias) that are routable to with multi-routing */
    vertexInfo: Record<string, MultiRoutingCacheItem[]>;
    multiRoutingGroups: MultiRoutingGroup[];
    multiRoutingGroupsPerNet: Record<string, MultiRoutingGroup[]>;
};
export type MultiRoutingCacheItem = {
    nodeUid: string;
    position: Vector2;
    netUid: string;
    type: PcbNodeTypes;
    notRoutable?: boolean;
    edge?: "start" | "end";
    connectedSegmentUid?: string;
};
/** A group of nets that can be traced together */
export type MultiRoutingGroup = {
    groupUid: string;
    netUids: string[];
    startingNodesUid: {[nodeUid: string]: string[]};
};

export type MultiRoutingState =
    | undefined
    | {
          state: "MULTIROUTING" | "MULTIROUTING_PAUSED";
          groupUid: string;
          targetDistance: number;
      };

export type PreviewSegment = {
    layoutUid: string;
    layer: string;
    traceWidth: number;
    startPos: Vector3;
    endPos: Vector3;
};

export type PcbEditorUiState = PcbEditorUiTransientState & PcbEditorUiPersistentState;

export type PcbEditorUiActions = {
    deleteFoundPlaceholderSegments: (allBakedNodes: PcbNodesMap<AnyPcbBakedNode>) => void;
    interact: (interaction: Interaction) => void;
    resetAbort: () => void;
    setActiveTouchpointContext: (activeTouchpointContext: ActiveTouchpointContext | null) => void;
    setCameraMode: (mode: CameraMode) => void;
    setDisableMultiRouting: (disableMultiRouting: boolean) => void;
    setFlippedCamera: (flip: boolean) => void;
    setHumanizedProjectedTraceWidth: (humanizedWidth: string) => void;
    setHumanizedSelectedTraceWidth: (humanizedWidth: string | undefined) => void;
    setLayerViewState: (state: LayerViewState) => void;
    setMultiRoutingCache: (multiRoutingCache: MultiRoutingCache) => void;
    setMultiRoutingHover: (multiRoutingHover: boolean) => void;
    setMultiRoutingState: (multiRoutingState: MultiRoutingState) => void;
    setPcbEditorR3FStore: (pcbEditorR3FStore: R3FStateEvents | null) => void;
    setPcbEnabledSnapping: (pcbEnabledSnapping: boolean) => void;
    setPlaceholderSegments: (placeholderSegments: PlaceholderSegments | undefined) => void;
    setPcbEnabledMeasurements: (pcbEnabledMeasurements: boolean) => void;
    setPcbThreeDModelsVisibility: (pcbThreeDModelsVisibility: boolean) => void;
    setPreviewSegments: (previewSegments: PreviewSegment[]) => void;
    setRoutingSnap: (snappedVertexUid: string | null, snappedPos: IVector3 | null) => void;
    setRoutingState: (
        position: IVector2,
        lastVertexId?: string,
        lastNodeId?: string,
        netId?: string,
        layer?: IPcbBoardLayerCopper,
        flip?: boolean,
        freeRoutingMode?: boolean,
        snapToSegment?: SnapToSegment | null,
        routeFromSegment?: SnapToSegment | null,
    ) => void;
    setThreeDSceneContentGroupMesh: (group: Group | null) => void;
    toggleMultiRouting: () => void;
    // Cache to store selected via configurations for specified connected layers and via options for smart vias
    // Computed in the workers after each bake (if needed) and stored in the store to be used in runtime
    // Use the `SmartViaConfigsCache` type to interact with this cache
    setSmartViaConfigsCache: (smartViaConfigsCache: Record<string, string[]>) => void;
};
