import {EditorModes, IElementData} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";
import {AppThunkAction} from "../../../../../store";
import {IVector2} from "@buildwithflux/models";

type AddElementsParam = {
    elements: IElementData[];
    /**
     * `dropPosition` is needed for remote change, in order to add the element
     * at the exact same position in a remote session
     */
    dropPosition: IVector2 | undefined;
    /**
     * Whether or not to select the new elements after we finish adding it
     */
    selectElements?: boolean;
};

export const addElements = createActionRecordAction(
    "addElements",
    (params: AddElementsParam, editorMode: EditorModes) => {
        const {elements, dropPosition, selectElements} = params;
        return {
            payload: {
                elements,
                selectElements,
                dropPosition,
                inPcbEditor: editorMode === EditorModes.pcb,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added elements",
);

export function addElementsThunk(params: AddElementsParam): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        const {editorMode} = services.usePersistedDocumentUiStore.getState();
        dispatch(addElements(params, editorMode));
    };
}
