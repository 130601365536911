import UserCodeRuntime from "./UserCodeRuntime.worker";

export class UserCodeRuntimeWrapper {
    private runtime: Promise<UserCodeRuntime> | undefined;

    public async init(): Promise<UserCodeRuntime> {
        if (!this.runtime) {
            this.runtime = new UserCodeRuntime() as unknown as Promise<UserCodeRuntime>;
            await (await this.runtime).initialize();
        }
        return await this.runtime;
    }

    public async dispose() {
        if (this.runtime !== undefined) {
            await (await this.runtime).dispose();
            this.runtime = undefined;
        }
    }
}
