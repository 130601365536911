import {
    AnyPcbBakedNode,
    BakedRulesFor,
    FootPrintPadHoleType,
    INetHashMap,
    LayerOrientation,
    PcbBakedNode,
    PcbBoardLayer,
    PcbBoardLayerMaterials,
    PcbBoardLayerType,
    PcbNodeTypes,
    PcbViaType,
    colors,
    getNodesOfType,
    getTopLevelLayouts,
    getTopLevelPcbLayoutNodeUids,
} from "@buildwithflux/core";
import {isCopperMaterial, isCopperLayer} from "@buildwithflux/models";
import createCachedSelector from "re-reselect";
import {useMemo} from "react";
import {shallowEqual} from "react-redux";
import {createSelector} from "reselect";

import type {IApplicationState} from "../../../../state";

export function getLayerColor(
    pcbLayoutNodeLayers: PcbBoardLayer[],
    // TODO: feels like this should be a predicate function specified by caller
    // to determine the criteria for layer selection, rather than mixing types
    // to this extent
    nodeLayer: LayerOrientation | string | undefined,
    material: PcbBoardLayerMaterials | undefined,
    type: PcbBoardLayerType | undefined,
) {
    // TODO: don't want to perform a search for multiple layers of every pad - this should at least be memo'd
    const layer = pcbLayoutNodeLayers.find(
        (layer) =>
            layer.uid === nodeLayer ||
            layer.name === nodeLayer ||
            (layer.orientation === nodeLayer &&
                ((material && layer.material === material) ||
                    (material === "Copper" && isCopperLayer(layer)) ||
                    (type && layer.type === type))),
    );

    if (layer?.color) {
        return layer.color;
    } else {
        if (nodeLayer === LayerOrientation.top) {
            if (material && isCopperMaterial(material)) {
                return colors.canvas.pcb.layout.stackupLayerColors.topCopper;
            }
        } else if (nodeLayer === LayerOrientation.bottom) {
            if (material && isCopperMaterial(material)) {
                return colors.canvas.pcb.layout.stackupLayerColors.bottomCopper;
            }
        }
    }
}

export function isThroughHole(node: AnyPcbBakedNode) {
    return (
        (node.type === PcbNodeTypes.pad &&
            node?.bakedRules?.hole?.holeType === FootPrintPadHoleType.platedThroughHole) ||
        (node.type === PcbNodeTypes.via && node?.bakedRules?.viaType === PcbViaType.throughHole)
    );
}

// NOTE: needs to be used with shallow comparison for proper memoization
export function getShapeFields(
    // TODO: Change this to `CommonBakedRules`
    bakedRules: BakedRulesFor<PcbNodeTypes>,
    topLevelLayoutBakedRules: BakedRulesFor<PcbNodeTypes.layout> | undefined,
    topLevelFootprintBakedRules: BakedRulesFor<PcbNodeTypes.footprint> | undefined,
) {
    const layer = bakedRules?.layer;

    let layerColor;

    if (topLevelLayoutBakedRules) {
        const pcbLayoutNodeLayers = Object.values(topLevelLayoutBakedRules?.stackup || {});

        layerColor = getLayerColor(pcbLayoutNodeLayers, layer, undefined, "Overlay");
    } else if (topLevelFootprintBakedRules) {
        const pcbLayoutNodeLayers = Object.values(topLevelFootprintBakedRules?.stackup || {});

        layerColor = getLayerColor(pcbLayoutNodeLayers, layer, undefined, "Overlay");
    } else {
        layerColor = getLayerColor([], layer, undefined, "Overlay");
    }

    return layerColor;
}

// NOTE: needs to be used with shallow comparison for proper memoization
const selectTopLevelPcbLayoutNodeUids = (state: IApplicationState) => {
    const pcbLayoutNodes = state.document?.pcbLayoutNodes;
    return pcbLayoutNodes ? getTopLevelPcbLayoutNodeUids(pcbLayoutNodes) : [];
};

/**
 * @deprecated Do not use this selector, it's very bad for performance.
 * QUESTION: why is this selecting from IApplicationState?
 */
const selectPcbLayoutNodes = (state: IApplicationState) => state.document?.pcbLayoutNodes;

// TODO: Convert this to use the new `usePcbNode` hook
// NOTE: needs to be used with shallow comparison for proper memoization
// QUESTION: how does this make any sense? it is cached always after the first run???
const usePcbAllRootLayoutNodes = () => {
    return useMemo(
        () => (state: IApplicationState) => {
            return getTopLevelLayouts(state.document?.pcbLayoutNodes || {});
        },
        [],
    );
};

/**
 * Caches output based on pcbLayoutNodes.
 */
export const selectNetMap = createSelector(
    [(state: IApplicationState) => state.document?.pcbLayoutNodes || {}],
    (pcbLayoutNodes): INetHashMap => {
        const allNetNodes = getNodesOfType(pcbLayoutNodes, PcbNodeTypes.net);
        const netIdToTerminalsMap: INetHashMap = {};
        Object.values(allNetNodes).forEach((node) => (netIdToTerminalsMap[node.uid] = node.terminals || {}));
        return netIdToTerminalsMap;
    },
);

/**
 * Caches output based on pcbLayoutNodes.
 */
const selectTerminalToNetIdMap = createSelector(
    [(state: IApplicationState) => state.document?.pcbLayoutNodes || {}],
    (pcbLayoutNodes) => {
        const allNetNodes = Object.values(pcbLayoutNodes).filter(
            (node) => node.type === PcbNodeTypes.net,
        ) as PcbBakedNode<PcbNodeTypes.net>[];
        const terminalToNetIdMap: Record<string, string> = {};
        Object.values(allNetNodes).forEach((node) =>
            Object.keys(node.terminals || {}).forEach((terminalId) => (terminalToNetIdMap[terminalId] = node.uid)),
        );
        return terminalToNetIdMap;
    },
    {
        memoizeOptions: {
            resultEqualityCheck: shallowEqual, // because new object is created in output
        },
    },
);

/**
 * Takes cached output of selectTerminalToNetIdMap and returns the net that
 * contains the pad uid.
 */
const selectContainingNetId = createCachedSelector(
    [selectTerminalToNetIdMap, (_state: IApplicationState, uid: string) => uid],
    (map, uid) => map[uid],
)((_map, uid) => uid);

const pcbLayoutNodesSelectors = {
    selectContainingNetId,
    selectPcbLayoutNodes,
    selectNetMap,
    selectTopLevelPcbLayoutNodeUids,
    usePcbAllRootLayoutNodes,
};

export default pcbLayoutNodesSelectors;
