import {OrganizationUid, UserHandle, isHandleMappingForOrganization} from "@buildwithflux/core";
import {OrganizationPublicHandle, Sentinel} from "@buildwithflux/models";
import {useEffect, useState} from "react";

import {useFluxServices} from "../../injection/hooks";
import {useCancelableEffect} from "../common/hooks/useCancelableEffect";

/**
 * @todo Won't work with non-public organizations or enterprises, because they don't have public handle mappings
 */
const usePossibleOrgUid = (handle: UserHandle | OrganizationPublicHandle): OrganizationUid | null => {
    const {handleRepository} = useFluxServices();

    const [orgUid, setOrgUid] = useState<OrganizationUid | null>(null);

    useEffect(() => {
        handleRepository.getByHandle(handle).then((handleMapping) => {
            if (handleMapping && isHandleMappingForOrganization(handleMapping)) {
                setOrgUid(handleMapping.organizationUid);
            }
        });
    }, [handle, handleRepository]);

    return orgUid;
};

/** @todo wrap firestore call in proper API */
function useUserProfileSentinel(handle: UserHandle): Sentinel | null {
    const {firestoreAdapter, logger} = useFluxServices();

    const [sentinel, setSentinel] = useState<Sentinel | null>(null);

    useCancelableEffect(
        (status) => {
            if (status.mounted) {
                const unsub = firestoreAdapter
                    .userSentinelsCollection(handle)
                    .doc("project-update")
                    .onSnapshot(
                        (snapshot) => {
                            const data = snapshot.data();
                            if (status.mounted && data) setSentinel(data);
                        },
                        (error) => {
                            logger.error(`Error fetching org sentinel: ${error.message}`, error);
                        },
                    );
                return unsub;
            }
        },
        [firestoreAdapter, handle, logger],
    );

    return sentinel;
}

/** @todo wrap firestore call in proper API */
function useOrgProfileSentinel(possibleOrgUid: OrganizationUid | null): Sentinel | null {
    const {firestoreAdapter, logger} = useFluxServices();

    const [sentinel, setSentinel] = useState<Sentinel | null>(null);

    useCancelableEffect(
        (status) => {
            if (status.mounted && possibleOrgUid) {
                const unsub = firestoreAdapter
                    .organizationSentinelsCollection(possibleOrgUid)
                    .doc("project-update")
                    .onSnapshot(
                        (snapshot) => {
                            const data = snapshot.data();
                            if (status.mounted && data) setSentinel(data);
                        },
                        (error) => {
                            logger.error(`Error fetching org sentinel: ${error.message}`, error);
                        },
                    );
                return unsub;
            }
        },
        [firestoreAdapter, logger, possibleOrgUid],
    );

    return sentinel;
}

/**
 * @remarks This sentinel tracks profile-page-related data. It updates anytime the profile page
 * should be re-rendered (e.g. a document changed).
 */
export function useProfileSentinel(handle: UserHandle | OrganizationPublicHandle): Sentinel | null {
    const possibleOrgUid = usePossibleOrgUid(handle);
    const isOrgProfile = !!possibleOrgUid;

    const userSentinel = useUserProfileSentinel(handle);
    const orgSentinel = useOrgProfileSentinel(possibleOrgUid);

    return isOrgProfile ? orgSentinel : userSentinel;
}
