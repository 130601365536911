import {IPartUpdateMetadata, IUserMap, MissingArgumentReducerError, PartUpdatingStatus} from "@buildwithflux/core";
import {IPartData} from "@buildwithflux/models";
import {createReducer} from "@reduxjs/toolkit";

import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";

import * as Actions from "./actions";

export interface IPartReducer {
    partData?: IPartData;
    partUpdateMetadata: IPartUpdateMetadata[];
    outOfDateParts: Record<string, IPartUpdateMetadata>;
    partOwners: IUserMap;
    partUpdatingMap: {[key: string]: PartUpdatingStatus};
}

const partInitialState: IPartReducer = {
    partUpdateMetadata: [],
    partOwners: {},
    partUpdatingMap: {},
    outOfDateParts: {},
};

const partReducer = createReducer(partInitialState, (builder) => {
    builder
        .addCase(Actions.setPartData, (draftState, action) => {
            const {partData} = action.payload;

            if (!partData) {
                FluxLogger.captureError(new MissingArgumentReducerError(action.type, "partData", action));

                return draftState;
            }

            draftState.partData = partData;
        })
        .addCase(Actions.clearPartData, (draftState) => {
            draftState.partData = undefined;
        })
        .addCase(Actions.acknowledgePartUpdate, (draftState, action) => {
            const {partUids} = action.payload;

            for (const partUid of partUids) {
                const outOfDatePart = draftState.outOfDateParts[partUid];
                // NOTE: outOfDatePart might not exist if it is updated to latest
                if (outOfDatePart) {
                    outOfDatePart.acknowledged = true;
                }
            }
        })
        .addCase(Actions.setPartsAreOutOfDate, (draftState, action) => {
            const {parts} = action.payload;

            for (const part of parts) {
                draftState.outOfDateParts[part.part_uid] = part;
            }
        })
        .addCase(Actions.setPartIsUpToDate, (draftState, action) => {
            const partUid = action.payload;
            delete draftState.outOfDateParts[partUid];
        })
        .addCase(Actions.setPartsAreUpToDate, (draftState, action) => {
            const partUids = action.payload;
            partUids.forEach((partUid) => delete draftState.outOfDateParts[partUid]);
        })
        .addCase(Actions.setPartUpdating, (draftState, action) => {
            const {partUid, updating} = action.payload;

            draftState.partUpdatingMap[partUid] = updating;
        })
        .addCase(Actions.setPartOwners, (draftState, action) => {
            const {owners} = action.payload;

            draftState.partOwners = owners;
        });
});

export default partReducer;
