import {DependencyList, useEffect} from "react";

export interface IEffect {
    mounted: boolean;
}

type TCancelableEffectHook = (effect: (status: IEffect) => (() => void) | void, deps?: DependencyList) => void;

export const useCancelableEffect: TCancelableEffectHook = (effect, deps) => {
    const status = {mounted: true};
    useEffect(() => {
        status.mounted = true;

        const unsubscribe = effect(status);
        return () => {
            status.mounted = false;
            if (unsubscribe && typeof unsubscribe === "function") {
                unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
