import {createReducer} from "@reduxjs/toolkit";

import * as Actions from "./actions";

export interface IAuthState {
    currentUserHasCommentingPermission: boolean;
    currentUserHasEditPermission: boolean;
}

const authInitialState: IAuthState = {
    currentUserHasCommentingPermission: false,
    currentUserHasEditPermission: false,
};

const authReducer = createReducer(authInitialState, (builder) => {
    builder
        .addCase(Actions.setCurrentUserHasCommentingPermission, (draftState, action) => {
            const {hasCommentingPermission} = action.payload;

            draftState.currentUserHasCommentingPermission = hasCommentingPermission;
        })
        .addCase(Actions.setCurrentUserHasEditPermission, (draftState, action) => {
            const {hasEditPermission} = action.payload;

            draftState.currentUserHasEditPermission = hasEditPermission;
        });
});

export default authReducer;
