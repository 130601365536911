import {IDocumentData} from "@buildwithflux/models";

/**
 * This function ensures the integrity of route vertices by forcing them to be either connected to a
 * terminal on a real element that actually exists, or being connected to nothing at all.
 */
function ensureRouteVertexState(document: IDocumentData): void {
    for (const vertex of Object.values(document.routeVertices)) {
        if (vertex.terminal) {
            const el = document.elements[vertex.terminal.element_uid];
            if (!el) {
                vertex.terminal = null;
            }
        }
    }
}

export default ensureRouteVertexState;
