/* eslint-disable import/no-unused-modules */
import type {IEnvSwitcherEnvironmentConfiguration, IEnvSwitcherUserAccess} from "@buildwithflux/core";
import {getEnvironmentName} from "@buildwithflux/shared";

import {useFeatureFlags} from "../../../modules/common/hooks/featureFlags/useFeatureFlag";
import {AnalyticsStorage} from "../AnalyticsStorage";

export class EnvironmentSwitcher {
    private currentEnvironment: string | undefined;
    private isEnabled = false;

    constructor(private readonly analyticsStorage: AnalyticsStorage) {
        this.currentEnvironment = getEnvironmentName() === "production" ? "prod" : getEnvironmentName();
    }

    public initializeEnvironment() {
        this.isEnabled = this.isSwitcherEnabled();
    }

    public switchEnvironment(environment: string) {
        const toConfig = this.getEnvironmentConfig(environment);
        if (!toConfig || !window?.location) {
            return;
        }

        const {hostname, name} = toConfig;
        const protocol = name === "local" ? "http" : "https";
        const url = `${protocol}://${hostname}${window.location.pathname}${window.location.search}`;

        this.analyticsStorage.logEvent("account_menu", {
            action: "switch_environment",
            from_url: window.location.href,
            from_name: this.currentEnvironment,
            from_hostname: window.location.hostname,
            to_url: url,
            to_name: name,
            to_hostname: hostname,
        });

        window.location.assign(url);
    }

    public isSwitcherEnabled(): boolean {
        return this.isSwitcherEnabledInEnv() && this.canUserSwitchEnvironments();
    }

    public getUsersAllowedEnvironments() {
        const {access} = this.getEnvironmentDetails();
        return access;
    }

    public getEnvironmentConfig(environment: string) {
        const globalConfig = this.getEnvironmentSwitcherConfigFeatureFlag();
        const envConfig = globalConfig?.environments.find((env) => env.name === environment);

        return envConfig;
    }

    public getCurrentEnvironmentName() {
        return this.currentEnvironment;
    }

    private getUserEnvironmentAccessFeatureFlag(): IEnvSwitcherUserAccess | undefined {
        return useFeatureFlags.getState().splitTestingAccess;
    }

    private getEnvironmentSwitcherConfigFeatureFlag(): IEnvSwitcherEnvironmentConfiguration | undefined {
        return useFeatureFlags.getState().splitTestingConfig;
    }

    private isSupportedHostname(hostname?: string) {
        const supportedHostnames =
            this.getEnvironmentSwitcherConfigFeatureFlag()
                // just in case an empty hostname is added to the config or cannot get feature flag (e.g., ad blockers)
                ?.environments.filter((env) => env?.hostname)
                .map((env) => env.hostname) || [];
        return supportedHostnames.some((supportedHostname) => supportedHostname === hostname);
    }

    private isSupportedEnvironmentName(environmentName?: string) {
        return (
            // enable for localhost
            environmentName === "local" ||
            this.getEnvironmentSwitcherConfigFeatureFlag()
                // just in case an empty env name is added to the config
                ?.environments.filter((env) => env?.name)
                .some((env) => env.name === environmentName) ||
            // sometimes cannot get feature flag (e.g., ad blockers), so default to false
            false
        );
    }

    private isSwitcherEnabledInEnv() {
        return (
            this.isSupportedEnvironmentName(this.currentEnvironment) ||
            this.isSupportedHostname(window?.location?.hostname)
        );
    }

    private canUserSwitchEnvironments() {
        const {access} = this.getEnvironmentDetails();
        const canAccessMoreThanOneEnv = (access?.allowed?.length || 0) > 1;
        return canAccessMoreThanOneEnv;
    }

    private getEnvironmentDetails() {
        const userAccess = this.getUserEnvironmentAccessFeatureFlag();
        const envConfig = this.currentEnvironment ? this.getEnvironmentConfig(this.currentEnvironment) : undefined;

        return {
            access: userAccess,
            envConfig,
        };
    }
}
