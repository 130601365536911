import {MultipleQueriesOptions, MultipleQueriesQuery, MultipleQueriesResponse} from "@algolia/client-search";
import type {Request, Requester, Response} from "@algolia/requester-common";
import {RequestOptions} from "@algolia/transporter";
import {IUserData} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {AlgoliaAuthContext, CurrentUser, PartLibrarySearchFilters, SiteSearchFilters} from "@buildwithflux/models";
import {
    algoliaConfig,
    areWeTestingWithJest,
    guid,
    isProductionEnvironment,
    Logger,
    Unsubscriber,
} from "@buildwithflux/shared";
import algoliaSearch, {SearchClient} from "algoliasearch/lite";
import {cloneDeep} from "lodash";

import {CurrentUserService} from "../../auth";
import {useAlgoliaSearchClient} from "../../auth/state/algoliaClient";

/**
 * This class is responsible for managing the Algolia search client and its
 * configuration. It encapsulates the current Algolia auth context and is
 * responsible for fetching new public keys from the backend as needed.
 */
export default class AlgoliaConnector {
    public static getAppConfig() {
        return {
            partsSearchPath: algoliaConfig.parts.primaryIndexName,
            documentsSearchPath: algoliaConfig.projects.primaryIndexName,
            documentsSearchStarPath: algoliaConfig.projectsByStars.primaryIndexName,
            usersSearchPath: algoliaConfig.users.primaryIndexName,
            organizationsSearchPath: algoliaConfig.organizations.primaryIndexName,
            clientApiId: process.env.REACT_APP_ALGOLIA_APP_ID || "",
            clientApiKey: process.env.REACT_APP_ALGOLIA_API_KEY || "",
        };
    }

    protected searchClient: SearchClient | undefined;
    protected authContext: AlgoliaAuthContext = {};
    protected _publicKey: string | undefined;

    protected readonly currentUserService: CurrentUserService;
    protected readonly functions: FunctionsAdapter;

    /**
     * The zustand store defined in frontend/src/modules/auth/state/algoliaClient.ts subscribes to this
     * service to allow the search client to be used reactively.
     *
     * Use this hook to ensure that the search client you pass to an
     * InstantSearch component is always up-to-date with the latest public key.
     */
    public readonly useSearchClient: () => AlgoliaConnector["searchClient"];
    public readonly useEmptyNoopSearchClient: () => AlgoliaConnector["searchClient"];
    protected readonly subscriptionHandles: Record<string, SearchClientChangeHandler> = {};
    protected readonly userChangeUnsub: Unsubscriber | undefined = undefined;

    constructor(
        functionsAdapter: FunctionsAdapter,
        currentUserService: CurrentUserService,
        private readonly logger: Logger,
    ) {
        this.functions = functionsAdapter;
        this.currentUserService = currentUserService;
        this.userChangeUnsub = this.currentUserService.subscribeToUserChanges(this.onCurrentUserChanged);
        this.useSearchClient = useAlgoliaSearchClient;

        this.useEmptyNoopSearchClient = () => {
            const baseClient = this.useSearchClient();
            if (!baseClient) return undefined;
            return {
                ...baseClient,
                search: <TObject>(
                    queries: ReadonlyArray<MultipleQueriesQuery>,
                    requestOptions?: RequestOptions & MultipleQueriesOptions,
                ): Readonly<Promise<MultipleQueriesResponse<TObject>>> =>
                    queries.every((query) => !query.params?.query)
                        ? Promise.resolve({
                              results: queries.map(() => ({
                                  hits: [],
                                  nbHits: 0,
                                  nbPages: 0,
                                  page: 0,
                                  processingTimeMS: 0,
                                  hitsPerPage: 0,
                                  exhaustiveNbHits: false,
                                  query: "",
                                  params: "",
                              })),
                          } as MultipleQueriesResponse<TObject>)
                        : baseClient.search(queries, requestOptions),
            };
        };
    }

    /**
     * Get the current Algolia configuration.
     */
    public get config(): ReturnType<typeof AlgoliaConnector.getAppConfig> {
        return AlgoliaConnector.getAppConfig();
    }

    /**
     * Get the current Algolia public key.
     */
    public get publicKey(): string | undefined {
        return this._publicKey;
    }

    /**
     * These filter functions can be used to avoid tight coupling to the
     * attributes defined in Algolia indexes.
     */
    public get filters() {
        return {
            siteSearch: SiteSearchFilters,
            partLibrarySearch: PartLibrarySearchFilters,
        };
    }

    /**
     * Set a new Algolia public key. This will cause the search client to be
     * re-initialized with the given key.
     */
    public set publicKey(value: string | undefined) {
        if (this._publicKey === value) {
            return;
        }

        this._publicKey = value;

        // NOTE: It'd be nice if we could just update the existing search client with a new API key.
        // There's a similar request in https://github.com/algolia/algoliasearch-client-javascript/issues/1307
        if (this.publicKey) {
            this.searchClient = this.makeSearchClient();
        } else {
            this.searchClient = undefined;
        }

        this.notifySearchClientListeners();
    }

    /**
     * Get the current Algolia search client. Avoid using this in React
     * components, as the search client may be reinitialized any time there is
     * a change to the current user or current auth context.
     */
    public getCurrentSearchClient(): AlgoliaConnector["searchClient"] {
        return this.searchClient;
    }

    /**
     * Used by the zustand store defined in frontend/src/modules/auth/state/algoliaClient.ts
     */
    public subscribeToSearchClientChanges(callback: SearchClientChangeHandler): Unsubscriber {
        const subscriberId = guid();
        this.subscriptionHandles[subscriberId] = callback;
        return () => {
            this.unsubscribeFromSearchClientChanges(subscriberId);
        };
    }

    public unsubscribeFromSearchClientChanges(subscriberId: string): void {
        delete this.subscriptionHandles[subscriberId];
    }

    public notifySearchClientListeners(): void {
        Object.values(this.subscriptionHandles).forEach((callback) => callback(this.searchClient));
    }

    /**
     * Clear the current search client's cache.
     */
    public clearCache() {
        if (this.searchClient) {
            this.searchClient.clearCache();
        }
    }

    public shutdown(): void {
        this.clearCache();
        if (this.userChangeUnsub) this.userChangeUnsub();
    }

    /**
     * Set the current Algolia auth context. This will cause a new public
     * key to be minted and a new search client to be initialized if necessary.
     */
    public async setAuthContext(authContext: AlgoliaAuthContext) {
        this.authContext = cloneDeep(authContext);

        // NOTE: even if the auth context is still {} (i.e. unchanged), rerun
        // the key generation to account for user change.
        // QUESTION: why?
        await this.updatePublicKeyForUser(this.currentUserService.getCurrentUser());
    }

    // TODO: move functionality into search models
    public filterTestData(and: boolean) {
        if (isProductionEnvironment()) {
            if (and) {
                return `AND test_data:false`;
            }
            return `test_data:false`;
        }
        return "";
    }

    protected makeSearchClient(): SearchClient {
        if (!this.publicKey) {
            throw new Error("Algolia public key not set");
        }
        return algoliaSearch(this.config.clientApiId, this.publicKey);
    }

    private onCurrentUserChanged = (currentUser: CurrentUser) => {
        void this.updatePublicKeyForUser(currentUser.user);
    };

    private async updatePublicKeyForUser(user: IUserData | undefined): Promise<void> {
        // HACK: This is bad and wrong.  However, something is going wrong in our unit tests where this is getting
        // called when it's not supposed to be and causing a firebase/deadline-exceeded error.  I'm taking a shortcut
        // for now because that error is uncaught and extremely difficult to track down... and it's actually harmless.
        // With launch looming, this is the tradeoff I'm making.
        if (areWeTestingWithJest()) {
            return;
        }

        this.logger.debug("Updating Algolia public key for user", {user, authContext: this.authContext});
        const result = await this.functions.algoliaAuth(this.authContext);
        const {publicKey} = result.data;

        /*
         * The user may change _during_ a call to the algoliaAuth backend function
         * That means two requests for an algolia public key may be in-flight at once, and they may finish out of order
         * This approach discards any public keys that were for previously-set users
         */
        if (this.currentUserService.getCurrentUser()?.uid === user?.uid) {
            this.publicKey = publicKey;
        }
    }
}

type SearchClientChangeHandler = (searchClient: ReturnType<typeof algoliaSearch> | undefined) => void;

function mockAlgoliaRequester(): Requester {
    return {
        send: (_request: Request): Readonly<Promise<Response>> => {
            return Promise.resolve({
                content: JSON.stringify({
                    results: [
                        {
                            hits: [],
                            nbHits: 0,
                            page: 0,
                            nbPages: 0,
                            hitsPerPage: 0,
                            exhaustiveNbHits: false,
                            query: "",
                            params: "",
                            processingTimeMS: 0,
                        },
                    ],
                }),
                status: 200,
                isTimedOut: false,
            });
        },
    };
}

export class MockAlgoliaConnector extends AlgoliaConnector {
    protected override makeSearchClient(): SearchClient {
        if (!this.publicKey) {
            throw new Error("Algolia public key not set");
        }

        return algoliaSearch(this.config.clientApiId, this.publicKey, {
            requester: mockAlgoliaRequester(),
        });
    }
}
