import {EditorModes, filterNotNull} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

export class AirwiresValidator implements IDrcValidator {
    problemTypeKey = "airwires";
    problemLabel = "Airwires";
    problemDescription =
        "Reports terminals that are not connected properly. NOTE: Only connections to the center of pads are considered valid.";

    checkForProblems(inputs: IDrcInputs) {
        // Our netMap gives us the info of the groups that should be connected together,
        // we use this info to overlap it with the connected components from the graph.
        const netWithProblems = Object.entries(inputs.pcbConnectivityGraph.getNetToConnectedComponents())
            // If we found a net that is part of multiple connected components, then it's disconnected!
            .filter((a) => a[1].length > 1);

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: netWithProblems.map((np) => ({
                problemTypeKey: this.problemTypeKey,
                key: `${this.problemTypeKey}_${np[0]}_${np[1].join("+")}`,
                affectedItems: filterNotNull(
                    np[1].map((a) => (a?.[0] ? {type: "pcbLayoutNode" as const, uid: a[0]} : null)),
                ),
                affectedViews: [EditorModes.pcb],
            })),
        };
    }
}
