import {Command, DocumentChange} from "@buildwithflux/core";
import {AbstractChangeCaptureUnitOfWork, FluxListener, NotifyListenersWithChangesFn} from "@buildwithflux/solder-core";

/**
 * A task here is to send a DocumentChange to a group of listeners
 */
type ChangeCaptureTask = {
    change: DocumentChange;

    listeners: FluxListener[];
};

/**
 * A unit of work that capture and handle all changes from document.
 *
 * When any change happens, we want to propagate it to different places that need to
 * take actions in response to the change. For example, UI need to be re-rendered, database need
 * to be updated, etc.
 *
 * This unit of work does the job by keeping track of the changes and send them to different
 * modules via corresponding listeners.
 *
 * It is important to note that this is the only job of this class, which means this class
 * knows nothing about where the change comes from and how the change will be handled. Its just
 * propagating/forwarding the changes, and one can think about this propagation/forwarding as a transaction
 *
 * It handles the following "transaction" when any change happens
 * - Notify all the local subscribers and send the change to them. These local subscribers are
 *    usually modules that need to respond to changes. Examples are: UI, DRC, PcbSceneGraph, etc.
 */
class ChangeCaptureUnitOfWork implements AbstractChangeCaptureUnitOfWork {
    private tasks: ChangeCaptureTask[];
    constructor(public readonly command?: Command) {
        this.tasks = [];
    }

    /**
     * Adds a change to this Unit of work. All changes added here will be committed
     * later. Commit usually means we batch all changes and notify remote listener
     * in one shot
     *
     * Please note that this function does NOT know anything about the change, the listeners,
     * nor how the listeners are notified. All its job is to keep track of the `change` so that
     * we can commit them altogether later
     *
     * @param {DocumentChange} change - the change that just happened, this is usually a result of
     * and determined by an {@link Command} in DocumentService
     * @param listeners - an array of listeners we want to notify when commit
     */
    public addChange(change: DocumentChange, listeners: FluxListener[]) {
        // Keep track of this `change`, so that we can commit all of them later
        this.tasks.push({change, listeners});
    }

    /**
     * When commit, we batch all changes and send them to modules that need to respond to them
     */
    public commit(notifyChanges: NotifyListenersWithChangesFn) {
        this.tasks.forEach((task) => {
            notifyChanges(task.change, task.listeners);
        });
    }
}

export const changeCaptureUnitOfWorkCreator = (command?: Command) => {
    return new ChangeCaptureUnitOfWork(command);
};
