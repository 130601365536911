import {createReducer} from "@reduxjs/toolkit";

import * as Actions from "./actions";
import {IDialogProps, IDialogState} from "./types";

const dialogsInitialState: IDialogState = {
    openedDialogs: [],
};

export const dialogsReducer = createReducer(dialogsInitialState, (builder) => {
    builder
        .addCase(Actions.openDialog, (draftState, action) => {
            const {type, props = {}} = action.payload;
            if (draftState.openedDialogs.some((dialog) => dialog.type === type)) return;
            draftState.openedDialogs.push({
                type,
                props,
            });
        })
        .addCase(Actions.modifyDialog, (draftState, action) => {
            const {type, props} = action.payload;
            const dialog = draftState.openedDialogs.find((dialog) => dialog.type === type);
            if (dialog) {
                dialog.props = props as IDialogProps<typeof type>; // Type enforced by action creator
            }
        })
        .addCase(Actions.closeDialog, (draftState, action) => {
            const {type} = action.payload;
            // This is assuming we will close modal one by one - cannot close a modal not on top
            if (draftState.openedDialogs.length > 0) {
                if (draftState.openedDialogs[draftState.openedDialogs.length - 1]!.type === type) {
                    draftState.openedDialogs.pop();
                }
            }
        })
        .addCase(Actions.closeAllDialogs, (draftState) => {
            if (draftState.openedDialogs.length > 0) {
                draftState.openedDialogs = [];
            }
        });
});
