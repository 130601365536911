import type {GetObjectRequest, PutObjectRequest, S3Client} from "@aws-sdk/client-s3";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";

import {btoaSafe} from "../../helpers/btoa";
import {is2gConnection, isSafeDataMode, isSlow2gConnection} from "../../helpers/connection";
import {preferredImageFormat} from "../../helpers/preferredImageFormat";

import {AnalyticsStorage} from "./AnalyticsStorage";
import {TrackingEvents} from "./common/TrackingEvents";
import {FileStorage, FileStorageBody, ImageFit, ImageSizes} from "./FileStorage.types";

const FileStorageConfigKeys = ["bucket", "region", "threeDCdnUrl", "imgCdnUrl", "cdnUrl"] as const;

type FileStorageConfig = Record<(typeof FileStorageConfigKeys)[number], string>;

enum RequestTypes {
    DEFAULT = "Default",
    CUSTOM = "Custom",
    THUMBOR = "Thumbor",
}

export enum ImageFormatTypes {
    JPG = "jpg",
    JPEG = "jpeg",
    PNG = "png",
    WEBP = "webp",
    TIFF = "tiff",
    HEIF = "heif",
    HEIC = "heic",
    RAW = "raw",
    GIF = "gif",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Headers = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ImageEdits = Record<string, any>;

interface ImageRequestInfo {
    requestType?: RequestTypes;
    bucket: string;
    key: string;
    edits?: ImageEdits;
    originalImage?: Buffer;
    headers?: Headers;
    contentType?: string;
    expires?: string;
    lastModified?: string;
    cacheControl?: string;
    outputFormat?: "jpg" | "jpeg" | "png" | "webp" | "tiff" | "heif" | "heic" | "raw" | "gif";
    effort?: number;
}

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_AWS_S3_CDN_CLOUDFRONT_URL?: string;
            REACT_APP_AWS_S3_3D_CDN_CLOUDFRONT_URL?: string;
            REACT_APP_AWS_S3_IMG_CDN_CLOUDFRONT_URL?: string;
            REACT_APP_AWS_S3_SYMBOLS_REGION?: string;
            REACT_APP_AWS_S3_SYMBOLS_BUCKET?: string;
        }
    }
}

/**
 * This function has to use the strings `process.env.REACT_APP_AWS_S3_CDN_CLOUDFRONT_URL` etc., exactly as they are,
 * otherwise the webpack bundler may not correctly replace them
 */
function getDefaultFileStorageConfig(): Partial<FileStorageConfig> {
    return {
        cdnUrl: process.env.REACT_APP_AWS_S3_CDN_CLOUDFRONT_URL || "https://cdn.flux.ai",
        imgCdnUrl: process.env.REACT_APP_AWS_S3_IMG_CDN_CLOUDFRONT_URL || "https://img-cdn.flux.ai",
        threeDCdnUrl: process.env.REACT_APP_AWS_S3_3D_CDN_CLOUDFRONT_URL || "https://assetpipeline.flux.ai",
        region: process.env.REACT_APP_AWS_S3_SYMBOLS_REGION || "us-west-1",
        bucket: process.env.REACT_APP_AWS_S3_SYMBOLS_BUCKET || "graviton-electric-symbols",
    };
}

/**
 * Validates that the attached configuration is complete and each property has a non-false value assigned
 *
 * Throws an error if the config is partial in some way, so you don't need to rely on the return value/using it as
 * a type guard
 */
function validateConfig(config: Partial<FileStorageConfig>): config is FileStorageConfig {
    for (const key of FileStorageConfigKeys) {
        if (!config[key]) {
            throw new Error(`Need to supply a ${key} config value`);
        }
    }

    return true;
}

export class S3FileStorage implements FileStorage {
    private client: S3Client | undefined;

    private readonly config: FileStorageConfig;

    constructor(
        config: Partial<FileStorageConfig>,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly functionsAdapter: ClientFunctionsAdapter,
    ) {
        config = {
            ...getDefaultFileStorageConfig(),
            ...config,
        };

        if (!validateConfig(config)) {
            throw new Error("Invalid configuration given");
        }

        this.config = config;
    }

    public getProcessedImageUrl(key: string, size: ImageSizes, fit: ImageFit) {
        const imageRequest: ImageRequestInfo = {
            bucket: this.config.bucket,
            key,
            edits: this.getEditsFromSize(size, fit),
            outputFormat: preferredImageFormat(),
        };

        // NOTE: bota only handles ascii... see
        // https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte
        return `${this.config.imgCdnUrl}/${btoaSafe(JSON.stringify(imageRequest))}`;
    }

    public getFileURL(key: string, cacheBlock = false) {
        if (cacheBlock) {
            // this is to solve a CORS bug in chrome
            // this is only a issue with file types that we also use via html such as .svg
            // Details: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
            return `${this.config.cdnUrl}/${key}?cacheblock=true`;
        } else {
            return `${this.config.cdnUrl}/${key}`;
        }
    }

    public getGlbUrlFromSvgKey(key: string): string {
        return `${this.config.threeDCdnUrl}/?key=${key}`;
    }

    public getProcessed3dModelUrl(key: string): string {
        return `${this.config.threeDCdnUrl}/?key=${encodeURIComponent(key)}&format=glb`;
    }

    public async uploadFile(
        key: string,
        body: FileStorageBody,
        contentType?: string,
        makePublic = false,
    ): Promise<void> {
        await this.analyticsStorage.logEvent(TrackingEvents.fileStorage, {
            content_type: contentType,
            content_id: key,
            action: "upload",
        });

        const url = await this.functionsAdapter.getPresignedUrl({key, makePublic, contentType});
        await fetch(url.data, {
            method: "PUT",
            body,
            headers: {
                "Content-Type": contentType || "application/octet-stream",
            },
        });
    }

    private getEditsFromSize(size: ImageSizes, fit: ImageFit) {
        // img processing api docs: https://sharp.pixelplumbing.com/api-resize
        let pixelRatio = window.devicePixelRatio;

        // don't use retina resolutions on slow connections or in data safe mode
        if ((isSlow2gConnection() || is2gConnection() || isSafeDataMode()) && pixelRatio > 1) {
            pixelRatio = 1;
        }

        return {
            resize: {
                width: Number(size) * pixelRatio,
                height: Number(size) * pixelRatio,
                fit: fit,
                background: {r: 0, g: 0, b: 0, alpha: 0},
            },
        };
    }

    private getRequestParams(key: string, body?: FileStorageBody): GetObjectRequest | PutObjectRequest {
        const request = {
            Key: key,
            Bucket: this.config.bucket,
        };

        if (body) {
            return {...request, Body: body};
        }

        return request;
    }
}

export class MockFileStorage implements FileStorage {
    getFileURL(): string {
        return "https://www.flux.ai/static/media/module-icon.3e52f709941f0a8a55fc.svg";
    }

    getGlbUrlFromSvgKey(): string {
        throw new Error("unimplemented");
    }

    getProcessedImageUrl(): string {
        throw new Error("unimplemented");
    }

    async uploadFile(): Promise<void> {
        throw new Error("unimplemented");
    }

    getProcessed3dModelUrl(): string {
        throw new Error("unimplemented");
    }
}
