import type {IApplicationState} from "../../state";
import {DialogType, IDialog} from "../reducers/dialogs";

const dialogIsImportDialog = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.IMPORT_PART> =>
    dialog.type === DialogType.IMPORT_PART;

const dialogIsImportDatasheetDialog = (
    dialog: IDialog<DialogType>,
): dialog is IDialog<DialogType.IMPORT_DATASHEET_PART> => dialog.type === DialogType.IMPORT_DATASHEET_PART;

const dialogIsOrganizationAddMembers = (
    dialog: IDialog<DialogType>,
): dialog is IDialog<DialogType.ORGANIZATION_ADD_MEMBERS> => dialog.type === DialogType.ORGANIZATION_ADD_MEMBERS;

const dialogIsFollowers = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.FOLLOWERS> =>
    dialog.type === DialogType.FOLLOWERS;

const dialogIsFollowing = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.FOLLOWING> =>
    dialog.type === DialogType.FOLLOWING;

const dialogIsNewProject = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.NEW_PROJECT> =>
    dialog.type === DialogType.NEW_PROJECT;

const dialogIsCreateOrganization = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.CREATE_ORGANIZATION> =>
    dialog.type === DialogType.CREATE_ORGANIZATION;

const dialogIsRestoreProject = (dialog: IDialog<DialogType>): dialog is IDialog<DialogType.RESTORE_PROJECT> =>
    dialog.type === DialogType.RESTORE_PROJECT;

const selectOpenDialog = (state: IApplicationState) => state.dialogs.openedDialogs;

const selectImportPartDialog = (state: IApplicationState) => state.dialogs.openedDialogs.find(dialogIsImportDialog);

const selectImportDatasheetPartDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find(dialogIsImportDatasheetDialog);

const selectOrganizationAddMembersDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find(dialogIsOrganizationAddMembers);

const selectCreateOrganizationDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find(dialogIsCreateOrganization);

const selectFollowersDialog = (state: IApplicationState) => state.dialogs.openedDialogs.find(dialogIsFollowers);

const selectFollowingDialog = (state: IApplicationState) => state.dialogs.openedDialogs.find(dialogIsFollowing);

const selectNewProjectDialog = (state: IApplicationState) => state.dialogs.openedDialogs.find(dialogIsNewProject);

const selectPublishPartDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find((dialog) => dialog.type === DialogType.PUBLISH_PART);

const selectPartUpdatesDialogIsOpen = (state: IApplicationState) =>
    state.dialogs.openedDialogs.some((dialog) => dialog.type === DialogType.PART_UPDATES);

const selectShadowbanDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find((dialog) => dialog.type === DialogType.SHADOWBAN);

const selectJEP30Dialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find((dialog) => dialog.type === DialogType.JEP30);

const selectRestoreProjectDialog = (state: IApplicationState) =>
    state.dialogs.openedDialogs.find(dialogIsRestoreProject);

const dialogsSelectors = {
    selectCreateOrganizationDialog,
    selectOpenDialog,
    selectImportPartDialog,
    selectImportDatasheetPartDialog,
    selectPublishPartDialog,
    selectPartUpdatesDialogIsOpen,
    selectOrganizationAddMembersDialog,
    selectShadowbanDialog,
    selectFollowersDialog,
    selectFollowingDialog,
    selectNewProjectDialog,
    selectRestoreProjectDialog,
    selectJEP30Dialog,
};

export default dialogsSelectors;
