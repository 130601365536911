import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const updateNetsLabel = createActionRecordAction(
    "updateNetsLabel",
    (netLabelMap: Record<string, string>, canUndo = true) => {
        return {
            payload: {
                netLabelMap,
                canUndo: canUndo,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "updated the label of a net",
);
