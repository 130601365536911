import {ClipperShapeFactory, ZeroVector2} from "@buildwithflux/core";
import {Vector2} from "three";

import {
    CircleShape,
    DifferenceShape,
    LineShape,
    OblongShape,
    RectangleShape,
    RoundedRectangleShape,
    Shape,
    ShapeType,
    UnionShape,
    ExpansionShape,
} from "../types";
import {absVector2, matrix3AffineSanityCheck, matrix3Decompose, polygonPointDistance} from "../utils";

import {approxFittingPolygon} from "./approxFittingPolygon";

// Distance functions! https://iquilezles.org/articles/distfunctions2d/

// BIG NOTE:
// SDFs are cool but they introduce problems with transformation matrices and distance computations
// Before computing the SDF we apply the inverse transformation to the point to get it in the shape's local coordinate space
// If the shape trasformation matrix includes a scaling factor, this will affect the output distance as well (translation/rotation is fine)
// Because of this, we need to transform the output distance by dividing by the scale factor
// How do you divide a scalar by a Vector2? Well you can't! This is why this method works only with equal scaling
// In case of non-equal scaling we need to compute the closestPoint and use that to compute the distance
// In some functions, though, we still don't have the closestPoint implemented, so we approximate with a polygon

const tempVector2 = new Vector2();

/**
 * Gets the minimum distance between the border of the shapes and a point (negative if inside)
 * NOTE: Not all the shapes actually modify the closestPoint, as it's still a TODO
 */
export function pointDistance(
    shape: Shape,
    pointX: number,
    pointY: number,
    closestPoint = new Vector2(),
    clipperShapeFactory: ClipperShapeFactory,
    polygonApproxSegments = 128,
): number {
    // Cases for which there is no need to worry about scaling (closestPoint implemented)
    if (shape.shapeType === ShapeType.Circle) {
        return circlePointDistance(shape, pointX, pointY, closestPoint);
    } else if (shape.shapeType === ShapeType.Rectangle) {
        return rectanglePointDistance(shape, pointX, pointY, closestPoint, clipperShapeFactory);
    } else if (shape.shapeType === ShapeType.Union) {
        return unionPointDistance(shape, pointX, pointY, closestPoint, clipperShapeFactory);
    }

    // Cases that only work if the scale is equal in both axes
    // This check can go away when we implement closestPoint (we can use it for computing the distance)
    const transformDecomposed = matrix3Decompose(shape.transform);
    const matrixIsSimple =
        matrix3AffineSanityCheck(shape.transform) &&
        transformDecomposed.scaleIsEqual &&
        transformDecomposed.xyDot === 0;
    if (matrixIsSimple && shape.shapeType !== ShapeType.ApproxPolygon) {
        const scale = transformDecomposed ? transformDecomposed.scaleX : 1;
        switch (shape.shapeType) {
            case ShapeType.Oblong:
                return oblongPointDistance(shape, pointX, pointY, closestPoint, scale);
            case ShapeType.RoundedRectangle:
                return roundedRectanglePointDistance(shape, pointX, pointY, closestPoint, scale);
            case ShapeType.Line:
                return linePointDistance(shape, pointX, pointY, closestPoint, scale);
            case ShapeType.Difference:
                return differencePointDistance(shape, pointX, pointY, closestPoint, clipperShapeFactory, scale);
            case ShapeType.Expansion:
                return expansionPointDistance(shape, pointX, pointY, closestPoint, clipperShapeFactory, scale);
        }
    } else {
        // Approximate the shape as a polygon as fallback
        const polygon = approxFittingPolygon(shape, polygonApproxSegments, clipperShapeFactory);
        tempVector2.set(pointX, pointY);
        return polygonPointDistance(tempVector2, polygon, closestPoint);
    }
}

function circlePointDistance(
    shape: Pick<CircleShape, "radius" | "transform" | "transformInverse">,
    pointX: number,
    pointY: number,
    closestPoint: Vector2,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);
    const sign = tempVector2.length() < shape.radius ? -1 : 1;
    closestPoint.copy(tempVector2).normalize().multiplyScalar(shape.radius).applyMatrix3(shape.transform);
    tempVector2.set(pointX, pointY);
    return closestPoint.distanceTo(tempVector2) * sign;
}

function rectanglePointDistance(
    shape: RectangleShape,
    pointX: number,
    pointY: number,
    closestPoint: Vector2,
    clipperShapeFactory: ClipperShapeFactory,
): number {
    // It's more convenient to just use the polygon way, since it's 4 points anyway
    const polygon = approxFittingPolygon(shape, 0, clipperShapeFactory);
    tempVector2.set(pointX, pointY);
    return polygonPointDistance(tempVector2, polygon, closestPoint);
}

function oblongPointDistance(
    shape: OblongShape,
    pointX: number,
    pointY: number,
    closestPoint: Vector2, // TODO
    scale: number,
): number {
    // Normal circle
    if (shape.width === shape.height) {
        return circlePointDistance(
            {radius: shape.width, transform: shape.transform, transformInverse: shape.transformInverse},
            pointX,
            pointY,
            closestPoint,
        );
    }

    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);

    // Consider the oblong as a very rounded rectangle
    const capRadius = Math.min(shape.width, shape.height) / 2;
    const d = absVector2(tempVector2);
    d.x -= shape.width / 2;
    d.y -= shape.height / 2;
    d.x += capRadius;
    d.y += capRadius;
    const dist = Math.min(Math.max(d.x, d.y), 0) + d.max(ZeroVector2).length() - capRadius;

    return dist / scale;

    // TODO closestPoint
}

function roundedRectanglePointDistance(
    shape: RoundedRectangleShape,
    pointX: number,
    pointY: number,
    _closestPoint: Vector2, // TODO
    scale: number,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);

    // https://i.stack.imgur.com/K6vRH.jpg
    const capRadiusX = tempVector2.x > 0.0 ? shape.radiusTopRight : shape.radiusTopLeft;
    const capRadiusY = tempVector2.x > 0.0 ? shape.radiusBottomRight : shape.radiusBottomLeft;
    const capRadius = (tempVector2.y > 0.0 ? capRadiusX : capRadiusY) ?? 0;
    const d = absVector2(tempVector2);
    d.x -= shape.width / 2;
    d.y -= shape.height / 2;
    d.x += capRadius;
    d.y += capRadius;
    const dist = Math.min(Math.max(d.x, d.y), 0) + d.max(ZeroVector2).length() - capRadius;

    return dist / scale;

    // TODO closestPoint
}

function linePointDistance(
    shape: LineShape,
    pointX: number,
    pointY: number,
    _closestPoint: Vector2, // TODO
    scale: number,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);

    const pa = new Vector2(shape.startX, shape.startY);
    const pb = new Vector2(shape.endX, shape.endY);
    const l = pa.distanceTo(pb);
    const d = pb.clone().sub(pa).divideScalar(l);
    const q = tempVector2.sub(pa.add(pb).divideScalar(2));
    q.set(d.x * q.x + d.y * q.y, -d.y * q.x + d.x * q.y);
    absVector2(q);
    q.x -= l / 2;
    const dist = Math.min(Math.max(q.x, q.y), 0) + q.max(ZeroVector2).length() - shape.thickness / 2;

    return dist / scale;

    // TODO closestPoint
}

function unionPointDistance(
    shape: UnionShape,
    pointX: number,
    pointY: number,
    closestPoint: Vector2,
    clipperShapeFactory: ClipperShapeFactory,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);
    const px = tempVector2.x;
    const py = tempVector2.y;
    const closestPointA = new Vector2();
    const closestPointB = new Vector2();
    pointDistance(shape.shapeA, px, py, closestPointA, clipperShapeFactory);
    pointDistance(shape.shapeB, px, py, closestPointB, clipperShapeFactory);
    tempVector2.set(pointX, pointY);
    closestPoint.copy(closestPointA);
    if (closestPointA.distanceTo(tempVector2) > closestPointA.distanceTo(tempVector2)) {
        closestPoint.copy(closestPointB);
    }
    return tempVector2.distanceTo(closestPoint);
}

function differencePointDistance(
    shape: DifferenceShape,
    pointX: number,
    pointY: number,
    _closestPoint: Vector2, // TODO
    clipperShapeFactory: ClipperShapeFactory,
    scale: number,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);
    const px = tempVector2.x;
    const py = tempVector2.y;
    const closestPointA = new Vector2();
    const closestPointB = new Vector2();
    return (
        Math.max(
            pointDistance(shape.shapeA, px, py, closestPointA, clipperShapeFactory),
            -pointDistance(shape.shapeB, px, py, closestPointB, clipperShapeFactory),
        ) / scale
    );

    // TODO closestPoint
}

function expansionPointDistance(
    shape: ExpansionShape,
    pointX: number,
    pointY: number,
    _closestPoint: Vector2, // TODO
    clipperShapeFactory: ClipperShapeFactory,
    scale: number,
): number {
    tempVector2.set(pointX, pointY);
    tempVector2.applyMatrix3(shape.transformInverse);
    const px = tempVector2.x;
    const py = tempVector2.y;
    return (pointDistance(shape.shape, px, py, new Vector2(), clipperShapeFactory) - shape.expansion) / scale;
}
