import {ElementHelper} from "@buildwithflux/core";
import {IDocumentData} from "@buildwithflux/models";
import {Vector2} from "three";

// TODO: move this somewhere out of components
import Wiring from "../schematic_editor/wiring/Wiring";
import {DocumentStorageHelper} from "../storage_engine/helpers/DocumentStorageHelper";

import {AutoWirePointToPointArgs, assertiveGet} from "./models";

/**
 * Function that will draw a wire between a set of terminals for parts in a document in the simplest way possible.
 * NOTE: This function will mutate the document data!
 */
export function autoWirePointToPoint(document: IDocumentData, args: AutoWirePointToPointArgs): void {
    const routeMgr = new Wiring(document.routeVertices, document.elements);

    for (const connection of args.connectionsToWire) {
        const element1 = assertiveGet(document.elements, connection.start.elementUid);
        const startPosition = assertiveGet(
            ElementHelper.getAbsoluteTerminalPlacements(element1),
            connection.start.terminalUid,
        )?.position;
        const element2 = assertiveGet(document.elements, connection.end.elementUid);
        const endPosition = assertiveGet(
            ElementHelper.getAbsoluteTerminalPlacements(element2),
            connection.end.terminalUid,
        )?.position;
        routeMgr.placeWire(
            {
                position: new Vector2(startPosition.x, startPosition.y),
                terminal: {element_uid: connection.start.elementUid, terminal_uid: connection.start.terminalUid},
            },
            {
                position: new Vector2(endPosition.x, endPosition.y),
                terminal: {
                    element_uid: connection.end.elementUid,
                    terminal_uid: connection.end.terminalUid,
                },
            },
        );
    }

    const routesVerticesAndElements = {
        routes: document.routes,
        vertices: document.routeVertices,
        elements: document.elements,
    };
    const {vertexUpdates} = routeMgr.getWiringUpdates();
    DocumentStorageHelper.applyVertexUpdates(routesVerticesAndElements, vertexUpdates ?? []);

    document.elements = routesVerticesAndElements.elements;
    document.routeVertices = routesVerticesAndElements.vertices;
    document.routes = routesVerticesAndElements.routes;
}
