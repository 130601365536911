import {
    relativePathForDocumentSnapshotDescriptor,
    DocumentSnapshotDescriptor,
    AbstractDocumentSnapshotStorageAdapter,
    StoredDocumentSnapshotData,
} from "@buildwithflux/core";
import {CloudCdnStorageConfig} from "@buildwithflux/shared";
import {Auth, onIdTokenChanged} from "firebase/auth";

/**
 * Adapter for static document snapshot data based on the browser fetch API.  It can only read data,
 * not write it.
 */
export class BrowserDocumentSnapshotStorageAdapter implements AbstractDocumentSnapshotStorageAdapter {
    baseUrl: string;
    idTokenUnsubscriber: () => void;
    authenticated = false;

    constructor(config: CloudCdnStorageConfig, authService: Auth) {
        this.baseUrl = config.baseUrl;
        // Set up a subscription so that any time the auth state changes to a definite user, we ask for auth credentials.
        // TODO: Don't love this, but it'll work for now. Also check `BrowserStaticPartVersionStorageAdapter.ts`

        this.idTokenUnsubscriber = onIdTokenChanged(authService, async (user) => {
            const jwt = await user?.getIdToken();
            if (jwt != null) return this.getAuthToken(jwt);
            else {
                this.authenticated = false;
            }
        });
    }

    /**
     * This will fire off the request to get the auth token.  On success, the server will set a cookie on the browser - its value
     * is never used other than to determine if the result was successful.
     */
    private async getAuthToken(firebaseJwtToken: Readonly<string>): Promise<void> {
        const result = await window.fetch(`${this.baseUrl}/authenticate`, {
            credentials: "include",
            headers: {Authorization: `Bearer ${firebaseJwtToken}`},
        });

        // If we receive a 204, then we did it.
        this.authenticated = result.status == 204;
    }

    /** @inheritDoc */
    public async get(
        descriptor: Readonly<DocumentSnapshotDescriptor>,
    ): Promise<StoredDocumentSnapshotData | undefined> {
        const path = relativePathForDocumentSnapshotDescriptor(descriptor);
        try {
            const baseData = await window.fetch(this.objectUrl(path), {credentials: "include"});
            if (baseData.ok) {
                return (await baseData.json()) as StoredDocumentSnapshotData;
            }
        } catch (err) {
            // TODO: what should we do here exactly?  How can we tell if the request was unauthorized or if the
            // data just didn't exist?  Should we even treat those cases differently?
            return undefined;
        }
    }

    /**
     * This method will always fail on the client - do not use it!
     */
    public save(
        _documentSnapshot: Readonly<StoredDocumentSnapshotData>,
        _descriptor: Readonly<DocumentSnapshotDescriptor>,
    ): Promise<void> {
        // This throw is highly intentional.  DO NOT REMOVE IT.
        // TODO: Must be a better way to restrict access to this.
        throw new Error("Method not implemented for client.");
    }

    /** @inheritDoc */
    public async exists(descriptor: Readonly<DocumentSnapshotDescriptor>): Promise<boolean> {
        const remoteData = await this.get(descriptor);
        return remoteData != null;
    }

    /**
     * Construct the URL for a given path.
     */
    private objectUrl(path: string): string {
        return `${this.baseUrl}/${path}`;
    }
}
