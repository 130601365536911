import {AnyPcbBakedNode, BakedRulesFor, PcbNodeTypes, PcbNodesMap} from "@buildwithflux/core";
import {IBaseDocumentData} from "@buildwithflux/models";
import {Unsubscriber} from "@buildwithflux/shared";
import {DocumentService, FluxListener} from "@buildwithflux/solder-core";

type SubscribeFn = (listener: FluxListener) => Unsubscriber;
export type FluxReactivitySubscriptions = {
    /**
     * Helpers to make a subscribe function that can subscribe to a single pcb node,
     * then we plug it into `useSyncExternalStore`
     */
    makeSubscriptionToPcbNode: (nodeUid: string | undefined | null) => SubscribeFn;
    /**
     * Helpers to make a snapshot function for a single pcb node, then we plug it
     * into `useSyncExternalStore`
     */
    makeGetPcbNodeSnapshot: (nodeUid: string | undefined | null) => () => AnyPcbBakedNode | undefined;
    /**
     * Helpers to make a subscribe function that can subscribe to a single pcb node's baked rules,
     * then we plug it into `useSyncExternalStore`
     */
    makeSubscriptionToPcbNodeBakedRules: (nodeUid: string | undefined | null) => SubscribeFn;
    /**
     * Helpers to make a snapshot function for a single pcb node's baked rules, then we plug it
     * into `useSyncExternalStore`
     */
    makeGetPcbNodeBakedRulesSnapshot: (
        nodeUid: string | undefined | null,
    ) => () => BakedRulesFor<PcbNodeTypes> | undefined;

    subscribeToAllPcbNodes: SubscribeFn;
    getPcbNodesSnapshot: () => PcbNodesMap<AnyPcbBakedNode>;

    subscribeToBaseDocument: SubscribeFn;
    getBaseDocumentSnapshot: () => Partial<IBaseDocumentData>;
};

const EMPTY_SUBSCRIBER = (_listener: FluxListener) => {
    return () => {};
};
export const createReactivitySubscriptions = (documentService: DocumentService): FluxReactivitySubscriptions => {
    return {
        // Helpers to subscribe to a single pcb node: helpers to make a subscriber and a snapshot
        makeSubscriptionToPcbNode: (nodeUid: string | undefined | null) => {
            if (!nodeUid) return EMPTY_SUBSCRIBER;
            const subscribe: SubscribeFn = (listener: FluxListener) => {
                return documentService.subscribe({
                    target: "pcbNode",
                    uid: nodeUid,
                    callback: listener,
                });
            };

            return subscribe;
        },
        makeGetPcbNodeSnapshot: (nodeUid: string | undefined | null) => {
            if (!nodeUid) return () => undefined;
            return () => {
                return documentService.snapshot().pcbLayoutNodes[nodeUid];
            };
        },
        // Helpers to subscribe to a single pcb node's baked rules: helpers to make a subscriber and a snapshot
        makeSubscriptionToPcbNodeBakedRules: (nodeUid: string | undefined | null) => {
            if (!nodeUid) return EMPTY_SUBSCRIBER;
            const subscribe: SubscribeFn = (listener: FluxListener) => {
                return documentService.subscribe({
                    target: "pcbNodeBakedRules",
                    uid: nodeUid,
                    callback: listener,
                });
            };

            return subscribe;
        },
        makeGetPcbNodeBakedRulesSnapshot: (nodeUid: string | undefined | null) => {
            if (!nodeUid) return () => undefined;
            return () => {
                return documentService.snapshot().pcbLayoutNodes[nodeUid]?.bakedRules;
            };
        },
        subscribeToAllPcbNodes: (listener: FluxListener) => {
            return documentService.subscribe({
                target: "allPcbNodes",
                callback: listener,
            });
        },
        getPcbNodesSnapshot: () => {
            const nodes = documentService.snapshot().pcbLayoutNodes;

            return nodes;
        },

        subscribeToBaseDocument: (listener: FluxListener) => {
            return documentService.subscribe({
                target: "baseDocument",
                callback: listener,
            });
        },
        getBaseDocumentSnapshot: () => {
            return documentService.snapshot();
        },
    };
};
