import React from "react";
import {IntercomProvider} from "react-use-intercom";

import {useIsBrowserIdle} from "../../../helpers/hooks/useIsBrowserIdle";
import {lazyRetry} from "../lazyLoader";

const IntercomBoot = lazyRetry(() => import(/* webpackChunkName: 'IntercomBoot' */ "./IntercomBoot"));

export function IntercomSetup() {
    // deferred loading intercom because it not critical and Lighthouse said so 😛
    // https://developer.chrome.com/docs/lighthouse/performance/third-party-facades/?utm_source=lighthouse&utm_medium=devtools
    const load = useIsBrowserIdle();

    if (!load) {
        return null;
    }

    return (
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_API_KEY || ""}>
            <IntercomBoot />
        </IntercomProvider>
    );
}
