import CircularProgress from "@material-ui/core/CircularProgress";
import {CircularProgressProps} from "@material-ui/core/CircularProgress/CircularProgress";
import Fade from "@material-ui/core/Fade";
import React, {CSSProperties, useMemo} from "react";

// eslint-disable-next-line import/no-unused-modules -- used by DelayedProgressSpinner.stories.tsx
export interface IDelayedProgressSpinnerProps extends CircularProgressProps {
    delay?: number;
}

function DelayedProgressSpinner(props: IDelayedProgressSpinnerProps) {
    const delayTime = props.delay || 500;

    const fadeStyle = useMemo<CSSProperties>(() => {
        return {
            transitionDelay: `${delayTime}ms`,
        };
    }, [delayTime]);

    return (
        // Disabling `forbid-component-props` here because this is a dynamic style
        // eslint-disable-next-line react/forbid-component-props
        <Fade in={true} style={fadeStyle} unmountOnExit>
            <CircularProgress {...props} />
        </Fade>
    );
}

export default React.memo(DelayedProgressSpinner);
