import type {IApplicationState} from "../../state";
import {AppStatus} from "../reducers/app/types";

const selectDocumentReady = (state: IApplicationState) => state.app.appStatus === AppStatus.documentReady;
const selectDocumentStatus = (state: IApplicationState) => state.app.documentStatus;
const selectError = (state: IApplicationState) => state.app.error;
const selectNotifications = (state: IApplicationState) => state.app.notifications;
const selectPartBrowserSearchKey = (state: IApplicationState) => state.app.partBrowserSearchKey;
const selectShowEditorBackdrop = (state: IApplicationState) => state.app.showEditorBackdrop;
const selectLastWriteDocumentPayload = (state: IApplicationState) => state.app.lastWriteDocumentPayload;
const selectIsReconnecting = (state: IApplicationState) => state.app.isReconnecting;
const selectIsOnboarding = (state: IApplicationState) => state.app.isOnboarding;

const appSelectors = {
    selectDocumentReady,
    selectDocumentStatus,
    selectError,
    selectNotifications,
    selectPartBrowserSearchKey,
    selectShowEditorBackdrop,
    selectLastWriteDocumentPayload,
    selectIsReconnecting,
    selectIsOnboarding,
};

export default appSelectors;
