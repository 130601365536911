import {LRUCache} from "lru-cache";

const DEFAULT_PREFIX = "lru-";
const DEFAULT_MAX = 500;
const DEFAULT_KEYS_TO_MIGRATE = [
    /ExpansionState$/,
    /system-default-rules$/,
    /object-specific rules$/,
    /inherited-rules-uid$/,
    /aboutGlobalRule$/,
    /configureGlobalRuleSelector$/,
    /useNavigationControlsConfig$/,
    /\/.*\/.*:[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/,
];

export type LRULocalStorageOptions = {
    keysToMigrate?: RegExp[];
    max?: number;
    localStorage?: Storage;
};
export class LRULocalStorage implements Storage {
    private readonly lruCache: LRUCache<string, string>;
    private readonly localStorage: Storage;
    constructor(options: LRULocalStorageOptions = {}) {
        const {
            keysToMigrate = DEFAULT_KEYS_TO_MIGRATE,
            max = DEFAULT_MAX,
            localStorage = window.localStorage,
        } = options;

        this.localStorage = localStorage;
        this.lruCache = new LRUCache<string, string>({
            max,
            noDisposeOnSet: true,
            fetchMethod: (key) => this.localStorage.getItem(key) || undefined,
            dispose: (_value, key) => {
                this.localStorage.removeItem(key);
            },
        });

        this.migrateLegacyKeys(keysToMigrate);
        this.loadExistingKeys();
    }

    key(index: number): string | null {
        let x = 0;
        for (const [key] of this.lruCache.keys()) {
            if (index === x++) {
                return key || null;
            }
        }
        return null;
    }

    getItem(key: string): string | null {
        return this.lruCache.get(this.namespacedKey(key)) || null;
    }

    setItem(key: string, value: string): void {
        // Explicitly write to the backing storage as the cache doesn't
        this.localStorage.setItem(this.namespacedKey(key), value);
        this.lruCache.set(this.namespacedKey(key), value);
    }

    removeItem(key: string): void {
        this.lruCache.delete(this.namespacedKey(key));
    }

    get length(): number {
        return this.lruCache.size;
    }

    clear(): void {
        this.lruCache.clear();
    }

    /**
     * Migrates existing keys matching the opt in regex from the backing storage to the LRU storage.
     * We do this so that we can identify which keys are controlled by the LRU storage and don't
     * keep migrating them every time we create a new instance of LRULocalStorage.
     */
    private migrateLegacyKeys(keysToMigrate: RegExp[]) {
        Object.entries(this.localStorage)
            .filter(([key]) => {
                if (key.startsWith(DEFAULT_PREFIX)) return false;
                return keysToMigrate.some((regex) => {
                    return regex.test(key);
                });
            })
            .forEach(([key, value]) => {
                this.setItem(key, value);
                this.localStorage.removeItem(key);
            });
    }

    /**
     * Load all existing keys to ensure that the LRU cache is aware of them.
     */
    private loadExistingKeys() {
        Object.entries(this.localStorage)
            .filter(([key]) => key.startsWith(DEFAULT_PREFIX))
            .forEach(([key, value]) => {
                this.lruCache.set(key, value);
            });
    }

    private namespacedKey(key: string): string {
        return DEFAULT_PREFIX + key;
    }
}
