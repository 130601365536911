import {
    AnyPcbBakedNode,
    DocumentSubCollectionChange,
    NodeUid,
    pcbLayoutRootNodeId,
    PcbNodesMap,
    PcbNodeTypes,
    ShapesMap,
    SubscriptionManager,
} from "@buildwithflux/core";
import {SubscribedTypeCallback} from "@buildwithflux/repositories";
import {produce} from "immer";
import {forEach, groupBy, has, isEqualWith, get as lget, set as lset} from "lodash";
import RBush from "rbush";
import {Matrix4, Vector2} from "three";

import {createShapesForNode} from "../createShapesForNode";
import {approxAABoundingBox, mergeApproxBoundingBoxes} from "../math";
import {PcbQuadTreeNode, ShapeContainerInfo} from "../types";

import {arePolygonsDiff, convertToVector2} from "./utils";

/*
 * NOTE: The interface of this store is still WIP
 * If you feel like having a different view of the data would make performance/your life easier, let's talk about it!
 * – Giulio
 */

export enum PcbShapesStoreOperation {
    Add,
    Remove,
    Update,
    Clear,
    UpdateFills,
    UpdateSpills,
}

export interface PcbShapesStoreEventPayload {
    operation: PcbShapesStoreOperation;
    shapesStore: PcbShapesStore;
    updatedNodeUids: NodeUid[];
    updateAll?: boolean;
}

const shapesSupportedNodeTypes = [
    // Silk
    PcbNodeTypes.circle,
    PcbNodeTypes.line,
    PcbNodeTypes.rectangle,
    PcbNodeTypes.text,

    // Direct Copper
    PcbNodeTypes.pad,
    PcbNodeTypes.routeSegment,
    PcbNodeTypes.via,

    // Indirect Copper
    PcbNodeTypes.fill,
    PcbNodeTypes.spill,

    // Meta
    PcbNodeTypes.zone,
];

/** An efficient store for PCB shapes */
export class PcbShapesStore {
    private readonly subscriptionManager = new SubscriptionManager<
        PcbShapesStoreOperation,
        PcbShapesStoreEventPayload
    >();

    private readonly nodeUidList: string[] = [];
    private readonly nodeShapesMap = new Map<string, PcbQuadTreeNode>();
    private readonly containerMap = new Map<string, ShapeContainerInfo>(); // a map of layout id <> shapes

    // TODO:
    // We want to remove the old ShapesMap for fills/spills and use the ShapesStrategy instead.
    // For now we just did the first step of merging the two stores together by copy-pasting,
    // but we actually want to remove this and use the nodeShapesMap instead
    private fillShapesMap: ShapesMap<Vector2> = {};
    private spillShapesMap: ShapesMap = {};

    // ================================================================
    // Interface functions for the PcbBakedGoodsManager
    // ================================================================

    /** This function is called only by the PcbBakedGoodsManager to update the store */
    public addNodes(nodeUids: NodeUid[], allNodes: PcbNodesMap<AnyPcbBakedNode>, updateOnly = false) {
        nodeUids.forEach((nodeUid) => {
            const node = allNodes[nodeUid];
            if (!node) {
                throw new Error(`Node with uid ${nodeUid} not found in allNodes`);
            }

            if (
                node.parentUid === pcbLayoutRootNodeId &&
                (node.type === PcbNodeTypes.layout || node.type === PcbNodeTypes.footprint)
            ) {
                // Creates a new "layer container" for each layout or footprint, which will provide a 3D transform context
                this.containerMap.set(node.uid, {
                    transform: new Matrix4().fromArray(node.bakedRules.transformRelativeToDocument),
                    transformInverse: new Matrix4().fromArray(node.bakedRules.transformRelativeToDocument).invert(),
                    quadTree: new RBush(),
                });
            }

            if (!shapesSupportedNodeTypes.includes(node.type)) return;

            if (!updateOnly) {
                this.nodeUidList.push(node.uid);
            }

            const layoutOrFootprintNode =
                node.bakedRules.rootFootprintOrLayoutUid && allNodes[node.bakedRules.rootFootprintOrLayoutUid];
            const hasNoRootLayer =
                !layoutOrFootprintNode ||
                !(
                    layoutOrFootprintNode.type === PcbNodeTypes.layout ||
                    layoutOrFootprintNode.type === PcbNodeTypes.footprint
                );
            if (!node.bakedRules.rootFootprintOrLayoutUid || hasNoRootLayer) {
                // If we have no root layout/footprint we have no stackup, so we can't emit shapes
                // TODO: this should not be possible but we could still put those shapes in an hardcoded layer
                return;
            }

            // TODO: this can be optimized by passing the stackup as baked value
            const stackup = Object.values(layoutOrFootprintNode.bakedRules.stackup ?? {});
            const shapes = createShapesForNode(node, allNodes, stackup);
            const treeNode = {
                nodeUid: node.uid,
                containerId: node.bakedRules.rootFootprintOrLayoutUid,
                shapes,
                ...mergeApproxBoundingBoxes(shapes.map((shape) => approxAABoundingBox(shape))),
            };
            this.nodeShapesMap.set(node.uid, treeNode);
        });

        this.rebuildRTrees();

        const eventOperation = updateOnly ? PcbShapesStoreOperation.Update : PcbShapesStoreOperation.Add;
        this.subscriptionManager.notify(eventOperation, {
            operation: eventOperation,
            shapesStore: this,
            updatedNodeUids: nodeUids,
            updateAll: updateOnly,
        });
    }

    /** This function is called only by the PcbBakedGoodsManager to update the store */
    public updateNodes(nodeUids: NodeUid[], allNodes: PcbNodesMap<AnyPcbBakedNode>) {
        this.addNodes(nodeUids, allNodes, true);
    }

    /** This function is called only by the PcbBakedGoodsManager to update the store */
    public removeNodes(nodeUids: NodeUid[], _allNodes: PcbNodesMap<AnyPcbBakedNode>) {
        nodeUids.forEach((nodeUid) => {
            const index = this.nodeUidList.indexOf(nodeUid);
            if (index !== -1) {
                this.nodeUidList.splice(this.nodeUidList.indexOf(nodeUid), 1);
            }
            this.nodeShapesMap.delete(nodeUid);
            this.containerMap.delete(nodeUid);
        });

        this.rebuildRTrees();

        this.subscriptionManager.notify(PcbShapesStoreOperation.Remove, {
            operation: PcbShapesStoreOperation.Remove,
            shapesStore: this,
            updatedNodeUids: nodeUids,
        });
    }

    // TODO: refactor this
    public setFills(fills: ShapesMap) {
        const newFillShapesMap = produce(this.fillShapesMap, (draftState) => {
            // First look for and remove any shapes that are currently in draftState, that are not present
            // in incoming state. It's important to remove shapes that are no longer present in incoming state,
            // to ensure the PCB canvas remains visually in sync with the output of virtual fill generation
            forEach(draftState, (layout, netUid) => {
                forEach(layout, (layers, layoutUid) => {
                    forEach(layers, (_, layerId) => {
                        if (has(fills, [netUid, layoutUid, layerId]) === false) {
                            // TODO: account for 2d array?

                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            delete draftState![netUid]![layoutUid]![layerId];

                            if (isEqualWith(lget(draftState, [netUid, layoutUid]), {})) {
                                delete draftState![netUid]![layoutUid];
                            }

                            if (isEqualWith(lget(draftState, [netUid]), {})) {
                                delete draftState![netUid];
                            }
                        }
                    });
                });
            });

            // Next, we iterate the incoming fill state, looking for newly added or updated polygons. We
            // upsert the draftState wherever these additions or changes are found
            forEach(fills, (layouts, netUid) => {
                forEach(layouts, (layers, layoutUid) => {
                    forEach(layers, (newPolygons, layerId) => {
                        const oldPolygons = lget(this.fillShapesMap, [netUid, layoutUid, layerId], []);
                        if (arePolygonsDiff(newPolygons, oldPolygons)) {
                            const vector2s = newPolygons.map(convertToVector2);
                            lset(draftState, [netUid, layoutUid, layerId], vector2s);
                        }
                    });
                });
            });
        });

        this.fillShapesMap = newFillShapesMap;

        this.subscriptionManager.notify(PcbShapesStoreOperation.UpdateFills, {
            operation: PcbShapesStoreOperation.UpdateFills,
            shapesStore: this,
            updatedNodeUids: [],
        });
    }

    // TODO: refactor this
    public setSpills(spills: ShapesMap) {
        this.spillShapesMap = spills;

        this.subscriptionManager.notify(PcbShapesStoreOperation.UpdateSpills, {
            operation: PcbShapesStoreOperation.UpdateSpills,
            shapesStore: this,
            updatedNodeUids: [],
        });
    }

    public applyChange(documentChange: DocumentSubCollectionChange, newBakedNodes: PcbNodesMap<AnyPcbBakedNode>) {
        if (documentChange.payload.pcbLayoutNodes?.remove) {
            this.removeNodes(documentChange.payload.pcbLayoutNodes.remove, newBakedNodes);
        }
        if (documentChange.payload.pcbLayoutNodes?.add) {
            this.addNodes(documentChange.payload.pcbLayoutNodes.add, newBakedNodes);
        }
        if (documentChange.payload.pcbLayoutNodes?.replace) {
            this.updateNodes(documentChange.payload.pcbLayoutNodes.replace, newBakedNodes);
        }
        if (documentChange.payload.pcbLayoutNodes?.set) {
            this.updateNodes(documentChange.payload.pcbLayoutNodes.set, newBakedNodes);
        }
    }

    /** This function is called only by the PcbBakedGoodsManager to update the store */
    public clear() {
        this.nodeShapesMap.clear();
        this.containerMap.clear();
        this.nodeUidList.splice(0, this.nodeUidList.length);

        // TODO: refactor this
        this.fillShapesMap = {};
        this.spillShapesMap = {};

        this.subscriptionManager.notify(PcbShapesStoreOperation.Clear, {
            operation: PcbShapesStoreOperation.Clear,
            shapesStore: this,
            updatedNodeUids: [],
        });
    }

    // ================================================================
    // Public interface functions for consumers
    // ================================================================

    /**
     * Get a map with all the PCB layout nodes and their associated shapes.
     * This map is NOT immutable: it will automatically update and it will be referentially stable.
     */
    public getAllNodeShapesMap() {
        return this.nodeShapesMap;
    }

    /**
     * Get a map with all the nodes with layout/footprint type and their associated transform matrices.
     * This map is NOT immutable: it will automatically update and it will be referentially stable.
     */
    public getAllContainersMap() {
        return this.containerMap;
    }

    public getAllNodeUidsWithShapes() {
        return this.nodeUidList;
    }

    // TODO: refactor this
    public getFillShapesMap() {
        return this.fillShapesMap;
    }

    // TODO: refactor this
    public getSpillShapesMap() {
        return this.spillShapesMap;
    }

    public subscribe(operation: PcbShapesStoreOperation, callback: SubscribedTypeCallback<PcbShapesStoreEventPayload>) {
        return this.subscriptionManager.addSubscription(operation, callback);
    }

    // TODO: for now we take the safe route and rebuild the RTree every time as it's still cheap, but this can be optimized
    private rebuildRTrees() {
        const nodesWithBB = Array.from(this.nodeShapesMap.values());
        const partitionedNodes = groupBy(nodesWithBB, (a) => a.containerId);
        this.containerMap.forEach((container, containerId) => {
            container.quadTree.clear();
            container.quadTree.load(partitionedNodes[containerId] ?? []);
        });
    }
}
