import appSelectors from "./appSelectors";
import authSelectors from "./authSelectors";
import changeHistorySelectors from "./changeHistorySelectors";
import dialogsSelectors from "./dialogsSelectors";
import documentSelectors from "./document";
import documentMetaSelectors from "./documentMetaSelectors";
import partDataSelectors from "./partDataSelectors";
import partVersionDataSelectors from "./partVersionDataSelectors";

const selectors = {
    app: appSelectors,
    auth: authSelectors,
    changeHistory: changeHistorySelectors,
    dialogs: dialogsSelectors,
    document: documentSelectors,
    documentMeta: documentMetaSelectors,
    partData: partDataSelectors,
    partVersionData: partVersionDataSelectors,
};

export default selectors;
