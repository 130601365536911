import {ElementHelper, IUserData} from "@buildwithflux/core";
import {
    IElementData,
    IPartVersionData,
    IPropertiesMap,
    PartSourcingProperties,
    PropertyValues,
} from "@buildwithflux/models";
import {chain} from "lodash";
import createCachedSelector from "re-reselect";
import {useMemo} from "react";

import {PartStorageHelper} from "../../../../modules/storage_engine/helpers/PartStorageHelper";
import type {IApplicationState} from "../../../../state";
import {selectDocumentConfigSectionWithOwnerDefault} from "../configs/selectors";
import {pickValues} from "@buildwithflux/shared";

// For memoization by ref equality
const emptyObject = {};

const useSelectedElement = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.elements?.[elementUid];
        },
        [elementUid],
    );
};

function useElementAttribute<T>(attributeName: keyof IElementData, elementUid?: string, fallbackValue?: T) {
    return useMemo(
        () =>
            // QUESTION: how can the return type here be just T? not T | undefined?
            (state: IApplicationState): T => {
                // Here it is safe to cast it as any, because we have limited the options for `attributeName`
                return elementUid
                    ? state.document?.elements?.[elementUid]?.[attributeName] || (fallbackValue as any)
                    : undefined;
            },
        [elementUid, attributeName, fallbackValue],
    );
}

const useElementLabel = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.elements?.[elementUid]?.label;
        },
        [elementUid],
    );
};

const useElementProperties = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.elements?.[elementUid]?.properties;
        },
        [elementUid],
    );
};

// This method is used to get an array of all the values of a property across
// all elements NOTE: This selector can be skipped for performance reasons using
// the `shouldSkip` parameter this is useful when the values will not be
// presented to the user. We need to do this here as we are not able to use
// hooks conditionally in the calling component.
const useElementPropertyValues = (propertyName: string, shouldSkip: boolean) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const values = new Set<string>();
            if (!state.document?.elements || shouldSkip) {
                return [];
            }

            Object.values(state.document?.elements).forEach((element) => {
                const value = getPropertyValue(element.properties, propertyName);
                if (value && typeof value === "string") values.add(value);
            });

            return Array.from(values);
        },
        [propertyName],
    );
};

const useSelectedElementsCount = (elementUids?: string[]) => {
    return useMemo(
        () => (state: IApplicationState) => {
            if (state.document?.elements && elementUids) {
                const selectedElementsData = pickValues(state.document?.elements, elementUids);
                return selectedElementsData.filter((element) => !ElementHelper.isBranchPointElement(element)).length;
            }
            return 0;
        },
        [elementUids],
    );
};

// NOTE: must use shallowEqual for memoization... for some unknown reason
const useElementPartVersionDataCacheAttribute = <T>(elementUid: string, attributeName: keyof IPartVersionData) => {
    return useMemo(
        () =>
            (state: IApplicationState): T => {
                // Here it is safe to cast it as any, because we have limited the options for `attributeName`
                return state.document?.elements?.[elementUid]?.part_version_data_cache[attributeName] as any;
            },
        [elementUid, attributeName],
    );
};

const useElement3DModelAssetStorageName = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const elementData = state.document?.elements?.[elementUid];

            if (elementData) {
                return PartStorageHelper.get3dModelFromAsset(elementData);
            }
        },
        [elementUid],
    );
};

const useElementPackageCode = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const elementData = state.document?.elements?.[elementUid];

            if (elementData) {
                return PartStorageHelper.getPackageCode(elementData);
            }
        },
        [elementUid],
    );
};

const useElementFootprintAssetStorageName = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const elementData = state.document?.elements?.[elementUid];

            if (elementData) {
                return PartStorageHelper.getFootprintAsset(elementData);
            }
        },
        [elementUid],
    );
};

const useElementMetaModule = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.elements?.[elementUid]?.metamodule || null;
        },
        [elementUid],
    );
};

const useElementLockState = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.elements?.[elementUid]?.locked;
        },
        [elementUid],
    );
};

// TODO: this is too slow
// NOTE: must use shallowEqual for memoization because getDefaultPartProperty returns a new object
// NOTE: getDefaultPartProperty may even return new objects in the new object
// but shallowEqual is enough in testing
const useElementDefaultPropertyValue = (elementUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            const defaultProperty = ElementHelper.getDefaultPartProperty(
                state.document?.elements?.[elementUid]?.properties || (emptyObject as IPropertiesMap),
            );

            return defaultProperty || undefined;
        },
        [elementUid],
    );
};

// TODO: this is too slow
// TODO: Properly separate plot metric visibility and property visibility. Metric
//       visibility has been changed to use document configs so users can
//       toggle them without needing write access to the document.
const selectElementVisibilityToggleMap = createCachedSelector(
    (state: IApplicationState, elementUid: string, currentUser: IUserData | undefined) =>
        selectDocumentConfigSectionWithOwnerDefault(state, `metrics-${elementUid}`, currentUser),
    (state: IApplicationState, elementUid: string) => state.document?.elements?.[elementUid],
    (userVisibilityToggleMap, element) => {
        const elementProperties = element?.properties ?? {};
        const defaultProperty = ElementHelper.getDefaultPartProperty(elementProperties);

        // TODO: all this below is ugly and slow and should be refactored
        // with a unit test to use lodash chain
        const notInProperties = Object.entries(element?.metamodule?.visibilityToggleMap ?? {})
            .filter(([key]) => !(key in elementProperties))
            .map(([key, value]) => [key, userVisibilityToggleMap[key] === true || value === true]);

        const val = Object.fromEntries([
            ...Object.entries(elementProperties)
                .filter(([key, property]) => {
                    const enabledByDefault = property.uid === defaultProperty?.uid;
                    const hasVisibilityInfo =
                        userVisibilityToggleMap[key] !== undefined ||
                        element?.metamodule?.visibilityToggleMap?.[key] !== undefined;
                    const enabled =
                        userVisibilityToggleMap[key] === true ||
                        element?.metamodule?.visibilityToggleMap?.[key] === true;
                    // A special type of property is the default one, which is visible by default
                    // For this reason we want it to be explicitly set to false to be hidden, defaulting
                    // to true if its visibility is undefined
                    return enabled || (enabledByDefault && !hasVisibilityInfo);
                })
                .map(([key, value]) => [key, Boolean(value)] as const),
            ...notInProperties,
            ...Object.entries(userVisibilityToggleMap),
        ]);

        return val;
    },
)((_, elementUid, userData) => `${elementUid}+${userData?.uid ?? ""}`);

function getPropertyValue(properties: IPropertiesMap, propertyName: string) {
    return chain(properties)
        .values()
        .find((p) => p.name === propertyName)
        .get("value", "")
        .value();
}

function getPropertyValueWith<T>(
    properties: IPropertiesMap,
    propertyName: string,
    converter: (value: PropertyValues) => T,
): T {
    const value = getPropertyValue(properties, propertyName);
    return converter(value);
}

const toTrimmedStringOrNull = (value: PropertyValues): string | null => (value ? String(value).trim() : null);

const selectElementPartSourcingProperties = createCachedSelector(
    (state: IApplicationState, elementUid: string): IElementData | undefined => state.document?.elements?.[elementUid],
    (elementState: IElementData | undefined): PartSourcingProperties | null => {
        if (!elementState) return null;
        const name = elementState.part_version_data_cache.name;
        const properties = elementState.properties;
        return {
            manufacturerName: getPropertyValueWith(properties, "Manufacturer Name", toTrimmedStringOrNull),
            manufacturerPartNumber: getPropertyValueWith(properties, "Manufacturer Part Number", toTrimmedStringOrNull),
            partName: name,
            partUid: elementState.part_uid,
        };
    },
)((_, elementUid) => elementUid);

const selectDocumentPartSourcingProperties = (state: IApplicationState): PartSourcingProperties | null => {
    const documentState = state.document;
    if (!documentState) return null;

    const name = documentState.name;
    const properties = documentState.properties;

    return {
        manufacturerName: getPropertyValueWith(properties, "Manufacturer Name", toTrimmedStringOrNull),
        manufacturerPartNumber: getPropertyValueWith(properties, "Manufacturer Part Number", toTrimmedStringOrNull),
        partName: name,
        partUid: documentState.belongs_to_part_uid,
    };
};

const selectElementsCount = (state: IApplicationState) => {
    const elements = state.document?.elements;
    if (elements) {
        return Object.keys(elements).length;
    } else {
        return 0;
    }
};

function getValidElements(state: IApplicationState) {
    if (state.document?.elements) {
        return Object.values(state.document?.elements).filter((element) => {
            return !ElementHelper.isBranchPointElement(element);
        });
    }
}

// NOTE: must use shallowEqual for memoization... for some unknown reason
const selectElementsButBranchPoint = (state: IApplicationState) => {
    return getValidElements(state);
};

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to memoize it
// TODO: this seems to need shallow comparison
const selectElements = (state: IApplicationState) => state.document?.elements;

const elementsSelectors = {
    selectElements,
    selectElementsButBranchPoint,
    selectElementsCount,
    selectElementVisibilityToggleMap,
    useElement3DModelAssetStorageName,
    useElementAttribute,
    useElementDefaultPropertyValue,
    selectElementPartSourcingProperties,
    selectDocumentPartSourcingProperties,
    useElementFootprintAssetStorageName,
    useElementMetaModule,
    useElementPackageCode,
    useElementPartVersionDataCacheAttribute,
    useElementLabel,
    useElementLockState,
    useElementProperties,
    useElementPropertyValues,
    useSelectedElement,
    useSelectedElementsCount,
};

export default elementsSelectors;
