import {useEffect, useState} from "react";

import {is2gConnection, isSafeDataMode} from "../../../helpers/connection";

const requestIdleCallback = typeof window !== "undefined" ? window.requestIdleCallback : null;

interface IuseIsBrowserIdleProps {
    loadWhenIdle?: boolean;
    //How long to wait in ms before loading the provider. Default is 1000. Set to 0 to never load. This value is used in a setTimeout in browsers that don't support requestIdleCallback.
    idlePeriod?: number;
}

// borrowed from https://github.com/calibreapp/react-live-chat-loader/blob/main/src/hooks/useChat.ts
export function useIsBrowserIdle(props: IuseIsBrowserIdleProps = {}) {
    const loadWhenIdle = props.loadWhenIdle ?? true;
    const idlePeriod = props.idlePeriod ?? 1000;

    const [load, setLoad] = useState(false);

    useEffect(() => {
        // Don't load if idlePeriod is 0, null or undefined
        if (typeof window === "undefined" || !loadWhenIdle || !idlePeriod) return;

        // Don't load if 2g connection or save-data is enabled
        if (is2gConnection() || isSafeDataMode()) return;

        if (isNaN(idlePeriod)) return;

        // deadline.timeRemaining() has an upper limit of 50 milliseconds
        // We want to ensure the page has been idle for a significant period of time
        // Therefore we count consecutive maximum timeRemaining counts and load chat when we reach our threshold
        let elapsedIdlePeriod = 0;
        let previousTimeRemaining = 0;
        const scheduleLoadChat = (deadline: IdleDeadline) => {
            if (elapsedIdlePeriod > idlePeriod) return setLoad(true);

            const timeRemaining = deadline.timeRemaining();
            // To ensure browser is idle, only accumalte elapsedIdlePeriod when
            // two consecutive maximum timeRemaining's have been observed
            if (previousTimeRemaining > 49 && timeRemaining > 49) elapsedIdlePeriod += timeRemaining;

            previousTimeRemaining = timeRemaining;
            requestIdleCallback?.(scheduleLoadChat);
        };

        if (requestIdleCallback) {
            requestIdleCallback(scheduleLoadChat);
        } else {
            setTimeout(() => setLoad(true), idlePeriod);
        }
    }, [idlePeriod, loadWhenIdle]);

    return load;
}
