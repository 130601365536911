import {GenericError} from "@buildwithflux/core";
import Link from "@material-ui/core/Link/Link";
import Typography from "@material-ui/core/Typography/Typography";
import {ErrorBoundary, Scope, withSentryRouting} from "@sentry/react";
import {useCallback} from "react";
import {Route, RouteProps} from "react-router-dom";

import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";
import R from "../../../resources/Namespace";
import ErrorPage from "../errors/common/ErrorPage";

// TODO: move this out of here to shared resources or something
export const defaultErrorMessage =
    "We're sorry, an internal error has occurred. A batch of cybernetically enhanced racoons have been dispatched to address the issue. Please refresh and try again.";

function fallbackComponent(props: {error: Error}) {
    // ESLint erroneously complains that these aren't consts. We're not using useMemo for them,
    // so that doesn't apply.
    //
    // eslint-disable-next-line react-memo/require-usememo
    let message = defaultErrorMessage;
    // eslint-disable-next-line react-memo/require-usememo
    let title = "500 Error";
    let content: React.ReactNode = <></>;
    // Handle specific known errors. Note: error message text can change
    // differ between library versions and across browsers, so for errors
    // where you have a more robust method of determining error type,
    // try to avoid text matching. However, in JavaScript that's not always
    // feasible.
    if (props.error.toString() === "Error: Error creating WebGL context.") {
        title = "Uh oh... we can't open that project";
        message = "";
        content = (
            <Typography>
                We can't open this project because WebGL 2 isn't supported or is disabled in your browser.
                <br />
                If your browser supports WebGL 2, check out{" "}
                <Link href={R.externalUrls.documentation.setup_browser}>this help article</Link> to find out how to
                enable it.
            </Typography>
        );
    }

    return (
        <ErrorPage title={title} pageTitle={"Uh oh... App crashed"} subtitle={message} statusCode={500}>
            {content}
        </ErrorPage>
    );
}

export function RouteWithErrorBoundary(props: RouteProps) {
    const SentryRoute = withSentryRouting(Route);
    const onBeforeCapture = useCallback(
        (scope: Scope) => {
            if (typeof props.path === "string") {
                scope.setTag("location", props.path);
                scope.setTag("errorBoundary", true);
            } else {
                FluxLogger.captureError(
                    new GenericError("Route path is not a string but type:" + typeof props.path, {path: props.path}),
                );
            }
        },
        [props.path],
    );
    return (
        <ErrorBoundary fallback={fallbackComponent} beforeCapture={onBeforeCapture}>
            <SentryRoute {...props} />
        </ErrorBoundary>
    );
}
