import {IUserData, IUserPresenceData, Organization} from "@buildwithflux/core";
import {Enterprise, EnterpriseOrganization, IBaseDocumentData} from "@buildwithflux/models";
import {createReducer} from "@reduxjs/toolkit";

import {updateDescription, updateName} from "../document/actions";

import * as Actions from "./actions";

/**
 * This state exists in addition to the IDocumentData state slice in order to hydrate related objects that aren't part
 * of the document, such as the owning organization and user data for the document owner.
 */
export interface IDocumentMetaState {
    documentOwner?: IUserData;
    documentOrganizationOwner?: Organization;
    documentEnterpriseOwner?: Enterprise;
    documentEnterpriseOwnerDefaultOrganization?: EnterpriseOrganization;
    remoteActiveUsers: IUserPresenceData[];
    hostDocumentForLocalComponent?: IBaseDocumentData;
    baseDocument?: IBaseDocumentData;
}

const getInitialDocumentMetaState = (): IDocumentMetaState => {
    return {
        remoteActiveUsers: [],
    };
};

const documentMetaReducer = createReducer(getInitialDocumentMetaState(), (builder) => {
    builder
        .addCase(Actions.setRemoteActiveUsers, (draftState, action) => {
            const {users} = action.payload;

            draftState.remoteActiveUsers = users;
        })
        .addCase(Actions.setDocumentOwner, (draftState, action) => {
            const {owner} = action.payload;

            draftState.documentOwner = owner;
        })
        .addCase(Actions.setDocumentOrganizationOwner, (draftState, action) => {
            const {organizationOwner} = action.payload;

            draftState.documentOrganizationOwner = organizationOwner;
        })
        .addCase(Actions.setDocumentEnterpriseOwner, (draftState, action) => {
            const {enterpriseOwner} = action.payload;

            draftState.documentEnterpriseOwner = enterpriseOwner;
        })
        .addCase(Actions.setDocumentEnterpriseOwnerDefaultOrganization, (draftState, action) => {
            const {enterpriseOwnerDefaultOrganization} = action.payload;

            draftState.documentEnterpriseOwnerDefaultOrganization = enterpriseOwnerDefaultOrganization;
        })
        .addCase(Actions.setBaseDocumentData, (draftState, action) => {
            const {baseDocument} = action.payload;

            draftState.baseDocument = baseDocument;
        })
        .addCase(Actions.setHostDocumentForLocalComponent, (draftState, action) => {
            const {hostDocument} = action.payload;

            draftState.hostDocumentForLocalComponent = hostDocument;
        })
        .addCase(updateName, (draftState, action) => {
            if (!draftState.baseDocument) return draftState;
            draftState.baseDocument.name = action.payload.newDocumentName;
            draftState.baseDocument.slug = action.payload.newDocumentSlug;
        })
        .addCase(updateDescription, (draftState, action) => {
            if (!draftState.baseDocument) return draftState;
            draftState.baseDocument.description = action.payload.description;
        });
});

export default documentMetaReducer;
