import {getManufacturerPartNumberProperty, getPreferredDistributorsFromProperties} from "@buildwithflux/core";

import {PartStorageHelper} from "../../../../modules/storage_engine/helpers/PartStorageHelper";
import type {IApplicationState} from "../../../../state";

const selectDocumentPackageCode = (state: IApplicationState) => {
    return PartStorageHelper.getPackageCodeFromProperties(state.document?.properties || {});
};
const selectDocumentManufacturerPartNumber = (state: IApplicationState) => {
    return getManufacturerPartNumberProperty(state.document?.properties || {});
};
const selectPreferredDistributors = (state: IApplicationState) => {
    return getPreferredDistributorsFromProperties(state.document?.properties || {});
};

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to memoize it
const selectProperties = (state: IApplicationState) => state.document?.properties;

const propertiesSelectors = {
    selectProperties,
    selectDocumentPackageCode,
    selectDocumentManufacturerPartNumber,
    selectPreferredDistributors,
};

export default propertiesSelectors;
