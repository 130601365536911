import {useEffect} from "react";

import {useFluxServices} from "../../../../injection/hooks";
import {FeatureFlagConnector} from "../../../../modules/storage_engine/connectors/FeatureFlagConnector";

export function useFeatureFlagConnector() {
    const {currentUserService} = useFluxServices();

    useEffect(() => {
        const currentUser = currentUserService.getCurrentUser();
        if (currentUser) {
            FeatureFlagConnector?.identifyUser(currentUser);
        }
        return currentUserService.subscribeToUserChanges((currentUser) => {
            const userUid = currentUser.user?.uid;
            if (userUid) {
                FeatureFlagConnector?.identifyUser(currentUser.user);
            }
        });
    }, []);
}
