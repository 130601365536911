import {StaticPartVersionDescriptor, AbstractStaticPartVersionStorageAdapter} from "@buildwithflux/core";
import {StoredStaticPartVersionData} from "@buildwithflux/repositories";

/**
 * This adapter is a no-op.  It _always_ results in a cache miss.
 */
export class NoOpStaticPartVersionStorageAdapter implements AbstractStaticPartVersionStorageAdapter {
    public get(_descriptor: Readonly<StaticPartVersionDescriptor>): Promise<StoredStaticPartVersionData | undefined> {
        return Promise.resolve(undefined);
    }
    public save(_partVersionData: Readonly<StoredStaticPartVersionData>): Promise<void> {
        throw new Error("Cannot save data using this client.");
    }
    public exists(_descriptor: Readonly<StaticPartVersionDescriptor>): Promise<boolean> {
        return Promise.resolve(false);
    }
}
