import {IDocumentData} from "@buildwithflux/models";
import {createReducer} from "@reduxjs/toolkit";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";

import {clearDocumentData, writeDocument} from "../document/actions";

import * as Actions from "./actions";
import {AppStatus, DocumentStatusStates} from "./types";
export * from "./types";

export interface IDocumentStatusState {
    state: DocumentStatusStates;
    timestamp: number;
    message?: string;
}

export interface ISimulatorStatusState {
    timestamp: number;
    message?: string;
}

export interface INotification {
    key: SnackbarKey;
    message: SnackbarMessage;
    options: OptionsObject;
    dismiss?: boolean;
}

export interface IAppState {
    error?: Actions.StorageError;
    appStatus: AppStatus;
    countOfSavingRequests: number;
    documentStatus: IDocumentStatusState;
    notifications: INotification[];
    partBrowserSearchKey: string;
    showEditorBackdrop: boolean;
    lastWriteDocumentPayload?: {
        documentState: IDocumentData;
    };
    isReconnecting: boolean;
    isOnboarding: boolean;
}

const getInitialAppState = (): IAppState => {
    return {
        countOfSavingRequests: 0,
        appStatus: AppStatus.noDocumentData,
        documentStatus: {
            state: DocumentStatusStates.none,
            timestamp: new Date().getTime(),
        },
        notifications: [],
        partBrowserSearchKey: "",
        showEditorBackdrop: false,
        isReconnecting: true,
        isOnboarding: false,
    };
};

const appReducer = createReducer(getInitialAppState(), (builder) => {
    builder
        .addCase(Actions.setError, (draftState, action) => {
            const {error} = action.payload;
            draftState.appStatus = AppStatus.noDocumentData;
            draftState.error = error;
        })
        .addCase(Actions.setDocumentStatus, (draftState, action) => {
            const {state, timestamp, message} = action.payload;
            // HACK: prevent nav here
            registerBeforeonunload(state);
            draftState.documentStatus = {
                state,
                timestamp,
                message,
            };
        })
        .addCase(Actions.setAppStatus, (draftState, action) => {
            const {status} = action.payload;
            draftState.appStatus = status;
        })
        .addCase(Actions.enqueueNotification, (draftState, action) => {
            const {key, message, options} = action.payload;

            draftState.notifications.push({
                key,
                message,
                options: options as any,
            });
        })
        .addCase(Actions.removeNotification, (draftState, action) => {
            const {key} = action.payload;

            draftState.notifications = draftState.notifications.map((notification) => {
                if (notification.key !== key) return notification;

                return {
                    ...notification,
                    dismiss: true,
                };
            });
        })
        .addCase(Actions.toggleEditorBackDrop, (draftState, action) => {
            const {show} = action.payload;

            draftState.showEditorBackdrop = !!show;
        })
        .addCase(writeDocument, (draftState, action) => {
            const {documentState} = action.payload;
            draftState.lastWriteDocumentPayload = {
                documentState,
            };
        })
        .addCase(Actions.setIsReconnecting, (draftState, action) => {
            const {reconnecting} = action.payload;

            draftState.isReconnecting = reconnecting;
        })
        .addCase(Actions.setIsOnboarding, (draftState, action) => {
            const {onboarding} = action.payload;

            draftState.isOnboarding = onboarding;
        })
        .addCase(clearDocumentData, (draftState) => {
            draftState.appStatus = AppStatus.noDocumentData;
        });
});

export default appReducer;

function registerBeforeonunload(state: DocumentStatusStates) {
    if (typeof window == "undefined") return;
    if (state === DocumentStatusStates.syncing) {
        window.onbeforeunload = (event) => {
            // Cancel the event
            event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
            // Chrome requires returnValue to be set
            event.returnValue = "Test";
        };
    } else {
        window.onbeforeunload = undefined as any;
    }
}
