import {createStyles, makeStyles} from "@material-ui/styles";
import React from "react";
import {Helmet} from "react-helmet";

import R from "../../../../resources/Namespace";
import NavBar from "../../../navigation_bar/NavBar";

import NestedLoader from "./NestedLoader";
import {IPageLoaderProps} from "./types";

const useStyles = makeStyles(() =>
    createStyles({
        "@global": {
            html: {
                height: "100%",
            },
            body: {
                height: "100%",
            },
            "body #root": {
                height: "100%",
            },
        },
    }),
);

/**
 * Use this component when you want to show a full page loading spinner indicating we are
 * loading something
 */
function FullPageLoader(props: IPageLoaderProps) {
    useStyles();

    return (
        <>
            <Helmet>
                <title>{`Loading · ${R.strings.app.name}`}</title>
                <meta name="description" content={R.strings.app.description2} />

                <meta property="og:title" content={`Loading · ${R.strings.app.name}`} />
                <meta property="og:description" content={R.strings.app.description2} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Flux.ai" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={R.strings.app.flux_twitter_handle} />
                <meta name="twitter:title" content={`Loading · ${R.strings.app.name}`} />
                <meta name="twitter:description" content={R.strings.app.description2} />
            </Helmet>

            {props.showNavBar && <NavBar position="relative" />}

            <NestedLoader
                content={props.content}
                delay={props.delay}
                showLongWaitExcuse={props.showLongWaitExcuse}
                bodyContent={props?.bodyContent}
            />
        </>
    );
}

export default React.memo(FullPageLoader);
