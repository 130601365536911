import {Vector2, Vector3} from "three";

import R from "../resources/Namespace";

import {roundFloat} from "./float";

export function roundToDragPrecision(value: number) {
    const draggingPrecision = R.behaviors.dragging.gridPrecision;
    return roundFloat(value, draggingPrecision);
}

export function roundVectorToDragPrecision(vector: Vector2): Vector2;
export function roundVectorToDragPrecision(vector: Vector3): Vector3;
export function roundVectorToDragPrecision(vector: Vector2 | Vector3) {
    if (vector instanceof Vector2) {
        return new Vector2(roundToDragPrecision(vector.x), roundToDragPrecision(vector.y));
    } else if (vector instanceof Vector3) {
        return new Vector3(
            roundToDragPrecision(vector.x),
            roundToDragPrecision(vector.y),
            roundToDragPrecision(vector.z),
        );
    }
}
