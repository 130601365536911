import {
    HandleRepository,
    IUserData,
    OrganizationHandle,
    OrganizationRepository,
    OrganizationUid,
    UserHandle,
    UserRepository,
} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {EnterpriseHandle, OrganizationPrivateHandle} from "@buildwithflux/models";
import {EnterpriseUid} from "@buildwithflux/models/src/identifier";
import {Logger} from "@buildwithflux/shared";

import {FluxLogger} from "../storage_engine/connectors/LogConnector";

export type ProfileParams = {
    handle: string;
    enterpriseOrganizationHandle?: string;
    0?: "enterprise";
};

export type ProfileData =
    | {
          profileType: "organization";
          organizationUid: OrganizationUid;
      }
    | {
          profileType: "enterprise";
          enterpriseUid: EnterpriseUid;
          defaultOrganizationUid: OrganizationUid;
          organizationUid: OrganizationUid | undefined;
      }
    | {
          profileType: "user";
          userHandle: UserHandle;
      }
    | {
          profileType: "not-found";
      };

export interface ProfileHelper {
    /**
     * Traditionally, the profile has been looked up using the key of the user profile (the handle), and ignoring the
     * handle mapping. Now we want to use the handle mapping, and this presents two conflicts:
     *  - What if there is more than one user with the same UID in the users table?
     *  - What if there is no handle mapping for the user under a given handle in the user's table?
     *
     * This resolver solves these conflicts, and tells you exactly which data to display: either a user profile, or an
     * organization profile. Note that it doesn't subscribe to further updates, nor give you data - it's expected some
     * subpart of the profile will do that next.
     *
     * Note that because of the above questions, the output `userHandle` in the profile data you receive MAY NOT MATCH
     * the user handle from the input data. This can be the case if a user has been badly renamed. But we want to retain
     * backward compatibility with the old way of looking up profiles.
     */
    resolveProfileData(routeParameters: ProfileParams): Promise<ProfileData>;
}

export class FirestoreProfileHelper implements ProfileHelper {
    constructor(
        private readonly handleRepository: HandleRepository,
        private readonly userRepository: UserRepository,
        private readonly organizationRepository: OrganizationRepository,
        private readonly functionsAdapter: FunctionsAdapter,
        private readonly logger: Logger,
    ) {}

    /** @inheritDoc */
    public async resolveProfileData(routeParameters: ProfileParams): Promise<ProfileData> {
        const isEnterpriseHandle =
            routeParameters[0] === "enterprise" || !!routeParameters.enterpriseOrganizationHandle;

        if (isEnterpriseHandle) {
            return this.resolveProfileDataForEnterprise(
                EnterpriseHandle.parse(routeParameters.handle),
                routeParameters.enterpriseOrganizationHandle
                    ? OrganizationPrivateHandle.parse(routeParameters.enterpriseOrganizationHandle)
                    : undefined,
            );
        }

        return this.resolveProfileDataForUserOrOrganization(routeParameters.handle);
    }

    private async resolveProfileDataForEnterprise(
        enterpriseHandle: EnterpriseHandle,
        enterpriseOrganizationHandle: OrganizationPrivateHandle | undefined,
    ): Promise<ProfileData> {
        try {
            const response = await this.functionsAdapter.findEnterpriseOrganization({
                enterpriseHandle: enterpriseHandle,
                organizationHandle: enterpriseOrganizationHandle,
            });

            if (response === "not-found") {
                return {profileType: "not-found"};
            }

            return {
                profileType: "enterprise",
                enterpriseUid: response.enterpriseUid,
                defaultOrganizationUid: response.defaultOrganizationUid,
                organizationUid: response.organizationUid ?? undefined,
            };
        } catch (error) {
            FluxLogger.captureError(error);
            return {profileType: "not-found"};
        }
    }

    private async resolveProfileDataForUserOrOrganization(
        userOrOrganizationHandle: OrganizationHandle | UserHandle,
    ): Promise<ProfileData> {
        const handleLookup = await this.handleRepository.getByHandle(userOrOrganizationHandle);

        if (handleLookup && handleLookup.organizationUid) {
            const organization = await this.organizationRepository.getByUid(handleLookup.organizationUid);

            if (!organization) {
                return {profileType: "not-found"};
            } else {
                return {
                    profileType: "organization",
                    organizationUid: handleLookup.organizationUid,
                };
            }
        }

        // If there's no user handle mapping for the user, but they exist in the user collection
        // This is for backward compatibility with the way the lookup by handle has worked before
        const userByHandle = await this.userRepository.getByHandle(userOrOrganizationHandle);

        if (userByHandle) {
            if (!handleLookup) {
                FluxLogger.captureError(new MissingProfileMappingError(userOrOrganizationHandle));
            } else if (handleLookup.user_uid !== userByHandle.uid) {
                FluxLogger.captureError(
                    new IncorrectProfileMappingError(userOrOrganizationHandle, handleLookup.user_uid, userByHandle.uid),
                );
            }

            return {
                profileType: "user",
                userHandle: userOrOrganizationHandle,
            };
        }

        if (handleLookup && handleLookup.user_uid) {
            const usersByHandleMappingUid = await this.userRepository.getAllByUid(handleLookup.user_uid);

            if (usersByHandleMappingUid.length === 1 && usersByHandleMappingUid[0]) {
                const user: IUserData = usersByHandleMappingUid[0];
                return {
                    profileType: "user",
                    userHandle: user.handle,
                };
            } else if (usersByHandleMappingUid.length > 1) {
                FluxLogger.captureError(
                    new DuplicateUserUidProfileMappingError(userOrOrganizationHandle, handleLookup.user_uid),
                );
                return {profileType: "not-found"};
            }
        }

        return {profileType: "not-found"};
    }
}

class ProfileMappingError extends Error {
    constructor(message: string) {
        super(message);
    }
}

class MissingProfileMappingError extends ProfileMappingError {
    constructor(userHandle: UserHandle) {
        super(`Handle (usernames collection) lookup is missing for user ${userHandle}`);
    }
}

class IncorrectProfileMappingError extends ProfileMappingError {
    constructor(userHandle: UserHandle, userUidFromHandleMapping: string, userUidFromUsersCollectionPath: string) {
        super(
            `Handle (usernames collection) lookup is incorrect for user ${userHandle}: it points to uid ${userUidFromHandleMapping}, but looking up handle in users collection gives ${userUidFromUsersCollectionPath}`,
        );
    }
}

class DuplicateUserUidProfileMappingError extends ProfileMappingError {
    constructor(userHandle: UserHandle, userUid: string) {
        super(
            `Handle (usernames collection) lookup is ambiguous for user ${userHandle}: it points at multiple users with the same UID ${userUid} and none have the expected handle`,
        );
    }
}
