import {AnyAction, PreloadedState, Reducer, StoreEnhancerStoreCreator} from "redux";

// eslint-disable-next-line boundaries/element-types
import {getActiveServicesContainerBadlyAsServiceLocator} from "../../injection/singleton";
import type {IApplicationState} from "../../state";

/**
 * Enhancer that adds a capability to the redux store:
 *
 * If current document AND dispatched action are supported in solder, instead of dispatching
 * a redux action, call `documentService.applyCommand` which triggers the solder flow to mutate
 * document, persist document data and history, etc. Otherwise, dispatch the action as usual
 *
 * Haven't figured out the best way to set up typing here - we want to set type of `solderEnhancer`
 * to something like StoreEnhancer<{}, IApplicationState>, but that leads to a circular reference...
 * I think that's because `IApplicationState` is inferred from `store.getState`, but then to define
 * store, we need to input `solderEnhancer`
 */
export const solderEnhancer: any =
    (next: StoreEnhancerStoreCreator<{}, IApplicationState>) =>
    (reducer: Reducer<IApplicationState, AnyAction>, preloadedState: PreloadedState<IApplicationState>) => {
        const solderedCompatibleReducer = (state: IApplicationState | undefined, action: AnyAction) => {
            const {documentService, reduxSolderAdapter} = getActiveServicesContainerBadlyAsServiceLocator();
            if (state?.document && reduxSolderAdapter.shouldUseSolder(state.document, action)) {
                documentService.applyCommand(reduxSolderAdapter.toSolderCommand(action));
                return state;
            }
            return reducer(state, action);
        };

        return next(solderedCompatibleReducer, preloadedState);
    };
