import {UserHandleCookie} from "@buildwithflux/constants";
import Cookie from "js-cookie";
import moment from "moment";
import {useEffect} from "react";

import {useFluxServices} from "../../../injection/hooks";

export function useUserHandleCookie() {
    const {currentUserService} = useFluxServices();

    const cookie = Cookie.get(UserHandleCookie);

    useEffect(() => {
        return currentUserService.subscribeToUserChanges((currentUser) => {
            const user = currentUser.user;

            if ((!user || user.isAnonymous) && cookie) {
                Cookie.remove(UserHandleCookie);
            } else if (user && !user?.isAnonymous && user.handle !== cookie) {
                Cookie.set(UserHandleCookie, user.handle, {
                    expires: moment().add(1, "year").toDate(),
                    secure: true,
                });
            }
        });
    }, []);
}
