import {IPcbBoardLayerBase, PcbBakedNode, PcbNodeTypes} from "@buildwithflux/core";
import {getLayerIdentifier, isCopperLayer} from "@buildwithflux/models";

import {Shape, ShapeRole, ShapeType} from "../types";
import {toThreeMatrix3} from "../utils";

// TODO: This implementation is currently missing solder mask (and expansion)
// This means that it will work fine for copper DRC but not for rendering or other stuff

export function createShapesForRouteSegment(
    node: PcbBakedNode<PcbNodeTypes.routeSegment>,
    stackup: IPcbBoardLayerBase[],
): Shape[] {
    const transform = toThreeMatrix3(node.bakedRules.transformRelativeToShapesLayer);
    const transformInverse = transform.clone().invert();

    const layer = stackup.find((layer) => isCopperLayer(layer) && getLayerIdentifier(layer) === node.bakedRules.layer);
    if (!layer) return [];

    return [
        {
            shapeRole: ShapeRole.copper,
            shapeType: ShapeType.Line,
            layerId: layer.uid,
            transform,
            transformInverse,
            startX: node.bakedRules.startPosition.x,
            startY: node.bakedRules.startPosition.y,
            endX: node.bakedRules.endPosition.x,
            endY: node.bakedRules.endPosition.y,
            thickness: node.bakedRules.size.x,
        },
    ];
}
