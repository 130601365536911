const presentation = {
    calendarStrings: {
        lastDay: "LT [Yesterday]",
        sameDay: "LT [Today]",
        lastWeek: "LT [last] dddd",
        sameElse: "lll",
    },
};

export default presentation;
