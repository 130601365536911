import {SuccessfulStripePaymentUrlParam} from "@buildwithflux/models";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {useFluxServices} from "../../injection/hooks";

/**
 * Check for SuccessfulStripePaymentUrlParam in URL parameters. If found, track an event and remove it.
 *
 * This is done this way so that the tracking event comes from the frontend, so the user identity can be the same as
 * that in other systems, and we can attribute the payment to a campaign.
 *
 * This effect is in a separate component because, while we want it to be applied to all pages, it uses react-router-dom's
 * useLocation hook, which can only be used inside the <Router />
 */
export default function TrackPayments() {
    const {analyticsStorage} = useFluxServices();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const priceUid = params.get(SuccessfulStripePaymentUrlParam);

        if (priceUid) {
            // Track the event
            void analyticsStorage.trackStripePaymentSuccess(priceUid);

            // Remove the URL parameter
            params.delete(SuccessfulStripePaymentUrlParam);

            const newUrl = params.size === 0 ? location.pathname : location.pathname + "?" + params.toString();
            window.history.pushState({}, document.title, newUrl);
        }
    }, [location, analyticsStorage]);

    return null;
}
