import {useEffect} from "react";

type Props = {
    // Why?
    // This is needed to tell prerender.io when the appropriate "finished" content is rendered.
    // We don't actually know when our document is loaded, so we have to fake it.
    delay?: number; // ms
};

const defaultDelay = 4000;

// QUESTION: is this still needed? I don't see any readers of prerenderReady
export default function PreRender(props: Props) {
    const timeout = props.delay ?? defaultDelay;

    useEffect(() => {
        setTimeout(() => {
            // @ts-ignore
            window.prerenderReady = true;
        }, timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
