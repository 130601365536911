import {PatchCreationStrategy} from "@buildwithflux/core";
import {AnyAction} from "redux";

// eslint-disable-next-line boundaries/element-types
import type {FluxServices} from "../../injection";
import {IBatchAction, isBatchAction} from "../epicCreators/helpers";
import {IDocumentReduxAction} from "../reducers/document/actions.types";

type WithPatchStrategy<T extends IDocumentReduxAction> = T & {patchCreationStrategy?: PatchCreationStrategy};
/**
 * This is a customized version of the redux-batched-actions middleware:
 *
 * https://github.com/tshelburne/redux-batched-actions/blob/master/src/index.js#L16
 *
 * Here we also configure `patchCreationStrategy` for batched actions in order to
 * batch patches properly
 */
export const batchDispatchAndPatchMiddleware = (store: any) => {
    function dispatchChildActions(store: any, action: AnyAction | IBatchAction, next: any) {
        if (isBatchAction(action)) {
            action.payload.forEach(function (childAction, index) {
                /**
                 * If there are more than one childAction, we need to
                 * combine patches generated by each child action so that
                 * the last childAction's patch reflect all changes from
                 * all batchedActions. Only so we end up with correct
                 * patches and process them in `makeDistributePatchesEpic`.
                 *
                 * Hence, we specify `patchCreationStrategy` === "combine" for
                 * any childAction except the first one
                 */
                const actionWithPatchCreationStrategy: WithPatchStrategy<typeof childAction> = {
                    ...childAction,
                    patchCreationStrategy: index === 0 ? "create" : "combine",
                };
                dispatchChildActions(store, actionWithPatchCreationStrategy, next);
            });
        } else {
            next(action);
        }
    }

    return function (next: any) {
        return function (action: AnyAction | IBatchAction) {
            if (action && action.meta && action.meta.batch) {
                dispatchChildActions(store, action, next);
                return;
            }
            return next(action);
        };
    };
};

/**
 * This middleware injects the current user into the action so it is available in reducers.
 *
 * TODO: We can probably remove this middleware now that we have service to get currentUser when needed
 *
 * @param getServiceContainer - A function that returns the current active service container.
 * @return A middleware function bound to the given service container.
 */
export const currentUserMiddleware =
    (getServiceContainer: () => FluxServices) => () => (next: any) => (action: any) => {
        const currentUser = getServiceContainer().currentUserService.getCurrentUser();
        next({
            ...action,
            currentUser,
        });
    };
