import {FluxErrorCodes} from "@buildwithflux/constants";
import {FluxBaseError} from "@buildwithflux/core";
import {createAction} from "@reduxjs/toolkit";
import type {FirestoreErrorCode} from "firebase/firestore";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";

import {AppStatus, DocumentStatusStates} from "./types";

export class StorageError extends FluxBaseError {
    constructor(message: string, public readonly code?: FirestoreErrorCode, options: ErrorOptions = {}) {
        super(FluxErrorCodes.StorageError, message, options);
    }
}

export const setError = createAction("setError", (error?: StorageError) => {
    return {
        payload: {
            error,
        },
    };
});

export const toggleEditorBackDrop = createAction("toggleEditorBackDrop", (show: boolean) => {
    return {
        payload: {
            show,
        },
    };
});

export const enqueueNotification = createAction(
    "enqueueNotification",
    (message: SnackbarMessage, options?: OptionsObject) => {
        return {
            payload: {
                key: options && options.key ? options.key : new Date().getTime() + Math.random(),
                message,
                options,
            },
        };
    },
);

export const setIsReconnecting = createAction("setIsReconnecting", (reconnecting: boolean) => {
    return {
        payload: {
            reconnecting,
        },
    };
});

export const removeNotification = createAction("removeNotification", (key: SnackbarKey) => {
    return {
        payload: {
            key,
        },
    };
});

export const setDocumentStatus = createAction(
    "setDocumentStatus",
    (state: DocumentStatusStates, timestamp: number, message?: string) => {
        return {
            payload: {
                state,
                timestamp,
                message,
            },
        };
    },
);

export const setAppStatus = createAction("setAppStatus", (status: AppStatus) => {
    return {
        payload: {
            status,
        },
    };
});

export const setLoading = createAction("setLoading", (loading?: boolean) => {
    return {
        payload: {
            loading,
        },
    };
});

export const setIsOnboarding = createAction("setIsOnboarding", (onboarding: boolean) => {
    return {
        payload: {
            onboarding,
        },
    };
});
