import {PerformanceBudgetViolationError, isSuspectedTestUser, AbstractFluxLogger} from "@buildwithflux/core";
import {
    APP_VERSION,
    areWeInStorybook,
    areWeTestingWithJest,
    hasBrowserWindowAccess,
    isDevEnv,
    isMergeRequestPreviewEnvironment,
    getCurrentAppVersionCommitRef,
    getEnvironmentName,
    isProductionEnvironment,
} from "@buildwithflux/shared";
import {
    CaptureConsole,
    ExtraErrorData as ExtraErrorDataIntegration,
    Offline as OfflineIntegration,
} from "@sentry/integrations";
import {captureException, init, reactRouterV5Instrumentation, setContext, setTag, setUser} from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {Integration} from "@sentry/types";
import {SnackbarKey, SnackbarMessage} from "notistack";

import {currentAgentIsBot} from "../../../helpers/isBot";
import {startSentryTransaction} from "../../../helpers/sentry";

class LogConnector implements AbstractFluxLogger {
    /** A function to call to notify the user with a snackbar. This is usually set in Editor.tsx. */
    public notifyUserByUIFn: ((msg: SnackbarMessage, key?: SnackbarKey) => void) | null = null;

    private sentryIsInitialized = false;

    /** A set with the already reported error keys, used to avoid flooding the logs. */
    private alreadyLoggedErrors = new Set<SnackbarKey>();

    constructor() {
        if (!areWeTestingWithJest() && !areWeInStorybook()) {
            this.initSentry();
        }
    }

    public initialize() {
        if (!this.sentryIsInitialized && !areWeTestingWithJest() && !areWeInStorybook()) {
            this.initSentry();
        }
    }

    public setUser(uid: string, handle?: string) {
        if (this.sentryIsInitialized) {
            if (uid && handle) {
                setUser({id: uid, username: handle});
                setTag("userHandle", handle);

                if (isSuspectedTestUser({handle})) {
                    setTag("isTestUser", true);
                }
            } else {
                setUser({id: uid});
            }
            setTag("userUid", uid);
        }
    }

    public setDocumentUid(uid: string, slug: string) {
        if (this.sentryIsInitialized) {
            setTag("documentUid", uid);
            setTag("documentSlug", slug);
        }
    }

    /**
     * Logs an error to Sentry, and optionally shows it to the user in the UI
     * @param error The error to log
     * @param options Optional, specifies the following parameters:
     *              - `notifyUserMessage`: the error string to show to the user. Usually presents as a persistent snackbar.
     *              - `errorKey`: a key for the error type to avoid repeating it multiple times.
     * @remarks The notification is done with the notifyUserByUIFn callback. This is usually set in Editor.tsx.
     */
    public captureError(error: unknown, options?: {notifyUserMessage?: SnackbarMessage; errorKey?: SnackbarKey}) {
        if (options?.errorKey !== undefined && this.alreadyLoggedErrors.has(options?.errorKey)) {
            // Skip error with the same key
            return;
        }
        if (error instanceof PerformanceBudgetViolationError && isMergeRequestPreviewEnvironment()) {
            // Don't log perf errors in preview builds, or UI tests
            // because the performance is too different
            return;
        }
        captureException(error);
        if (!isProductionEnvironment()) {
            // eslint-disable-next-line no-console -- allow legacy console statements
            console.error(error);
        }
        if (options?.notifyUserMessage && this.notifyUserByUIFn) {
            this.notifyUserByUIFn(
                options.notifyUserMessage,
                // Use the error key or the message as the key for preventDuplicate
                options?.errorKey ??
                    (typeof options.notifyUserMessage === "string" ? options.notifyUserMessage : undefined),
            );
        }
        if (options?.errorKey) {
            this.alreadyLoggedErrors.add(options?.errorKey);
        }
    }

    public setSentryContext(contextName: string, context: {}) {
        if (this.sentryIsInitialized && hasBrowserWindowAccess()) {
            setContext(contextName, context);
        }
    }

    /**
     * @deprecated Don't need a FluxLogger to start a Sentry transaction -- call the plain startSentryTransaction function from helpers/sentry.ts
     */
    public startSentryTransaction(transactionName: string) {
        return startSentryTransaction(transactionName);
    }

    private getRelease(): string {
        const ref = getCurrentAppVersionCommitRef();

        if (!ref) {
            // eslint-disable-next-line no-console -- not sure what the alternative is here?
            console.warn(
                "Sentry reporting requires an accurate commit reference, falling back to inaccurate release info",
            );
            return `fluxapp@${APP_VERSION}`;
        }

        return ref;
    }

    private initSentry() {
        if (!isDevEnv()) {
            let tracesSampleRate = 1;
            let environmentName: string | undefined = getEnvironmentName();
            let sentryDSN = process.env.REACT_APP_SENTRY_DSN_PRODUCTION;
            if (process.env.REACT_APP_GITHUB_PR_ID && process.env.REACT_APP_GITHUB_COMMIT_REF) {
                const slug = this.createMergeRequestSlug();
                if (slug) {
                    environmentName = slug;
                    tracesSampleRate = 0.01;
                } else {
                    environmentName = "testing";
                }
            } else {
                tracesSampleRate = 0.01;
            }
            if (process.env.REACT_APP_GITHUB_PR_ID) {
                sentryDSN = process.env.REACT_APP_SENTRY_DSN_PREVIEW;
                // eslint-disable-next-line no-console -- allow legacy console statements
                console.info("Environment Name Identifier: " + environmentName);
                // eslint-disable-next-line no-console -- allow legacy console statements
                console.info("Merge Request Identifier: " + process.env.REACT_APP_GITHUB_PR_ID);
                // eslint-disable-next-line no-console -- allow legacy console statements
                console.info("Code Version Identifier: " + process.env.REACT_APP_GITHUB_COMMIT_REF);
                // eslint-disable-next-line no-console -- allow legacy console statements
                console.info("Build Version Identifier: " + process.env.REACT_APP_GITHUB_BUILD_ID);
            }
            const integrations: Integration[] = [new ExtraErrorDataIntegration(), new OfflineIntegration()];
            // NOTE: this integration will cause a 'document is not defined' error inside of a service worker
            // See https://forum.sentry.io/t/react-browser-tracing-uncaught-referenceerror-document-is-not-defined/13407
            if (hasBrowserWindowAccess()) {
                integrations.push(
                    // eslint-disable-next-line no-restricted-globals
                    new Integrations.BrowserTracing({routingInstrumentation: reactRouterV5Instrumentation(history)}),
                    // include console errors in sentry so we can highlight these in PRs
                    new CaptureConsole({
                        levels: ["error"],
                    }),
                );
            }
            init({
                dsn: sentryDSN,
                autoSessionTracking: true,
                release: this.getRelease(),
                environment: environmentName,
                integrations,
                beforeSend(event) {
                    if (currentAgentIsBot) {
                        return null;
                    }
                    return event;
                },
                normalizeDepth: 2,
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate,
            });
            this.setVersionAndBuildTags();
            this.sentryIsInitialized = true;
        }
    }

    private createMergeRequestSlug() {
        if (process.env.REACT_APP_GITHUB_PR_ID) {
            const mergeRequestNumber = process.env.REACT_APP_GITHUB_PR_ID;
            const environmentNamePrefix = "MR-";
            return environmentNamePrefix + mergeRequestNumber;
        }
        return undefined;
    }

    private setVersionAndBuildTags() {
        if (process.env.REACT_APP_GITHUB_BUILD_ID) {
            setTag("buildID", process.env.REACT_APP_GITHUB_BUILD_ID);
        }

        if (isProductionEnvironment()) {
            // Only set this tag in production
            setTag("appVersion", APP_VERSION);
        }

        if (process.env.REACT_APP_GITHUB_PR_ID) {
            // Only set this tag in merge requests
            setTag("mergeRequestID", process.env.REACT_APP_GITHUB_PR_ID);
        }
    }
}

export const FluxLogger = new LogConnector();
