import {
    Direction,
    ElementHelper,
    IPin,
    ITerminalData,
    IUserData,
    setElementsAndGenerateNodes,
} from "@buildwithflux/core";
import {IDocumentData, IElementData, IPartVersionData, IVector2} from "@buildwithflux/models";
import {guid} from "@buildwithflux/shared";

import {ConnectionToWire} from "./models";
import {autoWirePointToPoint} from "./point_to_point";

type PortalAutoWireStrategyArgs = {
    /**
     * The portal data to use when connecting the terminals.
     */
    portalPartVersion: IPartVersionData;
    /**
     * Pin to "start" wiring from.
     */
    startPin: IPin;
    /**
     * Pin to "end" wiring at.
     */
    endPin: IPin;
    /**
     * Distance that should be created between the element terminals and the new portal terminals.
     */
    elementToPortalDistance: number;
};

/**
 * Generate a new net name for connecting two terminals together using portals.
 */
function createNetNameForConnectingPortals(
    startElement: Readonly<IElementData>,
    startTerminal: Readonly<ITerminalData>,
    targetElement: Readonly<IElementData>,
    targetTerminal: Readonly<ITerminalData>,
): string {
    const startElementName = startElement.label ?? "SRC";
    const startTerminalName = startTerminal.name ?? "SRC PIN";
    const targetElementName = targetElement.label ?? "TGT";
    const targetTerminalName = targetTerminal.name ?? "TGT PIN";
    return `${startElementName} ${startTerminalName} - ${targetElementName} ${targetTerminalName}`;
}

type TerminalData = {
    direction: Direction;
    position: IVector2;
    desiredTerminalSpacing: number;
};

/**
 * Creates a portal for one terminal in the pair of terminals to connect.
 */
function createPortalForTerminal(
    netName: string,
    currentUser: Readonly<IUserData>,
    portalPartVersion: Readonly<IPartVersionData>,
    terminalData: Readonly<TerminalData>,
): IElementData {
    // TODO: this is dirty, we're using knowledge of the portal part in here
    const portalTerminalDistance = 16;

    // Creates the position for the new portal element so that the terminal is offset by the correct
    // distance and the portal is oriented in the right direction relative to the terminal.
    const portalPosition = {
        orientation:
            terminalData.direction === "up"
                ? (3 * Math.PI) / 2
                : terminalData.direction === "down"
                ? Math.PI / 2
                : terminalData.direction === "left"
                ? 0
                : Math.PI,
        x:
            terminalData.position.x +
            (terminalData.direction === "left"
                ? -(terminalData.desiredTerminalSpacing + portalTerminalDistance)
                : terminalData.direction === "right"
                ? terminalData.desiredTerminalSpacing + portalTerminalDistance
                : 0),
        y:
            terminalData.position.y +
            (terminalData.direction === "up"
                ? terminalData.desiredTerminalSpacing + portalTerminalDistance
                : terminalData.direction === "down"
                ? -(terminalData.desiredTerminalSpacing + portalTerminalDistance)
                : 0),
    };

    return ElementHelper.createNewElementData(portalPartVersion, portalPosition, currentUser.uid, netName);
}

/**
 * Finds an existing portal that is connected to the given terminal, if one exists.
 */
function findEligibleExistingPortalForTerminal(
    document: Readonly<IDocumentData>,
    terminal: ITerminalData,
    element: IElementData,
): IElementData | undefined {
    const connectedPins = ElementHelper.getDirectlyConnectedPins(
        {element_uid: element.uid, terminal_uid: terminal.uid},
        document,
    );
    const existingPortalsForTerminal = connectedPins
        .map((pin) => document.elements[pin.element_uid])
        .filter((el) => {
            if (el == null) return false;
            return ElementHelper.isPortal(el);
        });
    for (const portal of existingPortalsForTerminal) {
        if (portal != null) return portal;
    }
}

/**
 * Merge two portals into one.
 */
function mergePortals(document: Readonly<IDocumentData>, portalA: IElementData, portalB: IElementData): void {
    // If they already have the same label, by the semantics of portals they're already connected.
    // TODO: shouldn't we be able to do this with nets?
    if (portalA.label === portalB.label) return;
    // If the source portal label is null, and the target portal label isn't, inherit it.
    if (portalA.label == null && portalB.label != null) {
        portalA.label = portalB.label;
        return;
    }
    // If the target portal label is null, and the source portal label isn't, inherit it.
    if (portalB.label == null && portalA.label != null) {
        portalB.label = portalA.label;
        return;
    }
    // Ok, so neither are null, and they're not the same. We need to merge them.
    const sourcePortalMatches = Object.values(document.elements).filter((el) => el.label === portalA.label);
    const targetPortalMatches = Object.values(document.elements).filter((el) => el.label === portalB.label);
    // If the source portal dominates, choose it.
    if (sourcePortalMatches.length > targetPortalMatches.length) {
        for (const targetPortalMatch of targetPortalMatches) {
            targetPortalMatch.label = portalA.label;
        }
    }

    // If the target portal dominates, choose it.
    else {
        for (const sourcePortalMatch of sourcePortalMatches) {
            sourcePortalMatch.label = portalB.label;
        }
    }
}

/**
 * Creates a new pair of portals that connect to each other.
 */
function createNewPortalPair(
    document: IDocumentData,
    portalName: string,
    currentUser: Readonly<IUserData>,
    portalPartVersion: IPartVersionData,
    sourceTerminalPosition: TerminalData,
    targetTerminalPosition: TerminalData,
    sourcePin: IPin,
    endPin: IPin,
): void {
    const newSourcePortal = createPortalForTerminal(portalName, currentUser, portalPartVersion, sourceTerminalPosition);
    const newTargetPortal = createPortalForTerminal(portalName, currentUser, portalPartVersion, targetTerminalPosition);

    setElementsAndGenerateNodes(document, [newSourcePortal, newTargetPortal]);

    const sourcePortalTerminal = Object.values(
        document.elements[newSourcePortal.uid]?.part_version_data_cache.terminals ?? [],
    )[0];
    if (!sourcePortalTerminal) return undefined; // FIXME
    const endPortalTerminal = Object.values(
        document.elements[newTargetPortal.uid]?.part_version_data_cache.terminals ?? [],
    )[0];
    if (!endPortalTerminal) return undefined; // FIXME

    const connectionsToWire: ConnectionToWire[] = [
        {
            start: sourcePin,
            end: {
                uid: guid(),
                elementUid: newSourcePortal.uid,
                terminalUid: sourcePortalTerminal.uid,
            },
        },

        {
            start: endPin,
            end: {
                uid: guid(),
                elementUid: newTargetPortal.uid,
                terminalUid: endPortalTerminal.uid,
            },
        },
    ];

    autoWirePointToPoint(document, {connectionsToWire});
}

/**
 * Creates a new portal to "match" a given terminal.
 */
function createMatchingPortal(
    document: IDocumentData,
    portalName: string,
    terminalPosition: TerminalData,
    pin: IPin,
    currentUser: IUserData,
    portalPartVersion: IPartVersionData,
): void {
    // Now we create the portal and update the document with it
    const newPortal = createPortalForTerminal(portalName, currentUser, portalPartVersion, terminalPosition);
    setElementsAndGenerateNodes(document, [newPortal]);

    // Lastly we wire from the terminal to the portal.
    const newPortalTerminal = Object.values(
        document.elements[newPortal.uid]?.part_version_data_cache.terminals ?? [],
    )[0];
    if (!newPortalTerminal) return undefined; // FIXME

    const connectionsToWire: ConnectionToWire[] = [
        {
            start: pin,
            end: {
                uid: guid(),
                elementUid: newPortal.uid,
                terminalUid: newPortalTerminal.uid,
            },
        },
    ];

    autoWirePointToPoint(document, {connectionsToWire});
}

/**
 * This module defines an auto-wiring strategy that will connect two terminals together
 * using portals.
 *
 * NOTE: THIS FUNCTION WILL MUTATE THE DOCUMENT DATA.
 */
export function portalAutoWireStrategy(
    document: IDocumentData,
    currentUser: Readonly<IUserData>,
    args: PortalAutoWireStrategyArgs,
) {
    const sourceElement = document.elements[args.startPin.elementUid];
    if (!sourceElement) return undefined; // FIXME
    const targetElement = document.elements[args.endPin.elementUid];
    if (!targetElement) return undefined; // FIXME
    const sourceTerminal = sourceElement.part_version_data_cache.terminals[args.startPin.terminalUid];
    if (!sourceTerminal) return undefined; // FIXME
    const targetTerminal = targetElement.part_version_data_cache.terminals[args.endPin.terminalUid];
    if (!targetTerminal) return undefined; // FIXME
    const sourceTerminalPosition =
        ElementHelper.getAbsoluteTerminalPlacements(sourceElement)[args.startPin.terminalUid];
    if (!sourceTerminalPosition) return undefined; // FIXME
    const targetTerminalPosition = ElementHelper.getAbsoluteTerminalPlacements(targetElement)[args.endPin.terminalUid];
    if (!targetTerminalPosition) return undefined; // FIXME

    const maybeExistingSourcePortal = findEligibleExistingPortalForTerminal(document, sourceTerminal, sourceElement);
    const maybeExistingTargetPortal = findEligibleExistingPortalForTerminal(document, targetTerminal, targetElement);

    /**
     * In this first case, both source and target are ALREADY connected to portals.  This means we do the portal merging dance
     * in which we assign them to a common portal.  Which portal?  We basically pick the portal that corresponds to the net with "more stuff".
     * Unfortunately we can't use the same net merging semantics here, because source and target are completely arbitrary in this case.
     */
    if (maybeExistingSourcePortal != null && maybeExistingTargetPortal != null) {
        return mergePortals(document, maybeExistingSourcePortal, maybeExistingTargetPortal);
    } else {
        /**
         * Here the source portal exists, but the target portal does not.  This means that we want to reuse the source
         * portal, but create a new portal for the target.  The new name for the portal will be inherited from the source portal,
         * unless it doesn't exist, in which case we'll create a new name.
         */
        if (maybeExistingSourcePortal != null) {
            const portalName =
                maybeExistingSourcePortal.label ??
                createNetNameForConnectingPortals(sourceElement, sourceTerminal, targetElement, targetTerminal);

            return createMatchingPortal(
                document,
                portalName,
                {...targetTerminalPosition, desiredTerminalSpacing: args.elementToPortalDistance},
                args.endPin,
                currentUser,
                args.portalPartVersion,
            );
        } else if (maybeExistingTargetPortal != null) {
            /**
             * This case is identical to the above, except now it's the target portal that existed already and the source portal
             * that didn't.
             */
            const portalName =
                maybeExistingTargetPortal.label ??
                createNetNameForConnectingPortals(sourceElement, sourceTerminal, targetElement, targetTerminal);

            return createMatchingPortal(
                document,
                portalName,
                {...sourceTerminalPosition, desiredTerminalSpacing: args.elementToPortalDistance},
                args.startPin,
                currentUser,
                args.portalPartVersion,
            );
        } else {
            // No portals exist at all, create both
            const portalName = createNetNameForConnectingPortals(
                sourceElement,
                sourceTerminal,
                targetElement,
                targetTerminal,
            );
            return createNewPortalPair(
                document,
                portalName,
                currentUser,
                args.portalPartVersion,
                {...sourceTerminalPosition, desiredTerminalSpacing: args.elementToPortalDistance},
                {...targetTerminalPosition, desiredTerminalSpacing: args.elementToPortalDistance},
                args.startPin,
                args.endPin,
            );
        }
    }
}
