import {combineReducers} from "redux";

import {enableBatchingAndPatch} from "../enhancers";

import appReducer from "./app";
import authReducer from "./auth";
import changeHistoryReducer from "./changeHistory";
import {dialogsReducer} from "./dialogs";
import documentReducer from "./document";
import documentMetaReducer from "./documentMeta";
import partReducer from "./part";
import partVersionReducer from "./partVersion";

export const rootReducer = enableBatchingAndPatch(
    combineReducers({
        app: appReducer,
        auth: authReducer,
        changeHistory: changeHistoryReducer,
        dialogs: dialogsReducer,
        document: documentReducer,
        documentMeta: documentMetaReducer,
        part: partReducer,
        partVersion: partVersionReducer,
    }),
);
