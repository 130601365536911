import {PcbBakedNode, PcbNodeTypes, EditorModes, filterNotNull} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

export class InvalidFillsValidator implements IDrcValidator {
    problemTypeKey = "overlapping_fills";
    problemLabel = "Board Layers with multiple Copper Fills";
    problemDescription =
        "Reports layers that have multiple copper fills of different nets. Make sure the Connected Layers rule value of nets with fills is valid.";

    checkForProblems({pcbLayoutNodes}: IDrcInputs) {
        const netsWithFills = Object.values(pcbLayoutNodes).filter((node) => {
            if (node.type === PcbNodeTypes.net) {
                return node.bakedRules.connectedLayers && node.bakedRules.connectedLayers.length > 0;
            }
            return false;
        }) as PcbBakedNode<PcbNodeTypes.net>[];

        const possibleFillLayers = filterNotNull(
            Array.from(new Set(netsWithFills.flatMap((net) => net.bakedRules.connectedLayers))),
        );
        const fillsPerLayer = possibleFillLayers.map((layer) => ({
            layer,
            fills: netsWithFills.filter((net) => net.bakedRules.connectedLayers?.includes(layer)),
        }));

        // If there is an "All" layer, we manualy add it to every fill
        const allRecord = fillsPerLayer.find((fpl) => fpl.layer === "All");
        if (allRecord) {
            fillsPerLayer.forEach((fpl) => {
                if (fpl !== allRecord) allRecord.fills.forEach((f) => fpl.fills.push(f));
            });
        }

        const layersWithProblems = fillsPerLayer.filter((fpl) => fpl.fills.length > 1);

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: layersWithProblems.map((el) => ({
                problemTypeKey: this.problemTypeKey,
                key: `${this.problemTypeKey}_${el.layer}`,
                label: el.layer,
                affectedItems: el.fills.map((f) => ({type: "pcbLayoutNode" as const, uid: f.uid})),
                affectedViews: [EditorModes.pcb],
            })),
        };
    }
}
