/**
 * These are the little icon filters a user can toggle, below the part search
 * text input.
 */
export enum PartSearchRefinement {
    organization = "organization",
    owner = "owner",
    starred = "starred",
    footprint = "footprint",
    layout = "layout",
    simulationModel = "simulationModel",
    datasheet = "datasheet",
    mpn = "mpn",
    threeDModel = "threeDModel",
    distributors = "distributors",
}

export type PartSearchRefinements = {
    organization: boolean;
    owner: boolean;
    starred: boolean;
    footprint: boolean;
    layout: boolean;
    simulationModel: boolean;
    datasheet: boolean;
    mpn: boolean;
    threeDModel: boolean;
    distributors: string[];
};

/**
 * The initial filter state, when a document has just been loaded (i.e. opened
 * a project page).
 */
export const initialPartSearchRefinements: PartSearchRefinements = {
    organization: false,
    owner: false,
    starred: false,
    footprint: false,
    layout: false,
    simulationModel: false,
    datasheet: false,
    mpn: false,
    threeDModel: false,
    distributors: [],
};
