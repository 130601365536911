import {Orientation} from "@buildwithflux/core";
import {Vector2} from "three";

import {WireConnectionPoint, WireProjectionAlgorithm, WireProjectionVertex} from "./Wiring";

const {HORIZONTAL, VERTICAL} = Orientation;

export class WireProjectionSimple implements WireProjectionAlgorithm {
    public project(from: WireConnectionPoint, to: WireConnectionPoint): WireProjectionVertex[] {
        return [
            {position: from.position},
            {direction: HORIZONTAL, position: new Vector2(to.position.x, from.position.y)},
            {direction: VERTICAL, position: to.position.clone()},
        ];
    }
}
