import behaviors from "./Behaviors";
import externalUrls from "./ExternalUrls";
import fonts from "./Fonts";
import keyCommands from "./KeyCommands";
import layout from "./Layout";
import presentation from "./Presentation";
import strings from "./Strings";

const R = {
    strings,
    layout,
    behaviors,
    fonts,
    keyCommands,
    presentation,
    externalUrls,
};

export default R;
