import {ClipperShapeFactory} from "@buildwithflux/core";
import {Vector2} from "three";

import {DifferenceShape, Shape, ShapeType, UnionShape} from "../types";

import {pointDistance} from "./pointDistance";

/** Checks if a 2D point is contained inside a shape */
export function pointInShape(
    shape: Shape,
    pointX: number,
    pointY: number,
    clipperShapeFactory: ClipperShapeFactory,
): boolean {
    switch (shape.shapeType) {
        case ShapeType.Union:
            return unionPointInShape(shape, pointX, pointY, clipperShapeFactory);
        case ShapeType.Difference:
            return differencePointInShape(shape, pointX, pointY, clipperShapeFactory);
        default:
            return pointDistance(shape, pointX, pointY, new Vector2(), clipperShapeFactory) <= 0;
    }
}

function unionPointInShape(
    shape: UnionShape,
    pointX: number,
    pointY: number,
    clipperShapeFactory: ClipperShapeFactory,
): boolean {
    const pos = new Vector2(pointX, pointY).applyMatrix3(shape.transformInverse);
    return (
        pointInShape(shape.shapeA, pos.x, pos.y, clipperShapeFactory) ||
        pointInShape(shape.shapeB, pos.x, pos.y, clipperShapeFactory)
    );
}

function differencePointInShape(
    shape: DifferenceShape,
    pointX: number,
    pointY: number,
    clipperShapeFactory: ClipperShapeFactory,
): boolean {
    const pos = new Vector2(pointX, pointY).applyMatrix3(shape.transformInverse);
    return (
        pointInShape(shape.shapeA, pos.x, pos.y, clipperShapeFactory) &&
        !pointInShape(shape.shapeB, pos.x, pos.y, clipperShapeFactory)
    );
}
