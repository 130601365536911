import {IAssetsMap} from "@buildwithflux/core";

import {PartStorageHelper} from "../../../../modules/storage_engine/helpers/PartStorageHelper";
import type {IApplicationState} from "../../../../state";

const select3dModelAssetStorageName = (state: IApplicationState): string | undefined => {
    return PartStorageHelper.get3DModelAssetFromAssets(state.document?.assets ?? {});
};

// NOTE: must use shallowEqual for memoization
const selectAssets = (state: IApplicationState): IAssetsMap => state.document?.assets ?? {};

const assetsSelectors = {
    selectAssets,
    select3dModelAssetStorageName,
};

export default assetsSelectors;
