import {
    FeatureFlagClientNotInitializedError,
    isTestUser,
    IUserData,
    OrganizationHandle,
    SubscriptionManager,
} from "@buildwithflux/core";
import {
    areWeInStorybook,
    areWeTestingWithJest,
    getEnvironmentName,
    hasBrowserWindowAccess,
    Unsubscriber,
} from "@buildwithflux/shared";
import {initialize, LDClient, LDContext, LDFlagSet} from "launchdarkly-js-client-sdk";
import {LDContextCommon, LDMultiKindContext, LDSingleKindContext} from "launchdarkly-js-sdk-common";
import {isEqual} from "lodash";

import {OrganizationStoreState} from "../../auth/state/organization";

import {FluxLogger} from "./LogConnector";

// IMPORTANT:
// LD bills based on number of monthly active contexts. Active contexts are defined by the number of unique keys that are
// tracked at *initialize* time or when identifying a context via *identify*. If a key is not specified in either
// scenario, LD creates a unique key for that user/session. Since we initialize the LD client before we have a user
// context, LD recommends to always initialize with the same user key, then replace it via *identify* once we know
// who the user is. Otherwise every session generates a new unique key (which we pay for).
// See LD documentation @ https://docs.launchdarkly.com/sdk/features/user-config#anonymous-users-in-the-nodejs-sdk
// See internal documentation @ https://buildwithflux.workplace.com/work/knowledge/670807980925056
const anonymousUser: LDSingleKindContext = {
    kind: "user",
    key: "anonymous-user",
    anonymous: true,
};
const initialUser: LDSingleKindContext = {
    kind: "user",
    key: "initial-user",
    anonymous: true,
};
const testUser: LDSingleKindContext = {
    kind: "user",
    key: "test-user",
    anonymous: false,
};

function isMultiKindContext(context: LDContext | undefined): context is LDMultiKindContext {
    return !!context && (context as LDMultiKindContext)?.kind === "multi";
}

export function isMultiKindContextWithUser(
    context: LDContext | undefined,
): context is LDMultiKindContext & {user: LDSingleKindContext} {
    return isMultiKindContext(context) && !!(context as LDMultiKindContext)?.user;
}

export function isAnonymousContext(context: LDContext): boolean {
    if (isMultiKindContext(context) && !!context.user) {
        return !!(context.user as LDContextCommon).anonymous;
    }
    return !!context.anonymous;
}

export type FlagSet = LDFlagSet | undefined | null;

/**
 * IMPORTANT NOTE: Please don't call this class directly in React components
 *
 * Please see PR description in https://github.com/buildwithflux/flux-app/pull/8833,
 * about how LaunchDarkly works in Flux, how flag values get changed, and the responsibility
 * of this class
 */
export class LaunchDarklyConnector {
    private static instance: LaunchDarklyConnector;

    private clientKey: string;
    private client?: LDClient;
    private isInitialized = false;
    private subscriptionManager: SubscriptionManager<"featureFlags", FlagSet>;
    private currentFlagSet: FlagSet;
    private currentContext: LDMultiKindContext | undefined;
    private enterpriseHandles: OrganizationHandle[] = [];
    private readonly siteContext: LDSingleKindContext;

    constructor() {
        this.clientKey = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_KEY_PRODUCTION!;
        // TODO launchdarkly doesn't work from jest tests. Is this the best way to work around it?
        // Maybe we should make it so getFlag always returns true from jest tests when not inited?
        this.subscriptionManager = new SubscriptionManager();

        this.siteContext = {
            kind: "site",
            key: getEnvironmentName() ?? "unknown",
        };
    }

    /**
     * Only set testMode to true in corresponding tests
     */
    async init(testMode?: boolean) {
        if ((!!testMode || !areWeTestingWithJest()) && !areWeInStorybook()) {
            await this.initClient();
        }
    }

    public static getInstance() {
        if (!hasBrowserWindowAccess()) {
            return null;
        }
        if (!LaunchDarklyConnector.instance) {
            LaunchDarklyConnector.instance = new LaunchDarklyConnector();
        }
        return LaunchDarklyConnector.instance;
    }

    public async identifyUser(user: IUserData | null | undefined): Promise<LDFlagSet | undefined> {
        if (!this.client || !this.isInitialized) {
            FluxLogger.captureError(
                new FeatureFlagClientNotInitializedError(new Error(`identifyUser called on uninitialized client`)),
            );
            return;
        }

        if (isTestUser(user)) {
            // NOTE: Instead of passing in an `onDone` callback to call updateStateAndNotify, await the
            // promise and then call it, because the `onDone` callback is called and wrapped in
            // a `setTimeout(..., 0)`, and could be flaky in terms of timing.
            // See their source code: https://github.com/launchdarkly/js-sdk-common/blob/main/src/utils.js#L68

            // this forces all test users to use the same key, otherwise every user counts towards our monthly user limit
            await this.client.identify({
                kind: "multi",
                user: testUser,
                site: this.siteContext,
            });

            // In case feature flags for test user is the same for initial user, call `updateStateAndNotify`
            // manually when finish identifying
            this.updateStateAndNotify(this.client?.allFlags());
        } else if (user && !user.isAnonymous) {
            const userContext: LDSingleKindContext = {
                kind: "user",
                key: user.handle,
                anonymous: false,
                email: user.email,
                name: user.full_name,
                created_at: user.created_at,
            };

            // Length check so we don't create too many duplicate contexts, because this logic is not present in the backend yet
            if (this.enterpriseHandles.length > 0) {
                userContext.enterprise_organization_handles = this.enterpriseHandles;
            }

            await this.client.identify({
                kind: "multi",
                user: userContext,
                site: this.siteContext,
            });
        } else {
            // NOTE: Instead of passing in an `onDone` callback to call updateStateAndNotify, await the
            // promise and then call it, because the `onDone` callback is called and wrapped in
            // a `setTimeout(..., 0)`, and could be flaky in terms of timing.
            // See their source code: https://github.com/launchdarkly/js-sdk-common/blob/main/src/utils.js#L68
            await this.client.identify({
                kind: "multi",
                user: anonymousUser,
                site: this.siteContext,
            });

            // In case feature flags for test user is the same for initial user, call `updateStateAndNotify`
            // manually when finish identifying
            this.updateStateAndNotify(this.client?.allFlags());
        }
    }

    public getContext() {
        return this.client?.getContext();
    }

    public allFlags() {
        return this.client?.allFlags();
    }

    public onIsReady(callback: (flags: LDFlagSet) => void) {
        if (this.client) {
            this.client.on("initialized", () => {
                if (this.client) {
                    callback(this.client.allFlags());
                }
            });
            this.client.on("change", () => {
                if (this.client) {
                    callback(this.client.allFlags());
                }
            });
        }
    }

    private subscribeToOrganizationChanges(): void {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const {getActiveServicesContainerBadlyAsServiceLocator} = require("../../../injection");
        const {useOrganizationStore} = getActiveServicesContainerBadlyAsServiceLocator();
        useOrganizationStore.subscribe(this.processOrganizationState.bind(this));
        this.processOrganizationState(useOrganizationStore.getState());
    }

    private processOrganizationState(state: OrganizationStoreState): void {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const {getActiveServicesContainerBadlyAsServiceLocator} = require("../../../injection");
        const {currentUserService} = getActiveServicesContainerBadlyAsServiceLocator();

        const enterpriseHandles = state.memberships
            .filter((membership) => membership.organization.visibility === "unlisted")
            .map((membership) => membership.organization.handle)
            .sort();

        if (!isEqual(enterpriseHandles, this.enterpriseHandles)) {
            this.enterpriseHandles = enterpriseHandles;
            void this.identifyUser(currentUserService.getCurrentUser());
        }
    }

    private async initClient() {
        if (this.isInitialized) return;
        // IMPORTANT: See above for why we reuse the initialUser key at initialize time
        this.client = initialize(
            this.clientKey,
            {
                kind: "multi",
                user: initialUser,
                site: this.siteContext,
            },
            {streaming: true, bootstrap: "localStorage"},
        );

        await this.client
            .waitForInitialization()
            .then(() => {
                if (!this.client) {
                    throw new Error("failed to initialize LD client");
                }
                this.isInitialized = true;
                const currentFlagValues = this.client?.allFlags();
                this.updateStateAndNotify(currentFlagValues);
                this.client.on("change", () => {
                    this.updateStateAndNotify(this.client?.allFlags());
                });
                this.subscribeToOrganizationChanges();
            })
            .catch((error) => {
                // we want to know when this fails to initialize but it shouldn't block the user
                FluxLogger.captureError(new FeatureFlagClientNotInitializedError(error));
            });
    }

    public subscribeToFlags(onChange: (flagValues: FlagSet) => void): Unsubscriber {
        const currentValue = this.client?.allFlags();
        if (currentValue != null && Object.keys(currentValue).length > 0) {
            onChange(currentValue);
        }
        return this.subscriptionManager.addSubscription("featureFlags", onChange);
    }

    private updateStateAndNotify(newFlags: FlagSet) {
        const newContext = this.client?.getContext();
        const contextHasIdentifiedUser =
            !!newContext && isMultiKindContextWithUser(newContext) && newContext.user.key !== initialUser.key;
        const flagsChanged = !isEqual(this.currentFlagSet, newFlags);
        const contextChanged = !isEqual(this.currentContext, newContext);

        // See more detail in https://github.com/buildwithflux/flux-app/pull/8722 about why
        // we setup these conditions here.
        // These conditions are tested by `FeatureFlagConnector.test.ts`
        if (contextHasIdentifiedUser && (flagsChanged || contextChanged)) {
            this.currentFlagSet = newFlags;
            this.currentContext = newContext;
            this.subscriptionManager.notify("featureFlags", newFlags);
        }
    }
}

const FeatureFlagConnector = LaunchDarklyConnector.getInstance();

(async function () {
    if (areWeTestingWithJest()) {
        // Let test to call init function. See tests in `FeatureFlagConnector.test.ts`
    } else {
        await FeatureFlagConnector?.init();
    }
})();
export {FeatureFlagConnector};
