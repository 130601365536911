import {AnyPcbBakedNode, IPcbBoardLayerBase, PcbNodeHelper, PcbNodeTypes, PcbNodesMap} from "@buildwithflux/core";

import {createShapesForPad} from "./generators/createShapesForPad";
import {createShapesForRouteSegment} from "./generators/createShapesForRouteSegment";
import {createShapesForVia} from "./generators/createShapesForVia";
import {createShapesForZone} from "./generators/createShapesForZone";
import {Shape} from "./types";

/** NOTE: This function returns an empty array with inactive nodes */
export function createShapesForNode(
    node: AnyPcbBakedNode,
    allNodes: PcbNodesMap<AnyPcbBakedNode>,
    stackup: IPcbBoardLayerBase[],
): Shape[] {
    if (!node.bakedRules.active) return [];
    if (!PcbNodeHelper.isNodeNotRoot(node)) return [];

    // Pads
    if (node.type === PcbNodeTypes.pad) {
        return createShapesForPad(node, stackup);
    }

    // Vias
    if (node.type === PcbNodeTypes.via) {
        return createShapesForVia(node, allNodes, stackup);
    }

    // RouteSegments
    if (node.type === PcbNodeTypes.routeSegment) {
        return createShapesForRouteSegment(node, stackup);
    }

    // Zones
    if (node.type === PcbNodeTypes.zone) {
        return createShapesForZone(node, stackup);
    }

    return [];
}
