/* eslint-disable import/no-unused-modules */
import device from "current-device";
import {detect} from "detect-browser";

/*
 * We only need to do this part once.
 */
const currentBrowser = detect();

export function isMacintosh() {
    // TODO: consider refactor to currentBrowser?.os?.includes('Mac'), to avoid use of depreciated feature?
    return navigator.platform.indexOf("Mac") > -1;
}

export function isWindows() {
    // TODO: consider refactor to currentBrowser?.os?.includes('Windows'), to avoid use of depreciated feature?
    return navigator.platform.indexOf("Win") > -1;
}

export function isLinux() {
    // TODO: consider refactor to currentBrowser?.os?.includes('Linux'), to avoid use of depreciated feature?
    return navigator.platform.indexOf("Linux") > -1;
}

export function isAndroid() {
    return device.android();
}

export function isIos() {
    return device.ios();
}

export function isMobile() {
    return device.ios() || device.android();
}

export function isMsEdge() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        // IE 10 or older
        return true;
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
        // IE 11
        // QUESTION: is this next line actually needed?
        // const rv = ua.indexOf("rv:");
        return true;
    }

    const edge = ua.indexOf("Edge/");
    const edg = ua.indexOf("Edg/");
    if (edge > 0 || edg > 0) {
        // Edge (IE 12+)
        return true;
    }

    // other browser
    return false;
}
export function isFirefox() {
    return currentBrowser?.name === "firefox";
}

export function isChrome() {
    return currentBrowser?.name === "chrome";
}

export function isSafari() {
    return currentBrowser?.name === "safari";
}
