import {areWebWorkersSupported} from "@buildwithflux/shared";
import {isAnyOf} from "@reduxjs/toolkit";

// eslint-disable-next-line boundaries/element-types
import {getActiveServicesContainerBadlyAsServiceLocator} from "../../../injection/singleton";
import {applyPcbLayoutEnginePatches} from "../../../modules/pcb_layout_engine/pcbLayoutEngineInMain";
import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";
import {applyActionRecords, revertActionRecords} from "../../../redux/reducers/document/actions";
import {isDocumentReduxAction} from "../../../redux/reducers/document/actions.types";
import DocumentPatchManager from "../../../redux/reducers/document/DocumentPatchManager";
import {bakeNodesFromLatestPatchWithRetry} from "../bakeNodesFromLatestPatch";
import {enqueueSyncErrorNotification} from "../helpers";

import {PatchHandlerSideEffect} from "./types";

/**
 * Runtime safety check to go with makeGenerateActionRecordsEpic
 * TODO: this is still being triggered by some actions like setPartOwners and
 * removeNotification that should not be affecting document state. Need to
 * investigate. See
 * https://buildwithflux.sentry.io/issues/?project=5669122&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D+patchHandlerEpicSideEffects.ts&referrer=issue-list&statsPeriod=90d
 */
export const runtimeCheck: PatchHandlerSideEffect = ({action, patch}) => {
    const paths = patch.forward.flat().map((p) => p.path);
    if (isDocumentReduxAction(action) && paths.length) {
        if (!("shouldGenerateActionRecord" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying shouldGenerateActionRecord from paths: ${paths}`,
                ),
            );
        }
        if (!("updatesDocumentTimestamp" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying updatesDocumentTimestamp from paths: ${paths}`,
                ),
            );
        }
        if (!("canUndo" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying canUndo from paths: ${paths}`,
                ),
            );
        }
    }
};

/**
 * Add the forward and reverse patches to the undo and redo stacks
 * We only do so when:
 * 1. The action is undoable - with `canUndo` flag
 * The action is NOT from remote user - we dont wanna undo any changes from other users
 */
export const processUndoRedo =
    (documentPatchManager: typeof DocumentPatchManager): PatchHandlerSideEffect =>
    ({action, patch}) => {
        if (
            isDocumentReduxAction(action) &&
            action.payload.canUndo &&
            !isAnyOf(applyActionRecords, revertActionRecords)(action)
        ) {
            // QUESTION: how do we want to handle errors from onNewUndoableAction?
            documentPatchManager.onNewUndoableAction(action, patch.forward, patch.reverse);
        }
    };

/**
 * Patch web workers state with the latest patch.
 * NOTE: this needs to happen before baking!
 */
export const syncPatchesWithWebWorkers: PatchHandlerSideEffect = ({patch}) => {
    // NOTE: web workers do not exist in jest
    if (!areWebWorkersSupported()) return;

    const {userCodeRuntimeWrapper, reduxStoreService} = getActiveServicesContainerBadlyAsServiceLocator();
    applyPcbLayoutEnginePatches(patch.forward).catch((error) => {
        const message = "Error syncing PCB data!";
        FluxLogger.captureError(error);
        reduxStoreService.getStore().dispatch(enqueueSyncErrorNotification(message));
    });

    userCodeRuntimeWrapper
        .init()
        .then((simulator) => simulator.applyPatches(patch))
        // Any error should be captured by the user code runtime, which will handle updating the error state
        .catch((error) => FluxLogger.captureError(error));
};

/**
 * Handle changes that require rebaking PCB nodes
 */
export const bakePcbNodes: PatchHandlerSideEffect = ({action, document}) => {
    const {reduxStoreService} = getActiveServicesContainerBadlyAsServiceLocator();
    // NOTE: Baking is called specially in the doc loader epic
    // AND `setDocumentData` action is already excluded
    // from withHistoryUpdate in the document reducer
    return bakeNodesFromLatestPatchWithRetry(action, document).catch((error) => {
        const message = "Error generating PCB data!";
        if (error instanceof Error) {
            error.message = action.type + ": " + message + " " + error.message;
        }
        FluxLogger.captureError(error);
        reduxStoreService.getStore().dispatch(enqueueSyncErrorNotification(message));
    });
};
