import {IUserData} from "@buildwithflux/core";
import {AnyAction} from "redux";

// TODO: can we reuse the type from redux toolkit or not
export type PayloadProps = Record<string, any | undefined>;
export type PayloadAction<P extends PayloadProps> = AnyAction & {payload: P; currentUser?: IUserData};

/**
 * @see createActionRecordAction for related typing
 */
export interface DocumentPayloadProps extends PayloadProps {
    canUndo?: boolean;
    updatesDocumentTimestamp?: boolean;
    shouldGenerateActionRecord?: boolean;
}

export type IDocumentReduxAction = PayloadAction<DocumentPayloadProps>;

// This is pretty weak as a type predicate, but it's as good as we're gonna do given how the
// payload props are defined.
export function isDocumentReduxAction(action: AnyAction): action is IDocumentReduxAction {
    return "payload" in action && action.payload != null;
}
