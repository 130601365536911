import {
    DocumentSnapshotDescriptor,
    AbstractDocumentSnapshotStorageAdapter,
    StoredDocumentSnapshotData,
} from "@buildwithflux/core";

/**
 * This adapter is a no-op.  It _always_ results in a cache miss.
 */
export class NoOpDocumentSnapshotStorageAdapter implements AbstractDocumentSnapshotStorageAdapter {
    public get(_descriptor: Readonly<DocumentSnapshotDescriptor>): Promise<StoredDocumentSnapshotData | undefined> {
        return Promise.resolve(undefined);
    }
    public save(_partVersionData: Readonly<StoredDocumentSnapshotData>): Promise<void> {
        throw new Error("Cannot save data using this client.");
    }
    public exists(_descriptor: Readonly<DocumentSnapshotDescriptor>): Promise<boolean> {
        return Promise.resolve(false);
    }
}
