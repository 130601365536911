import {GetEnterpriseByUidError, GetOrganizationByUidError, GetUserByUidError} from "@buildwithflux/core";
import {
    EnterpriseUid,
    OrganizationUid,
    UserUid,
    IUserData,
    Enterprise,
    Organization,
    isOrganizationUid,
    isEnterpriseUid,
    AccountWithDetails,
    AccountOrEnterpriseUid,
    isNonEnterpriseOrganization,
    isEnterpriseOrganization,
    OrganizationChangeCallback,
} from "@buildwithflux/models";
import {
    EnterpriseChangeCallback,
    EnterpriseRepository,
    OrganizationRepository,
    UserRepository,
} from "@buildwithflux/repositories";
import {isDevEnv, Unsubscriber, Logger, areWeTestingWithJest, areWeInStorybook} from "@buildwithflux/shared";
import {useEffect} from "react";
import {StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {createStore} from "zustand/vanilla";

import {createBoundUseStoreHook} from "../../helpers/zustand";
import {useFluxServices} from "../../injection/hooks";
import {FluxLogger} from "../storage_engine/connectors/LogConnector";

/**
 * API of this store - somewhat equivalent to actions, these are the only ways the store state can be modified from outside
 */
export interface AccountInformationStoreApi {
    /**
     * Stop all subscriptions and clear all the cached information
     */
    clearAll(): void;

    /**
     * Subscribe to changes in the account information store - this is called by hooks such as useAccountInformation(uid)
     */
    subscribe(
        identifier:
            | {type: "user"; uid: UserUid}
            | {type: "organization"; uid: OrganizationUid}
            | {type: "defaultOrganization"; uid: OrganizationUid}
            | {type: "enterprise"; uid: EnterpriseUid},
    ): Unsubscriber;
}

/**
 * The internal data state of this store
 *
 * The reason the subscriptions are split into more types than the data is to avoid an 'island', where an enterprise
 * refers to an organization as its default organization, and that same organization refers to the enterprise. This
 * would otherwise result in both having a refCount of 1, and neither being unsubscribed from.
 */
type AccountInformationStoreData = {
    // Cache
    users: Record<UserUid, CacheEntry<IUserData>>;
    organizations: Record<OrganizationUid, CacheEntry<Organization>>;
    enterprises: Record<EnterpriseUid, CacheEntry<Enterprise>>;

    // Subscriptions
    userSubscriptions: Record<UserUid, SubscriptionEntry>;
    organizationSubscriptions: Record<OrganizationUid, SubscriptionEntry>;
    defaultOrganizationSubscriptions: Record<OrganizationUid, SubscriptionEntry>;
    enterpriseSubscriptions: Record<EnterpriseUid, SubscriptionEntry>;
};

/**
 * The exported type of the store
 */
export type UseAccountInformationStore = UseBoundStore<StoreApi<AccountInformationStoreState>>;

/**
 * We default to caching account information for five minutes after it was used on the page
 *
 * We always reopen a subscription, in any case, when a piece of information is used on the page. And we don't start
 * counting towards this time limit until after the subscription is closed. This means this cache is only used to make
 * results instant, rather than needing to wait for the subscription to come back with the info
 */
const defaultCacheTtl = 5 * 60 * 1_000;

/**
 * A cache entry in the account information store
 *
 * We don't attach a TTL this data, because we may have received it from an active subscription, in which case
 * it'll always be up to date, even if it was originally received e.g. an hour ago.
 *
 * Once there are no active subscriptions for the data, then we attach a setTimeout() function to it, and when that
 * is triggered, the cache entry expires and is removed
 */
interface CacheEntry<T> {
    /**
     * The actual data stored in the cache entry
     */
    data: T;

    /**
     * An optional reference to a setTimeout() that will expire this cache entry, removing it from the store
     */
    timeout?: ReturnType<typeof setTimeout>;
}

/**
 * Sometimes the unsubscribers here need to run as part of an existing immer-middleware set() production function, so
 * these get passed the state (rather than call their own set() mutation)
 */
type StatefulUnsubscriber = (state: AccountInformationStoreState) => void;

/**
 * When we store subscriptions in the state, they're represented by an underlying unsubscriber function that will unsubscribe
 * from Firestore and any related information, and a refcount representing how many times this piece of information has been
 * subscribed to.
 */
interface SubscriptionEntry {
    unsubscriber: StatefulUnsubscriber;
    refCount: number;
}

/**
 * What the store looks like when it's empty
 */
const blankState: AccountInformationStoreData = {
    users: {},
    organizations: {},
    enterprises: {},

    userSubscriptions: {},
    organizationSubscriptions: {},
    defaultOrganizationSubscriptions: {},
    enterpriseSubscriptions: {},
};

type AccountInformationStoreState = AccountInformationStoreApi & AccountInformationStoreData;

/**
 * The main hook for using the account information store easily
 *
 * This hook takes a user, organization, or enterprise UID, and returns the account information for that UID, reactively.
 *
 * We recommend using this (or its `*IfNeeded` variant described below) as the entrypoint into everything else in this store
 */
export function useAccountInformation(accountUid: AccountOrEnterpriseUid | undefined): AccountWithDetails | undefined {
    const {logger, useAccountInformationStore} = useFluxServices();
    const {subscribe} = useAccountInformationStore.getState();

    useEffect(() => {
        if (!accountUid) {
            return;
        }

        if (isEnterpriseUid(accountUid)) {
            return subscribe({type: "enterprise", uid: accountUid});
        } else if (isOrganizationUid(accountUid)) {
            return subscribe({type: "organization", uid: accountUid});
        } else {
            return subscribe({type: "user", uid: accountUid});
        }
    }, [accountUid, subscribe]);

    return useAccountInformationStore((state) => {
        if (!accountUid) {
            return undefined;
        }

        return selectAccountWithDetailsFromStoreState(state, accountUid, logger);
    });
}

/**
 * Variant of the useAccountInformation() hook that also takes a pre-loaded account object, usually provided by a parent
 * component via props.
 *
 * If the account object is provided, it will be used directly, and the accountUid will be ignored. The accountUid and
 * the store are used to fetch information about the account if the account object is not provided.
 */
export function useAccountInformationIfNeeded(
    accountFromProp: AccountWithDetails | undefined,
    accountUidToLoadIfNeeded: AccountOrEnterpriseUid | undefined,
): AccountWithDetails | undefined {
    const {logger, useAccountInformationStore} = useFluxServices();
    const {subscribe} = useAccountInformationStore.getState();

    useEffect(() => {
        if (accountFromProp || !accountUidToLoadIfNeeded) {
            return;
        }

        if (isEnterpriseUid(accountUidToLoadIfNeeded)) {
            return subscribe({type: "enterprise", uid: accountUidToLoadIfNeeded});
        } else if (isOrganizationUid(accountUidToLoadIfNeeded)) {
            return subscribe({type: "organization", uid: accountUidToLoadIfNeeded});
        } else {
            return subscribe({type: "user", uid: accountUidToLoadIfNeeded});
        }
    }, [accountFromProp, accountUidToLoadIfNeeded, subscribe]);

    return useAccountInformationStore((state) => {
        if (accountFromProp) {
            return accountFromProp;
        }

        if (!accountUidToLoadIfNeeded) {
            return undefined;
        }

        return selectAccountWithDetailsFromStoreState(state, accountUidToLoadIfNeeded, logger);
    });
}

/**
 * Creates the vanilla non-bound-to-React account information store
 *
 * This is a vanilla Zustand store, and is only exported for use in tests. In components, you'd usually use the hooks
 * above, and even when trying to access low-level details of the store, you'd use the bound version of this store,
 * described below.
 *
 * The store returned from this function cannot be directly used as a hook in a component. For that, see
 * `createAccountInformationStoreHook` below.
 */
export function createAccountInformationStore(
    userRepository: UserRepository,
    organizationRepository: OrganizationRepository,
    enterpriseRepository: EnterpriseRepository,
) {
    return createStore<AccountInformationStoreState>()(
        devtools(
            immer((set, get): AccountInformationStoreState => {
                function startNewUserSubscription(userUid: UserUid): StatefulUnsubscriber {
                    const unsubscribeFromUser = userRepository.subscribeToUser(userUid, (change): void => {
                        if (change.type === "error") {
                            FluxLogger.captureError(
                                new GetUserByUidError(
                                    `AccountInformationStore subscription for ${userUid}`,
                                    change.error,
                                ),
                            );
                            return;
                        }

                        if (change.type === "created" || change.type === "updated") {
                            set(
                                (state) => {
                                    (state.users[userUid] ??= toCacheEntry(change.data)).data = change.data;
                                },
                                false,
                                "storeDataAfterUserSubscriptionResult",
                            );
                        }

                        if (change.type === "deleted") {
                            set(
                                (state) => {
                                    delete state.users[userUid];
                                },
                                false,
                                "deleteDataAfterUserSubscriptionDeleteResult",
                            );
                        }
                    });

                    return (state) => {
                        unsubscribeFromUser();

                        // Now that we are no longer subscribed to this user, we should clear any cache entry after 5 minutes
                        setCacheTimeout(state.users[userUid], () => {
                            set(
                                (state) => {
                                    delete state.users[userUid];
                                },
                                false,
                                "cacheTimeoutOnUser",
                            );
                        });
                    };
                }

                function startNewOrganizationSubscription(
                    organizationUid: OrganizationUid,
                    asDefaultOrganizationForEnterprise: boolean,
                ): StatefulUnsubscriber {
                    let unsubscribeFromEnterprise: Unsubscriber | undefined;

                    const onChange: OrganizationChangeCallback = (change): void => {
                        if (change.type === "error") {
                            FluxLogger.captureError(
                                new GetOrganizationByUidError(
                                    `AccountInformationStore subscription for ${organizationUid}`,
                                    change.error,
                                ),
                            );
                            return;
                        }

                        if (change.type === "created" || change.type === "updated") {
                            const organization = change.data;

                            if (isEnterpriseOrganization(organization) && !asDefaultOrganizationForEnterprise) {
                                unsubscribeFromEnterprise = get().subscribe({
                                    type: "enterprise",
                                    uid: organization.enterpriseUid,
                                });
                            }

                            set(
                                (state) => {
                                    (state.organizations[organizationUid] ??= toCacheEntry(change.data)).data =
                                        change.data;
                                },
                                false,
                                "storeDataAfterOrganizationSubscriptionResult",
                            );
                        }

                        if (change.type === "deleted") {
                            set(
                                (state) => {
                                    delete state.organizations[organizationUid];
                                },
                                false,
                                "deleteDataAfterOrganizationSubscriptionDeleteResult",
                            );
                        }
                    };

                    const unsubscribeFromOrganization = organizationRepository.subscribeToOrganization(
                        organizationUid,
                        onChange,
                    );

                    return (state) => {
                        unsubscribeFromEnterprise?.();
                        unsubscribeFromOrganization();

                        // Now that we are no longer subscribed to this organization, we should clear any cache entry after 5 minutes
                        setCacheTimeout(state.organizations[organizationUid], () => {
                            set(
                                (state) => {
                                    delete state.organizations[organizationUid];
                                },
                                false,
                                "cacheTimeoutOnOrganization",
                            );
                        });
                    };
                }

                function startNewEnterpriseSubscription(enterpriseUid: EnterpriseUid): StatefulUnsubscriber {
                    let unsubscribeFromDefaultOrganization: Unsubscriber | undefined;

                    const onChange: EnterpriseChangeCallback = (change): void => {
                        if (change.type === "error") {
                            FluxLogger.captureError(
                                new GetEnterpriseByUidError(
                                    `AccountInformationStore subscription for ${enterpriseUid}`,
                                    change.error,
                                ),
                            );
                            return;
                        }

                        if (change.type === "created" || change.type === "updated") {
                            const enterprise = change.data;

                            unsubscribeFromDefaultOrganization = get().subscribe({
                                type: "defaultOrganization",
                                uid: enterprise.defaultOrganizationUid,
                            });

                            set(
                                (state) => {
                                    (state.enterprises[enterpriseUid] ??= toCacheEntry(change.data)).data = change.data;
                                },
                                false,
                                "storeDataAfterUserSubscriptionResult",
                            );
                        }

                        if (change.type === "deleted") {
                            set(
                                (state) => {
                                    delete state.enterprises[enterpriseUid];
                                },
                                false,
                                "deleteDataAfterEnterpriseSubscriptionDeleteResult",
                            );
                        }
                    };

                    const unsubscribeFromEnterprise = enterpriseRepository.subscribeToEnterprise(
                        enterpriseUid,
                        onChange,
                    );

                    return (state) => {
                        unsubscribeFromDefaultOrganization?.();
                        unsubscribeFromEnterprise();

                        // Now that we are no longer subscribed to this enterprise, we should clear any cache entry after 5 minutes
                        setCacheTimeout(state.enterprises[enterpriseUid], () => {
                            set(
                                (state) => {
                                    delete state.enterprises[enterpriseUid];
                                },
                                false,
                                "cacheTimeoutOnEnterprise",
                            );
                        });
                    };
                }

                return {
                    // API
                    clearAll() {
                        set((state) => {
                            for (const subscription of Object.values(state.userSubscriptions)) {
                                subscription.unsubscriber(state);
                            }

                            for (const cacheEntry of [
                                ...Object.values(state.users),
                                ...Object.values(state.organizations),
                                ...Object.values(state.enterprises),
                            ]) {
                                clearTimeout(cacheEntry.timeout);
                            }

                            state.userSubscriptions = {};
                            state.organizationSubscriptions = {};
                            state.defaultOrganizationSubscriptions = {};
                            state.enterpriseSubscriptions = {};

                            state.users = {};
                            state.organizations = {};
                            state.enterprises = {};

                            // TODO: would be nice to reuse blankState here, but typing is awkward
                        });
                    },

                    subscribe(
                        identifier:
                            | {type: "user"; uid: UserUid}
                            | {type: "organization"; uid: OrganizationUid}
                            | {type: "defaultOrganization"; uid: OrganizationUid}
                            | {type: "enterprise"; uid: EnterpriseUid},
                    ): Unsubscriber {
                        if (areWeInStorybook()) {
                            /*
                             * Storybook has no actual database access, so we don't load the data
                             *
                             * Error is like: TypeError: this.db.userCollection(...).where is not a function
                             *    at FirestoreUserRepository.subscribeToUser
                             *
                             * This approach where we don't load the data predates this store, and was also the case when we used
                             * useEffects directly in components for this account information
                             */
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            return () => {};
                        }

                        const subscriptionCollection = (
                            state: AccountInformationStoreState,
                        ): Record<string, SubscriptionEntry> =>
                            identifier.type === "enterprise"
                                ? state.enterpriseSubscriptions
                                : identifier.type === "organization"
                                ? state.organizationSubscriptions
                                : identifier.type === "defaultOrganization"
                                ? state.defaultOrganizationSubscriptions
                                : state.userSubscriptions;

                        const existingSubscription = (
                            state: AccountInformationStoreState,
                        ): SubscriptionEntry | undefined => subscriptionCollection(state)[identifier.uid];

                        const entryCollection = (
                            state: AccountInformationStoreState,
                        ): Record<string, CacheEntry<unknown>> =>
                            identifier.type === "enterprise"
                                ? state.enterprises
                                : identifier.type === "organization"
                                ? state.organizations
                                : identifier.type === "defaultOrganization"
                                ? state.organizations
                                : state.users;

                        const existingEntry = (state: AccountInformationStoreState): CacheEntry<unknown> | undefined =>
                            entryCollection(state)[identifier.uid];

                        const startNewSubscription = (): StatefulUnsubscriber => {
                            if (identifier.type === "enterprise") {
                                return startNewEnterpriseSubscription(identifier.uid);
                            } else if (identifier.type === "organization") {
                                return startNewOrganizationSubscription(identifier.uid, false);
                            } else if (identifier.type === "defaultOrganization") {
                                return startNewOrganizationSubscription(identifier.uid, true);
                            } else {
                                return startNewUserSubscription(identifier.uid);
                            }
                        };

                        set(
                            (state) => {
                                const subscription = existingSubscription(state);
                                const entry = existingEntry(state);

                                if (subscription) {
                                    subscription.refCount += 1;
                                } else {
                                    subscriptionCollection(state)[identifier.uid] = {
                                        unsubscriber: startNewSubscription(),
                                        refCount: 1,
                                    };
                                    clearCacheTimeout(entry);
                                }
                            },
                            false,
                            "subscribe",
                        );

                        return () => {
                            set(
                                (state) => {
                                    const existing = existingSubscription(state);

                                    if (!existing) {
                                        return;
                                    }

                                    existing.refCount -= 1;

                                    if (existing.refCount === 0) {
                                        existing.unsubscriber(state);
                                        delete subscriptionCollection(state)[identifier.uid];
                                    }
                                },
                                false,
                                "unsubscribe",
                            );
                        };
                    },

                    // Initial blank state
                    ...blankState,
                };
            }),
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "AccountInformationStore"},
        ),
    );
}

/**
 * Creates the store hook with injected dependencies as a bound store, for use within React
 *
 * This is what we actually attach to the container, but it's easier to test the vanilla store definition above, because
 * it doesn't need any React dependencies
 */
export const createAccountInformationStoreHook = (
    userRepository: UserRepository,
    organizationRepository: OrganizationRepository,
    enterpriseRepository: EnterpriseRepository,
): UseAccountInformationStore =>
    createBoundUseStoreHook(
        createAccountInformationStore(userRepository, organizationRepository, enterpriseRepository),
    );

/**
 * Selector function that loads the specified account information from the store's state
 *
 * Used to make sure we're hydrating related entities in other places that access the store. Related entities are like
 * the default organization of an enterprise, or the enterprise of an organization.
 */
function selectAccountWithDetailsFromStoreState(
    state: AccountInformationStoreState,
    accountUid: AccountOrEnterpriseUid,
    logger: Logger,
): AccountWithDetails | undefined {
    if (isEnterpriseUid(accountUid)) {
        const enterprise: Enterprise | undefined = fromCacheEntry(state.enterprises[accountUid]);

        if (!enterprise) {
            return undefined;
        }

        const defaultOrganization = fromCacheEntry(state.organizations[enterprise.defaultOrganizationUid]);

        if (!defaultOrganization) {
            logger.warn("defaultOrganization was not present in account store state for enterprise - maybe loading?", {
                enterprise,
            });
            return undefined;
        }

        return {
            ...enterprise,
            defaultOrganization,
        };
    } else if (isOrganizationUid(accountUid)) {
        const organization: Organization | undefined = fromCacheEntry(state.organizations[accountUid]);

        if (!organization) {
            return undefined;
        }

        if (isNonEnterpriseOrganization(organization)) {
            return organization;
        } else if (isEnterpriseOrganization(organization)) {
            const enterprise = fromCacheEntry(state.enterprises[organization.enterpriseUid]);

            if (!enterprise) {
                logger.warn("enterprise was not present in account store state for organization - maybe loading?", {
                    organization,
                });
                return undefined;
            }

            const defaultOrganization = fromCacheEntry(state.organizations[enterprise.defaultOrganizationUid]);

            if (!defaultOrganization) {
                logger.warn(
                    "defaultOrganization was not present in account store state for enterprise for organization - maybe loading?",
                    {
                        enterprise,
                        organization,
                    },
                );
                return undefined;
            }

            return {
                ...organization,
                enterprise: {
                    ...enterprise,
                    defaultOrganization,
                },
            };
        } else {
            // Should be unreachable
            throw new Error("Logical error - organization was neither non-enterprise nor enterprise");
        }
    }

    return fromCacheEntry(state.users[accountUid]);
}

/**
 * A helper function that converts account information into a cache entry
 */
function toCacheEntry<T>(data: T): CacheEntry<T> {
    return {
        data,
        timeout: undefined,
    };
}

/**
 * A helper function that converts a cache entry back into account information
 */
function fromCacheEntry<T>(entry: CacheEntry<T> | undefined): T | undefined {
    return entry?.data;
}

/**
 * Sets a timeout on the cache entry, after which the onExpires callback is called
 */
function setCacheTimeout<T>(entry: CacheEntry<T> | undefined, onExpires: () => void): void {
    if (!entry) return;
    entry.timeout = setTimeout(onExpires, defaultCacheTtl);
}

/**
 * Clears the timeout on the cache entry, if it exists
 */
function clearCacheTimeout<T>(entry: CacheEntry<T> | undefined): void {
    if (!entry) {
        return;
    }

    const timeout = entry.timeout;

    if (timeout) {
        clearTimeout(timeout);
    }

    entry.timeout = undefined;
}
