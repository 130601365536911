import {IPin} from "@buildwithflux/core";

/**
 * This function just reduces boilerplate - not intended for general use yet but pretty useful when it comes
 * to getting data from a collection where we "know" that it should be there.
 */
export function assertiveGet<U, K extends string | number, T extends Record<K, U>>(collection: T, key: K): T[K] {
    const value = collection[key];
    if (value == null) {
        throw new Error(`Expected ${key} to be defined on ${collection}`);
    }
    return value;
}

/**
 * Single source and destination pair that we want to wire together.
 */
export type ConnectionToWire = {
    start: IPin;
    end: IPin;
};

/**
 * Arguments for the point-to-point autowiring solution.
 */
export type AutoWirePointToPointArgs = {
    connectionsToWire: ConnectionToWire[];
};
