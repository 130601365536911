import {ICommentData, ICommentLikeData, sortByProperty, SortOrder} from "@buildwithflux/core";
import {allFromSnapshot} from "@buildwithflux/firestore-compatibility-layer";

import {StorageError} from "../../redux/reducers/app/actions";

import {BaseStorage} from "./BaseStorage";

/**
 * @deprecated Use CommentRepository instead
 */
export class CommentStorage extends BaseStorage {
    public getCommentsForThread(
        documentUid: string,
        commentThreadUid: string,
        callbackSuccess: (commentsData: ICommentData[]) => void,
        callbackError?: (error: StorageError) => void,
    ) {
        return this.queryCommentsByThreadUid(documentUid, commentThreadUid).onSnapshot(
            (querySnapshot) => {
                const comments = allFromSnapshot(querySnapshot);

                if (comments) {
                    callbackSuccess(comments.sort(sortByProperty("created_at", {order: SortOrder.asc})));
                } else {
                    callbackError?.(new StorageError("Can't find Comments", "not-found"));
                }
            },
            (error) => {
                callbackError?.(new StorageError("Can't get comments", "unknown", {cause: error}));
                this.logger.error(error);
            },
        );
    }

    public async deleteComment(documentUid: string, commentUid: string) {
        const comment = this.firestoreAdapter.documentComment(documentUid, commentUid);

        // FIXME: this always returns true
        if (comment) {
            const replies = await this.queryCommentReplyThreads(documentUid, commentUid).get();
            for (const reply of replies.docs) {
                const replyComments = await this.queryCommentsByThreadUid(documentUid, reply.id).get();
                for (const replyComment of replyComments.docs) {
                    await this.deleteComment(documentUid, replyComment.id);
                }
            }
            // See if comment is also a thread
            return comment.delete();
        }
    }

    public getCommentLikes(
        documentUid: string,
        commentUid: string,
        callbackSuccess: (commentsData: ICommentLikeData[]) => void,
        callbackError?: (error: StorageError) => void,
    ) {
        return this.firestoreAdapter.documentCommentLikesCollection(documentUid, commentUid).onSnapshot(
            (querySnapshot) => {
                const commentLikes = allFromSnapshot(querySnapshot);
                if (commentLikes) {
                    callbackSuccess(commentLikes);
                } else {
                    callbackError?.(new StorageError("Can't find Comments", "not-found"));
                }
            },
            (error) => {
                callbackError?.(new StorageError("Can't load comments", "unknown", {cause: error}));
                // eslint-disable-next-line no-console -- allow legacy console statements
                console.error({message: error.message, error});
            },
        );
    }

    public async deleteCommentThread(documentUid: string, commentThreadUid: string) {
        const commentThread = await this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).get();
        if (commentThread.exists) {
            await this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).delete();
            const comments = await this.queryCommentsByThreadUid(documentUid, commentThreadUid).get();
            for (const comment of comments.docs) {
                await comment.ref.delete();
            }
        }
    }

    public async pinCommentThread(documentUid: string, commentThreadUid: string, pinned: boolean) {
        const commentThread = await this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).get();
        if (commentThread.exists) {
            return this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).update({
                "data.pinned": pinned,
                "data.updated_at": Date.now(),
            });
        }
    }

    public async resolveCommentThread(documentUid: string, commentThreadUid: string, resolved: boolean) {
        const commentThread = await this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).get();
        if (commentThread.exists) {
            return this.firestoreAdapter.documentCommentThread(documentUid, commentThreadUid).update({
                "data.resolved": resolved,
                "data.updated_at": Date.now(),
            });
        }
    }

    public deleteCommentLike(documentUid: string, commentUid: string, likeOwnerUserUid: string) {
        return this.firestoreAdapter.documentCommentLike(documentUid, commentUid, likeOwnerUserUid).delete();
    }

    private queryCommentsByThreadUid(documentUid: string, commentThreadUid: string) {
        return this.firestoreAdapter
            .documentCommentsCollection(documentUid)
            .where("data.belongs_to_comment_thread_uid", "==", commentThreadUid);
    }

    private queryCommentReplyThreads(documentUid: string, commentUid: string) {
        return this.firestoreAdapter
            .documentCommentThreadsCollection(documentUid)
            .where("data.anchor.reply_to_comment_uid", "==", commentUid);
    }
}
