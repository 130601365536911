import {IActionRecordsMap, IDocumentData} from "@buildwithflux/models";
import {createAction} from "@reduxjs/toolkit";

import {IChangeHistoryListItemData} from "./index.types";

export const addChangeHistoryRecords = createAction("loadChangeHistoryRecords", (actionRecords: IActionRecordsMap) => {
    return {
        payload: {
            actionRecords,
        },
    };
});
export const setChangeHistoryListItems = createAction(
    "setChangeHistoryListItems",
    (items: IChangeHistoryListItemData[]) => {
        return {
            payload: {
                items,
            },
        };
    },
);
export const setCurrentChangeId = createAction("setCurrentChangeId", (currentChangeId: string) => {
    return {
        payload: {
            currentChangeId,
        },
    };
});
export const setRequestedChangeId = createAction("setRequestedChangeId", (requestedChangeId: string) => {
    return {
        payload: {
            requestedChangeId,
        },
    };
});
export const clearChangeHistory = createAction("clearChangeHistory");

export const setLatestSnapshot = createAction("setLatestSnapshot", (latestSnapshot: IDocumentData | null) => {
    return {
        payload: {
            latestSnapshot,
        },
    };
});
