import {DocumentChange, DocumentPatch, ImmerPatchAdapter, SubscriptionManager} from "@buildwithflux/core";
import {Unsubscriber} from "@buildwithflux/shared";
import {DocumentService, FluxListener} from "@buildwithflux/solder-core";
import {Unsubscribe} from "redux";

import type {ReduxStoreService} from "../../redux/util/service";

export class DocumentObserver {
    private prevDocumentPatches: DocumentPatch | undefined;
    private subscriptionManager: SubscriptionManager<
        "netNodes" | "bakedNodes" | "elements" | "globalRules",
        DocumentChange
    >;

    constructor(
        private readonly reduxStoreService: ReduxStoreService,
        private readonly documentService: DocumentService,
        private readonly immerPatchAdapter: ImmerPatchAdapter,
    ) {
        this.prevDocumentPatches = undefined;
        this.subscriptionManager = new SubscriptionManager();

        this.subscribeToDocumentPatches();
        this.subscribeToPcbNodes((change) => {
            if (change.kind !== "subCollection" || !change.payload.pcbLayoutNodes) return;
            this.subscriptionManager.notify("bakedNodes", change);
        });
    }

    /**
     * Subscribe to changes of any element in Redux
     *
     * Caller should use the return `Unsubscriber` method to clean up the subscription properly
     */
    public subscribeToElements(onChange: (changes: DocumentChange) => void): Unsubscriber {
        return this.subscriptionManager.addSubscription("elements", onChange);
    }

    /**
     * Subscribe to changes of any globalRules in Redux
     *
     * Caller should use the return `Unsubscriber` method to clean up the subscription properly
     */
    public subscribeToGlobalRules(onChange: (changes: DocumentChange) => void): Unsubscriber {
        return this.subscriptionManager.addSubscription("globalRules", onChange);
    }

    /**
     * Subscribe changes of any pcb baked nodes in document service
     *
     * Caller should use the return `Unsubscriber` method to clean up the subscription properly
     */
    public subscribeToBakedNodes(onChange: (changes: DocumentChange) => void): Unsubscriber {
        return this.subscriptionManager.addSubscription("bakedNodes", onChange);
    }

    private subscribeToPcbNodes(callback: FluxListener): Unsubscriber {
        return this.documentService.subscribe({
            target: "allPcbNodes",
            callback,
        });
    }

    private subscribeToDocumentPatches(): Unsubscribe {
        return this.reduxStoreService.getStore().subscribe(() => {
            const documentPatches = this.reduxStoreService.getStore().getState().document?.latestPatch;

            if (!!documentPatches && documentPatches !== this.prevDocumentPatches) {
                const elementsChanges = this.immerPatchAdapter.toDocumentCollectionChanges(documentPatches).elements;
                const globalRulesChanges =
                    this.immerPatchAdapter.toDocumentCollectionChanges(documentPatches).pcbLayoutRuleSets;

                if (elementsChanges) {
                    this.subscriptionManager.notify("elements", {
                        kind: "subCollection",
                        payload: {
                            elements: elementsChanges,
                        },
                    });
                }

                if (globalRulesChanges) {
                    this.subscriptionManager.notify("globalRules", {
                        kind: "subCollection",
                        payload: {
                            pcbLayoutRuleSets: globalRulesChanges,
                        },
                    });
                }
                this.prevDocumentPatches = documentPatches;
            }
        });
    }
}
