import {EditorModes, pcbRuleImportantString, PcbLayoutRuleCompiler} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

export class ImportantOverrideValidator implements IDrcValidator {
    problemTypeKey = "important_override";
    problemLabel = "Component Overrides";
    problemDescription = `Reports components that have been modified using the "${pcbRuleImportantString}" flag.`;

    checkForProblems({pcbLayoutRuleSets, pcbLayoutNodes}: IDrcInputs) {
        const ruleSetsWithImportantProblems = Object.values(pcbLayoutRuleSets)
            .filter((ruleSet) => Object.values(ruleSet.rules).filter(PcbLayoutRuleCompiler.isRuleImportant).length > 0)
            .map((ruleSet) => {
                // We try to extract the affected element uid from the selector
                // This is supposed to work with the important rules generated automatically by dragging
                const nodeUidWithoutFirstPart = ruleSet.selector.split("[uid=")[1];
                let nodeUid = nodeUidWithoutFirstPart?.substring(0, nodeUidWithoutFirstPart.length - 1);
                if (nodeUid?.startsWith('"')) {
                    nodeUid = nodeUid.substring(1);
                }
                if (nodeUid?.endsWith('"')) {
                    nodeUid = nodeUid && nodeUid.substring(0, nodeUid.length - 1);
                }

                const affectedUid =
                    nodeUid && pcbLayoutNodes[nodeUid] ? [{type: "pcbLayoutNode" as const, uid: nodeUid}] : [];

                return {
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${ruleSet.uid}`,
                    affectedItems: [{type: "ruleSet" as const, uid: ruleSet.uid}, ...affectedUid],
                    affectedViews: [EditorModes.pcb],
                };
            });

        const localRulesWithImportantProblems = Object.values(pcbLayoutNodes)
            .filter(
                (node) =>
                    // See TEMP_patchMissingButRequiredDocumentData
                    Object.values(node.pcbNodeRuleSet || {}).filter(PcbLayoutRuleCompiler.isRuleImportant).length > 0,
            )
            .map((node) => {
                return {
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${node.uid}`,
                    affectedItems: [{type: "pcbLayoutNode" as const, uid: node.uid}],
                    affectedViews: [EditorModes.pcb],
                };
            });

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: [...ruleSetsWithImportantProblems, ...localRulesWithImportantProblems],
        };
    }
}
