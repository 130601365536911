const layout = {
    canvas: {
        top: 65,
        pcb: {
            meta: {
                outlineStrokeWidth: 4, // pixels
                airwireStrokeWidth: 2, // pixels
            },
            hoverOutline: 4,
            selectOutline: 2,
        },
    },
    grid: {
        size: 100000,
        divisions: 6500,
    },
    terminals: {
        segments: 32,
        radius: 2,
        clickTargetRadius: 3,
        hoverExpandFactor: 1.5,
        autoConvertOverlapPreventionMargin: 20,
    },
    symbols: {
        pinLabelMargin: {x: 1, y: 5},
        fontScale: 1.5,
    },
    elements: {
        nameLabelHeight: 5,
        nameLabelVerticalOffset: 8,
        labelBottomMargin: 5,
        labelFontSize: 10,
        maxLabelCharacters: 15,
    },
    presence: {
        maxLabelCharacters: 20,
        scale: 0.8,
    },
    routes: {
        route_width: 9,
        route_width_factor: 1.055,
        clickTargetWidth: 4,
    },
    labels: {
        font_face: {
            regular: "/fonts/roboto-v20-latin-ext_latin_greek-ext_greek-regular.woff",
            bold: "/fonts/roboto-v20-latin-ext_latin_greek-ext_greek-700.woff",
        },
        font_size: 6,
    },
    code_editor: {
        font_size: 16,
    },
    timeline: {
        height: 20,
        width: 20,
        padding: 2,
        offset: 15,
    },
    plots: {
        movingWindowLength: 30,
        xAxisSvgWidth: 6,
        xAxisWidth: 4,
        svgWidth: 36,
        svgHeight: 12,
    },
    error_pins: {
        outerRingRadius: 1.5,
        outerRingWidth: 0.5,
        innerRingRadius: 0.75,
        innerRingWidth: 0.75,
        innerRedRingRadius: 0.75,
    },
    error_model: {
        height_meters: 0.005,
        outlineOpacity: 0.2,
        outlineWidth: "5%",
        outlineBlur: "5%",
        outlineColor: "#000000",
    },
};

export default layout;
