import {APP_VERSION} from "@buildwithflux/shared";
import {useEffect} from "react";

export default function SetVersion() {
    useEffect(() => {
        // eslint-disable-next-line no-console -- allow legacy console statements
        console.info("FLUX: Current application version: " + APP_VERSION);
    }, []);

    return null;
}
