import {Command, CommandType} from "@buildwithflux/core";
import {IDocumentData} from "@buildwithflux/models";
import {documentIsSupportedBySolder} from "@buildwithflux/solder-core";
import {isAnyOf} from "@reduxjs/toolkit";
import {AnyAction} from "redux";

import {updateDescription, updateName} from "../reducers/document/actions";

/**
 * @deprecated solder is defunct until further notice
 */
export class ReduxSolderAdapter {
    /**
     * Maps a redux action to a Command supported in solder
     * @deprecated solder is defunct until further notice
     */
    public toSolderCommand(action: AnyAction): Command {
        if (isAnyOf(updateDescription)(action)) {
            return {
                type: CommandType.setDocumentDescriptionCommand,
                description: action.payload.description,
            };
        } else if (isAnyOf(updateName)(action)) {
            return {
                type: CommandType.setDocumentNameCommand,
                name: action.payload.newDocumentName,
            };
        }

        throw new Error("Not supported in solder yet");
    }

    /**
     * @deprecated solder is defunct until further notice
     */
    public shouldUseSolder(document: IDocumentData, action: AnyAction): boolean {
        let useSolder = true;
        // TODO: Handle and test batch action
        // If document is not migrated, return false
        if (!documentIsSupportedBySolder(document)) useSolder = false;

        // Check if we have a correspoding command in solder system, by attempting to convert
        // the redux action to a command.
        // If the conversion failed, return false
        let command: Command | undefined = undefined;
        try {
            command = this.toSolderCommand(action);
        } catch (error) {}
        if (!command) useSolder = false;

        return useSolder;
    }
}

/**
 * @deprecated solder is defunct until further notice
 */
export const createReduxSolderAdapter = (): ReduxSolderAdapter => {
    return new ReduxSolderAdapter();
};
