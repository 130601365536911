import {IElementTerminalBareData, Orientation} from "@buildwithflux/core";
import {IVertexObject} from "@buildwithflux/models";
import {guid} from "@buildwithflux/shared";
import {Vector2} from "three";

export default class Vertex {
    public readonly position: Vector2;
    public connectedVertices: Array<{vertex: Vertex; type: Orientation}>;
    public id: string;
    public routeIds = new Set<string>();
    public isSaved: boolean;
    public terminalData: IElementTerminalBareData | null;
    constructor(
        x: number,
        y: number,
        routeIds?: Set<string> | string[],
        id?: string,
        terminalData?: IElementTerminalBareData,
    ) {
        this.position = new Vector2(x, y);
        this.connectedVertices = [];
        this.isSaved = id ? true : false;
        this.id = id || guid();
        if (routeIds) {
            this.routeIds = routeIds instanceof Array ? new Set(routeIds) : routeIds;
        }
        this.terminalData = terminalData ? {...terminalData} : null;
    }
    public serialize(): IVertexObject {
        return {
            id: this.id,
            position: {x: this.position.x, y: this.position.y},
            connectedVertices: Object.fromEntries(this.connectedVertices.map(({vertex, type}) => [vertex.id, type])),
            terminal: this.terminalData,
            routeIds: Object.fromEntries([...this.routeIds.values()].map((v) => [v, true])),
        };
    }
}
