import {MissingArgumentReducerError, PartPublishingStatus} from "@buildwithflux/core";
import {IPartVersionData} from "@buildwithflux/models";
import {createReducer} from "@reduxjs/toolkit";

import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";

import * as Actions from "./actions";

export interface IPartVersionReducer {
    // eslint-disable-next-line id-blacklist
    data?: IPartVersionData;
    publishStatus: PartPublishingStatus;
}

const partVersionInitialState: IPartVersionReducer = {
    publishStatus: PartPublishingStatus.idle,
};

const partVersionReducer = createReducer(partVersionInitialState, (builder) => {
    builder
        .addCase(Actions.setPartVersionData, (draftState, action) => {
            const {partVersionData} = action.payload;

            if (!partVersionData) {
                FluxLogger.captureError(new MissingArgumentReducerError(action.type, "partVersionData", action));

                return draftState;
            }

            draftState.data = partVersionData;
        })
        .addCase(Actions.clearPartVersionData, (draftState, _action) => {
            draftState.data = undefined;
        })
        .addCase(Actions.setPartPublishingStatus, (draftState, action) => {
            const {status} = action.payload;
            draftState.publishStatus = status;
        });
});

export default partVersionReducer;
