import {RightEditorDrawerTabState} from "@buildwithflux/constants";
import {DocumentUid, UserUid, UserUiState, UserUiStateMap} from "@buildwithflux/models";
import {UserUiStateRepository} from "@buildwithflux/repositories";
import {areWeTestingWithJest, isDevEnv, Logger} from "@buildwithflux/shared";
import {produce} from "immer";
import {create, StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";

import {useFluxServices} from "../../injection/hooks";
import {CurrentUserService} from "../auth";
import {useFeatureFlags} from "../common/hooks/featureFlags/useFeatureFlag";

export type Uninitialized = {
    userUiStates: undefined;
    type: "uninitialized";
};

export type Initialized = {
    userUiStates: UserUiStateMap;
    type: "initialized";
};

type UserUiStateStoreState = Uninitialized | Initialized;
type UserUiStateStoreApi = {
    loadCurrentUserUiState(documentUid: DocumentUid): Promise<void>;

    setRightHandPanelTabState(documentUid: DocumentUid, tabState: RightEditorDrawerTabState): void;

    clear(): void;
};

type UserUiStore = UserUiStateStoreState & UserUiStateStoreApi;
export type UseUserUiStateStore = UseBoundStore<StoreApi<UserUiStore>>;

export const createUseUserUiStateStoreHook = (
    currentUserService: CurrentUserService,
    userUiStateRepository: UserUiStateRepository,
    logger: Logger,
): UseUserUiStateStore => {
    return create<UserUiStore>()(
        devtools(
            (set, _get) => {
                return {
                    type: "uninitialized",
                    userUiStates: undefined,
                    loadCurrentUserUiState: async (documentUid: DocumentUid) => {
                        const currentUser = currentUserService.getCurrentUser();

                        if (!currentUser) return;

                        // If not remembering panel config, no need to load anything, return initialized as projectChat.
                        if (!shouldRememberPanelConfig()) {
                            set(
                                produce((state: UserUiStore) => {
                                    if (state.type === "uninitialized") {
                                        return {
                                            type: "initialized",
                                            userUiStates: {
                                                [currentUser.uid]: RightEditorDrawerTabState.projectChat,
                                            },
                                        };
                                    }
                                }),
                            );
                        }

                        const currentUserUiState = await userUiStateRepository.getUiState(documentUid, currentUser.uid);

                        set(
                            produce((state: UserUiStore) => {
                                if (state.type === "uninitialized") {
                                    return {
                                        type: "initialized",
                                        userUiStates: {
                                            [currentUser.uid]: currentUserUiState
                                                ? currentUserUiState
                                                : RightEditorDrawerTabState.projectChat,
                                        },
                                    };
                                }
                            }),
                        );
                    },
                    setRightHandPanelTabState: async (
                        documentUid: DocumentUid,
                        tabState: RightEditorDrawerTabState,
                    ) => {
                        const currentUser = currentUserService.getCurrentUser();

                        if (!currentUser) return;

                        const uiState: UserUiState = {
                            userUid: currentUser.uid,
                            rightHandPanelState: {
                                tabState,
                            },
                        };

                        set(
                            produce((state: UserUiStore) => {
                                if (state.type !== "initialized") {
                                    throw new Error("UseUserUiStateStore has been not initialized yet");
                                }
                                state.userUiStates[currentUser.uid] = uiState;
                            }),
                        );

                        if (shouldRememberPanelConfig()) {
                            await userUiStateRepository
                                .setUiState(uiState, documentUid, currentUser.uid)
                                .catch((error) => {
                                    logger.error("Error when saving user selection for right-hand panel", error);
                                });
                        }
                    },
                    clear: () => {
                        set({
                            type: "uninitialized",
                            userUiStates: undefined,
                        });
                    },
                };
            },
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "UserUiStore"},
        ),
    );
};

/**
 * Check the feature flag value to check if we should remember the selection
 * state in right hand panel:
 * 1. "off" and "control": don't remember the user's state, always open to project chat
 * 2. "on" and "remember": first time a user opens a particular document, open to the project chat, after that remember which tab they're on last and open to that tab.
 */
const shouldRememberPanelConfig = () => {
    const rememberPanelConfig = useFeatureFlags.getState().segmentRememberPanelConfig;

    return rememberPanelConfig === "on" || rememberPanelConfig === "remember";
};

/**
 * Gets the last tab selection in the right hand panel
 */
export function useRightHandPanelTabSelectionForCurrentUser(currentUserUid: UserUid | undefined) {
    return useFluxServices().useUserUiStateStore((store) => {
        if (!currentUserUid || store.type === "uninitialized") return RightEditorDrawerTabState.projectChat;

        const userUiState = store.userUiStates[currentUserUid];

        // Fallback to open "project chat", this can happen when first time a user opens a particular document
        if (!userUiState || !userUiState.rightHandPanelState) return RightEditorDrawerTabState.projectChat;

        // Always use the persisted selection
        return userUiState.rightHandPanelState.tabState;
    });
}

export function useIsRightHandPanelInitialized() {
    return useFluxServices().useUserUiStateStore((store) => store.type === "initialized");
}
