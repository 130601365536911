import {Euler, Vector3, Vector2, Shape} from "three";

const rot90Deg = new Euler(0, Math.PI / 2, 0);
export function pointsAlongOblong(
    center: Vector3,
    rotation: Euler,
    size: Vector3,
    insideOffset: number,
    nPoints: number,
) {
    if (size.x === size.y) {
        // Circle, not oblong
        return new Array(nPoints)
            .fill(0)
            .map(
                (_, i) =>
                    new Vector2(
                        center.x + (size.x / 2 - insideOffset) * Math.cos((i / nPoints) * Math.PI * 2),
                        center.y + (size.x / 2 - insideOffset) * Math.sin((i / nPoints) * Math.PI * 2),
                    ),
            );
    }

    const longestSize = Math.max(size.x, size.y);
    const shortestSize = Math.min(size.x, size.y);
    const rectPointsN = nPoints / 4;
    const rectWidth = longestSize - shortestSize;
    const widthDelta = rectWidth / (rectPointsN - 1);
    const overPoints = new Array(rectPointsN)
        .fill(0)
        .map((_, i) => new Vector3(i * widthDelta - rectWidth / 2, shortestSize - insideOffset));
    const underPoints = new Array(rectPointsN)
        .fill(0)
        .map((_, i) => new Vector3(i * widthDelta - rectWidth / 2, shortestSize + insideOffset));

    const circlePointsN = nPoints / 4;
    const radius = shortestSize / 2;
    const radiusInner = shortestSize / 2 - insideOffset;
    const centerLX = -rectWidth / 2 + radius + insideOffset;
    const centerRX = +rectWidth / 2 - radius - insideOffset;
    const circleLPoints = new Array(circlePointsN)
        .fill(0)
        .map(
            (_, i) =>
                new Vector3(
                    centerLX - radiusInner * Math.cos((i / circlePointsN) * Math.PI * 2),
                    radiusInner * Math.sin((i / circlePointsN) * Math.PI * 2),
                ),
        );
    const circleRPoints = new Array(circlePointsN)
        .fill(0)
        .map(
            (_, i) =>
                new Vector3(
                    centerRX + radiusInner * Math.cos((i / circlePointsN) * Math.PI * 2),
                    radiusInner * Math.sin((i / circlePointsN) * Math.PI * 2),
                ),
        );

    return [...overPoints, ...underPoints, ...circleLPoints, ...circleRPoints]
        .map((p) =>
            longestSize === size.x
                ? p.applyEuler(rotation).add(center)
                : p.applyEuler(rot90Deg).applyEuler(rotation).add(center),
        )
        .map((v) => new Vector2(v.x, v.y));
}

export function pointsAlongRectangle(center: Vector3, rotation: Euler, size: Vector3, insideOffset: number) {
    // Z is ignored, XY plane assumed
    return [
        new Vector3(+size.x / 2 - insideOffset, +size.y / 2 - insideOffset, 0).applyEuler(rotation).add(center),
        new Vector3(-size.x / 2 + insideOffset, +size.y / 2 - insideOffset, 0).applyEuler(rotation).add(center),
        new Vector3(-size.x / 2 + insideOffset, -size.y / 2 + insideOffset, 0).applyEuler(rotation).add(center),
        new Vector3(+size.x / 2 - insideOffset, -size.y / 2 + insideOffset, 0).applyEuler(rotation).add(center),

        new Vector3(0, +size.y / 2 - insideOffset, 0).applyEuler(rotation).add(center),
        new Vector3(0, -size.y / 2 + insideOffset, 0).applyEuler(rotation).add(center),

        new Vector3(-size.x / 2 + insideOffset, 0, 0).applyEuler(rotation).add(center),
        new Vector3(+size.x / 2 - insideOffset, 0, 0).applyEuler(rotation).add(center),
    ].map((v) => new Vector2(v.x, v.y));
}

export function pointsAlongCustomShape(center: Vector3, rotation: Euler, shape: Shape) {
    const vectors = shape.extractPoints(32).shape;

    // Z is ignored, XY plane assumed
    return vectors
        .map((vector) => {
            return new Vector3(vector.x, vector.y, 0).applyEuler(rotation).add(center);
        })
        .map((v) => new Vector2(v.x, v.y));
}
