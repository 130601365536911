import type {IApplicationState} from "../../../../state";

// TODO - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to  it
const selectRoutes = (state: IApplicationState) => state.document?.routes;

const routesSelectors = {
    selectRoutes,
};

export default routesSelectors;
