import {IUserData, UserState as AbstractUserState} from "@buildwithflux/models";
import {Logger, silentLogger} from "@buildwithflux/shared";
import {User as FirebaseUser} from "firebase/auth";
import {StoreApi} from "zustand";
import {createWithEqualityFn, UseBoundStoreWithEqualityFn} from "zustand/traditional";

import {useFluxServices} from "../../../injection/hooks";
import {getUserState} from "../helpers";
import {AuthService} from "../services/auth";
import {CurrentUserService, UserState, UserStateType} from "../types";

type CurrentUserStoreApi = {
    unsubscribe: () => void;
};

type CurrentUserState = CurrentUserStoreApi & UserState;
export type UseCurrentUserStore = UseBoundStoreWithEqualityFn<StoreApi<CurrentUserState>>;

export const createCurrentUserStoreHook = (
    currentUserService: CurrentUserService,
    firebaseAuthService: AuthService,
    logger: Logger = silentLogger,
): UseCurrentUserStore =>
    createWithEqualityFn<CurrentUserState>()((set, get) => {
        const getCurrentFirebaseUser = () => firebaseAuthService.getCurrentFirebaseUser() ?? undefined;

        const userChangeUnsubscriber = currentUserService.subscribeToUserChanges((newUserData) => {
            logger.debug("currentUserStore received user change", newUserData);

            const newState = getUserState(
                newUserData.user,
                getCurrentFirebaseUser(),
                currentUserService.isSignUpInProgress,
                currentUserService.isLogOutInProgess,
                get(),
            );
            logger.debug(
                "currentUserStore new state is",
                newUserData.suggestedState ? {...newState, type: newUserData.suggestedState} : newState,
            );
            set(() =>
                newUserData.suggestedState
                    ? ({...newState, type: newUserData.suggestedState} as AbstractUserState<FirebaseUser>) // TODO: Figure out how to make this assertion safer
                    : newState,
            );
        });

        const firebaseUserChangeUnsubscriber = currentUserService.subscribeToFirebaseUserChanges((firebaseUser) => {
            logger.debug("currentUserStore received firebase user change", firebaseUser);
            const {currentUser, type} = get();
            const newState = getUserState(
                currentUser,
                firebaseUser,
                currentUserService.isSignUpInProgress,
                currentUserService.isLogOutInProgess,
                get(),
            );
            logger.debug("currentUserStore received firebase user change", {from: type, to: newState});
            set(() => newState);
        });

        const firebaseCurrentUser = getCurrentFirebaseUser();
        const unsubscribe = () => {
            firebaseUserChangeUnsubscriber();
            userChangeUnsubscriber();
        };

        if (firebaseCurrentUser) {
            logger.debug("Initializing on initial state", currentUserService.isSignUpInProgress);
            return {
                currentUser: undefined,
                firebaseCurrentUser,
                unsubscribe,
                type: "initializing",
            };
        }

        return {
            currentUser: undefined,
            firebaseCurrentUser: undefined,
            unsubscribe,
            type: "uninitialized",
        };
    });

export function createMockCurrentUserStoreHook(initialUserState?: UserState): UseCurrentUserStore {
    return createWithEqualityFn<CurrentUserState>()(() => {
        return {
            ...(initialUserState ?? {
                currentUser: undefined,
                firebaseCurrentUser: undefined,
                type: "uninitialized",
            }),
            unsubscribe: () => {},
        };
    });
}

/**
 * Gets a reactive slice of the current user store: the current user
 *
 * This is reactive in the sense that if you call this hook from a component, it'll re-execute if the value
 * of that piece of state changes (similar to if you called a useState setter)
 *
 * If this slice returns undefined, it may mean:
 *  - The service is initializing, and the user may appear soon (and it may be fully authenticated when it does appear), or
 *  - The user is completely anonymous, or
 *  - The user is partially authenticated
 *  - Or anything in between: when this slice returns undefined, you can't know what the state is: turn to useCurrentUserAuthenticationState()
 *    to figure out what is going on.
 *
 * On the other hand, if this slice returns an IUserData, it may _still_ mean the user isn't fully authenticated; they
 * may be anonymous (with their IUserData correctly stored in Firestore). You can check their isAnonymous property, but
 * we recommend using useCurrentUserAuthenticationState() to figure out what is happening, so that you can react the same
 * way in the 'undefined' case mentioned above
 */
export function useCurrentUser(
    equalityFn?: (a: IUserData | undefined, b: IUserData | undefined) => boolean,
): IUserData | undefined {
    return useFluxServices().useCurrentUserStore((state) => state.currentUser, equalityFn);
}

export function useCurrentUserUid(): string | undefined {
    return useFluxServices().useCurrentUserStore((state) => state.currentUser?.uid);
}

export function useCurrentUserAuthenticationState(): UserStateType {
    return useFluxServices().useCurrentUserStore((state) => state.type);
}

/**
 * Gets API methods for the current user store
 */
export function useCurrentUserStoreApi(): CurrentUserStoreApi {
    return useFluxServices().useCurrentUserStore(({unsubscribe}) => ({
        unsubscribe,
    }));
}
