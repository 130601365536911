import {useEffect} from "react";
import {useLocation} from "react-router-dom";

/**
 * This effect is in a separate component because, while we want it to be applied to all pages, it uses react-router-dom's
 * useLocation hook, which can only be used inside the <Router />
 */
export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
