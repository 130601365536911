import {ClipperShapeFactory} from "@buildwithflux/core";
import {ShapeType, Shape} from "../types";
import {chainCompoundShapeTransforms, matrix3AffineSanityCheck, matrix3Decompose, polygonDistance} from "../utils";

import {approxAABoundingBox, boundingBoxDistance} from "./approxAABoundingBox";
import {approxBoundingCircle, boundingCircleDistance} from "./approxBoundingCircle";
import {approxFittingPolygon} from "./approxFittingPolygon";

// TODO: unify with shortestSegmentBetweenShapes

/**
 * Checks if the two shapes are closer than a given value, returns true if they are intersecting.
 *
 * NOTE: This function over approximates to smaller values when it would be too complicated/expensive to
 * compute the actual minimum. This means that the actual distance could be bigger than the returned one,
 * making it safe to use for checking keepouts.
 * Every approximation in the following code is marked with an APPROX comment.
 *
 * NOTE: This function can throw exceptions in some cases, such as computing unsupported shapes distance
 * or when using unsupported matrix transformations (like shear or normalized non-affine).
 */
export function isCloserThanApprox(
    shapeA: Shape,
    shapeB: Shape,
    minDist: number,
    clipperShapeFactory: ClipperShapeFactory,
): boolean {
    // Should never happen as we call this only with copper shapes which has no expansion
    if (shapeA.shapeType === ShapeType.Expansion || shapeB.shapeType === ShapeType.Expansion) {
        throw new Error("Expansion shapes not supported yet.");
    }

    // 1. Recurses over compound shapes
    if (shapeA.shapeType === ShapeType.Union || shapeA.shapeType === ShapeType.Difference) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeA);
        const resultA = isCloserThanApprox(transformedA, shapeB, minDist, clipperShapeFactory);
        const resultB = isCloserThanApprox(transformedB, shapeB, minDist, clipperShapeFactory);
        return shapeA.shapeType === ShapeType.Union ? resultA || resultB : resultA && !resultB;
    }
    if (shapeB.shapeType === ShapeType.Union || shapeB.shapeType === ShapeType.Difference) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeB);
        const resultA = isCloserThanApprox(transformedA, shapeA, minDist, clipperShapeFactory);
        const resultB = isCloserThanApprox(transformedB, shapeA, minDist, clipperShapeFactory);
        return shapeB.shapeType === ShapeType.Union ? resultA || resultB : resultA && !resultB;
    }

    // 2. Sanity checks for transforms, also decomposes the matrices
    if (!matrix3AffineSanityCheck(shapeA.transform) || !matrix3AffineSanityCheck(shapeB.transform)) {
        throw new Error("Transform matrix of input shapes is not a simple affine.");
    }
    const transformDecomposeA = matrix3Decompose(shapeA.transform);
    const transformDecomposeB = matrix3Decompose(shapeB.transform);
    if (transformDecomposeA.xyDot > 0 || transformDecomposeB.xyDot > 0) {
        throw new Error("Transform matrix of input shapes has unsupported shear.");
    }

    // To solve this problem we use a multi-stage approach, bailing out with false value if that specific
    // stage indicates that the shapes distance is surely bigger than minDist
    // TODO: evaluate if some stages are useless (aka do not speedup or increase accuracy)

    // 1st pass: Bounding Circle check
    const boundingCircleA = approxBoundingCircle(shapeA, transformDecomposeA);
    const boundingCircleB = approxBoundingCircle(shapeB, transformDecomposeB);
    const boundingCircleDist = boundingCircleDistance(boundingCircleA, boundingCircleB);

    // 2st pass: Bounding Box check
    const boundingBoxA = approxAABoundingBox(shapeA);
    const boundingBoxB = approxAABoundingBox(shapeB);
    const boundingBoxDist = boundingBoxDistance(boundingBoxA, boundingBoxB);

    // We use both checks together in AND, as they are both needed for a collision to happen
    // This is because both bounding shapes fully contain the underlying shape
    if (boundingCircleDist > minDist && boundingBoxDist > minDist) {
        return false;
    }

    // 3st pass: Fitting polygon check
    // We choose 16 as segments, if it's 8 then numerical errors happen with oblongs
    const fittingPolygonA = approxFittingPolygon(shapeA, 16, clipperShapeFactory);
    const fittingPolygonB = approxFittingPolygon(shapeB, 16, clipperShapeFactory);
    const dist = polygonDistance(fittingPolygonA, fittingPolygonB);
    if (!dist) {
        // NOTE: this was added to catch a previous runtime error more explicitly
        throw new Error("Cannot calculate distance to empty polygon.");
    }

    const {minDist: fittingPolygonDist} = dist;
    if (fittingPolygonDist > minDist) {
        return false;
    }

    return true;
}
