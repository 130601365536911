import {createSelector} from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import {useMemo} from "react";
import {shallowEqual} from "react-redux";

import {DocumentStorageHelper} from "../../modules/storage_engine/helpers/DocumentStorageHelper";
import type {IApplicationState} from "../../state";

const selectIsArchived = (state: IApplicationState) =>
    state.part && state.part.partData ? state.part.partData.archived : false;
const selectPartUid = (state: IApplicationState) => state.part?.partData?.uid;
const selectLatestPartVersion = (state: IApplicationState) => state.part?.partData?.latest_version;
const selectPartUpdateMetadata = (state: IApplicationState) => state.part?.partUpdateMetadata;
const selectPartOwners = (state: IApplicationState) => state.part?.partOwners;
const useSelectPartDataForUpdate = (uids: string[], selectAll: boolean) => {
    return useMemo(() => {
        return createSelector(
            (state: IApplicationState) => state.document?.elements,
            (elements) => {
                if (!elements) return [];
                const uniquePartVersion = DocumentStorageHelper.getUniquePartVersions(Object.values(elements));

                if (selectAll) {
                    return uniquePartVersion;
                } else {
                    return uniquePartVersion.filter((partVersion) => {
                        return uids.indexOf(partVersion.part_uid) !== -1;
                    });
                }
            },
            {
                memoizeOptions: {
                    resultEqualityCheck: shallowEqual,
                },
            },
        );
    }, [uids, selectAll]);
};
const useSelectOutOfDateParts = createSelector(
    (state: IApplicationState) => state.part,
    (part) => part?.outOfDateParts,
    {
        memoizeOptions: {
            // HACK: not clear why deep is needed
            resultEqualityCheck: isEqual,
        },
    },
);

const usePartUpdatingStatus = (partUid: string) => {
    return useMemo(() => (state: IApplicationState) => state.part?.partUpdatingMap[partUid], [partUid]);
};

const partDataSelectors = {
    selectIsArchived,
    selectPartUid,
    selectLatestPartVersion,
    selectPartUpdateMetadata,
    selectPartOwners,
    useSelectPartDataForUpdate,
    useSelectOutOfDateParts,
    usePartUpdatingStatus,
};

export default partDataSelectors;
