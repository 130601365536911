import {PatchCreationStrategy} from "@buildwithflux/core";
import {AnyAction, Reducer} from "redux";

import {IBatchAction, isBatchAction} from "../epicCreators/helpers";
import {IDocumentReduxAction} from "../reducers/document/actions.types";

type WithPatchStrategy<T extends IDocumentReduxAction> = T & {patchCreationStrategy?: PatchCreationStrategy};

/**
 * This is a customized version of the redux-batched-actions middleware:
 *
 * https://github.com/tshelburne/redux-batched-actions/blob/master/src/index.js#L7
 *
 * Here we also configure `patchCreationStrategy` for batched actions in order to
 * batch patches properly
 *
 * We should use enhancer instead of middleware for batching because we only want
 * to notify subscribers/epics once per batched actions.
 * See `enableBatchingAndPatchEnhancer.test.ts` for more detail
 */
export function enableBatchingAndPatch<S>(reduce: Reducer<S>): Reducer<S> {
    return function batchingReducer(state: any, action: AnyAction | IBatchAction): any {
        if (isBatchAction(action)) {
            return action.payload
                .map((act, index): WithPatchStrategy<typeof act> => {
                    return {
                        ...act,
                        patchCreationStrategy: index === 0 ? "create" : "combine",
                    };
                })
                .reduce(batchingReducer, state);
        }
        return reduce(state, action);
    };
}
