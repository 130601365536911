import {IUserData} from "@buildwithflux/core";
import {User as FirebaseUser} from "firebase/auth";

import {UserState} from "./types";

/**
 * Computes the user state from the current user and the firebase current user, both optional
 *
 * This is the main state transition function for the user state machine in the currentUser store
 *
 * @param currentUser The flux profile of the current user, if any
 * @param firebaseCurrentUser The firebase user, if any
 * @param isSignUpInProgress Whether the user is currently signing up - during which it is expected that the Firebase
 *                            user must be signed in before the Firestore user record is created
 * @param isLogOutInProgess Whether the user is currently logging out - during which it is expected that, briefly, the
 *                           Firebase user will become signed out before the current user state is cleared
 * @param previous The previous user state, if any
 * @return UserState The computed user state
 */
export function getUserState(
    currentUser?: IUserData,
    firebaseCurrentUser?: FirebaseUser,
    isSignUpInProgress?: boolean,
    isLogOutInProgess?: boolean,
    previous?: UserState,
): UserState {
    if (currentUser && firebaseCurrentUser) {
        // Happy paths
        if (firebaseCurrentUser.isAnonymous && currentUser.isAnonymous) {
            return {currentUser, firebaseCurrentUser, type: "anonymous"};
        } else if (!firebaseCurrentUser.isAnonymous && !currentUser.isAnonymous) {
            return {currentUser, firebaseCurrentUser, type: "authenticated"};
        } else if (firebaseCurrentUser.isAnonymous && !currentUser.isAnonymous) {
            // This usually happens during sign out
            return {currentUser: undefined, firebaseCurrentUser: undefined, type: "loggedOut"};
        } else {
            if (currentUser.uid !== firebaseCurrentUser.uid || isSignUpInProgress) {
                return {currentUser: undefined, firebaseCurrentUser, type: "initializing"};
            }
            // Need to overwrite the user profile with the non-anonymous details
            return {currentUser, firebaseCurrentUser, type: "partiallyAuthenticated"};
        }
    } else if (currentUser && !firebaseCurrentUser) {
        if (isLogOutInProgess) {
            return {currentUser: undefined, firebaseCurrentUser: undefined, type: "loggedOut"};
        }
        throw new Error("Invalid user state: currentUser exists but firebaseCurrentUser does not");
    } else if (!currentUser && firebaseCurrentUser) {
        // THis state can't be distinguished from a final partiallyAuthenticated easily
        const newType = previous?.type === "partiallyAuthenticated" ? "partiallyAuthenticated" : "initializing";
        return {currentUser: undefined, firebaseCurrentUser, type: newType};
    } /* i.e. (!currentUser && !firebaseCurrentUser) */ else {
        return {currentUser: undefined, firebaseCurrentUser: undefined, type: "loggedOut"};
    }
}
