import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {SnackbarOrigin, useSnackbar} from "notistack";
import React, {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";

import selectors from "../../../../../redux/selectors";
import {register} from "../../../../../serviceWorkerRegistration";
import {customSnackbarStyle} from "../../../../../modules/common/providers/SnackbarCustomProvider";

const anchorOrigin: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            "& .MuiSnackbarContent-root": customSnackbarStyle(theme),
        },
    }),
);

const message = "There is a new version available.";

function NotistackAppUpdate(props: {action?: React.ReactNode}) {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        enqueueSnackbar(message, {
            action: props.action,
            persist: true,
            preventDuplicate: true,
            anchorOrigin: anchorOrigin,
        });
    }, [props.action, enqueueSnackbar]);

    return null;
}

function AppUpdateSnackbar(props: {useNotistack: boolean}) {
    const classes = useStyles();

    const documentUid = useSelector(selectors.document.baseDocument.selectUid);

    const [showReload, setShowReload] = React.useState<boolean>(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        register({onUpdate: onSWUpdate});

        // TODO: unregister??
    }, []);

    const reloadPage = useCallback(() => {
        waitingWorker?.postMessage({type: "SKIP_WAITING"});
        setShowReload(false);
        window.location.reload();
    }, [waitingWorker]);

    const handleClose = useCallback((_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setShowReload(false);
    }, []);

    const action = useMemo(
        () => (
            <>
                <Button color="primary" size="small" onClick={reloadPage}>
                    Reload Page
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>
        ),
        [reloadPage, handleClose],
    );

    // lets talk about whats happening here
    // So when the editor is open we want to use notistack to queue snackbars
    // but when the editor is not open and notistack issn't available then we just use a regular MUI snackbar
    // why dont we use notistack everywhere you ask?
    // because to be able to link to internal routes from snackbars notistack needs to be a child of the react router
    // and we can't do that because then we would need to manage which snackbars are visible on which route
    // and that's a headache that adds complexity for the one exception that is the app update snackbar
    // so here we are :)
    if (documentUid && props.useNotistack && showReload) {
        return <NotistackAppUpdate action={action} />;
    } else if (!props.useNotistack && !documentUid) {
        return (
            <Snackbar
                anchorOrigin={anchorOrigin}
                open={showReload}
                onClose={handleClose}
                message={message}
                action={action}
                classes={classes}
            />
        );
    } else {
        return null;
    }
}

export default React.memo(AppUpdateSnackbar);
