import {IDocumentData, LayoutRules} from "@buildwithflux/models";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";
import {AppThunkAction} from "../../../../../store";

export const toggleNodeSpecificRule = createActionRecordAction(
    "toggleNodeSpecificRule",
    (ruleId: string, nodeId: string, toggleState: boolean, latestDocumentState: IDocumentData | null) => {
        const node = latestDocumentState!.pcbLayoutNodes[nodeId]!;
        const rule = Object.values(node.pcbNodeRuleSet).find((rule) => rule.uid === ruleId);
        if (!rule) throw new Error("Cannot find the rule to be toggled");

        const hasInheritedRules = LayoutRules[rule.key].allChildNodesWillInheritThisRule;
        const hasInheritedCalculations = LayoutRules[rule.key].affectsDescendantCalculations ?? false;
        const requiresDescendantRebake = LayoutRules[rule.key].requiresDescendantRebake ?? false;
        return {
            payload: {
                nodeId,
                ruleId,
                toggleState,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                hasInheritedRules,
                hasInheritedCalculations,
                requiresDescendantRebake,
            },
        };
    },
    "toggles an object-specific layout rule",
);

export const toggleNodeSpecificRuleThunk = (
    ruleId: string,
    nodeId: string,
    toggleState: boolean,
): AppThunkAction<void> => {
    return (dispatch, getState) => {
        const documentState = getState().document;
        dispatch(toggleNodeSpecificRule(ruleId, nodeId, toggleState, documentState));
    };
};
