import {
    aggregateReviewStatus,
    ReviewAssignedStatus,
    ReviewCategory,
    ReviewStatus,
    ReviewUid,
} from "@buildwithflux/models";
import {create, StateCreator, StoreApi, UseBoundStore} from "zustand";

export interface ReviewState {
    overallStatus?: ReviewStatus;
    overallAssignedStatus?: ReviewAssignedStatus;
    categories: ReviewCategory[];
    setCategories(categories: ReviewCategory[]): void;
    updateReviewStatus(reviewUid: ReviewUid, status: ReviewStatus): void;
    updateReviewAssignedStatus(reviewUid: ReviewUid, assignedStatus: ReviewAssignedStatus | undefined): void;
}

export type UseReviewStore = UseBoundStore<StoreApi<ReviewState>>;

function updateReviewStatus(categories: ReviewCategory[], uid: ReviewUid, status: ReviewStatus) {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item) => {
            if (item.uid !== uid) return item;
            return {
                ...item,
                status,
                checks: Object.keys(item.checks).reduce((acc, k) => ({...acc, [k]: {...item.checks[k], status}}), {}),
            };
        }),
    }));
}

function updateReviewAssignedStatus(
    categories: ReviewCategory[],
    uid: ReviewUid,
    assignedStatus: ReviewAssignedStatus | undefined,
) {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item) => {
            if (item.uid !== uid) return item;
            return {
                ...item,
                assignedStatus,
            };
        }),
    }));
}

function calculateOverallStatus(categories: ReviewCategory[]) {
    const status = categories.map((category): ReviewStatus => {
        const reviewStatusList = category.reviews
            .map((review) => (review.assignedStatus ? undefined : review.status))
            .filter(Boolean) as ReviewStatus[];
        return reviewStatusList.length > 0 ? aggregateReviewStatus(reviewStatusList) : ReviewStatus.OPEN;
    });
    return status.length > 0 ? aggregateReviewStatus(status) : ReviewStatus.OPEN;
}

function calculateOverallAssignedStatus(categories: ReviewCategory[]) {
    const isMuted = categories.every((category): boolean =>
        category.reviews.every((review) => review.assignedStatus === ReviewAssignedStatus.MUTED),
    );
    return isMuted ? ReviewAssignedStatus.MUTED : undefined;
}

export function createReviewStore() {
    const selectionControllerStoreConfig: StateCreator<ReviewState> = (set) => ({
        categories: [],

        setCategories(categories: ReviewCategory[]) {
            set(
                (state): ReviewState => ({
                    ...state,
                    categories,
                    overallStatus: calculateOverallStatus(categories),
                    overallAssignedStatus: calculateOverallAssignedStatus(categories),
                }),
            );
        },

        updateReviewStatus(reviewUid: ReviewUid, status: ReviewStatus) {
            set((state): ReviewState => {
                const categories = updateReviewStatus(state.categories, reviewUid, status);
                return {
                    ...state,
                    categories,
                    overallStatus: calculateOverallStatus(categories),
                    overallAssignedStatus: calculateOverallAssignedStatus(categories),
                };
            });
        },

        updateReviewAssignedStatus(reviewUid: ReviewUid, assignedStatus: ReviewAssignedStatus | undefined) {
            set((state): ReviewState => {
                const categories = updateReviewAssignedStatus(state.categories, reviewUid, assignedStatus);
                return {
                    ...state,
                    categories,
                    overallStatus: calculateOverallStatus(categories),
                    overallAssignedStatus: calculateOverallAssignedStatus(categories),
                };
            });
        },
    });

    return create<ReviewState>()(selectionControllerStoreConfig);
}
