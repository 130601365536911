import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const toggleGlobalPcbRule = createActionRecordAction(
    "toggleGlobalPcbRule",
    (ruleId: string, state: boolean, subRuleId?: string) => {
        return {
            payload: {
                subRuleId,
                ruleId,
                state,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "toggles a pcb layout rule",
);
