import {DocumentEventVersion} from "@buildwithflux/constants";
import {
    DocumentEventRepository,
    DocumentUid,
    DocumentEventsSelection,
    AcceptedDocumentEvent,
    SolderCommand,
    SubscriptionManager,
} from "@buildwithflux/core";
import type {ClientFirestoreAdapter} from "@buildwithflux/firestore-compatibility-layer";
import {IDocument, acceptedDocumentEventSchema} from "@buildwithflux/models";
import {EventNotificationCallbackFn} from "@buildwithflux/repositories";
import {isDevEnv, Logger, silentLogger, shouldUseLocalServices, Unsubscriber} from "@buildwithflux/shared";
import {getEventsResponseBodySchema} from "@buildwithflux/solder-core";
import type {AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults} from "axios";
import type {Auth} from "firebase/auth";
import type {default as firebase} from "firebase/compat/app";

export class SolderClientError extends Error {
    constructor(message: string, public readonly status: number) {
        super(message);
    }
}

const DEVELOPMENT_BASE_URL = "http://localhost:8081";
const STAGING_BASE_URL = "https://solder-app-rgzfxbsgpa-wl.a.run.app";
const PRODUCTION_BASE_URL = "https://solder-app-myfpimhjlq-wl.a.run.app";

function getSolderBaseUrl(): string {
    if (isDevEnv()) {
        return shouldUseLocalServices("solder") ? DEVELOPMENT_BASE_URL : STAGING_BASE_URL;
    }
    // TODO: detect Flux frontend running on staging project
    return PRODUCTION_BASE_URL;
}

export function getSolderAxiosDefaults(): CreateAxiosDefaults {
    return {
        baseURL: getSolderBaseUrl(),
        timeout: 10_000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };
}

export class FirestoreAxiosDocumentEventRepository implements DocumentEventRepository {
    private readonly manager: SubscriptionManager<DocumentUid, DocumentEventVersion> = new SubscriptionManager();
    private readonly lastSeenVersion: Record<DocumentUid, DocumentEventVersion> = {};

    constructor(
        private readonly db: ClientFirestoreAdapter,
        private readonly axios: AxiosInstance,
        private readonly firebaseAuth: Auth,
        private readonly logger: Logger = silentLogger,
    ) {}

    public async migrateDocument(documentUid: DocumentUid): Promise<void> {
        await this.post(`/v1/documents/migrate/${encodeURIComponent(documentUid)}`, {});
    }

    /** @inheritDoc */
    public async getEvents(
        documentUid: DocumentUid,
        range: DocumentEventsSelection,
    ): Promise<Readonly<AcceptedDocumentEvent[]>> {
        const response = await this.get(`/v1/documents/${encodeURIComponent(documentUid)}/events`, {
            params: {
                selection: range,
            },
        });

        return getEventsResponseBodySchema.parse(response);
    }

    /** @inheritDoc */
    public async saveCommand<C extends SolderCommand>(
        documentUid: DocumentUid,
        command: C,
    ): Promise<AcceptedDocumentEvent<C>> {
        const response = await this.post(`/v1/documents/${encodeURIComponent(documentUid)}/commands`, {
            command,
        });

        return acceptedDocumentEventSchema(command).parse(response);
    }

    /** @inheritDoc */
    public subscribeToEventNotification(documentUid: DocumentUid, handler: EventNotificationCallbackFn): Unsubscriber {
        const unsubscribeFromFirestore = this.db
            .document(documentUid)
            .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<IDocument>) => {
                const version = snapshot.get("documentData.version");
                const lastSeenVersion = this.lastSeenVersion[documentUid] ?? 0;

                if (version > lastSeenVersion) {
                    // TODO: document deletion, documents that haven't received a version yet, both come through as version=0
                    this.manager.notify(documentUid, snapshot.get("documentData.version") ?? 0);
                    this.lastSeenVersion[documentUid] = version;
                }
            });

        return this.manager.addSubscription(documentUid, handler, () => unsubscribeFromFirestore());
    }

    private async get<T>(path: string, config: AxiosRequestConfig<undefined> = {}): Promise<unknown> {
        return this.axios.get<T>(path, await this.getConfig(config)).then((response) => {
            if (response.status >= 400) {
                throw new SolderClientError(
                    `Request to Solder backend failed with status ${response.status}`,
                    response.status,
                );
            }
            return response.data;
        });
    }

    private async post<D>(path: string, data: D, config: AxiosRequestConfig<D> = {}): Promise<unknown> {
        return this.axios.post(path, data, await this.getConfig(config)).then((response) => {
            if (response.status >= 400) {
                throw new SolderClientError(
                    `Request to Solder backend failed with status ${response.status}`,
                    response.status,
                );
            }
            return response.data;
        });
    }

    /**
     * Get some standard configuration for an axios request, including the Authorization header
     *
     * We do this here, not when creating the axios client, because the JWT can change over the lifecycle of this class,
     *  e.g. when refreshed
     */
    private async getConfig<D>(perRequestConfig: AxiosRequestConfig<D> = {}): Promise<AxiosRequestConfig<D>> {
        if (!this.firebaseAuth.currentUser) {
            // TODO: we might expect this to happen during the first few seconds of the app loading if we don't correctly block components from loading?
            throw new Error(
                "Cannot make requests to backend without a Firebase user: anonymous user should be logged in.",
            );
        }

        return {
            ...perRequestConfig,
            headers: {
                Authorization: `Bearer ${await this.firebaseAuth.currentUser?.getIdToken()}`,
            },
        };
    }
}
