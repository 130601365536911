import {getDocumentUrl} from "@buildwithflux/core";
import {EnterpriseWithDefaultOrganization, Slug} from "@buildwithflux/models";
import {createSelector} from "@reduxjs/toolkit";

import type {IApplicationState} from "../../state";

const selectDocumentOwner = (state: IApplicationState) => state.documentMeta.documentOwner;
const selectDocumentOrganizationOwner = (state: IApplicationState) => state.documentMeta.documentOrganizationOwner;
const selectDocumentEnterpriseOwner = (state: IApplicationState) => state.documentMeta.documentEnterpriseOwner;
const selectDocumentEnterpriseOwnerDefaultOrganization = (state: IApplicationState) =>
    state.documentMeta.documentEnterpriseOwnerDefaultOrganization;

const selectDocumentEnterpriseOwnerWithDefaultOrganization = createSelector(
    (state: IApplicationState) => state.documentMeta.documentEnterpriseOwner,
    (state: IApplicationState) => state.documentMeta.documentEnterpriseOwnerDefaultOrganization,
    (enterpriseOwner, enterpriseOwnerDefaultOrganization): EnterpriseWithDefaultOrganization | undefined =>
        enterpriseOwner && enterpriseOwnerDefaultOrganization
            ? {
                  ...enterpriseOwner,
                  defaultOrganization: enterpriseOwnerDefaultOrganization,
              }
            : undefined,
);

const selectBaseSlugForLocalComponent = (state: IApplicationState): Slug | undefined =>
    state.documentMeta.hostDocumentForLocalComponent?.slug;

const selectRemoteActiveUsers = (state: IApplicationState) => state.documentMeta.remoteActiveUsers;

const selectRemoteActiveUsersUids = (state: IApplicationState) =>
    state.documentMeta.remoteActiveUsers.map((user) => user.user_uid);

const useSelectRemoteActiveUser = (userUid: string) => {
    return (state: IApplicationState) => {
        return state.documentMeta.remoteActiveUsers.find((user) => user.user_uid === userUid);
    };
};

const selectDocumentUid = (state: IApplicationState) => state.documentMeta.baseDocument?.uid;
const selectDocumentName = (state: IApplicationState) => state.documentMeta.baseDocument?.name;
const selectDocumentForkCount = (state: IApplicationState) => {
    const baseDocument = state.documentMeta.baseDocument;
    if (!baseDocument) return baseDocument;
    return baseDocument.fork_count;
};
const selectDocumentStarCount = (state: IApplicationState) => {
    const baseDocument = state.documentMeta.baseDocument;
    if (!baseDocument) return baseDocument;
    return baseDocument.star_count;
};
const selectDocumentUsageCount = (state: IApplicationState) => {
    const baseDocument = state.documentMeta.baseDocument;
    if (!baseDocument) return baseDocument;
    return baseDocument.usage_count;
};

const selectCanonicalUrl = createSelector(
    (state: IApplicationState) => state.documentMeta.baseDocument,
    selectBaseSlugForLocalComponent,
    (baseDocument, baseSlugForLocalComponent) => {
        if (!baseDocument) {
            return undefined;
        }

        return getDocumentUrl(baseDocument, {
            baseSlugForLocalComponent,
        });
    },
);

const documentMetaSelectors = {
    selectCanonicalUrl,
    selectDocumentOwner,
    selectDocumentOrganizationOwner,
    selectDocumentEnterpriseOwner,
    selectDocumentEnterpriseOwnerDefaultOrganization,
    selectDocumentEnterpriseOwnerWithDefaultOrganization,
    selectRemoteActiveUsers,
    selectRemoteActiveUsersUids,
    selectBaseSlugForLocalComponent,
    useSelectRemoteActiveUser,
    selectDocumentUid,
    selectDocumentName,
    selectDocumentForkCount,
    selectDocumentStarCount,
    selectDocumentUsageCount,
};

export default documentMetaSelectors;
