import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setIsReconnecting} from "../../../../../redux/reducers/app/actions";
import {writeDocument} from "../../../../../redux/reducers/document/actions";
import appSelectors from "../../../../../redux/selectors/appSelectors";
import R from "../../../../../resources/Namespace";

const useStyles = makeStyles(() =>
    createStyles({
        snackBarRoot: {
            display: "flex",
            flexDirection: "row",
            height: "104px",
            width: "450px",
            backgroundColor: "#F44336",
            borderRadius: "4px",
            padding: "8px 16px",
        },
        text: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
        },
        body1: {
            marginBottom: "4px",
            fontWeight: 500,
        },
        retry: {
            marginTop: "-4px",
            marginLeft: "8px",
        },
    }),
);

/**
 * @see https://github.com/buildwithflux/flux-app/pull/1217 for explanation and
 * visuals.
 */
const NetworkErrorSnackbar = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [countDown, setCountDown] = useState(0);
    const [startTimer, setStartTimer] = useState(false);
    const lastWriteDocumentPayload = useSelector(appSelectors.selectLastWriteDocumentPayload);
    const isReconnecting = useSelector(appSelectors.selectIsReconnecting);

    useEffect(() => {
        if (!isReconnecting) {
            // NOTE: this is implicity dependent on timeout in writeDocumentEpic
            setCountDown(20);
            setStartTimer(true);
        }
    }, [isReconnecting]);

    const onRetry = useCallback(() => {
        if (!lastWriteDocumentPayload) return;
        dispatch(setIsReconnecting(true));
        dispatch(writeDocument(lastWriteDocumentPayload?.documentState));
    }, [dispatch, lastWriteDocumentPayload]);

    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                setCountDown(countDown - 1);
                // NOTE: this is implicity dependent on timeout in writeDocumentEpic
            }, 1000);
        }

        if (countDown === 0 && startTimer) {
            onRetry();
            setStartTimer(false);
        }
    }, [countDown, onRetry, startTimer]);

    const renderReconnecting = () => {
        return (
            <Typography align="left" variant="body2">
                Attempting to reconnect now...
            </Typography>
        );
    };

    const renderLostConnectionText = () => {
        return (
            <Typography align="left" variant="body2">
                {`${R.strings.app.network_error_prompt} ${countDown}.`}
                <Link
                    className={classes.retry}
                    component="button"
                    variant="body2"
                    underline="always"
                    color="textPrimary"
                    onClick={onRetry}
                >
                    Retry
                </Link>
            </Typography>
        );
    };

    return (
        <Box className={`${classes.snackBarRoot}`}>
            <InfoIcon />

            <Box className={classes.text}>
                <Typography className={classes.body1} align="left" variant="body1">
                    Lost Connection
                </Typography>
                {isReconnecting ? renderReconnecting() : renderLostConnectionText()}
            </Box>
        </Box>
    );
};

// eslint-disable-next-line import/no-unused-modules
export default NetworkErrorSnackbar;
