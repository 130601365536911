import {
    createShapeFromDxfString,
    createShapeFromSvgString,
    createSvgShape,
    filterNotNull,
    FootPrintPadHoleType,
    IPcbBoardLayerBase,
    isDxfFileName,
    isSvgFileName,
    LayerOrientation,
    PcbBakedNode,
    PcbLayoutFootprintPadShape,
    PcbNodeTypes,
} from "@buildwithflux/core";
import {getLayerIdentifier, isCopperLayer, isSolderMaskLayer, isSolderPasteLayer} from "@buildwithflux/models";
import {Matrix3, Shape as ThreeShape} from "three";

import {isConvexFromPoints} from "../math/isConvex";
import {
    ApproxPolygonShape,
    CircleShape,
    DifferenceShape,
    ShapeType,
    OblongShape,
    RectangleShape,
    RoundedRectangleShape,
    Shape,
    ShapeRole,
} from "../types";
import {matrix3MakeScale, matrix3MakeTranslation, toThreeMatrix3} from "../utils";

export function createShapesForPad(node: PcbBakedNode<PcbNodeTypes.pad>, stackup: IPcbBoardLayerBase[]): Shape[] {
    const outerCopperShape = getCopperOuterShape(node);
    const baseHoleShape = getHoleShape(node);

    const copperLayers = getCopperLayers(node, stackup);
    const mainCopperLayer = copperLayers[0];

    // We also need to chain the scale transform, which is not already included in transformRelativeToShapesLayer
    // TODO: when doing the solder paste/mask expansion consider scale correctly!
    const transform = toThreeMatrix3(node.bakedRules.transformRelativeToShapesLayer);
    const transformInverse = transform.clone().invert();

    // Copper with no hole
    if (outerCopperShape && !baseHoleShape && mainCopperLayer) {
        // TODO: here we are assuming it to be SMD, so we are returning a single copper layer with solder paste
        // Is it possible to have multiple layers instead or no solder paste?

        const solderPasteLayer = stackup.find(
            (layer) => isSolderPasteLayer(layer) && layer.orientation === getLayerIdentifier(mainCopperLayer),
        );
        const solderPasteShapes =
            (solderPasteLayer &&
                getSolderPasteShapes(node, solderPasteLayer, outerCopperShape, transform, transformInverse)) ??
            [];

        const solderMaskLayerTop = stackup.find(
            (layer) =>
                isSolderMaskLayer(layer) &&
                layer.orientation === getLayerIdentifier(mainCopperLayer) &&
                layer.orientation === LayerOrientation.top,
        );
        const solderMaskLayerBottom = stackup.find(
            (layer) =>
                isSolderMaskLayer(layer) &&
                layer.orientation === getLayerIdentifier(mainCopperLayer) &&
                layer.orientation === LayerOrientation.bottom,
        );
        const solderMaskShapes = getSolderMaskShapes(
            node,
            solderMaskLayerTop,
            solderMaskLayerBottom,
            outerCopperShape,
            undefined,
            transform,
            transformInverse,
        );

        const copperShape = {
            ...outerCopperShape,
            shapeRole: ShapeRole.copper,
            transform: transform.clone().multiply(outerCopperShape.transform),
            transformInverse: outerCopperShape.transformInverse.clone().multiply(transformInverse),
            layerId: mainCopperLayer.uid,
        };

        return filterNotNull([copperShape, ...solderPasteShapes, ...solderMaskShapes]);
    }

    // Copper with hole
    if (outerCopperShape && baseHoleShape) {
        // NOTE: if we have an hole we always spawn the top/bottom pads and solder mask opening
        // Also we don't spawn solder paste as that's for SMD only

        // TODO: The note above it's actually not correct! We also want to spawn solder paste
        // for through hole components if there is a solder paste expansion, starting from 0
        // (see https://www.figma.com/design/KJtwglIeAa2BZ10qySohru/Pad-%26-Via-Specs?node-id=13-61859&t=SAO1PjxzJaYWwvQM-0)
        // We are not doing it now since it's not supported yet in gerber and it poses some challenges with layers

        const solderMaskLayerTop = stackup.find(
            (layer) => isSolderMaskLayer(layer) && layer.orientation === LayerOrientation.top,
        );
        const solderMaskLayerBottom = stackup.find(
            (layer) => isSolderMaskLayer(layer) && layer.orientation === LayerOrientation.bottom,
        );
        const solderMaskShapes = getSolderMaskShapes(
            node,
            solderMaskLayerTop,
            solderMaskLayerBottom,
            outerCopperShape,
            baseHoleShape,
            transform,
            transformInverse,
        );

        const copperShapes: DifferenceShape[] = copperLayers.map((layer) => ({
            shapeRole: ShapeRole.copper,
            shapeType: ShapeType.Difference,
            shapeA: {...outerCopperShape, shapeRole: ShapeRole.copper, layerId: layer.uid},
            shapeB: {...baseHoleShape, shapeRole: ShapeRole.copper, layerId: layer.uid},
            layerId: layer.uid,
            transform,
            transformInverse,
        }));

        const holeShape = {
            ...baseHoleShape,
            transform: transform.clone().multiply(baseHoleShape.transform),
            transformInverse: baseHoleShape.transformInverse.clone().multiply(transformInverse),
            layerId: null,
            shapeRole: ShapeRole.layoutHole,
        };

        return filterNotNull([...copperShapes, ...solderMaskShapes, holeShape]);
    }

    // Hole Only
    if (!outerCopperShape && baseHoleShape) {
        // TODO: Also here we also want to support solder mask opening (not paste)
        // see https://www.figma.com/design/KJtwglIeAa2BZ10qySohru/Pad-%26-Via-Specs?node-id=13-61859&t=SAO1PjxzJaYWwvQM-0

        return [
            {
                ...baseHoleShape,
                transform: transform.clone().multiply(baseHoleShape.transform),
                transformInverse: baseHoleShape.transformInverse.clone().multiply(transformInverse),
                layerId: null,
                shapeRole: ShapeRole.layoutHole,
            },
        ];
    }

    return [];
}

function getCopperLayers(node: PcbBakedNode<PcbNodeTypes.pad>, stackup: IPcbBoardLayerBase[]) {
    const isSMD =
        !node.bakedRules.hole ||
        node.bakedRules.hole.holeType === FootPrintPadHoleType.SurfaceMountDevice ||
        node.bakedRules.hole.holeType === FootPrintPadHoleType.testPinOrCardEdgeConnector;

    if (isSMD) {
        const mainLayer = stackup.find(
            (layer) => isCopperLayer(layer) && getLayerIdentifier(layer) === node.bakedRules.layer,
        );
        return mainLayer ? [mainLayer] : [];
    } else {
        const matchingLayers = stackup.filter(
            (layer) =>
                isCopperLayer(layer) &&
                (node.bakedRules.connectedLayers?.includes("All") ||
                    node.bakedRules.connectedLayers?.includes(getLayerIdentifier(layer))),
        );
        return matchingLayers;
    }
}

type CopperOuterShape =
    | Omit<CircleShape, "layerId" | "shapeRole">
    | Omit<RectangleShape, "layerId" | "shapeRole">
    | Omit<OblongShape, "layerId" | "shapeRole">
    | Omit<RoundedRectangleShape, "layerId" | "shapeRole">
    | Omit<ApproxPolygonShape, "layerId" | "shapeRole">;

function getCopperOuterShape(node: PcbBakedNode<PcbNodeTypes.pad>): CopperOuterShape | null {
    const hasCopper = !node.bakedRules.hole || node.bakedRules.hole.holeType !== FootPrintPadHoleType.nonPlatedHole;
    if (!hasCopper) {
        return null;
    }

    // Will be multiplied by the caller, sets only scale
    const scale = node.bakedRules.scale;
    const transform = matrix3MakeScale(new Matrix3(), scale ? scale.x : 1, scale ? scale.y : 1);
    const transformInverse = transform.clone().invert();
    const transformTwice = matrix3MakeScale(
        new Matrix3(),
        scale ? scale.x * scale.x : 1,
        scale ? scale.y * scale.y : 1,
    );
    const transformTwiceInverse = transformTwice.clone().invert();

    // If valid asset data is contained in bakedRules then attempt shape creation from that data - asset data takes
    // precedence in all cases
    if (node.bakedRules.asset?.payloadFileName && node.bakedRules.asset?.payloadAsBase64) {
        let threeShape: ThreeShape | undefined = undefined;
        if (isSvgFileName(node.bakedRules.asset.payloadFileName)) {
            threeShape = createShapeFromSvgString(atob(node.bakedRules.asset.payloadAsBase64));
        } else if (isDxfFileName(node.bakedRules.asset.payloadFileName)) {
            threeShape = createShapeFromDxfString(atob(node.bakedRules.asset.payloadAsBase64), {
                center: false,
            });
        }
        if (!threeShape) return null;

        const points = threeShape.getPoints();
        return {
            shapeType: ShapeType.ApproxPolygon,
            points,
            // Scale is applied twice when using custom shapes (??)
            transform: transformTwice,
            transformInverse: transformTwiceInverse,
            convex: isConvexFromPoints(points),
        };
    }

    if (node.bakedRules.padShape === PcbLayoutFootprintPadShape.circular) {
        const isSquare = node.bakedRules.size.x === node.bakedRules.size.y;
        if (isSquare) {
            return {
                shapeType: ShapeType.Circle,
                radius: node.bakedRules.size.x / 2,
                transform,
                transformInverse,
            };
        } else {
            return {
                shapeType: ShapeType.Oblong,
                width: node.bakedRules.size.x,
                height: node.bakedRules.size.y,
                transform,
                transformInverse,
            };
        }
    }

    if (node.bakedRules.padShape === PcbLayoutFootprintPadShape.rectangle) {
        const hasCornerRadius =
            node.bakedRules.cornerRadius &&
            (node.bakedRules.cornerRadius.topLeft > 0 ||
                node.bakedRules.cornerRadius.bottomLeft > 0 ||
                node.bakedRules.cornerRadius.topRight > 0 ||
                node.bakedRules.cornerRadius.bottomRight > 0);
        if (hasCornerRadius && node.bakedRules.cornerRadius) {
            return {
                shapeType: ShapeType.RoundedRectangle,
                width: node.bakedRules.size.x,
                height: node.bakedRules.size.y,
                radiusTopLeft: node.bakedRules.cornerRadius.topLeft,
                radiusBottomLeft: node.bakedRules.cornerRadius.bottomLeft,
                radiusTopRight: node.bakedRules.cornerRadius.topRight,
                radiusBottomRight: node.bakedRules.cornerRadius.bottomRight,
                transform,
                transformInverse,
            };
        } else {
            return {
                shapeType: ShapeType.Rectangle,
                width: node.bakedRules.size.x,
                height: node.bakedRules.size.y,
                transform,
                transformInverse,
            };
        }
    }

    if (!node.bakedRules.padShape) return null;
    const customPadShapeThree = createSvgShape(node.bakedRules.padShape);
    if (!customPadShapeThree) return null;

    const points = customPadShapeThree.getPoints();
    return {
        shapeType: ShapeType.ApproxPolygon,
        points,
        // Scale is applied twice when using custom shapes (??)
        transform: transformTwice,
        transformInverse: transformTwiceInverse,
        convex: isConvexFromPoints(points),
    };
}

type BaseHoleShape = Omit<CircleShape, "layerId" | "shapeRole"> | Omit<OblongShape, "layerId" | "shapeRole">;

function getHoleShape(node: PcbBakedNode<PcbNodeTypes.pad>): BaseHoleShape | null {
    const hole = node.bakedRules.hole;
    const hasHole =
        hole?.holeType === FootPrintPadHoleType.nonPlatedHole ||
        hole?.holeType === FootPrintPadHoleType.platedThroughHole;
    if (!hole || !hasHole) {
        return null;
    }

    // Invariant from scale
    const transform = matrix3MakeTranslation(new Matrix3(), hole.holePosition.x, hole.holePosition.y);
    const transformInverse = transform.clone().invert();

    const isSquare = hole.holeSize.x === hole.holeSize.y;
    if (isSquare) {
        return {
            shapeType: ShapeType.Circle,
            radius: hole.holeSize.x / 2,
            transform,
            transformInverse,
        };
    } else {
        return {
            shapeType: ShapeType.Oblong,
            width: hole.holeSize.x,
            height: hole.holeSize.y,
            transform,
            transformInverse,
        };
    }
}

function getSolderPasteShapes(
    node: PcbBakedNode<PcbNodeTypes.pad>,
    solderPasteLayer: IPcbBoardLayerBase,
    outerCopperShape: CopperOuterShape,
    transform: Matrix3,
    transformInverse: Matrix3,
): Shape[] {
    const expansion = node.bakedRules.solderPasteMaskExpansion;
    const unexpanded = {
        ...outerCopperShape,
        layerId: solderPasteLayer.uid,
        shapeRole: ShapeRole.solderPaste,
        transform: transform.clone().multiply(outerCopperShape.transform),
        transformInverse: outerCopperShape.transformInverse.clone().multiply(transformInverse),
    };
    const emptyTransform = new Matrix3().identity();
    if (expansion !== undefined && expansion !== 0) {
        return [
            {
                shapeType: ShapeType.Expansion,
                layerId: solderPasteLayer.uid,
                shapeRole: ShapeRole.solderPaste,
                shape: unexpanded,
                expansion,
                transform: emptyTransform,
                transformInverse: emptyTransform,
            },
        ];
    } else {
        return [unexpanded];
    }
}

function getSolderMaskShapes(
    node: PcbBakedNode<PcbNodeTypes.pad>,
    // Layers can also not exist (think of a single layer board)
    solderMaskLayerTop: IPcbBoardLayerBase | undefined,
    solderMaskLayerBottom: IPcbBoardLayerBase | undefined,
    outerCopperShape: CopperOuterShape,
    baseHoleShape: BaseHoleShape | undefined,
    transform: Matrix3,
    transformInverse: Matrix3,
): Shape[] {
    const expansionTop = node.bakedRules.solderMaskExpansion?.topExpansion ?? 0;
    const expansionBottom = node.bakedRules.solderMaskExpansion?.bottomExpansion ?? 0;
    const expansionFromHoleEdge = node.bakedRules.solderMaskExpansion?.expansionFromTheHoleEdge ?? 0;
    const emptyTransform = new Matrix3().identity();

    const holeShapeGen = (layer: IPcbBoardLayerBase, baseHoleShape: BaseHoleShape): Shape =>
        expansionFromHoleEdge === 0
            ? {
                  ...baseHoleShape,
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: layer.uid,
              }
            : {
                  shapeType: ShapeType.Expansion,
                  shape: {
                      ...outerCopperShape,
                      shapeRole: ShapeRole.solderMaskOpening,
                      layerId: layer.uid,
                  },
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: layer.uid,
                  expansion: expansionFromHoleEdge,
                  transform: emptyTransform,
                  transformInverse: emptyTransform,
              };

    const shapeTop: Shape | undefined =
        solderMaskLayerTop &&
        (baseHoleShape
            ? {
                  shapeType: ShapeType.Difference,
                  shapeA:
                      expansionTop === 0
                          ? {
                                ...outerCopperShape,
                                shapeRole: ShapeRole.solderMaskOpening,
                                layerId: solderMaskLayerTop.uid,
                            }
                          : {
                                shapeType: ShapeType.Expansion,
                                shapeRole: ShapeRole.solderMaskOpening,
                                layerId: solderMaskLayerTop.uid,
                                expansion: expansionTop,
                                transform: emptyTransform,
                                transformInverse: emptyTransform,
                                shape: {
                                    ...outerCopperShape,
                                    shapeRole: ShapeRole.solderMaskOpening,
                                    layerId: solderMaskLayerTop.uid,
                                },
                            },
                  shapeB: holeShapeGen(solderMaskLayerTop, baseHoleShape),
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: solderMaskLayerTop.uid,
                  transform,
                  transformInverse,
              }
            : {
                  ...outerCopperShape,
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: solderMaskLayerTop.uid,
                  transform: transform.clone().multiply(outerCopperShape.transform),
                  transformInverse: outerCopperShape.transformInverse.clone().multiply(transformInverse),
              });
    const shapeBottom: Shape | undefined =
        solderMaskLayerBottom &&
        (baseHoleShape
            ? {
                  shapeType: ShapeType.Difference,
                  shapeA:
                      expansionBottom === 0
                          ? {
                                ...outerCopperShape,
                                shapeRole: ShapeRole.solderMaskOpening,
                                layerId: solderMaskLayerBottom.uid,
                            }
                          : {
                                shapeType: ShapeType.Expansion,
                                shapeRole: ShapeRole.solderMaskOpening,
                                layerId: solderMaskLayerBottom.uid,
                                expansion: expansionBottom,
                                transform: emptyTransform,
                                transformInverse: emptyTransform,
                                shape: {
                                    ...outerCopperShape,
                                    shapeRole: ShapeRole.solderMaskOpening,
                                    layerId: solderMaskLayerBottom.uid,
                                },
                            },
                  shapeB: holeShapeGen(solderMaskLayerBottom, baseHoleShape),
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: solderMaskLayerBottom.uid,
                  transform,
                  transformInverse,
              }
            : {
                  ...outerCopperShape,
                  shapeRole: ShapeRole.solderMaskOpening,
                  layerId: solderMaskLayerBottom.uid,
                  transform: transform.clone().multiply(outerCopperShape.transform),
                  transformInverse: outerCopperShape.transformInverse.clone().multiply(transformInverse),
              });

    return filterNotNull([shapeTop, shapeBottom]);
}
