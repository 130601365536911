import {IUserData, IUserPresenceData, Organization} from "@buildwithflux/core";
import {Enterprise, EnterpriseOrganization, IBaseDocumentData} from "@buildwithflux/models";
import {createAction} from "@reduxjs/toolkit";

export const setDocumentOwner = createAction("setDocumentOwner", (owner?: IUserData) => {
    return {
        payload: {
            owner,
        },
    };
});

export const setDocumentEnterpriseOwner = createAction("setDocumentEnterpriseOwner", (enterpriseOwner?: Enterprise) => {
    return {
        payload: {
            enterpriseOwner,
        },
    };
});

export const setDocumentEnterpriseOwnerDefaultOrganization = createAction(
    "setDocumentEnterpriseOwnerDefaultOrganization",
    (enterpriseOwnerDefaultOrganization?: EnterpriseOrganization) => {
        return {
            payload: {
                enterpriseOwnerDefaultOrganization,
            },
        };
    },
);

export const setDocumentOrganizationOwner = createAction(
    "setDocumentOrganizationOwner",
    (organizationOwner?: Organization) => {
        return {
            payload: {
                organizationOwner,
            },
        };
    },
);

export const setHostDocumentForLocalComponent = createAction(
    "setHostDocumentForLocalComponent",
    (hostDocument?: IBaseDocumentData) => {
        return {
            payload: {
                hostDocument,
            },
        };
    },
);

export const setRemoteActiveUsers = createAction("setRemoteActiveUsers", (users: IUserPresenceData[]) => {
    return {
        payload: {
            users,
        },
    };
});

export const setBaseDocumentData = createAction("setBaseDocumentData", (baseDocument?: IBaseDocumentData) => {
    return {
        payload: {
            baseDocument,
        },
    };
});
