import {BaseStorage} from "./BaseStorage";

/**
 * NOTE: In the editor, data read and write of IProjectInviteData happens
 * through Redux. This class is for the SignUp page or other pages.
 *
 * @see acceptProjectInvite.ts cloud function also
 */
export class ProjectInviteStorage extends BaseStorage {
    public async getProjectInvite(documentUid: string, inviteUid: string) {
        const snap = await this.queryProjectInvites(documentUid).doc(inviteUid).get();
        return snap.data();
    }

    private queryProjectInvites(documentUid: string) {
        return this.firestoreAdapter.documentInvitesCollection(documentUid);
    }
}
