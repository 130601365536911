import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const duplicateGlobalPcbRuleSet = createActionRecordAction(
    "duplicateGlobalPcbRuleSet",
    (ruleUids: string[]) => {
        return {
            payload: {
                ruleUids,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "duplicates a pcb layout rule",
);
