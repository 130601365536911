import {CommentThreadAnchorType, DocumentCommentContext, ICommentData, ICommentThreadData} from "@buildwithflux/core";

// Map from missing parent thread uids to reply thread uids
type OrphanedThreads = Map<ICommentThreadData["uid"], ICommentThreadData[]>;
// Map from parent thread uids to comment uids
type OrphanedComments = Map<ICommentThreadData["uid"], ICommentData[]>;
export type CommentReplies = {[uid: ICommentData["uid"]]: ICommentData[]};

export function createThreadedMessages(): ThreadedMessages {
    return {
        threads: {},
        orphanedComments: new Map(),
        orphanedThreads: new Map(),
        namedThreadIndex: {
            projectChat: undefined,
        },
    };
}

function addCommentToMessageThread(messageThread: MessageThread, comment: ICommentData): void {
    if (comment.belongs_to_comment_thread_uid === messageThread.thread.uid) {
        insertOrUpdateTimeOrderedComment(messageThread.comments, comment);
    } else {
        // Find the parent thread, determine the right child comment, and insert.
        const replyThread = messageThread.childThreads.find(
            (thread) => thread.uid === comment.belongs_to_comment_thread_uid,
        );
        if (replyThread == null)
            throw new Error(`logical error: tried to add a comment as a reply but no reply thread found`);
        if (replyThread.anchor.type !== CommentThreadAnchorType.reply)
            throw new Error(`logical error: replyThread was not of reply type`);
        return addReplyToMessageThread(messageThread, comment, replyThread.anchor.reply_to_comment_uid);
    }
}

function removeCommentFromMessageThread(messageThread: MessageThread, comment: ICommentData): void {
    if (comment.belongs_to_comment_thread_uid === messageThread.thread.uid) {
        const index = messageThread.comments.findIndex((c) => c.uid === comment.uid);
        if (index === -1) throw new Error(`logical error: comment not found in thread`);
        messageThread.comments.splice(index, 1);
    } else {
        // Find the parent thread, determine the right child comment, and remove.
        const replyThread = messageThread.childThreads.find(
            (thread) => thread.uid === comment.belongs_to_comment_thread_uid,
        );
        if (replyThread == null)
            throw new Error(`logical error: tried to remove a comment reply but no reply thread found`);
        if (replyThread.anchor.type !== CommentThreadAnchorType.reply)
            throw new Error(`logical error: replyThread was not of reply type`);
        return removeReplyFromMessageThread(messageThread, comment, replyThread.anchor.reply_to_comment_uid);
    }
}

function addReplyToMessageThread(
    messageThread: MessageThread,
    reply: ICommentData,
    replyToUid: ICommentData["uid"],
): void {
    const existingReplies = messageThread.replies[replyToUid];
    if (existingReplies) insertOrUpdateTimeOrderedComment(existingReplies, reply);
    else {
        messageThread.replies[replyToUid] = [reply];
    }
}

function removeReplyFromMessageThread(
    messageThread: MessageThread,
    reply: ICommentData,
    replyToUid: ICommentData["uid"],
): void {
    const existingReplies = messageThread.replies[replyToUid];
    if (!existingReplies) throw new Error(`logical error: tried to remove a reply but no replies found`);
    const index = existingReplies.findIndex((c) => c.uid === reply.uid);
    existingReplies.splice(index, 1);
}

function insertOrUpdateTimeOrderedComment(thread: ICommentData[], comment: ICommentData): void {
    const existingIndex = thread.findIndex((existingComment) => existingComment.uid === comment.uid);
    if (existingIndex !== -1) {
        thread[existingIndex] = comment;
    } else {
        thread.push(comment);
        thread.sort((a, b) => Math.sign(a.created_at - b.created_at));
    }
}

function gatherChildComments(messages: ThreadedMessages, thread: Readonly<ICommentThreadData>): ICommentData[] {
    const comments = messages.orphanedComments.get(thread.uid);
    if (comments == null) return [];
    messages.orphanedComments.delete(thread.uid);
    return comments;
}

function gatherChildThreads(messages: ThreadedMessages, thread: Readonly<ICommentThreadData>): ICommentThreadData[] {
    const threads = messages.orphanedThreads.get(thread.uid);
    if (threads == null) return [];
    messages.orphanedThreads.delete(thread.uid);
    return threads;
}

// TODO: consistency check on the parentThreadUid?
function addChildThread(messages: ThreadedMessages, parentThreadUid: string, thread: ICommentThreadData): void {
    if (thread.anchor.type !== CommentThreadAnchorType.reply)
        throw new Error(`logical error: thread of type ${thread.anchor.type} cannot be a child`);
    const existingThread = messages.threads[parentThreadUid];
    if (!existingThread) {
        const existingOrphans = messages.orphanedThreads.get(thread.anchor.parent_thread_uid);
        if (existingOrphans) existingOrphans.push(thread);
        else {
            messages.orphanedThreads.set(thread.anchor.parent_thread_uid, [thread]);
        }

        return;
    } else if (existingThread.type === "messageThreadPointer")
        throw new Error(`logical error: child thread cannot be added to child thread`);
    existingThread.childThreads.push(thread);

    const previouslyOrphanedComments = gatherChildComments(messages, thread);
    // Because this is a child thread, all of the orphans are added as replies.
    for (const orphanComment of previouslyOrphanedComments) {
        const previousReplies = existingThread.replies[thread.anchor.reply_to_comment_uid];
        if (previousReplies) insertOrUpdateTimeOrderedComment(previousReplies, orphanComment);
        else {
            existingThread.replies[thread.anchor.reply_to_comment_uid] = [orphanComment];
        }
    }
    // now just add the pointer
    messages.threads[thread.uid] = {
        type: "messageThreadPointer",
        parentThreadUid: existingThread.thread.uid,
    };
}

function addTopLevelThread(messages: ThreadedMessages, thread: ICommentThreadData): void {
    // This is a top level comment, so all of its children are definitionally top level comments.
    const previouslyOrphanedComments = gatherChildComments(messages, thread);
    previouslyOrphanedComments.sort((a, b) => Math.sign(a.created_at - b.created_at));
    messages.threads[thread.uid] = {
        type: "messageThread",
        thread,
        childThreads: [],
        comments: previouslyOrphanedComments,
        replies: {},
        threadUid: thread.uid,
    };
    const previouslyOrphanedThreads = gatherChildThreads(messages, thread);
    for (const childThread of previouslyOrphanedThreads) addChildThread(messages, thread.uid, childThread);
}

function updateTopLevelThread(messages: ThreadedMessages, thread: ICommentThreadData): void {
    const existing = messages.threads[thread.uid];
    if (existing == null || existing.type === "messageThreadPointer")
        throw new Error(`function was called without appropriate check`);
    existing.thread = thread;
}

export function addThread(messages: ThreadedMessages, thread: ICommentThreadData): void {
    if (thread.anchor.type === CommentThreadAnchorType.reply) {
        return addChildThread(messages, thread.anchor.parent_thread_uid, thread);
    } else if (thread.anchor.type === CommentThreadAnchorType.document) {
        if (thread.anchor.context === DocumentCommentContext.projectChat) {
            messages.namedThreadIndex.projectChat = thread.uid;
        }
    }
    const existingThread = messages.threads[thread.uid];
    if (!existingThread) {
        return addTopLevelThread(messages, thread);
    } else {
        return updateTopLevelThread(messages, thread);
    }
}

function addOrphanComment(messages: ThreadedMessages, comment: ICommentData): void {
    const existingOrphans = messages.orphanedComments.get(comment.belongs_to_comment_thread_uid);
    if (existingOrphans) existingOrphans.push(comment);
    else {
        messages.orphanedComments.set(comment.belongs_to_comment_thread_uid, [comment]);
    }
}

export function addComment(messages: ThreadedMessages, comment: ICommentData): void {
    const existing = messages.threads[comment.belongs_to_comment_thread_uid];
    if (!existing) {
        return addOrphanComment(messages, comment);
    }
    if (existing.type === "messageThreadPointer") {
        const parent = messages.threads[existing.parentThreadUid];
        if (!parent) throw new Error(`logical error - pointer exists without parent`);
        if (parent.type === "messageThreadPointer") throw new Error(`logical error - multiply nested threads`);
        return addCommentToMessageThread(parent, comment);
    } else {
        return addCommentToMessageThread(existing, comment);
    }
}

export function removeComment(messages: ThreadedMessages, comment: ICommentData): void {
    const existing = messages.threads[comment.belongs_to_comment_thread_uid];
    if (!existing) {
        throw new Error(`logical error - tried to delete a comment from a thread that does not exist`);
    }
    if (existing.type === "messageThreadPointer") {
        const parent = messages.threads[existing.parentThreadUid];
        if (!parent) throw new Error(`logical error - pointer exists without parent`);
        if (parent.type === "messageThreadPointer") throw new Error(`logical error - multiply nested threads`);
        return removeCommentFromMessageThread(parent, comment);
    } else {
        return removeCommentFromMessageThread(existing, comment);
    }
}

type ChildMessageThreadPointer = {
    type: "messageThreadPointer";
    parentThreadUid: string;
};

type MessageThread = {
    type: "messageThread";
    thread: ICommentThreadData;
    childThreads: ICommentThreadData[];
    comments: ICommentData[];
    replies: CommentReplies;
    threadUid: string;
};

type NamedThreadIndex = {
    projectChat: string | undefined;
};

/**
 * Data structure for keeping track of messages and their relationships.
 */
export type ThreadedMessages = {
    threads: {[threadUid: ICommentThreadData["uid"]]: MessageThread | ChildMessageThreadPointer};
    /**
     * Orphaned comments are those that have been added to the data structure but for which we do not
     * have enough parentage data to figure out where they should go yet.
     */
    orphanedComments: OrphanedComments;
    /**
     * Orphaned threads are those that have parent threads (i.e. they are replies) but for which we have
     * not seen the parent thread.
     */
    orphanedThreads: OrphanedThreads;
    /**
     * There are special threads in a project - for example, the project chat thread.  They are named
     * and can be looked up by that name.  The data isn't special or different, so it's stored in the
     * same place as the regular threads, but this index helps them be found quickly.
     */
    namedThreadIndex: NamedThreadIndex;
};
