import {IElementTerminalBareData, IElementTerminalData, IRouteData} from "@buildwithflux/core";
import {IVertexMap} from "@buildwithflux/models";
import {Dictionary} from "lodash";

import Vertex from "./Vertex";

export type IMinimalRoutesMap = Dictionary<Pick<IRouteData, "endpoints">>;

function createRouteMap(terminalData: {[routeId: string]: IElementTerminalBareData[]}) {
    return Object.fromEntries(
        Object.entries(terminalData)
            .filter(([_key, terminalArray]) => terminalArray.length === 2)
            .map(([key, terminalArray]) => {
                return [
                    key,
                    {
                        endpoints: {
                            // *** The previous code did this without the casts below, but still didn't include the uid, because the uid is non-existent in vertices.
                            start_element_terminal: terminalArray[0] as IElementTerminalData,
                            end_element_terminal: terminalArray[1] as IElementTerminalData,
                        },
                    },
                ];
            }),
    );
}

export function mapVertexMapToRoutes(vertices: IVertexMap): IMinimalRoutesMap {
    const terminalData: {[routeId: string]: IElementTerminalBareData[]} = Object.values(vertices)
        .filter((v) => !!v.terminal)
        .reduce((acc, curr) => {
            Object.keys(curr.routeIds).forEach((routeId) => {
                if (acc[routeId]!) {
                    acc[routeId]!.push(curr.terminal!);
                } else {
                    acc[routeId] = [curr.terminal!];
                }
            });
            return acc;
        }, {} as {[routeId: string]: IElementTerminalBareData[]});
    return createRouteMap(terminalData);
}

export default function mapVerticesToRoutes(vertices: Vertex[]): IMinimalRoutesMap {
    const terminalData: {[routeId: string]: IElementTerminalBareData[]} = vertices
        .filter((v) => !!v.terminalData)
        .reduce((acc, curr) => {
            [...curr.routeIds.values()]!.forEach((routeId) => {
                if (acc[routeId]!) {
                    acc[routeId]!.push(curr.terminalData!);
                } else {
                    acc[routeId] = [curr.terminalData!];
                }
            });
            return acc;
        }, {} as {[routeId: string]: IElementTerminalBareData[]});
    return createRouteMap(terminalData);
}
