import type {AnyPcbBakedNode, BakedRulesFor, PcbBakedNode, PcbNodeTypes, PcbNodesMap} from "@buildwithflux/core";
import {useMemo} from "react";
import {useSyncExternalStoreWithSelector} from "use-sync-external-store/with-selector";

import type {FluxReactivitySubscriptions} from "../subscriptions";

import {EqualityFn} from "./models";

export type PcbNodeSelector<Selection extends unknown> = (node: AnyPcbBakedNode | undefined) => Selection | undefined;
const defaultSelector: PcbNodeSelector<AnyPcbBakedNode> = (node) => node;

type UsePcbNodeWithoutSelector = <T extends unknown>(
    nodeUid: string | undefined | null,
) => undefined | (T extends PcbNodeTypes ? PcbBakedNode<T> : AnyPcbBakedNode);
type UsePcbNodeWithSelector = <Selection extends unknown>(
    nodeUid: string | undefined | null,
    selector: PcbNodeSelector<Selection>,
    isEqual?: EqualityFn,
) => Selection;
/**
 * Use this to reactively subscribe to one single pcb node
 */
export type UsePcbNode = UsePcbNodeWithoutSelector & UsePcbNodeWithSelector;

export const createUsePcbNode = (reactivitySubscriptions: FluxReactivitySubscriptions): UsePcbNode => {
    function usePcbNode<_Selection extends unknown>(nodeUid: string | undefined | null): undefined | AnyPcbBakedNode;
    function usePcbNode<Selection extends unknown>(
        nodeUid: string | undefined | null,
        selector: PcbNodeSelector<Selection>,
        isEqual?: EqualityFn,
    ): undefined | Selection;
    function usePcbNode<Selection extends unknown>(
        nodeUid: string | undefined | null,
        selector?: PcbNodeSelector<Selection>,
        isEqual?: EqualityFn,
    ): undefined | Selection | AnyPcbBakedNode {
        // It is important to memoize the subscribe function here; otherwise, React will re-subscribe
        // between re-renders
        // See notes: https://react.dev/reference/react/useSyncExternalStore#my-subscribe-function-gets-called-after-every-re-render
        const subscribe = useMemo(() => {
            return reactivitySubscriptions.makeSubscriptionToPcbNode(nodeUid);
        }, [nodeUid]);
        const getSnapshot = useMemo(() => {
            return reactivitySubscriptions.makeGetPcbNodeSnapshot(nodeUid);
        }, [nodeUid]);

        return useSyncExternalStoreWithSelector<AnyPcbBakedNode | undefined, AnyPcbBakedNode | Selection | undefined>(
            subscribe,
            getSnapshot,
            undefined,
            selector ? selector : defaultSelector,
            isEqual,
        );
    }

    return usePcbNode;
};

export type UsePcbNodeBakedRules = ((
    nodeUid: string | undefined | null,
    isEqual?: EqualityFn,
) => undefined | BakedRulesFor<PcbNodeTypes>) &
    (<T extends PcbNodeTypes>(
        nodeUid: string | undefined | null,
        type: T,
        isEqual?: EqualityFn,
    ) => undefined | BakedRulesFor<T>);
export const createUsePcbNodeBakedRules = (
    reactivitySubscriptions: FluxReactivitySubscriptions,
): UsePcbNodeBakedRules => {
    function usePcbNodeBakedRules<_T extends PcbNodeTypes>(
        nodeUid: string | undefined | null,
        isEqual?: EqualityFn,
    ): undefined | BakedRulesFor<PcbNodeTypes>;
    function usePcbNodeBakedRules<T extends PcbNodeTypes>(
        nodeUid: string | undefined | null,
        type: T,
        isEqual?: EqualityFn,
    ): undefined | BakedRulesFor<T>;
    function usePcbNodeBakedRules<T extends PcbNodeTypes>(
        nodeUid: string | undefined | null,
        isEqualOrType?: EqualityFn | T,
        isEqual?: EqualityFn,
    ): undefined | BakedRulesFor<PcbNodeTypes> | BakedRulesFor<T> {
        const equalityFn = isEqual
            ? isEqual
            : isEqualOrType && typeof isEqualOrType === "function"
            ? isEqualOrType
            : undefined;

        // It is important to memoize the subscribe function here; otherwise, React will re-subscribe
        // between re-renders
        // See notes: https://react.dev/reference/react/useSyncExternalStore#my-subscribe-function-gets-called-after-every-re-render
        const subscribe = useMemo(() => {
            return reactivitySubscriptions.makeSubscriptionToPcbNodeBakedRules(nodeUid);
        }, [nodeUid]);
        const getSnapshot = useMemo(() => {
            return reactivitySubscriptions.makeGetPcbNodeBakedRulesSnapshot(nodeUid);
        }, [nodeUid]);
        return useSyncExternalStoreWithSelector<
            BakedRulesFor<PcbNodeTypes> | undefined,
            BakedRulesFor<PcbNodeTypes> | undefined
        >(subscribe, getSnapshot, undefined, (bakedRules) => bakedRules, equalityFn);
    }

    return usePcbNodeBakedRules;
};

/**
 * Use this to reactively subscribe to ALL pcb nodes
 */
type AllPcbNodesSelector<Selection extends unknown> = (nodes: PcbNodesMap<AnyPcbBakedNode>) => Selection;
type UseAllPcbNodesWithoutSelector = () => PcbNodesMap<AnyPcbBakedNode>;
type UseAllPcbNodesWithSelector = <Selection extends unknown>(
    selector: AllPcbNodesSelector<Selection>,
    isEqual?: EqualityFn,
) => Selection;
export type UseAllPcbNodes = UseAllPcbNodesWithoutSelector & UseAllPcbNodesWithSelector;

const defaultAllPcbNodesSelector: AllPcbNodesSelector<PcbNodesMap<AnyPcbBakedNode>> = (nodes) => nodes;
export const createUseAllPcbNodes = (reactivitySubscriptions: FluxReactivitySubscriptions): UseAllPcbNodes => {
    function useAllPcbNodes<_Selection extends unknown>(): PcbNodesMap<AnyPcbBakedNode>;
    function useAllPcbNodes<Selection extends unknown>(
        selector: AllPcbNodesSelector<Selection>,
        isEqual?: EqualityFn,
    ): Selection;
    function useAllPcbNodes<Selection extends unknown>(
        selector?: AllPcbNodesSelector<Selection>,
        isEqual?: EqualityFn,
    ): PcbNodesMap<AnyPcbBakedNode> | Selection {
        return useSyncExternalStoreWithSelector<PcbNodesMap<AnyPcbBakedNode>, PcbNodesMap<AnyPcbBakedNode> | Selection>(
            reactivitySubscriptions.subscribeToAllPcbNodes,
            reactivitySubscriptions.getPcbNodesSnapshot,
            undefined,
            selector ? selector : defaultAllPcbNodesSelector,
            isEqual,
        );
    }

    return useAllPcbNodes;
};
