/**
 * This class exists to keep a "shadow" of the current clipboard content.
 * This is because we cannot read the clipboard contents in most browsers,
 * so we need to fake it to support paste from context menus.
 */
export class ClipboardService {
    private clipboardData: string | null = null;

    /**
     * Updates the shadow clipboard data without modifying the actual clipboard.
     * This is to be used when we know the clipboard changed and we want to update its
     * shadow, for example after a paste operation.
     * @param clipboardData The text data to be copied
     */
    public updateClipboardData(clipboardData: string | null) {
        this.clipboardData = clipboardData;
    }

    /**
     * Uses an event to try to read the current clipboard value.
     * @param event The event to extract the data from
     * @returns The value extracted from the event
     * @remark In most cases it's not possible to read the clipboard, `paste` events are probably the only ones supported.
     *         In other cases, such as copy events, it's advisable to clear the shadow clipboard instead.
     */
    public updateClipboardDataFromEvent(event: Event) {
        if ("clipboardData" in event) {
            const clipboardData = (event as ClipboardEvent).clipboardData?.getData("text");
            this.updateClipboardData(clipboardData ?? null);
            return clipboardData;
        }
        return null;
    }

    /**
     * Copies a text string into the clipboard, updating also the shadow value.
     * @param clipboardData The text data to be copied
     */
    public async copyText(clipboardData: string | null) {
        this.updateClipboardData(clipboardData);

        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(clipboardData ?? "");
        } else {
            // added for backward compatibility with browser that don't yet support the new Clipboard API
            return document.execCommand("copy", true, clipboardData ?? "");
        }
    }

    /**
     * Reads the current clipboard value by using either the browser API or the shadow value.
     */
    public async getClipboardData() {
        try {
            return navigator.clipboard.readText();
        } catch (error: any) {
            return this.clipboardData;
        }
    }
}
