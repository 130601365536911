import {IUserData, toAbsoluteUrl} from "@buildwithflux/core";
import Slugify from "slugify";
import {animals, colors, Config, names, starWars, uniqueNamesGenerator} from "unique-names-generator";

import R from "../../../resources/Namespace";
import {FileStorage, ImageSizes} from "../FileStorage.types";

export class UserStorageHelper {
    constructor(private readonly fileStorage: FileStorage) {}

    public static generateAnonUserName() {
        const displayName = uniqueNamesGenerator(this.nameGeneratorConfig);

        const replacements = ["", "", "", ".", "_", "-"];

        const r = Math.floor(Math.random() * replacements.length);
        // eslint-disable-next-line no-magic-numbers
        const uniqHandle = `${displayName}${this.getRandomArbitrary(1000, 999999)}`;

        const handle = Slugify(uniqHandle, {
            lower: true,
            strict: true,
            replacement: replacements[r],
        });

        return {handle, displayName};
    }

    public getAbsoluteUserPictureUrl(
        user: AtLeast<IUserData, "custom_picture_key" | "email" | "handle" | "picture">,
        size = ImageSizes.large,
    ) {
        const relativeUrl = this.getUserPicture(user, size);

        return toAbsoluteUrl(relativeUrl);
    }

    public getUserPicture(
        user: AtLeast<IUserData, "custom_picture_key" | "email" | "handle" | "picture">,
        size = ImageSizes.large,
    ) {
        if (user.custom_picture_key) {
            return this.fileStorage.getProcessedImageUrl(user.custom_picture_key, size, "cover");
        } else if (!user.picture && (user.email || user.handle)) {
            const gravatarUrl = require("gravatar-url");

            return gravatarUrl(user.email || user.handle, {size, default: "retro"});
        } else {
            return user.picture || "";
        }
    }

    private static nameGeneratorConfig: Config = {
        dictionaries: [
            names,
            colors,
            starWars,
            animals,
            R.strings.randomNamesDictionaries.backToTheFuture,
            R.strings.randomNamesDictionaries.dune,
            R.strings.randomNamesDictionaries.mandalorian,
        ],
        separator: " ",
        style: "capital",
        length: Math.floor(Math.random() * 3) + 1,
    };

    private static getRandomArbitrary(min: number, max: number) {
        return Math.round(Math.random() * (max - min) + min);
    }
}
