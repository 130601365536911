import {AnyPcbNode, EditorModes, PcbNodesMap} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";
import {AppThunkAction} from "../../../../../store";

/**
 * Consider using dispatch(addNodesThunk(...)) instead of dispatching this action directly: it provides injected arguments
 */
export const addNodes = createActionRecordAction(
    "addNodes",
    (nodes: PcbNodesMap<AnyPcbNode>, editorMode: EditorModes) => {
        return {
            payload: {
                nodes,
                canUndo: true,
                inPcbEditor: editorMode === EditorModes.pcb,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added PCB nodes",
);

export function addNodesThunk(nodes: PcbNodesMap<AnyPcbNode>): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        const {editorMode} = services.usePersistedDocumentUiStore.getState();
        dispatch(addNodes(nodes, editorMode));
    };
}
