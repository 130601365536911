import {useStore, StoreApi, UseBoundStore} from "zustand";

/**
 * Given a vanilla Zustand store, created with createStore() (which is not bound to React, and cannot be used as a
 * reactive useSyncExternalStore hook), bind it using the `useStore` hook, and return the result with the correct types
 *
 * @see https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#bounded-usestore-hook-for-vanilla-stores
 */
export const createBoundUseStoreHook = <S extends StoreApi<unknown>>(store: S) => {
    const useBoundStore = ((selector) => useStore(store, selector)) as UseBoundStore<S>;

    // We assign the StoreApi back into the useBoundStore, so that we can use things like useBoundStore.getState(), making
    //   it act the same as a store created with create() rather than the vanilla createStore()
    // See https://github.com/pmndrs/zustand/blob/6d9c0cff0dcb0b48027e555fd84b43d9e0cba853/src/react.ts#L88
    // See https://github.com/pmndrs/zustand/discussions/1564 - which recommends against using UseBoundStore<S> the way we do here
    // See https://github.com/pmndrs/zustand/discussions/1964 - which says the typing would not be trivial (but UseBoundStore<S> already does it for us via the `& S` bit)
    Object.assign(useBoundStore, store);

    return useBoundStore;
};
