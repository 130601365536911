import {type Organization, toAbsoluteUrl} from "@buildwithflux/core";

import type {FileStorage} from "../storage_engine/FileStorage.types";
import {ImageSizes} from "../storage_engine/FileStorage.types";

export abstract class OrganizationStorageHelper {
    public getAbsoluteOrganizationPictureUrl(
        organization: Pick<Organization, "customPictureKey" | "handle" | "picture">,
        size = ImageSizes.large,
    ): string {
        const relativeUrl = this.getOrganizationPicture(organization, size);
        return toAbsoluteUrl(relativeUrl);
    }

    public abstract getOrganizationPicture(
        organization: Pick<Organization, "customPictureKey" | "handle" | "picture">,
        size?: ImageSizes,
    ): string;
}

export class FileOrganizationStorageHelper extends OrganizationStorageHelper {
    constructor(private readonly fileStorage: FileStorage) {
        super();
    }

    public getOrganizationPicture(
        organization: Pick<Organization, "customPictureKey" | "handle" | "picture">,
        size = ImageSizes.large,
    ) {
        if (organization.customPictureKey) {
            return this.fileStorage.getProcessedImageUrl(organization.customPictureKey, size, "cover");
        } else if (!organization.picture && organization.handle) {
            const gravatarUrl = require("gravatar-url");
            return gravatarUrl(organization.handle, {size, default: "retro"});
        } else {
            return organization.picture || "";
        }
    }
}

export class MockOrganizationStorageHelper extends OrganizationStorageHelper {
    public getOrganizationPicture(
        organization: Pick<Organization, "customPictureKey" | "handle" | "picture">,
        size = ImageSizes.large,
    ) {
        if (organization.customPictureKey) {
            return `https://mock-storage-engine.example.com/${organization.customPictureKey}`;
        } else if (!organization.picture && organization.handle) {
            const gravatarUrl = require("gravatar-url");
            return gravatarUrl(organization.handle, {size, default: "retro"});
        } else {
            return organization.picture || "";
        }
    }
}
