/* eslint-disable import/no-unused-modules */
// lint false alarm?!

import {createAction, PayloadActionCreator, PrepareAction} from "@reduxjs/toolkit";

/***
 * This enum is used to display the action names in the change history. The string should be build to work with
 * a profile name. Example: "<User> updated the document".
 * Only actions that are displayed to the user need to be added to this enum.
 */
export const ActionsDisplayString: {[key: string]: string} = {
    // Keeping these strings for backward compatibility - these actions are removed already, but the
    // corresponding action records could be existing in older document. So we still need them to support
    // old documents.
    updateDocument: "updated the document",
    setPropertiesToSubject: "set properties for subject",
    addNewRoute: "added a route",
    addRoutes: "added routes",
    addBranchPoint: "added a route connection",
    addNewElement: "added an element",
    updateElementsPosition: "moved schematic elements", // rename to "updateElementsSchematicPosition"
    // The following ones are generated by creating an action record manually instead of dispatching an action
    // - forkDocument: created in `ForkDocument.tsx`
    // - createDocument: created in `CreateDocument.tsx`
    // - cloneDocument: created in `createDocumentClonedActionRecord`
    forkDocument: "forked the project",
    createDocument: "created the project",
    cloneDocument: "cloned the project",
    createPartTemplate: "created the project from part template",
    undoDocument: "undid a change",
    redoDocument: "redid a change",
    updateElementsLabel: "updated the label of an element",
};

type ActionRecordActionFlags = {
    canUndo: boolean;
    updatesDocumentTimestamp: true;
    shouldGenerateActionRecord: true;
};

type HiddenActionRecordActionFlags = {
    canUndo: false;
    updatesDocumentTimestamp: false;
    shouldGenerateActionRecord: true;
};

type SystemActionFlags = {
    canUndo: false;
    updatesDocumentTimestamp: false;
    shouldGenerateActionRecord: false;
};

/**
 * NOTE: Any action that is to be displayed in the change history should be
 * created using this method!
 *
 * createActionRecordAction is doing two things:
 * 1. Record the `displayString` under ActionsDisplayString, as we need to use
 *    it in `ChangeHistoryListItem.tsx`
 * 2. call `createAction` as usual and return the resulting action
 *
 * HACK: has a side effect to update `ActionsDisplayString` with `type` as key
 * and `displayString` as value. The benefit is that we configure everything for
 * an action in one place. The drawback is we are mutating a global variable,
 * which doesn't look good... We should figure out some ways to fix it.
 *
 * @param type - redux action type
 * @param prepareAction - same parameter for `createAction`
 * @param displayString - a user-readable string that will be used and displayed
 * in `ChangeHistoryListItem.tsx`
 *
 * @returns a configured redux action
 */
export const createActionRecordAction = <PA extends PrepareAction<ActionRecordActionFlags>, T extends string = string>(
    type: T,
    prepareAction: PA,
    displayString: string,
): PayloadActionCreator<ReturnType<PA>["payload"], T, PA> => {
    ActionsDisplayString[type] = displayString;
    return createAction(type, prepareAction);
};

/**
 * For actions that should sync to other clients but should not be revertable in
 * the change history.
 *
 * NOTE: Consequently, the display string is not currently used.
 */
export const createHiddenActionRecordAction = <
    PA extends PrepareAction<HiddenActionRecordActionFlags>,
    T extends string = string,
>(
    type: T,
    prepareAction: PA,
    displayString: string,
): PayloadActionCreator<ReturnType<PA>["payload"], T, PA> => {
    ActionsDisplayString[type] = displayString;
    return createAction(type, prepareAction);
};

/**
 * For actions that are generated by the system and should generate a patch and
 * trigger a rebake, but should not be undoable by the user and not create an
 * action record.
 *
 * These actions are risky to use for a number of reasons.
 *
 * 1. When triggered automatically, they can happen at a much higher rate than a
 *    human user would trigger actions and cause a performance issue
 * 2. When they change the state of the document in a way that affects
 *    subsequent actions, they break the chain of action records that are needed
 *    for a complete history.
 * 3. In a multiplayer session, if the other user also does not get the action,
 *    it will cause inconsistent states.
 */
export const createSystemAction = <PA extends PrepareAction<SystemActionFlags>, T extends string = string>(
    type: T,
    prepareAction: PA,
): PayloadActionCreator<ReturnType<PA>["payload"], T, PA> => {
    return createAction(type, prepareAction);
};
