import {DrcEnabledCheckers} from "@buildwithflux/core";

import {IDrcValidator} from "../types";

import {AirwiresValidator} from "./AirwiresValidator";
import {DeprecatedRulesValidator} from "./DeprecatedRulesValidator";
import {FloatingCopperValidator} from "./FloatingCopperValidator";
import {ImportantOverrideValidator} from "./ImportantOverrideValidator";
import {InvalidFillsValidator} from "./InvalidFillsValidator";
import {InvalidLayerValidator} from "./InvalidLayerValidator";
import {MissingFootprintsValidator} from "./MissingFootprintsValidator";
import {OverlappingCopperValidator} from "./OverlappingCopperValidator";
import {OverlappingTracesValidator} from "./OverlappingTracesValidator";

export function generateValidatorsFromFeatureFlag(drcEnabledCheckers: DrcEnabledCheckers): IDrcValidator[] {
    const validators: (IDrcValidator | false | undefined)[] = [
        drcEnabledCheckers.OverlappingTracesValidator && new OverlappingTracesValidator(),
        drcEnabledCheckers.OverlappingCopperValidator && new OverlappingCopperValidator(),
        drcEnabledCheckers.MissingFootprintsValidator && new MissingFootprintsValidator(),
        drcEnabledCheckers.InvalidLayerValidator && new InvalidLayerValidator(),
        drcEnabledCheckers.ImportantOverrideValidator && new ImportantOverrideValidator(),
        drcEnabledCheckers.AirwiresValidator && new AirwiresValidator(),
        drcEnabledCheckers.DeprecatedRulesValidator && new DeprecatedRulesValidator(),
        drcEnabledCheckers.InvalidFillsValidator && new InvalidFillsValidator(),
        drcEnabledCheckers.FloatingCopperValidator && new FloatingCopperValidator(),
    ];
    return validators.filter<IDrcValidator>((v): v is IDrcValidator => Boolean(v));
}
