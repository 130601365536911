import {IUserData, Suggestion} from "@buildwithflux/core";

import {createActionRecordAction} from "../../helpers/actionCreator";
import {AppThunkAction} from "../../store";

import {EnrichedCommand, enrichCommands} from "./enrichment";
import {ApplySuggestionResult} from "./models";

/**
 * The actual redux action that is processed by the reducer.
 */
export const applyCommands = createActionRecordAction(
    "applyCommands",
    (commands: EnrichedCommand[], actingUser: IUserData) => ({
        payload: {
            commands,
            actingUser,
            canUndo: true,
            updatesDocumentTimestamp: true,
            shouldGenerateActionRecord: true,
        },
    }),
    "Accepted suggestions from @copilot",
);

/**
 * Do the work of actually applying suggestions to the current document state.  During this thunk, it's possible for a bunch of i/o
 * to be performed as we "enrich" the operations (i.e. commands) in the suggestion with data.
 */
export const applySuggestion = (suggestion: Suggestion): AppThunkAction<Promise<ApplySuggestionResult>> => {
    return async (dispatch, getState, services) => {
        // First we have to do the enrichment.  This is the potentially slow part as it involves i/o.
        const enrichedOps = await enrichCommands(suggestion.commands, services);
        const startingState = getState();
        const patchChanged = (patchUid: string) => patchUid !== startingState.document?.latestPatch?.uid;
        const currentUser = services.currentUserService.getCurrentUser();
        if (!currentUser) {
            throw new Error(`cannot apply operations without a current user`);
        }

        dispatch(applyCommands(enrichedOps, currentUser));
        const endingState = getState();

        /**
         * If the patch exists and is different from the starting state, then we successfully applied the suggestion.
         */
        if (endingState.document?.latestPatch?.uid && patchChanged(endingState.document.latestPatch.uid)) {
            return {
                type: "success",
                // NOTE: latestPatch.uid is not always the same as the document.latestActionRecordUid
                appliedAsActionRecordUid: endingState.document.latestPatch.uid,
            };
        } else {
            return {
                type: "failure",
                reason: "Could not apply suggestion",
            };
        }
    };
};
