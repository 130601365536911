import {concatErrorArgs} from "@buildwithflux/core";
import {wait} from "@buildwithflux/shared";
import {ComponentType, lazy} from "react";

const RETRY_COUNT_MAX = 10;
const RETRY_WAIT_TIME_MS_MAX = 5000;
const RETRY_WAIT_TIME_MS_MIN = 1000;

function calculateNewRetryWaitTimeInMs(attemptsLeft: number) {
    return (
        RETRY_WAIT_TIME_MS_MIN +
        (RETRY_COUNT_MAX - attemptsLeft) * ((RETRY_WAIT_TIME_MS_MAX - RETRY_WAIT_TIME_MS_MIN) / RETRY_COUNT_MAX)
    );
}

type ComponentModule<T> = {default: ComponentType<T>};

export default async function retry<T>(
    asyncComponentFactory: () => Promise<ComponentModule<T>>,
    attemptsLeft: number = RETRY_COUNT_MAX,
): Promise<ComponentModule<T>> {
    try {
        return await asyncComponentFactory();
    } catch (error) {
        if (attemptsLeft > 1) {
            return wait(calculateNewRetryWaitTimeInMs(attemptsLeft)).then(() =>
                retry(asyncComponentFactory, attemptsLeft - 1),
            );
        }

        throw new Error(
            ...concatErrorArgs(`Failed to lazy load function after ${RETRY_COUNT_MAX} retry attempts`, error),
        );
    }
}

/**
 * Combines the lazy and retry helpers together (commonly used together)
 */
export function lazyRetry<T>(asyncComponentFactory: () => Promise<ComponentModule<T>>) {
    return lazy(() => retry(() => asyncComponentFactory()));
}
