import {
    FootPrintPadHoleType,
    KicadImportTextPlaceholder,
    AbstractKicadImporter,
    FileHeaderCommands,
    FileSectionCommands,
    FootprintModuleRecordTypes,
    KiCadHelpers,
    newLine,
    SchemaTypes,
    Units,
} from "@buildwithflux/core";
import {
    FootPrintPadShape,
    FootPrintShapeType,
    IFootprintCircleShapeData,
    IFootprintData,
    IFootprintLineShapeData,
    IFootprintMap,
    IFootprintPadBaseMixin,
    IFootprintPadData,
    IFootprintShapeData,
    IFootprintTextShapeData,
    IVector2,
    ZoneConnectionType,
} from "@buildwithflux/models";
import SparkMD5 from "spark-md5";

// kicad footprint specs: https://www.compuphase.com/electronics/LibraryFileFormats.pdf
// general info: https://forum.kicad.info/t/what-is-the-meaning-of-the-layers-in-pcb-new-and-in-the-footprint-editor-kicad-5-and-earlier/9688
interface IPadDefinition {
    name: string;
    shape: FootPrintPadShape | undefined;
    size: IVector2;
    orientation: number;
    delta?: IVector2;
}

// We can't remove the v3 Footprint Importer as footprints are parsed at
// runtime and there may be parts that have v3 footprints as assets.
export class KiCadv3FootprintImporter extends AbstractKicadImporter {
    private readonly fileArray: string[];
    private kicadFootprintUnits: Units = Units.decimil;

    constructor(textFileContent: string) {
        super(textFileContent);
        this.fileArray = this.moduleData.split(newLine);
    }

    public parse() {
        if (!this.isValidKiCadPartLibFile(this.fileArray)) {
            return {};
        }

        return this.createFootprintObjectFromFileArray(this.fileArray);
    }

    private isValidKiCadPartLibFile(fileArray: string[]) {
        return fileArray[0]!.startsWith(SchemaTypes.footprint);
    }

    private createFootprintObjectFromFileArray(fileArray: string[]): IFootprintMap {
        const footprints: IFootprintMap = {};
        let startOfIndex = false;
        const index: string[] = [];

        fileArray.every((rowContent, _rowIndex) => {
            if (rowContent.startsWith(FileHeaderCommands.units)) {
                this.kicadFootprintUnits = this.parseUnitDefinition(rowContent);
            }

            if (rowContent.startsWith(FileSectionCommands.startIndex)) {
                startOfIndex = true;
            } else if (startOfIndex) {
                if (rowContent.startsWith(FileSectionCommands.endIndex)) {
                    startOfIndex = false;
                    return false;
                } else {
                    index.push(rowContent);
                }
            }

            return true;
        });

        index.forEach((index) => {
            const footprint = this.parseIndexDataFromFileArray(fileArray, index);

            footprints[footprint.uid] = footprint;
        });

        return footprints;
    }

    private parseUnitDefinition(rowContent: string) {
        const value = rowContent.split(" ")[1];
        if (value === Units.millimetres) {
            return Units.millimetres;
        } else {
            return Units.decimil;
        }
    }

    private parseIndexDataFromFileArray(fileArray: string[], moduleName: string): IFootprintData {
        const moduleRows = this.getModuleRows(fileArray, moduleName);
        let description: string | undefined;
        let keywords: string | undefined;
        let solderPaste: number | undefined;
        let solderMask: number | undefined;
        let solderPasteRatioPercentage: number | undefined;
        let localClearance: number | undefined;
        let zoneConnection: ZoneConnectionType | undefined;
        let thermalWidth: number | undefined;
        let thermalGap: number | undefined;
        const shapes: IFootprintShapeData[] = [];

        const moduleHash = SparkMD5.hash(fileArray.join(""));

        moduleRows.forEach((indexRow) => {
            if (indexRow.startsWith(FootprintModuleRecordTypes.description)) {
                description = this.parseSingleValueFromRow(indexRow);
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.keywords)) {
                keywords = this.parseSingleValueFromRow(indexRow);
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.solderPaste)) {
                solderPaste = this.convertNumberToMeters(this.parseSingleValueFromRow(indexRow));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.solderMask)) {
                solderMask = this.convertNumberToMeters(this.parseSingleValueFromRow(indexRow));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.solderPasteRatio)) {
                solderPasteRatioPercentage = Number(this.parseSingleValueFromRow(indexRow));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.localClearance)) {
                localClearance = this.convertNumberToMeters(this.parseSingleValueFromRow(indexRow));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.zoneConnection)) {
                const code = Number(this.parseSingleValueFromRow(indexRow));

                if (code === 0) {
                    zoneConnection = ZoneConnectionType.padsNotCovered;
                } else if (code === 1) {
                    zoneConnection = ZoneConnectionType.thermalRelief;
                } else if (code === 2) {
                    zoneConnection = ZoneConnectionType.padSolidInsideTheZone;
                } else if (code === 3) {
                    zoneConnection = ZoneConnectionType.thermalReliefOnlyForThPads;
                }
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.thermalWidth)) {
                thermalWidth = this.convertNumberToMeters(this.parseSingleValueFromRow(indexRow));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.thermalGap)) {
                thermalGap = this.convertNumberToMeters(this.parseSingleValueFromRow(indexRow));
            }

            if (indexRow.startsWith(FootprintModuleRecordTypes.drawText)) {
                shapes.push(this.parseTextFromRow(indexRow, moduleName, moduleHash));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.drawLine)) {
                shapes.push(this.parseLineFromRow(indexRow, moduleHash));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.drawCircle)) {
                shapes.push(this.parseCircleFromRow(indexRow, moduleHash));
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.drawArc)) {
                // TODO: implement
            }
            if (indexRow.startsWith(FootprintModuleRecordTypes.drawPolygon)) {
                // TODO: implement
            }
        });

        const pads = this.getPadsFromModuleRows(moduleRows, moduleHash);

        return {
            uid: moduleHash,
            name: moduleName,
            description,
            keywords,
            pads,
            shapes,
            solderPaste,
            solderMask,
            solderPasteRatioPercentage,
            localClearance,
            zoneConnection,
            thermalWidth,
            thermalGap,
        };
    }

    private getModuleRows(fileArray: string[], indexName: string) {
        let startOfModule = false;
        const indexRows: string[] = [];

        fileArray.every((rowContent, _rowIndex) => {
            if (rowContent.startsWith(`${FileSectionCommands.startModule} ${indexName}`)) {
                startOfModule = true;
            }

            if (startOfModule) {
                if (rowContent.startsWith(`${FileSectionCommands.endModule} ${indexName}`)) {
                    startOfModule = false;
                    return false;
                } else {
                    indexRows.push(rowContent);
                }
            }

            return true;
        });

        return indexRows;
    }

    private getPadsRows(moduleRows: string[]) {
        let startOfPad = false;
        let currentPadIndex: number;
        const pads: {padRows: string[]}[] = [];

        moduleRows.forEach((rowContent, _rowIndex) => {
            if (rowContent.startsWith(FileSectionCommands.startPad)) {
                startOfPad = true;
                currentPadIndex = pads.push({padRows: []}) - 1;
            }

            if (startOfPad) {
                if (rowContent.startsWith(FileSectionCommands.endPad)) {
                    startOfPad = false;
                } else if (!rowContent.startsWith("$")) {
                    pads[currentPadIndex]!.padRows.push(rowContent);
                }
            }
        });

        return pads;
    }

    private parseSingleValueFromRow(rowContent: string) {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        return splitRowArray.join(" ");
    }

    private parseVector2ValueFromRow(rowContent: string): IVector2 {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        return {
            x: this.convertNumberToMeters(splitRowArray[0]!),
            // Kicad has a flipped Y axis, so we need to account for that
            y: -this.convertNumberToMeters(splitRowArray[1]!),
        };
    }

    private parseSizeFromRow(rowContent: string): {size: number; position: IVector2} {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        return {
            size: this.convertNumberToMeters(splitRowArray[0]!),
            position: {
                x: this.convertNumberToMeters(splitRowArray[1]!),
                y: this.convertNumberToMeters(splitRowArray[2]!),
            },
        };
    }

    private parseObroundPadSizeParamsFromRow(rowContent: string): {position: IVector2; size: IVector2} {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        if (splitRowArray.length === 3) {
            return {
                size: {
                    x: this.convertNumberToMeters(splitRowArray[0]!),
                    y: this.convertNumberToMeters(splitRowArray[0]!),
                },
                position: {
                    x: this.convertNumberToMeters(splitRowArray[1]!),
                    y: this.convertNumberToMeters(splitRowArray[2]!),
                },
            };
        } else {
            return {
                position: {
                    x: this.convertNumberToMeters(splitRowArray[1]!),
                    // Kicad has a flipped Y axis, so we need to account for that
                    y: -this.convertNumberToMeters(splitRowArray[2]!),
                },
                size: {
                    x: this.convertNumberToMeters(splitRowArray[4]!),
                    y: this.convertNumberToMeters(splitRowArray[5]!),
                },
            };
        }
    }

    private parseTextFromRow(rowContent: string, moduleName: string, moduleHash: string): IFootprintTextShapeData {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        let italic = false;
        let text: string;
        if (splitRowArray[9] === "I") {
            italic = true;
            text = KiCadHelpers.extractStringFromDoubleQuotes(splitRowArray[10]);
        } else if (splitRowArray[9] === "N") {
            italic = false;
            text = KiCadHelpers.extractStringFromDoubleQuotes(splitRowArray[10]);
        } else {
            text = KiCadHelpers.extractStringFromDoubleQuotes(splitRowArray[9]);
        }

        // rules for placeholders: replace "text" with:
        //      if moduleName, then "*`REF`*" (part designator)
        //      if "VAL**", then "*`VALUE`*" (part value)

        switch (text) {
            case moduleName:
                text = KicadImportTextPlaceholder.refReplace;
                break;

            case "VAL**":
                text = KicadImportTextPlaceholder.valueReplace;
                break;

            default:
                break;
        }

        return {
            uid: SparkMD5.hash(moduleHash + rowContent),
            type: FootPrintShapeType.text,
            position: {
                x: this.convertNumberToMeters(splitRowArray[0]!),
                // Kicad has a flipped Y axis, so we need to account for that
                y: -this.convertNumberToMeters(splitRowArray[1]!),
                mirror: splitRowArray[6] === "M",
            },
            size: {
                x: this.convertNumberToMeters(splitRowArray[2]!),
                y: this.convertNumberToMeters(splitRowArray[3]!),
            },
            strokeWidth: this.convertNumberToMeters(splitRowArray[5]!),
            italic,
            text,
            layerUid: splitRowArray[8]!,
        };
    }

    private parseLineFromRow(rowContent: string, moduleHash: string): IFootprintLineShapeData {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        return {
            uid: SparkMD5.hash(moduleHash + rowContent),
            type: FootPrintShapeType.line,
            start: {
                x: this.convertNumberToMeters(splitRowArray[0]!),
                // Kicad has a flipped Y axis, so we need to account for that
                y: -this.convertNumberToMeters(splitRowArray[1]!),
            },
            end: {
                x: this.convertNumberToMeters(splitRowArray[2]!),
                // Kicad has a flipped Y axis, so we need to account for that
                y: -this.convertNumberToMeters(splitRowArray[3]!),
            },
            // TODO unclear what unit strike width is in...so this is a approximation
            strokeWidth: this.convertNumberToMeters(Number(splitRowArray[5]!) / 250),
            layerUid: splitRowArray[8]!,
        };
    }

    private parseCircleFromRow(rowContent: string, moduleHash: string): IFootprintCircleShapeData {
        const splitRowArray = rowContent.split(" ");

        splitRowArray.shift();

        return {
            uid: SparkMD5.hash(moduleHash + rowContent),
            type: FootPrintShapeType.circle,
            position: {
                x: this.convertNumberToMeters(splitRowArray[0]!),
                // Kicad has a flipped Y axis, so we need to account for that
                y: -this.convertNumberToMeters(splitRowArray[1]!),
            },
            radius: this.distanceBetweenTwoPositions(
                {
                    x: this.convertNumberToMeters(splitRowArray[0]!),
                    y: this.convertNumberToMeters(splitRowArray[1]!),
                },
                {
                    x: this.convertNumberToMeters(splitRowArray[2]!),
                    y: this.convertNumberToMeters(splitRowArray[3]!),
                },
            ),
            strokeWidth: this.convertNumberToMeters(splitRowArray[4]!),
            layerUid: splitRowArray[5]!,
        };
    }

    private convertNumberToMeters(value: number | string) {
        if (this.kicadFootprintUnits === Units.decimil) {
            return Number(value) / 39.37;
        } else if (this.kicadFootprintUnits === Units.millimetres) {
            return Number(value) / 1000;
        }

        return Number(value);
    }

    private distanceBetweenTwoPositions(start: IVector2, end: IVector2) {
        const a = start.x - end.x;
        const b = start.y - end.y;

        return Math.sqrt(a * a + b * b);
    }

    private getPadsFromModuleRows(moduleRows: string[], moduleHash: string) {
        const pads: IFootprintPadData[] = [];
        const padsRows = this.getPadsRows(moduleRows);

        padsRows.forEach((padRows) => {
            let solderPaste: number | undefined;
            let solderMask: number | undefined;
            let solderPasteRatioPercentage: number | undefined;
            let localClearance: number | undefined;
            let padToDieLength: number | undefined;
            let padPosition: IVector2 | undefined;
            let padOrientation: number | undefined;
            let padSize: IVector2 | undefined;
            let roundHoleDiameter: number | undefined;
            let roundHolePosition: IVector2 | undefined;
            let slottedHoleSize: IVector2 | undefined;
            let slottedHolePosition: IVector2 | undefined;
            let zoneConnection: ZoneConnectionType | undefined;
            let thermalWidth: number | undefined;
            let thermalGap: number | undefined;
            let delta: IVector2 | undefined;
            let shape: FootPrintPadShape | undefined;
            let name: string | undefined;
            let holeType: FootPrintPadHoleType | undefined;
            let holeMask: string | undefined;

            padRows.padRows.forEach((padRow) => {
                if (padRow.startsWith(FootprintModuleRecordTypes.padPosition)) {
                    padPosition = this.parseVector2ValueFromRow(padRow);
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.padDefinition)) {
                    const padDefinition = this.parsePadDefinitionFromRow(padRow);

                    shape = padDefinition.shape;
                    padSize = padDefinition.size;
                    name = padDefinition.name;
                    delta = padDefinition.delta;
                    padOrientation = this.kiCadPadOrientationToRadians(padDefinition);
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.padHoleSize)) {
                    if (shape !== FootPrintPadShape.obround) {
                        const padSize = this.parseSizeFromRow(padRow);
                        roundHoleDiameter = padSize.size;
                        roundHolePosition = padSize.position;
                    } else {
                        const oblongSizeParams = this.parseObroundPadSizeParamsFromRow(padRow);

                        slottedHoleSize = oblongSizeParams.size;
                        slottedHolePosition = oblongSizeParams.position;
                    }
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.padType)) {
                    const typeAndMask = this.parsePadTypeAndMaskFromRow(padRow);

                    holeType = typeAndMask.type;
                    holeMask = typeAndMask.mask;
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.padToDieLength)) {
                    padToDieLength = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.padNetNumberAndName)) {
                    // TODO: implement
                }

                if (padRow.startsWith(FootprintModuleRecordTypes.solderPaste)) {
                    solderPaste = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.solderMask)) {
                    solderMask = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.solderPasteRatio)) {
                    solderPasteRatioPercentage = Number(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.localClearance)) {
                    localClearance = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.zoneConnection)) {
                    const code = Number(this.parseSingleValueFromRow(padRow));

                    if (code === 0) {
                        zoneConnection = ZoneConnectionType.padsNotCovered;
                    } else if (code === 1) {
                        zoneConnection = ZoneConnectionType.thermalRelief;
                    } else if (code === 2) {
                        zoneConnection = ZoneConnectionType.padSolidInsideTheZone;
                    } else if (code === 3) {
                        zoneConnection = ZoneConnectionType.thermalReliefOnlyForThPads;
                    }
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.thermalWidth)) {
                    thermalWidth = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
                if (padRow.startsWith(FootprintModuleRecordTypes.thermalGap)) {
                    thermalGap = this.convertNumberToMeters(this.parseSingleValueFromRow(padRow));
                }
            });

            if (padPosition && padSize && shape && name && holeType && holeMask) {
                const pad: IFootprintPadBaseMixin = {
                    uid: SparkMD5.hash(moduleHash + padsRows.join("")),
                    position: {...padPosition, orientation: padOrientation},
                    name,
                    size: padSize,
                    holeType,
                    holeMask,
                    padToDieLength,
                    solderPaste,
                    solderMask,
                    solderPasteRatioPercentage,
                    localClearance,
                    zoneConnection,
                    thermalWidth,
                    thermalGap,
                };

                if (shape === FootPrintPadShape.rectangle) {
                    pads.push({
                        ...pad,
                        shape: FootPrintPadShape.rectangle,
                        holeSize: this.circleFromDiameterIfExists(roundHoleDiameter),
                        holePosition: roundHolePosition,
                    });
                } else if (shape === FootPrintPadShape.circular) {
                    pads.push({
                        ...pad,
                        shape: FootPrintPadShape.circular,
                        holeSize: this.circleFromDiameterIfExists(roundHoleDiameter),
                        holePosition: roundHolePosition,
                    });
                } else if (shape === FootPrintPadShape.obround) {
                    pads.push({
                        ...pad,
                        shape: FootPrintPadShape.obround,
                        holePosition: slottedHolePosition,
                        slottedHoleSize: slottedHoleSize,
                    });
                } else if (shape === FootPrintPadShape.trapezoid) {
                    pads.push({
                        ...pad,
                        shape: FootPrintPadShape.trapezoid,
                        delta: delta ?? {x: 0, y: 0},
                    });
                }
            }
        });

        return pads;
    }

    private circleFromDiameterIfExists(diameter: number | undefined): IVector2 | undefined {
        if (diameter) return {x: diameter, y: diameter};
        return undefined;
    }

    private kiCadPadOrientationToRadians(padDefinition: IPadDefinition) {
        // as per kicad format docs Orientation is in 0.1 degrees
        const degrees = padDefinition.orientation * 0.1;
        return KiCadHelpers.degreesToRadians(degrees);
    }

    private parsePadDefinitionFromRow(padRow: string): IPadDefinition {
        const splitRowArray = padRow.split(" ");

        splitRowArray.shift();

        const padShape = this.getPadShape(splitRowArray[1]!);

        const def: IPadDefinition = {
            name: KiCadHelpers.extractStringFromDoubleQuotes(splitRowArray[0]!),
            shape: padShape,
            size: {
                x: this.convertNumberToMeters(splitRowArray[2]!),
                y: this.convertNumberToMeters(splitRowArray[3]!),
            },
            orientation: Number(splitRowArray[6]!),
        };

        if (padShape === FootPrintPadShape.trapezoid) {
            def.delta = {
                x: this.convertNumberToMeters(splitRowArray[4]!),
                y: this.convertNumberToMeters(splitRowArray[5]!),
            };
        }

        return def;
    }

    private getPadShape(padShapeString: string): FootPrintPadShape | undefined {
        if (padShapeString === "C") {
            return FootPrintPadShape.circular;
        } else if (padShapeString === "R") {
            return FootPrintPadShape.rectangle;
        } else if (padShapeString === "O") {
            return FootPrintPadShape.obround;
        } else if (padShapeString === "T") {
            return FootPrintPadShape.trapezoid;
        }
    }

    private parsePadTypeAndMaskFromRow(padRow: string): {type: FootPrintPadHoleType | undefined; mask: any} {
        const splitRowArray = padRow.split(" ");

        splitRowArray.shift();

        const rawType = splitRowArray[0];

        let type;
        if (rawType === FootPrintPadHoleType.platedThroughHole.toString()) {
            type = FootPrintPadHoleType.platedThroughHole;
        } else if (rawType === FootPrintPadHoleType.SurfaceMountDevice.toString()) {
            type = FootPrintPadHoleType.SurfaceMountDevice;
        } else if (rawType === FootPrintPadHoleType.testPinOrCardEdgeConnector.toString()) {
            type = FootPrintPadHoleType.testPinOrCardEdgeConnector;
        } else if (rawType === FootPrintPadHoleType.nonPlatedHole.toString()) {
            type = FootPrintPadHoleType.nonPlatedHole;
        }

        return {
            type,
            mask: splitRowArray[2],
        };
    }
}
