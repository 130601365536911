import {IPcbLayoutNodesRuleSetsMap, hasSomeLayoutRuleConfigInMap} from "@buildwithflux/models";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const setNodeRules = createActionRecordAction(
    "setNodeRules",
    (updatedNodesRulesSetsMap: IPcbLayoutNodesRuleSetsMap) => {
        const hasInheritedRules = hasSomeLayoutRuleConfigInMap(
            updatedNodesRulesSetsMap,
            "allChildNodesWillInheritThisRule",
        );
        const hasInheritedCalculations = hasSomeLayoutRuleConfigInMap(
            updatedNodesRulesSetsMap,
            "affectsDescendantCalculations",
        );
        const requiresDescendantRebake = hasSomeLayoutRuleConfigInMap(
            updatedNodesRulesSetsMap,
            "requiresDescendantRebake",
        );

        return {
            payload: {
                // TODO: rename updatedNodes
                updatedNodes: updatedNodesRulesSetsMap,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,

                hasInheritedRules,
                hasInheritedCalculations,
                requiresDescendantRebake,
            },
        };
    },
    "set object-specific pcb layout rules",
);
