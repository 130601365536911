import {IBaseDocumentData} from "@buildwithflux/models";
import {documentIsSupportedBySolder} from "@buildwithflux/solder-core";
import {useSelector} from "react-redux";
import {EqualityFn} from "reselect";
import {useSyncExternalStoreWithSelector} from "use-sync-external-store/with-selector";

import {IApplicationState, ReduxStoreService} from "../../../export";
import {FluxReactivitySubscriptions} from "../subscriptions";

type BaseDocumentSelector<Selection extends unknown> = (document: Partial<IBaseDocumentData> | null) => Selection;

export type UseBaseDocument = <Selection extends unknown>(
    selector: BaseDocumentSelector<Selection>,
    isEqual?: EqualityFn,
) => Selection;

export const createUseBaseDocument = (
    reactivitySubscriptions: FluxReactivitySubscriptions,
    reduxStoreService: ReduxStoreService,
): UseBaseDocument => {
    function useBaseDocument<Selection extends unknown>(
        selector: BaseDocumentSelector<Selection>,
        isEqual?: EqualityFn,
    ): Selection {
        return useSyncExternalStoreWithSelector<Partial<IBaseDocumentData>, Selection>(
            reactivitySubscriptions.subscribeToBaseDocument,
            reactivitySubscriptions.getBaseDocumentSnapshot,
            undefined,
            selector,
            isEqual,
        );
    }

    // If document is supported by solder, use our own hook; otherwise, use `useSelector` hook
    const document = reduxStoreService.getStore().getState().document;
    if (document && documentIsSupportedBySolder(document)) return useBaseDocument;

    // If not supported by solder, return an enhanced selector instead of `useSelector` directly, because the
    // data model stored in DocumentService and Redux are different:
    // - In DocumentService, we store `document` there
    // - In Redux, we store a state contains `document`: {document, app, ...}
    function useEnhancedSelector<Selection extends unknown>(
        selector: BaseDocumentSelector<Selection>,
        isEqual?: EqualityFn,
    ): Selection {
        return useSelector((state: IApplicationState) => selector(state.document), isEqual);
    }
    return useEnhancedSelector;
};
