/*
 * Entrypoint for emitting analytics events related to part publishing.
 */

import {AnalyticsStorage} from "../../../../modules/storage_engine/AnalyticsStorage";
import {TrackingEvents} from "../../../../modules/storage_engine/common/TrackingEvents";

enum PublishingAnalyticsAction {
    partPublished = "part_published",
    partVersionPublished = "part_version_published",
    receivingLatestDrafts = "part_receiving_latest_drafts",
    updatedInProject = "part_updated_in_project",
}

interface PartPublished {
    action: PublishingAnalyticsAction.partPublished;
}

interface PartVersionPublished {
    action: PublishingAnalyticsAction.partVersionPublished;
    version_notes_character_count: number;
}

interface PartReceivingLatestDrafts {
    action: PublishingAnalyticsAction.receivingLatestDrafts;
    user_owns_part: boolean;
}

interface PartUpdatedInProject {
    action: PublishingAnalyticsAction.updatedInProject;
    update_was_batch: boolean;
}

type PublishingAction = PartUpdatedInProject | PartReceivingLatestDrafts | PartVersionPublished | PartPublished;

const emitPublishingAction = (action: PublishingAction, analyticsStorage: AnalyticsStorage) => {
    return analyticsStorage.logEvent(TrackingEvents.partPublishing, action);
};

export const emitDocumentPublishedAsPartEvent = (analyticsStorage: AnalyticsStorage) => {
    return emitPublishingAction(
        {
            action: PublishingAnalyticsAction.partPublished,
        },
        analyticsStorage,
    );
};

export const emitPartVersionPublishedEvent = (
    versionNotesCharacterCount: number,
    analyticsStorage: AnalyticsStorage,
) => {
    return emitPublishingAction(
        {
            action: PublishingAnalyticsAction.partVersionPublished,
            version_notes_character_count: versionNotesCharacterCount,
        },
        analyticsStorage,
    );
};

export const emitReceivingLatestDraftsEvent = (userOwnsPart: boolean, analyticsStorage: AnalyticsStorage) => {
    return emitPublishingAction(
        {
            action: PublishingAnalyticsAction.receivingLatestDrafts,
            user_owns_part: userOwnsPart,
        },
        analyticsStorage,
    );
};

export const emitPartUpdatedInProject = (updatedAsPartOfBatchWithSize = 1, analyticsStorage: AnalyticsStorage) => {
    return emitPublishingAction(
        {
            action: PublishingAnalyticsAction.updatedInProject,
            update_was_batch: updatedAsPartOfBatchWithSize > 1,
        },
        analyticsStorage,
    );
};
