import {devAssert} from "@buildwithflux/core";
import {areWeInStorybook, areWeTestingWithJest, hasBrowserWindowAccess, isDevEnv} from "@buildwithflux/shared";
import {get, set} from "lodash";

import {FluxLogger} from "../modules/storage_engine/connectors/LogConnector";

import {FluxServices} from "./container";
import {createServiceContainer} from "./creator";

/**
 * A singleton instance that can be statically accessed with getActiveServicesContainer()
 */
let servicesSingleton: FluxServices | undefined = undefined;

/**
 * Do not add references to this function from the middle of application code. It is only for use from composition roots.
 */
export function getActiveServicesContainerSafelyFromRoot(): FluxServices {
    return getActiveServicesContainerInner(true);
}

/**
 * Get the currently active services container singleton.
 *
 * @deprecated Do not add new references to this function, because it represents the Service Locator anti-pattern. Do
 *             injection instead. See https://coda.io/d/_dZhtNCBApM2/Why-not-Service-Locator_susQP
 */
export function getActiveServicesContainerBadlyAsServiceLocator(): FluxServices {
    return getActiveServicesContainerInner(false);
}

function getActiveServicesContainerInner(fromRoot = false): FluxServices {
    /*
     * We definitely don't want this code path: even if we need a static
     * reference to the active service container, we want the composition root
     * to simply define the container, not rely on lazy instantiation of the
     * container itself. (i.e. there should always be a container, so we
     * shouldn't need to create one here)
     */
    if (typeof servicesSingleton === "undefined") {
        const container = createServiceContainer();

        if ((isDevEnv() || areWeTestingWithJest()) && !fromRoot) {
            // It's normal to get this warning, but if you want to help push the state of the art forward,
            // follow the stack trace, and remove whatever reference is statically creating the container early.
            // Known: store.ts
            container.logger.warn(
                "Creating container on first use: composition root should define container instance instead",
                new Error("Container created here"),
            );
        }

        setActiveServicesContainer(container);
    }

    if (!servicesSingleton) {
        // Will never happen, just to assert to TS that setActiveServicesContainer above has set the value
        throw new Error("No services singleton was created on static active service container miss");
    }

    return servicesSingleton;
}

let lastError: Error | undefined = undefined;

/**
 * Sets the active services container instance
 *
 * It's important that we can mutate this static global singleton, because we should set this state in the composition
 * root when possible (rather than leave it to be lazily instantiated via getActiveServicesContainer())
 */
export function setActiveServicesContainer(services: FluxServices): void {
    devAssert(
        typeof servicesSingleton === "undefined",
        "Existing service container is being replaced with a new instance - this can cause bad service references",
        FluxLogger,
        lastError,
    );

    if (isDevEnv()) {
        lastError = new Error("Previous container instantiation was here");
    }

    servicesSingleton = services;

    if (hasBrowserWindowAccess()) {
        // Load bearing for the Visual Tests, because they use this access to control the page under test
        set(window, ["__FLUX__", "services"], servicesSingleton);
    }
}

/**
 * Unsets a given services container as the active services container singleton, if it is (still) the active service container
 */
export function unsetActiveServicesContainer(services: FluxServices): void {
    if (servicesSingleton === services) {
        servicesSingleton = undefined;
    }

    if (hasBrowserWindowAccess() && get(window, ["__FLUX__", "services"]) === services) {
        set(window, ["__FLUX__", "services"], undefined);
    }
}

/**
 * Less preferred method of clearning an active services container singleton (prefer unsetActiveServicesContainer)
 *
 * But necessary in testing cases where we want to destroy an early-initialized singleton without initializing one ourselves
 *
 * Should not be used outside of testing contexts
 */
export function unsetAnyActiveServicesContainer(): void {
    devAssert(
        areWeTestingWithJest() || areWeInStorybook(),
        "unsetAnyActiveServicesContainer should only be used in testing contexts",
        FluxLogger,
    );

    if (servicesSingleton) {
        servicesSingleton = undefined;
    }

    if (hasBrowserWindowAccess()) {
        set(window, ["__FLUX__", "services"], undefined);
    }
}
