import {hasBrowserWindowAccess, isDevEnv} from "@buildwithflux/shared";

import {AnalyticsStorage} from "../AnalyticsStorage";
import {TrackingEvent} from "../common/TrackingEvents";

// Global requestId returned by requestIdleCallback
let timeoutId: unknown = 0;
let requestIdleId = 0;
let requestAnimationId = 0;
let frozenDurationInMs = 0;

/**
 * Although the concept of a user interaction is easy to define intuitively, the
 * end state is not formally defined in our system. Being reactive, any part of
 * the UI can freely change in response to an update of a store of application
 * state (Redux, Zustand, and so forth).
 *
 * To deal with this gap, we take a shortcut. Assuming that:
 *
 * 1. The CPU is idle when there is no user input
 * 2. All effects of a user input happen immediately after the input with the
 *    highest priority
 * 3. All effects require CPU time
 * 4. The interaction is done when the CPU returns to idle
 *
 * We can leverage requestIdleCallback to indicate when an interaction is done.
 * A further assumption is that there are no previous callbacks queued up that
 * would displace the callback created for measurement.
 *
 * Similarly, we can leverage requestAnimationFrame to indicate when an
 * interaction has yielded control back to the main thread, "unfreezing" the UI.
 *
 * In sum, we measure user interactions by queuing a requestAnimationFrame and
 * requestIdleCallback at the start of an interaction and recording the time
 * when the callbacks fire.
 *
 * @see https://coda.io/d/Performance-Team-Strategy_dy2fqvJBWGU/Measuring-user-interaction-performance_suAjk
 *
 * NOTE: Instead of using this method directly, where you'll need to pass in an AmplitudeConnector, use the
 * version on AnalyticsStorage:
 *
 *     const { analyticsStorage } = useFluxServices()
 *     analyticsStorage.logTimeToNextIdle('someName')
 *
 */
export function logTimeToNextIdle(analytics: AnalyticsStorage, name: TrackingEvent) {
    if (!hasBrowserWindowAccess() || !window.requestIdleCallback) {
        return;
    }

    const consoleName = `FLUX-PERF:${name}`;
    const startMarkName = `FLUX-PERF:${name}_start`;
    // Cancel requests to keep to model of single user input, single UI response
    if (timeoutId || requestIdleId || requestAnimationId) {
        if (isDevEnv() && !timeoutId) {
            // eslint-disable-next-line no-console
            console.warn(consoleName + " is being ignored because of an exisiting idle callback");
        }
        return;
    }

    window.performance.mark(startMarkName);

    // setTimeout avoids the possibilty that the idle callback somehow fires
    // before the interaction effects have started. 10ms should be long enough
    // for any effect to start in a new task.
    timeoutId = setTimeout(() => {
        timeoutId = 0;

        requestAnimationId = requestAnimationFrame(() => {
            requestAnimationId = 0;
            const measure = window.performance.measure(`FLUX-PERF:${name}_frozen`, startMarkName);
            // NOTE: FF and Safari don't support
            if (!measure) return;
            frozenDurationInMs = Math.round(measure.duration);
        });
        requestIdleId = requestIdleCallback(
            ({didTimeout}) => {
                requestIdleId = 0;

                const endMarkName = `FLUX-PERF:${name}_end`;
                window.performance.mark(endMarkName);
                const measure = window.performance.measure(`FLUX-PERF:${name}`, startMarkName, endMarkName);
                // NOTE: FF and Safari don't support
                if (!measure) return;
                const durationInMs = Math.round(measure.duration);

                analytics.logEvent(name, {
                    durationInMs,
                    didTimeout,
                    frozenDurationInMs,
                });
                if (isDevEnv()) {
                    // eslint-disable-next-line no-console -- dev only
                    console.info(
                        `FLUX-PERF: ${name} took ${durationInMs}ms until idle, frozen ${frozenDurationInMs}ms`,
                        didTimeout ? " TIMEOUT" : "",
                    );
                }
            },
            {
                // 10s timeout so duration is not unbounded and outliers don't
                // mess up the stats.
                timeout: 10000,
            },
        );
    }, 10);
}
