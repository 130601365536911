import {
    AbstractKicadImporter,
    FileSuffix,
    KiCadFootprintImporter as KiCadv4FootprintImporter,
    convertFootprintToFluxFootprintMap,
} from "@buildwithflux/core";
import {IFootprintMap} from "@buildwithflux/models";

import {KiCadv3FootprintImporter} from "./KiCadv3/KiCadFootprintImporter";

const getImporterForFileExtension = (
    moduleSuffix: FileSuffix,
    moduleData: string,
): AbstractKicadImporter | undefined => {
    switch (moduleSuffix) {
        case FileSuffix.modFile:
            return new KiCadv3FootprintImporter(moduleData);
        case FileSuffix.kicadModFile:
            return new KiCadv4FootprintImporter(moduleData);
    }
};

// This function is injected into contexts that don't have build time access
// to the v3 KiCad footprint importer. We also only have the file suffix
// (which is used to determine which importer to use) at runtime.
// This function currently both determines which importer to use and
// converts the footprint data to a Flux footprint.
const convertLegacyOrModernKiCadFootprintToFluxFootprint = (
    moduleSuffix: FileSuffix,
    moduleData: string,
): IFootprintMap | undefined => {
    const importer = getImporterForFileExtension(moduleSuffix, moduleData);
    return convertFootprintToFluxFootprintMap(moduleData, importer);
};

export default convertLegacyOrModernKiCadFootprintToFluxFootprint;
