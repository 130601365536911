import {
    FirebaseAuth,
    FirebaseUser,
    FirebaseUserCredential,
    FirebaseUserListener,
    FirebaseUserListenerUnsubscribe,
} from "@buildwithflux/firestore-compatibility-layer";
import {IUserData, LoginMethod} from "@buildwithflux/models";
import {isCypressTestUserAgent} from "@buildwithflux/shared";
import {
    createUserWithEmailAndPassword,
    deleteUser,
    EmailAuthCredential,
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    GithubAuthProvider,
    GoogleAuthProvider,
    linkWithCredential,
    linkWithPopup,
    linkWithRedirect,
    setPersistence,
    signInAnonymously,
    signInWithCredential,
    signInWithPopup,
    signInWithRedirect,
    updateProfile,
    UserCredential,
    inMemoryPersistence,
    indexedDBLocalPersistence,
    AuthProvider,
} from "firebase/auth";

/**
 * Service for accessing authentication data e.g. underlying user accounts
 *
 * Really just a wrapper for Firebase Auth that allows us to mock it as a whole easily
 */
export interface AuthService {
    getCredentialProvider(
        login: LoginMethod,
        user?: Readonly<FirebaseUser> | null, // Weird signature because null != undefined, but we still want "?"
    ): () => Promise<UserCredential>;

    setUserHandleForFirebaseUser(user: FirebaseUser, userHandle: Readonly<string>): Promise<void>;

    getKnownAuthProvidersForEmail(firebaseUserEmail: string): Promise<string[]>;

    getCurrentFirebaseUser(): FirebaseUser | null;

    createAnonymousFirebaseUser(): Promise<FirebaseUserCredential>;

    createFirebaseUser(userData: Readonly<{email: string; password: string}>): Promise<FirebaseUserCredential>;

    logOutOfFirebase(): Promise<void>;

    subscribeToUserChanges(callback: FirebaseUserListener): Promise<FirebaseUserListenerUnsubscribe>;

    createGoogleAuthProvider(allowSignup?: boolean): GoogleAuthProvider;

    createGithubAuthProvider(allowSignup?: boolean): GithubAuthProvider;

    disableLocalPersistence(): Promise<void>;

    enableLocalPersistence(): Promise<void>;

    destroyFirebaseUser(user: Readonly<FirebaseUser>): Promise<void>;
}

/**
 * Service for accessing firebase authentication data e.g. firebase users.
 *
 * Can't easily be moved to other packages, because it's important that when we set up the firebase/auth module with
 * emulators, it take effect in this implementation
 */
export class FirebaseAuthService implements AuthService {
    private static readonly credentialProviderImpls = {
        link: isCypressTestUserAgent() ? linkWithRedirect : linkWithPopup,
        signIn: isCypressTestUserAgent() ? signInWithRedirect : signInWithPopup,
    };

    constructor(protected readonly authService: FirebaseAuth) {}

    public getCredentialProvider(
        login: LoginMethod,
        user?: Readonly<FirebaseUser> | null, // Weird signature because null != undefined, but we still want ?
    ): () => Promise<UserCredential> {
        if (login.type === "password") {
            return () => {
                return user
                    ? linkWithCredential(user, this.createEmailAuthCredential(login))
                    : createUserWithEmailAndPassword(this.authService, login.email, login.password);
            };
        }

        let authProvider: AuthProvider;

        if (login.type === "google.com") {
            authProvider = this.createGoogleAuthProvider();
        } else if (login.type === "github.com") {
            authProvider = this.createGithubAuthProvider();
        }

        return () =>
            user
                ? FirebaseAuthService.credentialProviderImpls.link(user, authProvider)
                : FirebaseAuthService.credentialProviderImpls.signIn(this.authService, authProvider);
    }

    public async setUserHandleForFirebaseUser(user: FirebaseUser, userHandle: Readonly<string>): Promise<void> {
        await updateProfile(user, {displayName: userHandle});
    }

    /**
     * Get all known providers for a given email address.
     */
    public async getKnownAuthProvidersForEmail(firebaseUserEmail: string): Promise<string[]> {
        return fetchSignInMethodsForEmail(this.authService, firebaseUserEmail);
    }

    /**
     * Get the current firebase user.
     */
    public getCurrentFirebaseUser(): FirebaseUser | null {
        return this.authService.currentUser;
    }

    /**
     * Create an anonymous firebase user.
     */
    public async createAnonymousFirebaseUser(): Promise<FirebaseUserCredential> {
        return signInAnonymously(this.authService);
    }

    /**
     * Create a firebase user for a given email and password.
     */
    public async createFirebaseUser(
        userData: Readonly<{email: string; password: string}>,
    ): Promise<FirebaseUserCredential> {
        return createUserWithEmailAndPassword(this.authService, userData.email, userData.password);
    }

    /**
     * Log out from firebase.
     */
    public async logOutOfFirebase(): Promise<void> {
        return this.authService.signOut();
    }

    /**
     * Destroy a firebase user.
     */
    public async destroyFirebaseUser(user: Readonly<FirebaseUser>): Promise<void> {
        return deleteUser(user);
    }

    /**
     * Fire a callback whenever the current auth state changes.  Callback will receive whatever the new current
     * authentication state is.
     */
    public async subscribeToUserChanges(callback: FirebaseUserListener): Promise<FirebaseUserListenerUnsubscribe> {
        return this.authService.onAuthStateChanged(callback);
    }

    /**
     * Create a google auth provider.
     */
    public createGoogleAuthProvider(allowSignup = true): GoogleAuthProvider {
        const provider = new GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        if (!allowSignup) {
            provider.setCustomParameters({
                allow_signup: "false",
            });
        }
        return provider;
    }

    /**
     * Create a github auth provider.
     */
    public createGithubAuthProvider(allowSignup = true): GithubAuthProvider {
        const provider = new GithubAuthProvider();
        provider.addScope("user:email");
        if (!allowSignup) {
            provider.setCustomParameters({
                allow_signup: "false",
            });
        }
        return provider;
    }

    /**
     * Create an email auth credential.
     */
    public createEmailAuthCredential(userData: Readonly<{email: string; password: string}>): EmailAuthCredential {
        return EmailAuthProvider.credential(userData.email, userData.password);
    }

    /**
     * Enable local persistence mode for the auth service.
     */
    public enableLocalPersistence(): Promise<void> {
        return setPersistence(this.authService, indexedDBLocalPersistence);
    }

    /**
     * Enable local persistence mode for the auth service.
     */
    public disableLocalPersistence(): Promise<void> {
        return setPersistence(this.authService, inMemoryPersistence);
    }
}

/**
 * Emulator based repository.  The only difference here is that the credentials that it returns are based on a mock
 * instead of actually calling out to oauth providers.
 */
export class EmulatedFirebaseAuthService extends FirebaseAuthService {
    public override getCredentialProvider(
        login: LoginMethod,
        user?: Readonly<FirebaseUser> | null, // Weird signature because null != undefined, but we still want ?
    ): () => Promise<UserCredential> {
        if (login.type === "google.com") {
            return () => {
                const credential = GoogleAuthProvider.credential(
                    '{"sub": "abc123", "email": "hello@goodbye.io", "email_verified": true}',
                );
                return user ? linkWithCredential(user, credential) : signInWithCredential(this.authService, credential);
            };
        } else if (login.type === "github.com") {
            return () => {
                const credential = GithubAuthProvider.credential(
                    '{"sub": "abc123", "email": "hello@goodbye.io", "email_verified": true}',
                );
                return user ? linkWithCredential(user, credential) : signInWithCredential(this.authService, credential);
            };
        } else {
            return () => {
                const credential = this.createEmailAuthCredential(login);
                return user
                    ? linkWithCredential(user, credential)
                    : createUserWithEmailAndPassword(this.authService, login.email, login.password);
            };
        }
    }
}

export class MockAuthService implements AuthService {
    constructor(private currentUser: IUserData | undefined) {}

    async createAnonymousFirebaseUser(): Promise<FirebaseUserCredential> {
        return Promise.resolve({} as FirebaseUserCredential);
    }

    createEmailAuthCredential(_userData: Readonly<{email: string; password: string}>): EmailAuthCredential {
        return {} as EmailAuthCredential;
    }

    async createFirebaseUser(_userData: Readonly<{email: string; password: string}>): Promise<FirebaseUserCredential> {
        return Promise.resolve({} as FirebaseUserCredential);
    }

    createGithubAuthProvider(_allowSignup?: boolean): GithubAuthProvider {
        return {} as GithubAuthProvider;
    }

    createGoogleAuthProvider(_allowSignup?: boolean): GoogleAuthProvider {
        return {} as GoogleAuthProvider;
    }

    async destroyFirebaseUser(_user: Readonly<FirebaseUser>): Promise<void> {
        return Promise.resolve();
    }

    disableLocalPersistence(): Promise<void> {
        return Promise.resolve();
    }

    enableLocalPersistence(): Promise<void> {
        return Promise.resolve();
    }

    getCredentialProvider(_login: LoginMethod, _user?: Readonly<FirebaseUser> | null): () => Promise<UserCredential> {
        return function () {
            return Promise.resolve({} as UserCredential);
        };
    }

    getCurrentFirebaseUser(): FirebaseUser | null {
        // TODO: convert this to something closer
        return (this.currentUser as unknown as FirebaseUser) ?? null;
    }

    async getKnownAuthProvidersForEmail(_firebaseUserEmail: string): Promise<string[]> {
        return Promise.resolve([]);
    }

    async logOutOfFirebase(): Promise<void> {
        this.currentUser = undefined;
        return Promise.resolve();
    }

    async setUserHandleForFirebaseUser(_user: FirebaseUser, _userHandle: Readonly<string>): Promise<void> {
        return Promise.resolve();
    }

    async subscribeToUserChanges(_callback: FirebaseUserListener): Promise<FirebaseUserListenerUnsubscribe> {
        return Promise.resolve(() => {});
    }
}
