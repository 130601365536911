export const schematicModeStats: {
    drawCalls?: number | undefined;
    p50TimePerFrameInSeconds?: number | undefined;
    p90TimePerFrameInSeconds?: number | undefined;
    avgTimePerFrameInSeconds?: number | undefined;
    p50FramesPerSecond?: number | undefined;
    p90FramesPerSecond?: number | undefined;
    avgFramesPerSecond?: number | undefined;
    frameCount?: number | undefined;
    timeToFirstFramesInMs?: number;
} = {};
