import {documentConfigUid, IDocumentConfigValue, IDocumentConfigsMap, IUserData} from "@buildwithflux/core";
import createCachedSelector from "re-reselect";

import type {IApplicationState} from "../../../../state";

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to  it
const selectConfigs = (state: IApplicationState) => state.document?.configs;

/**
 * Selects the union of document config settings for the document owner and the
 * current user from the given `section`, preferring the current user's
 * settings for any conflicts.
 *
 * @param state - The app state.
 * @param section - The name of the document config section.
 * @returns - An object mapping the setting keys in the given section to their determined values.
 */
export const selectDocumentConfigSectionWithOwnerDefault = createCachedSelector(
    (_appState: IApplicationState, section: string, _currentUser: IUserData | undefined) => section,
    (state: IApplicationState, section: string, currentUser: IUserData | undefined) => currentUser?.uid,
    (state: IApplicationState) => state.document?.owner_uid,
    (state: IApplicationState) => state.document?.configs,
    (section: string, currentUserUid?: string, ownerUid?: string, documentConfigs?: IDocumentConfigsMap) => {
        if (!documentConfigs) return {};

        const currentUserSettings: Array<[string, IDocumentConfigValue]> = [];
        const ownerSettings: Array<[string, IDocumentConfigValue]> = [];

        Object.entries(documentConfigs).forEach(([uid, config]) => {
            if (
                currentUserUid &&
                uid.endsWith(documentConfigUid({key: "", section: section, user_uid: currentUserUid}))
            ) {
                currentUserSettings.push([config.key, config.value]);
            } else if (ownerUid && uid.endsWith(documentConfigUid({key: "", section: section, user_uid: ownerUid}))) {
                ownerSettings.push([config.key, config.value]);
            }
        });

        return Object.fromEntries(ownerSettings.concat(currentUserSettings));
    },
)((_state, section: string, currentUser: IUserData | undefined) => `${section}+${currentUser?.uid ?? ""}`);

const configsSelectors = {
    selectConfigs,
    selectDocumentConfigSectionWithOwnerDefault,
};

export default configsSelectors;
