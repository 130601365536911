import {hasBrowserWindowAccess, isDevEnv} from "@buildwithflux/shared";
import {isAnyOf} from "@reduxjs/toolkit";
import {Patch} from "immer";
import {set} from "lodash";

import {redoDocument, undoDocument} from "./actions";
import {IDocumentReduxAction} from "./actions.types";

// The relationship here goes:
//         (triggers)                      (geneartes)
// action ------------> mutation in state -------------> patches
interface IChange {
    // The Redux action that results in a mutation to the state
    actionType: string;
    // The patches (i.e. diffs) result from the mutation
    patchesForRedux: Patch[];
}

/**
 * This class is used to manage undo and redo stack. Stack should only contain
 * patches resulting from Immer update. Here we defined an interface `IChange` to
 * relate each list of patches with the corresponding action.
 *
 * Immer patches are generated __AFTER__ an action is handled, so we have to use a helper
 * class like this to store them
 *
 * NOTE: This class is a singleton.
 */
class DocumentPatchManager {
    /**
     * In this class we expose APIs to push/pop items to/from each stack, and it
     * is the caller's responsibility to determine when to push/pop items
     * to/from each stack.
     */
    private _redoStack: IChange[];
    private _undoStack: IChange[];

    constructor() {
        this._redoStack = [];
        this._undoStack = [];
    }

    get redoStack() {
        return this._redoStack;
    }

    get undoStack() {
        return this._undoStack;
    }

    /**
     * This function updates both redo and undo stack for redux, whenever an undoable action takes place.
     * This function should only be used in document reducer when the patces/inversePatches pair are available
     *
     * @param action
     * @param patches
     * @param inversePatches
     * @returns
     */
    public onNewUndoableAction(action: IDocumentReduxAction, patches: Patch[], inversePatches: Patch[]) {
        if (patches.length === 0 && inversePatches.length === 0) return;

        // If we are performing an undo, push the diff to redo stack
        if (isAnyOf(undoDocument)(action)) {
            this._redoStack.push({
                actionType: action.type,
                patchesForRedux: inversePatches,
            });
        } else {
            // If we are performing other undoable actions (including redo!), push the diff to undo stack
            this._undoStack.push({
                actionType: action.type,
                patchesForRedux: inversePatches,
            });

            // If we are performing undoable actions OTHER THAN redo, we should clear the redo stack, because
            // that means there's nothing you can redo
            if (!isAnyOf(redoDocument)(action)) {
                this._redoStack = [];
            }
        }
    }

    /**
     * Pop the latest change from undoStack and return it. Please note this will
     * modify the undoStack!
     *
     * This method should only be used in `document/thunks` -> undo thunk, where we are about
     * to perform an undo
     *
     * @returns {IChange} Latest change from undoStack if the stack is NOT empty; otherwise undefined
     */
    public popLatestChangeFromUndoStack(): IChange | undefined {
        if (this._undoStack.length === 0) return;

        return this._undoStack.pop();
    }

    /**
     * Returns the latest change from undoStack.
     *
     * Please note the difference between this function and `popLatestChangeFromUndoStack` is
     * this function does NOT mutate the undo stack.
     *
     * We usually only use this function from tests
     *
     * @returns {IChange} Latest change from undoStack if the stack is NOT empty; otherwise undefined
     */
    public peekLatestChangeFromUndoStack(): IChange | undefined {
        if (this._undoStack.length === 0) return;

        return this._undoStack[this._undoStack.length - 1];
    }

    /**
     *
     * Pop the latest change from redoStack and return it. Please note this will
     * modify the redoStack!
     *
     * This method should only be used in `document/thunks` -> redo thunk, where we are about
     * to perform a redo
     *
     * @returns {IChange} Latest change from redoStack if the stack is NOT empty; otherwise undefined
     */
    public popLatestChangeFromRedoStack(): IChange | undefined {
        if (this._redoStack.length === 0) return;

        return this._redoStack.pop();
    }

    /**
     * Returns the latest change from redoStack.
     *
     * Please note the difference between this function and `popLatestChangeFromRedoStack` is
     * this function does NOT mutate the undo stack.
     *
     * We usually only use this function from tests
     *
     * @returns {IChange} Latest change from redoStack if the stack is NOT empty; otherwise undefined
     */
    public peekLatestChangeFromRedoStack(): IChange | undefined {
        if (this._redoStack.length === 0) return;

        return this._redoStack[this._redoStack.length - 1];
    }

    public reset() {
        this._undoStack = [];
        this._redoStack = [];
    }
}

const manager = new DocumentPatchManager();
// Attach it to global scope so that one could easily check the stacks locally, for debugging purposes
if (isDevEnv() && hasBrowserWindowAccess()) {
    set(window, ["__FLUX__", "DocumentPatchManager"], manager);
}

export default manager;
