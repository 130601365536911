import partPublishingSelectors from "../../reducers/parts/publishing/selectors";

import assetsSelectors from "./assets/selectors";
import baseDocumentSelectors from "./baseDocument/selectors";
import commentThreadsSelectors from "./commentThreads/selectors";
import commonSelectors from "./common/selectors";
import configsSelectors from "./configs/selectors";
import elementsSelectors from "./elements/selectors";
import netSelectors from "./nets/selectors";
import pcbEditorUISelectors from "./pcbEditorUi/selectors";
import pcbLayoutNodesSelectors from "./pcbLayoutNodes/selectors";
import pcbLayoutRulesSelectors from "./pcbLayoutRules/selectors";
import propertiesSelectors from "./properties/selectors";
import rolesSelectors from "./roles/selectors";
import routesSelectors from "./routes/selectors";
import routeVerticesSelectors from "./routeVertices/selectors";

const documentSelectors = {
    assets: assetsSelectors,
    baseDocument: baseDocumentSelectors,
    commentThreads: commentThreadsSelectors,
    common: commonSelectors,
    configs: configsSelectors,
    elements: elementsSelectors,
    nets: netSelectors,
    partSubscriptions: partPublishingSelectors,
    pcbEditorUi: pcbEditorUISelectors,
    pcbLayoutNodes: pcbLayoutNodesSelectors,
    pcbLayoutRules: pcbLayoutRulesSelectors,
    properties: propertiesSelectors,
    roles: rolesSelectors,
    routes: routesSelectors,
    routeVertices: routeVerticesSelectors,
};

export default documentSelectors;
