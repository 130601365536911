import {areWeTestingWithJest, hasDocumentAccess} from "@buildwithflux/shared";

function doesSupportWebp() {
    if (!hasDocumentAccess() || areWeTestingWithJest()) return false;

    const elem = document.createElement("canvas");

    try {
        if (elem.getContext && elem.getContext("2d")) {
            // was able or not to get WebP representation
            return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        }

        // very old browser like IE 8, canvas not supported
        return false;
    } catch (error) {
        // jsdom or other environment where getContext() throws a not implemented exception
        return false;
    }
}

const supportsWebp = doesSupportWebp();

export const preferredImageFormat = () => {
    if (supportsWebp) return "webp";

    return "png";
};
