import {PcbNodeTypes} from "@buildwithflux/core";

// QUESTION: is this type-only import ok? or should we lift it into models or something?
// eslint-disable-next-line boundaries/element-types
import type {ResolutionConfig} from "../modules/selection/types";

/** NOTE: The behavior for elements will be changed later in code following a feature flag */
const pcb_selection_modes: ResolutionConfig<PcbNodeTypes> = {
    layout: {
        root: {normal: "frame", selected: "frame", deepSelect: "frame"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    container: {
        root: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
    },
    group: {
        root: {normal: "specific", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "passthrough"},
    },
    element: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    footprint: {
        root: {normal: "frame", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
    },
    model: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    pad: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    zone: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    root: {
        root: {normal: "passthrough", selected: "passthrough", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "passthrough", deepSelect: "passthrough"},
    },
    text: {
        root: {normal: "generous", selected: "generous", deepSelect: "generous"},
        nonRoot: {normal: "generous", selected: "generous", deepSelect: "generous"},
    },
    line: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    circle: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    rectangle: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    via: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    smartVia: {
        root: {normal: "specific", selected: "generous", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "generous", deepSelect: "specific"},
    },
    net: {
        root: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
    },
    routeSegment: {
        root: {normal: "specific", selected: "specific", deepSelect: "specific"},
        nonRoot: {normal: "specific", selected: "specific", deepSelect: "specific"},
    },
    fill: {
        root: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
    },
    spill: {
        root: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
        nonRoot: {normal: "passthrough", selected: "generous", deepSelect: "passthrough"},
    },
};

const pcb_selection_priorities: PcbNodeTypes[] = [
    PcbNodeTypes.root,
    PcbNodeTypes.layout,
    PcbNodeTypes.net,
    PcbNodeTypes.routeSegment,
    PcbNodeTypes.model,
    PcbNodeTypes.container,
    PcbNodeTypes.group,
    PcbNodeTypes.footprint,
    PcbNodeTypes.element,
    PcbNodeTypes.fill,
    PcbNodeTypes.smartVia,
    PcbNodeTypes.via,
    PcbNodeTypes.pad,
    PcbNodeTypes.zone,
    PcbNodeTypes.circle,
    PcbNodeTypes.rectangle,
    PcbNodeTypes.line,
    PcbNodeTypes.text,
];

const behaviors = {
    camera: {
        pcb: {
            two_d: {
                frustrum: {
                    near: 0.001,
                    far: 100,
                },
            },
            three_d: {
                frustrum: {
                    near: 0.001,
                    far: 100,
                },
            },
        },
        schematic: {
            frustrum: {
                near: 0,
                far: 15,
            },
        },
        default_state: {
            two_d: {
                zoom: 6_000,
                offset: {x: 0, y: 0, z: 0},
                position: {x: 0, y: 0, z: 1},
                target: {x: 0, y: 0, z: 0},
                rotation: {azimuth: 0, polar: 0},
            },
            three_d: {
                zoom: 1,
                offset: {x: 0, y: 0, z: 0},
                position: {x: 0, y: 0, z: 0.1},
                target: {x: 0, y: 0, z: 0},
                rotation: {azimuth: 0, polar: 0},
            },
        },
    },
    navigation_controls: {
        damping_factor: 0.75,
        key_pan_speed: 15,
        panning_speed: 1000, // lower is faster
        pcb_key_pan_speed: 0.0025,
        wheel_drag_speed: 1 / 2,
        wheel_zoom_speed: 1 / 3,
        pcb_wheel_middle_speed: 1.4,
        pcb_wheel_drag_speed: 2,
        pcb_wheel_zoom_speed: 25,
    },
    zoom_controls: {
        pcb_dolly: {
            min: 0.004,
            max: 0.6,
            step: 0.3,
        },
        pcb_zoom: {
            min: 1,
            max: 10000_000,
            step: 0.1,
        },

        minZoom: 0.01,
        maxZoom: 18,
        minZoomBleed: 0.1,
        default_zoom: 2.5,
        navigation_threshold: 0.030000000000000672,
        manual_zoom_step: 0.3,
        zoomToFitPadding: 0.4,
        writeDelay: 3000,
        zoomSpeed: 1 / 60,
    },
    select_controls: {
        raycaster_line_precision: 5,
    },
    part_data: {
        availabilityRefreshInterval: 10 * 1000,
    },
    pcb_selection_modes,
    pcb_selection_priorities,
    subjects: {
        labels: {},
    },
    action_panel: {
        duplicate: {
            spacing: 150,
        },
    },
    inspector: {
        outputs_panel: {
            metrics_update_rate: 100,
        },
    },
    storage: {
        // TODO: need to revisit debounce delays in light of perf gains...
        //  interactions should finish in less than 200ms - but note that we don't have versioning, so we will cause more
        //  concurrency issues by reducing these without first ensuring consistency (i.e. a client has received all pending
        //  action records before triggering the save)
        writeDelayFast: 300,
        writeDelay: 1000,
        writeDelaySlow: 2000,
        writeMaxWait: 3000,
        userPresence: {
            writeDelay: 60000,
        },
    },
    publishing: {
        draftSubs: {
            debounceUserInputsMs: 5000,
        },
    },
    editorModes: {
        idleTimeBeforeUnmountingInactiveModesInMs: 1000 * 60 * 3, // 3 minutes
    },
    diagram_editor: {
        glContextRestoreDelayInMs: 1000, // TODO: this is expected to be temporary and redundant once schematic editor migrated to R3F
        glb_loader: {
            geometry_reducer: {
                threshold: 1000,
                reduction_factor: 0.7, // higher is more aggressive
            },
        },
        metamodule_snap_size: 10,
        metamodule: {
            snap_distance: 10,
            max_scale: 1.2,
            // Text is basically unreadable below this value:
            min_scale: 0.65,
            // `zoom` range is not the same as the `scale` range above. The default zoom is
            // currently 2.5, which is effectively the `100%` value for metamodules, so 0.5
            // below equates to a scale of 0.2
            hide_at_zoom_threshold: 0.5,
        },
        windowResizeDebounceDelay: 1000 / 60,
        versionHistory: {
            // see Create5Digits and getDisplayed5DigitChangeId function
            displayShaLengthInDigits: 8,
        },
        snappingMultiplier: 1,
        snappingOffset: 6,
        snappingDefaultThreshold: 0.5,
        writeDelay: 200,
    },
    code_editor: {
        writeMaxWait: 3000,
    },
    pcb: {
        airwiresRegraphInterval: 1000,
    },
    profile: {
        recentWindowInMinutes: 4320,
        nowWindowInMinutes: 5,
    },
    realtime_networking: {
        bufferExpirationTimeInMS: 2000,
        networkFlushBufferRateInMS: 200,
        networkClientUpdateRateInMs: 1000 / 60,
        minBufferSize: 2,
        maxBufferSize: 80,
        hideCursorAfterMS: 3 * 60 * 1000,
        recentWindowInMinutes: 5,
    },
    dragging: {
        snapSensitivity: 3,
        gridPrecision: 1,
    },
    simulator: {
        loopInterval: 0.5,
        throttleUIStateInterval: 1000,
        throttleSliderControlMax: 300,
        throttleSliderControlDefault: 2,
    },
};

export default behaviors;
