import {IPartUpdateMetadata, IUserMap, PartUpdatingStatus} from "@buildwithflux/core";
import {IPartData} from "@buildwithflux/models";
import {createAction} from "@reduxjs/toolkit";

export const setPartData = createAction("setPartData", (partData: IPartData) => {
    return {
        payload: {
            partData,
        },
    };
});

export const setPartUpdating = createAction("setPartUpdating", (partUid: string, updating: PartUpdatingStatus) => {
    return {
        payload: {
            partUid,
            updating,
        },
    };
});

export const setPartsAreOutOfDate = createAction("setPartsAreOutOfDate", (parts: IPartUpdateMetadata[]) => {
    return {
        payload: {
            parts,
        },
    };
});

export const setPartsAreUpToDate = createAction("setPartsAreUpToDate", (partUids: string[]) => {
    return {
        payload: partUids,
    };
});

export const setPartIsUpToDate = createAction("setPartIsUpToDate", (partUid: string) => {
    return {
        payload: partUid,
    };
});

export const setPartOwners = createAction("setPartOwners", (owners: IUserMap) => {
    return {
        payload: {
            owners,
        },
    };
});

export const acknowledgePartUpdate = createAction("acknowledgePartUpdate", (partUids: string[]) => {
    return {
        payload: {
            partUids,
        },
    };
});

export const clearPartData = createAction("clearPartData");
