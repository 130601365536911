import {ClipperShapeFactory} from "@buildwithflux/core";
import {Matrix3, Vector2, Shape as ThreeShape} from "three";

import {
    Shape,
    CircleShape,
    LineShape,
    OblongShape,
    RectangleShape,
    RoundedRectangleShape,
    ShapeType,
    ApproxPolygonShape,
    ExpansionShape,
    UnionShape,
    DifferenceShape,
} from "../types";
import {booleanDifferencePolygons, booleanUnionPolygons, resizePolygonByExpansion} from "../utils";

/**
 * Given a shape returns a set of (already transformed) points that fit the shape border.
 * NOTE: The segments parameter is just a suggestion, the function can return less or more points when needed.
 */
export function approxFittingPolygon(
    shape: Shape,
    segments: number,
    clipperShapeFactory: ClipperShapeFactory,
    outside = false,
): Vector2[] {
    switch (shape.shapeType) {
        case ShapeType.Circle:
            return circleFittingPolygon(shape, segments, outside);
        case ShapeType.Oblong:
            return oblongFittingPolygon(shape, segments, outside);
        case ShapeType.Rectangle:
            return rectangleFittingPolygon(shape, segments, outside);
        case ShapeType.RoundedRectangle:
            return roundedRectangleFittingPolygon(shape, segments, outside);
        case ShapeType.Line:
            return lineFittingPolygon(shape, segments, outside);
        case ShapeType.ApproxPolygon:
            return approxPolygonFittingPolygon(shape, segments, outside);
        case ShapeType.Expansion:
            return expansionFittingPolygon(shape, segments, outside, clipperShapeFactory);
        case ShapeType.Union:
            return unionFittingPolygon(shape, segments, outside, clipperShapeFactory);
        case ShapeType.Difference:
            return differenceFittingPolygon(shape, segments, outside, clipperShapeFactory);
    }
}

// NOTE: If you ask for less than 3 segments you will get incorrect results!!
function computeCircleErrorRadius(segments: number, radius: number) {
    if (segments < 3) throw new Error("Cannot approximate circle with fewer than 3 segments");

    // APPROXIMATION: To keep the polygon outside the circle itself we have to offset it by the error
    const segmentAngleDist = (Math.PI * 2) / segments;

    // https://www.geogebra.org/calculator/myajdn9h
    const r = radius;
    const l = segmentAngleDist;
    const segSq = (r - (r + Math.cos(l) * r) / 2) ** 2 + ((Math.sin(l) * r) / 2) ** 2;
    const dis = r - Math.sqrt(r ** 2 - segSq);
    const newRadius = r + dis / Math.cos(l / 2);

    return newRadius;
}

function circleFittingPolygon(
    shape: Pick<CircleShape, "radius" | "transform">,
    segments: number,
    outside: boolean,
): Vector2[] {
    const newRadius = outside ? computeCircleErrorRadius(segments, shape.radius) : shape.radius;
    const points = new Array(segments).fill(0).map((_p, i) => {
        const angle = (i * Math.PI * 2) / segments;
        return new Vector2(Math.cos(angle) * newRadius, Math.sin(angle) * newRadius);
    });
    return transformFittingPolygon(points, shape.transform);
}

function oblongFittingPolygon(
    shape: Pick<OblongShape, "transform" | "width" | "height">,
    segments: number,
    outside: boolean,
): Vector2[] {
    if (shape.width === shape.height) {
        // Normal circle
        return circleFittingPolygon({radius: shape.width, transform: shape.transform}, segments, outside);
    }

    const rectShort = Math.min(shape.width, shape.height);
    const rectLong = Math.max(shape.width, shape.height) - rectShort;
    const radius = rectShort / 2;
    const nSegmentsCap = Math.floor((segments - 4) / 2);
    const newRadius = outside ? computeCircleErrorRadius(segments - 4, radius) : radius;
    const rectShortWithError = newRadius * 2; // Needed to preserve convexity

    if (shape.width > shape.height) {
        const startAngle = Math.PI / 2 + Math.PI / nSegmentsCap / 2;
        const endAngle = (3 * Math.PI) / 2 + Math.PI / nSegmentsCap / 2;
        const angleRange = endAngle - startAngle;
        const capPoints = new Array(nSegmentsCap).fill(0).map((_p, i) => {
            const angle = startAngle + i * (angleRange / nSegmentsCap);
            return new Vector2(Math.cos(angle) * newRadius, Math.sin(angle) * newRadius);
        });
        const leftCapPoints = capPoints.map((p) => new Vector2(p.x - rectLong / 2, p.y)).reverse();
        const rightCapPoints = capPoints.map((p) => new Vector2(rectLong / 2 - p.x, p.y));
        return transformFittingPolygon(
            [
                ...leftCapPoints,
                new Vector2(-rectLong / 2, rectShortWithError / 2),
                new Vector2(rectLong / 2, rectShortWithError / 2),
                ...rightCapPoints,
                new Vector2(rectLong / 2, -rectShortWithError / 2),
                new Vector2(-rectLong / 2, -rectShortWithError / 2),
            ],
            shape.transform,
        );
    } else {
        const startAngle = 0 + Math.PI / nSegmentsCap / 2;
        const endAngle = Math.PI + Math.PI / nSegmentsCap / 2;
        const angleRange = endAngle - startAngle;
        const capPoints = new Array(nSegmentsCap).fill(0).map((_p, i) => {
            const angle = startAngle + i * (angleRange / nSegmentsCap);
            return new Vector2(Math.cos(angle) * newRadius, Math.sin(angle) * newRadius);
        });
        const topCapPoints = capPoints.map((p) => new Vector2(p.x, rectLong / 2 + p.y));
        const bottomCapPoints = capPoints.map((p) => new Vector2(p.x, -p.y - rectLong / 2)).reverse();
        return transformFittingPolygon(
            [
                ...topCapPoints,
                new Vector2(-rectShortWithError / 2, rectLong / 2),
                new Vector2(-rectShortWithError / 2, -rectLong / 2),
                ...bottomCapPoints,
                new Vector2(rectShortWithError / 2, -rectLong / 2),
                new Vector2(rectShortWithError / 2, rectLong / 2),
            ],
            shape.transform,
        );
    }
}

function rectangleFittingPolygon(shape: RectangleShape, _segments: number, _outside: boolean): Vector2[] {
    const points = [
        new Vector2(-shape.width / 2, -shape.height / 2),
        new Vector2(shape.width / 2, -shape.height / 2),
        new Vector2(shape.width / 2, shape.height / 2),
        new Vector2(-shape.width / 2, shape.height / 2),
    ];
    return transformFittingPolygon(points, shape.transform);
}

function roundedRectangleFittingPolygon(shape: RoundedRectangleShape, segments: number, _outside: boolean): Vector2[] {
    // TODO: Shape outside not supported for now

    // We use ThreeJS as shortcut, as we already have this code written
    const left = -shape.width / 2;
    const bottom = -shape.height / 2;
    const threeShape = new ThreeShape();
    threeShape.absarc(
        left + shape.radiusBottomLeft,
        bottom + shape.radiusBottomLeft,
        shape.radiusBottomLeft,
        -Math.PI / 2,
        -Math.PI,
        true,
    );
    threeShape.absarc(
        left + shape.radiusTopLeft,
        bottom + shape.height - shape.radiusTopLeft,
        shape.radiusTopLeft,
        Math.PI,
        Math.PI / 2,
        true,
    );
    threeShape.absarc(
        left + shape.width - shape.radiusTopRight,
        bottom + shape.height - shape.radiusTopRight,
        shape.radiusTopRight,
        Math.PI / 2,
        0,
        true,
    );
    threeShape.absarc(
        left + shape.width - shape.radiusBottomRight,
        bottom + shape.radiusBottomRight,
        shape.radiusBottomRight,
        0,
        -Math.PI / 2,
        true,
    );
    const points = threeShape.getPoints(segments);
    return transformFittingPolygon(points, shape.transform);
}

function lineFittingPolygon(shape: LineShape, segments: number, outside: boolean): Vector2[] {
    // Treat it as a rotated oblong
    const pa = new Vector2(shape.startX, shape.startY);
    const pb = new Vector2(shape.endX, shape.endY);
    const len = pa.distanceTo(pb);
    if (len === 0) {
        // To prevent divide by zero, a line with zero length is a circle
        return circleFittingPolygon({radius: shape.thickness / 2, transform: shape.transform}, segments, outside);
    }
    const d = pb.clone().sub(pa).divideScalar(len);
    const center = pa.clone().add(pb).divideScalar(2);
    const height = shape.thickness;
    const width = len + height;
    const mat = new Matrix3().set(d.x, -d.y, center.x, d.y, d.x, center.y, 0, 0, 1);

    return transformFittingPolygon(
        oblongFittingPolygon({width, height, transform: mat}, segments, outside),
        shape.transform,
    );
}

function approxPolygonFittingPolygon(shape: ApproxPolygonShape, _segments: number, _outside: boolean): Vector2[] {
    // TODO: here we are not really computing the fitting polygon but just returning the points as they are,
    // without limiting the points with the input segments nor offsetting the shape approximation error,
    // since it's quite difficult to compute with an SVG/DXF source.
    // This approximation works for now as it's the same one that it's used as well for gerber and rendering,
    // but we will have to revisit it in the future.
    return transformFittingPolygonClone(shape.points, shape.transform);
}

function expansionFittingPolygon(
    shape: ExpansionShape,
    segments: number,
    outside: boolean,
    clipperShapeFactory: ClipperShapeFactory,
): Vector2[] {
    const origPolygon = approxFittingPolygon(shape.shape, segments, clipperShapeFactory, outside);
    // WARNING: could return undefined, the unexpanded polygon is used in that case
    const expandedPolygon =
        resizePolygonByExpansion(origPolygon, shape.expansion, clipperShapeFactory, {
            joinType: "Round",
        }) ?? origPolygon;
    return transformFittingPolygon(expandedPolygon, shape.transform);
}

function unionFittingPolygon(
    shape: UnionShape,
    segments: number,
    outside: boolean,
    clipperShapeFactory: ClipperShapeFactory,
): Vector2[] {
    const origPolygonA = approxFittingPolygon(shape.shapeA, segments, clipperShapeFactory, outside);
    const origPolygonB = approxFittingPolygon(shape.shapeB, segments, clipperShapeFactory, outside);
    const transformedPolygon = booleanUnionPolygons(origPolygonA, origPolygonB, clipperShapeFactory);
    return transformFittingPolygon(transformedPolygon ?? [], shape.transform);
}

function differenceFittingPolygon(
    shape: DifferenceShape,
    segments: number,
    outside: boolean,
    clipperShapeFactory: ClipperShapeFactory,
): Vector2[] {
    const origPolygonA = approxFittingPolygon(shape.shapeA, segments, clipperShapeFactory, outside);
    const origPolygonB = approxFittingPolygon(shape.shapeB, segments, clipperShapeFactory, outside);
    const transformedPolygon = booleanDifferencePolygons(origPolygonA, origPolygonB, clipperShapeFactory);
    return transformFittingPolygon(transformedPolygon ?? [], shape.transform);
}

function transformFittingPolygon(points: Vector2[], transform: Matrix3): Vector2[] {
    return points.map((op) => op.applyMatrix3(transform));
}
function transformFittingPolygonClone(points: Vector2[], transform: Matrix3): Vector2[] {
    return points.map((op) => op.clone().applyMatrix3(transform));
}
