import {PcbNode, PcbNodeTypes} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const updateRouteSegments = createActionRecordAction(
    // QUESTION: why is this mixed plural and singular?
    "updateRouteSegment",
    (updates: {[uid: string]: PcbNode<PcbNodeTypes.routeSegment>}) => {
        return {
            payload: {
                updates,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // NOTE: assume the worst
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "updated route segment",
);
