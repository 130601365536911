import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import ExternalUrls from "../../../../resources/ExternalUrls";
import ErrorPage from "../common/ErrorPage";

export default function NetworkConnectivityErrorPage() {
    return (
        <ErrorPage
            title="Network Connectivity Issue"
            pageTitle="Network Connectivity Issue"
            subtitle={undefined}
            statusCode={500}
        >
            {/* See https://coda.io/d/Firewall-detected_dDkP2hJPBbZ/Firewall-detected_su6gV#_luDDA */}
            <Typography variant="body1" component="p" gutterBottom>
                It looks like we're having trouble with network access to some of our server infrastructure.
            </Typography>
            <Typography variant="body2" component="p" gutterBottom>
                Please see <Link href={ExternalUrls.documentation.setup_browser}>our documentation</Link> for further
                information. You can <Link href={ExternalUrls.marketing.feedback}>file a ticket here</Link> if the issue
                persists.
            </Typography>
        </ErrorPage>
    );
}
