import type {IApplicationState} from "../../state";

const selectLastPublishedAt = (state: IApplicationState) => state.partVersion?.data?.created_at;
const selectControls = (state: IApplicationState) => state.partVersion?.data?.controls;
const selectPartUid = (state: IApplicationState) => state.partVersion?.data?.part_uid;
const selectPreviewImage = (state: IApplicationState) => state.partVersion?.data?.preview_image;
const selectAssets = (state: IApplicationState) => state.partVersion?.data?.assets;
const selectHasPartVersionData = (state: IApplicationState) => !!state.partVersion?.data;
const selectPartPublishingStatus = (state: IApplicationState) => state.partVersion.publishStatus;

const partVersionDataSelectors = {
    selectControls,
    selectPartUid,
    selectPreviewImage,
    selectAssets,
    selectHasPartVersionData,
    selectLastPublishedAt,
    selectPartPublishingStatus,
};

export default partVersionDataSelectors;
