import {PartPublishingStatus, PartUpdatingStatus} from "@buildwithflux/core";
import {AnyAction} from "redux";
import {StateObservable} from "redux-observable";
import {interval, Observable, of} from "rxjs";
import {bufferWhen, filter, mergeMap, switchMap} from "rxjs/operators";

import {createActionDiscriminator} from "../../epicCreators/helpers";
import R from "../../../resources/Namespace";
import type {IApplicationState} from "../../../state";
import {enqueueNotification} from "../app/actions";
import {writeDocumentToFirebase} from "../parts/publishing/drafts/thunks";
import {setPartPublishingStatus} from "../partVersion/actions";

import {setPartUpdating} from "./actions";

export const publishPartResultNotifier = (
    action: Observable<AnyAction>,
    _documentState: StateObservable<IApplicationState>,
) => {
    return action.pipe(
        filter(createActionDiscriminator(setPartPublishingStatus)),
        switchMap((result) => {
            const {status} = result.payload;
            if (status === PartPublishingStatus.failure) {
                return of(
                    enqueueNotification(R.strings.part_publish_dialog.publish_failed_message, {
                        variant: "error",
                        persist: false,
                        preventDuplicate: true,
                        autoHideDuration: 5000,
                    }),
                );
            }
            return [];
        }),
    );
};

export const updatePartResultNotifier = (
    action: Observable<AnyAction>,
    _documentState: StateObservable<IApplicationState>,
) => {
    const downstreamActionsForSuccess = (successCount: number) => {
        if (successCount === 0) return [];
        const messageObject = successCount > 1 ? `${successCount} parts` : "part";
        const message = `Successfully updated ${messageObject}.`;
        return [
            enqueueNotification(message, {
                variant: "success",
                persist: false,
                preventDuplicate: true,
                autoHideDuration: 5000,
            }),
            writeDocumentToFirebase(),
        ];
    };

    const downstreamActionsForFailures = (failuresCount: number) => {
        if (failuresCount === 0) return [];
        const messageObject = failuresCount > 1 ? `${failuresCount} parts` : "part";
        const message = `Failed to update ${messageObject}.  Please try again in a moment.`;
        return [
            enqueueNotification(message, {
                variant: "error",
                persist: false,
                preventDuplicate: true,
                autoHideDuration: 5000,
            }),
        ];
    };

    const downstreamActionsForDenieds = (deniedsCount: number) => {
        if (deniedsCount === 0) return [];
        const messageObject = deniedsCount > 1 ? `${deniedsCount} parts` : "part";
        const message = `Failed to update ${messageObject}.  Permission denied.`;
        return [
            enqueueNotification(message, {
                variant: "error",
                persist: false,
                preventDuplicate: true,
                autoHideDuration: 5000,
            }),
        ];
    };

    const flattenActions = (actions: (AnyAction | {meta: boolean; payload: AnyAction[]})[]) => {
        const result = [];
        for (const action of actions) {
            if (action.meta) result.push(...action.payload);
            else result.push(action);
        }
        return result;
    };

    return action.pipe(
        filter(createActionDiscriminator(setPartUpdating)),
        bufferWhen(() => interval(3000)),
        mergeMap((bufferedActions) => {
            const actionsToProcess = flattenActions(bufferedActions);
            let failures = 0;
            let successes = 0;
            let denieds = 0;
            for (const action of actionsToProcess) {
                // NOTE: Yikes, the type here is any because of the buffer.
                const {_partUid, updating} = action.payload;
                if (updating === PartUpdatingStatus.failure) failures += 1;
                else if (updating === PartUpdatingStatus.success) successes += 1;
                else if (updating === PartUpdatingStatus.denied) denieds += 1;
            }
            return of(
                ...downstreamActionsForSuccess(successes),
                ...downstreamActionsForFailures(failures),
                ...downstreamActionsForDenieds(denieds),
            );
        }),
    );
};
