import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const setNodeLabel = createActionRecordAction(
    "setNodeLabel",
    (nodeUid: string, label: string, canUndo = true) => {
        return {
            payload: {
                nodeUid,
                label,
                canUndo: canUndo,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // QUESTION: why flags all true for a label change? because of
                // possible interaction with a global rule that selects on the
                // node label?
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "updated PCB node label",
);
