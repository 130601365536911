import {CommentThreadAnchorType, ICommentThreadData} from "@buildwithflux/core";
import {useMemo} from "react";

import {currentAgentIsBot} from "../../../../helpers/isBot";
import type {IApplicationState} from "../../../../state";

// NOTE: must use shallowEqual for memoization
const selectPcb2dCommentThreadsUids = (state: IApplicationState) => {
    return Object.values(state.document?.comment_threads || {})
        .filter((commentThread) => commentThread.anchor.type === CommentThreadAnchorType.pcb2d_position)
        .map((commentThread) => commentThread.uid);
};

// NOTE: must use shallowEqual for memoization
const useCommentThread = (uid: string) => {
    return useMemo(
        () =>
            (state: IApplicationState): ICommentThreadData | undefined => {
                return state.document?.comment_threads[uid];
            },
        [uid],
    );
};

// NOTE: must use shallowEqual for memoization
const selectPinnedSchematicCommentThreadsUids = (state: IApplicationState) => {
    return (
        Object.values(state.document?.comment_threads || {})
            .filter((commentThread) => commentThread.anchor.type === CommentThreadAnchorType.position)
            // show all comment threads to crawlers so they help with SEO
            .filter((commentThread) => commentThread.pinned || currentAgentIsBot)
            .map((commentThread) => commentThread.uid)
    );
};

const useCommentThreadAttribute = <T>(attributeName: keyof ICommentThreadData, uid?: string) => {
    return useMemo(
        () =>
            (state: IApplicationState): T | undefined => {
                // Here it is safe to cast it as any, because we have limited the options for `attributeName`
                return uid ? (state.document?.comment_threads?.[uid]?.[attributeName] as any) : undefined;
            },
        [attributeName, uid],
    );
};

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to  it
const selectCommentThreads = (state: IApplicationState) => state.document?.comment_threads;

const selectCommentThreadsCountPcb = (state: IApplicationState) =>
    Object.entries(state.document?.comment_threads || {}).filter(
        (entry) => entry[1].anchor.type === CommentThreadAnchorType.pcb2d_position && !entry[1].resolved,
    ).length;

const selectCommentThreadsCountSchematic = (state: IApplicationState) =>
    Object.entries(state.document?.comment_threads || {}).filter(
        (entry) => entry[1].anchor.type === CommentThreadAnchorType.position && !entry[1].resolved,
    ).length;

const selectCommentThreadsCount = (state: IApplicationState) =>
    Object.keys(state.document?.comment_threads ?? {}).length;

const commentThreadsSelectors = {
    selectCommentThreads,
    selectCommentThreadsCount,
    selectCommentThreadsCountPcb,
    selectCommentThreadsCountSchematic,
    selectPinnedSchematicCommentThreadsUids,
    selectPcb2dCommentThreadsUids,
    useCommentThreadAttribute,
    useCommentThread,
};

export default commentThreadsSelectors;
