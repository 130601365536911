import {AnyPcbBakedNode} from "@buildwithflux/core";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

/**
 * This action can either entirely remove PCB nodes from their UID, or "toggle" them by using a rule set with an !important rule.
 * If you want to toggle the PCB node, pass them as baked nodes in the `toggleBakedNodes` and the action will create/remove a rule set for it.
 */
export const removeNodes = createActionRecordAction(
    "removeNodes",
    (removeNodeUids: string[], toggleBakedNodes: AnyPcbBakedNode[] = []) => {
        return {
            payload: {
                removeNodeUids,
                toggleBakedNodes,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed PCB layout nodes",
);
