import {RightEditorDrawerTabState} from "@buildwithflux/constants";
import {EditorModes} from "@buildwithflux/core";
import {z} from "zod";
import {create, StoreApi, UseBoundStore} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {LocalStorageKey} from "../../../resources/constants/localStorageKey";

// QUESTION: why use integer enum here? strings are more transparent
export enum LeftEditorDrawerTabState {
    componentLibrary = 0,
    objects = 1,
    rules = 2,
}

export enum ProjectFilter {
    allProjects = "All Projects",
    myProjects = "My Projects",
    myTemplates = "My Templates",
    starredProjects = "Starred Projects",
}

const userCopilotOnboardingState = z.object({
    /**
     * Stores whether the user has ever opened the Copilot shortcuts fab
     * on the schematic editor.
     **/
    openedShortcutsFab: z.boolean(),

    numTimesTooltipShown: z.number().optional(), // Need optional for backwards compatability
    lastTimeTooltipShown: z.number().optional(), // timestamp, ms
});

export type UserCopilotOnboardingState = z.infer<typeof userCopilotOnboardingState>;

const makeInitialCopilotOnboardingState = (): UserCopilotOnboardingState => ({
    openedShortcutsFab: false,
    numTimesTooltipShown: 0,
    lastTimeTooltipShown: undefined,
});

type PersistedDocumentUiState = {
    showPcbGrid: boolean;
    showSchematicGrid: boolean;

    editorMode: EditorModes;

    leftDrawerActiveTab: {
        [editorMode: string]: LeftEditorDrawerTabState;
    };
    rightDrawerActiveTab: RightEditorDrawerTabState;
    rightDrawerChangedSinceSessionStart: boolean;

    showBottomDrawerOpen: boolean;
    leftPaneWidth: number;
    leftPaneCollapsed: boolean;
    rightPaneWidth: number;
    rightPaneCollapsed: boolean;
};

type PersistedDocumentUiApi = {
    setPcbShowGrid: (show: boolean) => void;
    setSchematicShowGrid: (show: boolean) => void;

    setEditorMode: (mode: EditorModes) => void;

    setLeftDrawerActiveTab: (tabState: LeftEditorDrawerTabState, editorMode: EditorModes) => void;
    setRightDrawerActiveTab: (tabState: RightEditorDrawerTabState) => void;

    setBottomDrawerOpen: (show: boolean) => void;
    setLeftPaneWidth: (width: number) => void;
    setLeftPaneCollapsed: (collapsed: boolean) => void;
    setRightPaneWidth: (width: number) => void;
    setRightPaneCollapsed: (collapsed: boolean) => void;
};

export type PersistedDocumentUiStoreState = PersistedDocumentUiState & PersistedDocumentUiApi;
export type UsePersistedDocumentUiStore = UseBoundStore<StoreApi<PersistedDocumentUiStoreState>>;

const migratePersistedDocumentUiStore = (persistedState: unknown, version: unknown): PersistedDocumentUiStoreState => {
    // TODO: this cast is unsafe: to correctly migrate the state, we should keep previous types, assert on those
    //  when the version matches some previous version, and safely upgrade the data to a PersistedDocumentUiStoreState,
    //  not just cast it.
    const existing = persistedState as PersistedDocumentUiStoreState;

    if (version === 0) {
        // default version, should only be present prior to the change where EditorModes becomes
        // a string enum.
        const editorModeMap: {[key: number]: EditorModes} = {
            0: EditorModes.schematic,
            1: EditorModes.code,
            2: EditorModes.pcb,
        };
        const maybeValidEditorMode = EditorModes[existing.editorMode];
        if (maybeValidEditorMode === undefined) {
            const currentState: unknown = existing.editorMode;
            existing.editorMode = editorModeMap[currentState as number]!;
            existing.leftDrawerActiveTab = {
                [EditorModes.code]: LeftEditorDrawerTabState.componentLibrary,
                [EditorModes.pcb]: LeftEditorDrawerTabState.componentLibrary,
                [EditorModes.schematic]: LeftEditorDrawerTabState.componentLibrary,
            };
        }
    }

    return existing;
};

export const createUsePersistedDocumentUiStoreHook = () =>
    create<PersistedDocumentUiStoreState>()(
        persist(
            (set) => ({
                showPcbGrid: true,
                setPcbShowGrid: (show: boolean) => {
                    set({showPcbGrid: show});
                },
                showSchematicGrid: true,
                setSchematicShowGrid: (show: boolean) => {
                    set({showSchematicGrid: show});
                },
                editorMode: EditorModes.schematic,
                setEditorMode: (mode: EditorModes) => {
                    set({editorMode: mode});
                },
                leftDrawerActiveTab: {
                    [EditorModes.code]: LeftEditorDrawerTabState.componentLibrary,
                    [EditorModes.pcb]: LeftEditorDrawerTabState.objects,
                    [EditorModes.schematic]: LeftEditorDrawerTabState.componentLibrary,
                },
                setLeftDrawerActiveTab: (tabState: LeftEditorDrawerTabState, editorMode: EditorModes) => {
                    set((state) => {
                        return {...state, leftDrawerActiveTab: {...state.leftDrawerActiveTab, [editorMode]: tabState}};
                    });
                },
                rightDrawerActiveTab: RightEditorDrawerTabState.inspector,
                rightDrawerChangedSinceSessionStart: false,
                setRightDrawerActiveTab: (tabState: RightEditorDrawerTabState) => {
                    set((state) => {
                        return {
                            ...state,
                            rightDrawerActiveTab: tabState,
                            rightDrawerChangedSinceSessionStart:
                                state.rightDrawerChangedSinceSessionStart || tabState !== state.rightDrawerActiveTab,
                        };
                    });
                },
                showBottomDrawerOpen: false,
                setBottomDrawerOpen: (show: boolean) => {
                    set({showBottomDrawerOpen: show});
                },
                leftPaneWidth: 276,
                setLeftPaneWidth: (width: number) => {
                    set((state) => {
                        return {...state, leftPaneWidth: width};
                    });
                },
                leftPaneCollapsed: false,
                setLeftPaneCollapsed: (collapsed: boolean) => {
                    set((state) => {
                        return {...state, leftPaneCollapsed: collapsed};
                    });
                },
                rightPaneWidth: 276,
                setRightPaneWidth: (width: number) => {
                    set((state) => {
                        return {...state, rightPaneWidth: width};
                    });
                },
                rightPaneCollapsed: false,
                setRightPaneCollapsed: (collapsed: boolean) => {
                    set((state) => {
                        return {...state, rightPaneCollapsed: collapsed};
                    });
                },
            }),
            {
                name: "usePersistedEditorUiStore",
                storage: createJSONStorage(() => window.sessionStorage),
                version: 1,
                migrate: migratePersistedDocumentUiStore,
            },
        ),
    );

interface UserPreferences {
    templateFilter: ProjectFilter;
    setTemplateFilter: (to: ProjectFilter) => void;

    // Copilot onboarding
    copilotOnboarding: UserCopilotOnboardingState;
    setCopilotFabOpened: () => void;
    trackCopilotFabTooltipViewed: () => void;
    resetCopilotOnboarding: () => void;
}

/**
 * This hook provides client-side storage of user preferences across browser
 * sessions (but *not* across browsers / devices).
 *
 * @remarks
 *
 * DO NOT expose private user data in this store. This should be treated as effectively public data,
 * since it lives in localStorage attached to the browser+domain, *not* the user (e.g. two users logging
 * into the same browser).
 */
export const createUseLocalUserPublicPreferences = (userUid: string | undefined) =>
    create(
        persist<UserPreferences>(
            (set) => ({
                templateFilter: ProjectFilter.allProjects,
                setTemplateFilter: (filter) => set({templateFilter: filter}),

                // Copilot onboarding
                copilotOnboarding: makeInitialCopilotOnboardingState(),
                setCopilotFabOpened: () =>
                    set((state) => ({
                        ...state,
                        copilotOnboarding: {...state.copilotOnboarding, openedShortcutsFab: true},
                    })),
                trackCopilotFabTooltipViewed: () =>
                    set((state) => {
                        const now = new Date().getTime(); // ms
                        if (
                            !state.copilotOnboarding.lastTimeTooltipShown ||
                            // Include global throttling here using the stored timestamp
                            //
                            // Why? Because the tooltip will re-render up to 7x in rapid succession due to remounts
                            // of its ancestors.
                            //
                            // NOTE: using lodash throttle does not work here (or at the caller), due to technical limitations:
                            // (the function is re-created each time this store changes / caller is re-mounted, effectively
                            // making throttling useless)
                            state.copilotOnboarding.lastTimeTooltipShown < now - 1000
                        ) {
                            const copilotOnboarding = {
                                ...state.copilotOnboarding,
                                numTimesTooltipShown: (state.copilotOnboarding.numTimesTooltipShown ?? 0) + 1,
                                lastTimeTooltipShown: now,
                            };
                            return {
                                ...state,
                                copilotOnboarding,
                            };
                        }
                        return state;
                    }),
                resetCopilotOnboarding: () =>
                    set((state) => ({...state, copilotOnboarding: makeInitialCopilotOnboardingState()})),
            }),
            {
                name: userUid ? `${LocalStorageKey.userPrefs}-${userUid}` : LocalStorageKey.userPrefs, // allows for a default store (for unknown user)
                storage: createJSONStorage(() => localStorage), // this is the default
            },
        ),
    );
