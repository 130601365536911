import {
    IUserData,
    Organization,
    OrganizationRepository,
    OrganizationRole,
    OrganizationUid,
    UserUid,
} from "@buildwithflux/core";
import {Logger} from "@buildwithflux/shared";
import {get, set} from "lodash";
import debounce from "lodash/debounce";

import R from "../../../resources/Namespace";
import {CurrentUserService} from "../../auth";
import {UserStorage} from "../UserStorage";

/**
 * Used by reference to detect a non-empty queue (avoids an Object.ke)
 */
const EMPTY = {};

type UserProfileData = Pick<
    IUserData,
    "full_name" | "github_handle" | "twitter_handle" | "email" | "website_url" | "tag_line"
>;

type OrgProfileData = Pick<
    Organization,
    "displayName" | "githubHandle" | "twitterHandle" | "email" | "websiteUrl" | "bioContent"
>;

/**
 * Updates profile information using a debounced queue
 *
 * You can call enqueueUpdate() very frequently, with partial updates to user profile information, and
 * this call will take care of making sensible, less frequent updates to the UserStorage
 */
abstract class DebouncedProfileUpdater<
    ProfileData = UserProfileData | OrgProfileData,
    ProfileUid = UserUid | OrganizationUid,
> {
    private queuedUpdates: Partial<ProfileData> = EMPTY;

    private debounce = debounce(
        () => {
            void this.debouncedFlush().catch((err) => this.logger.error("Error flushing profile changes", err));
        },
        R.behaviors.storage.writeDelay,
        {leading: false, maxWait: R.behaviors.storage.writeMaxWait},
    );

    protected constructor(
        protected readonly profileUid: ProfileUid,
        protected readonly currentUserService: CurrentUserService,
        protected readonly logger: Logger,
    ) {}

    /**
     * Enqueues an update to the profile data
     */
    public enqueueUpdate(update: Partial<ProfileData>) {
        this.queuedUpdates = {
            ...this.queuedUpdates,
            ...update,
        };
        this.debounce();
    }

    /**
     * Flushes any waiting updates
     *
     * Cancels any further debounced invocations that might be waiting, then triggers
     * the debounced flush that will write changes to the userStorage
     */
    public async flush() {
        this.debounce.cancel(); // cancels any waiting debounced invocations (there'd be nothing to flush)
        await this.debouncedFlush();
    }

    private async debouncedFlush() {
        const queuedUpdates = this.queuedUpdates;

        if (queuedUpdates !== EMPTY) {
            const update = this.performUpdate(queuedUpdates);
            this.queuedUpdates = EMPTY;
            await update;
        }
    }

    protected getCurrentUser(): IUserData | undefined {
        const currentUser = this.currentUserService.getCurrentUser();

        if (!currentUser || currentUser.isAnonymous) {
            return undefined;
        }

        return currentUser;
    }

    protected abstract performUpdate(queuedUpdates: Partial<ProfileData>): Promise<void>;
}

export class UserProfileUpdater extends DebouncedProfileUpdater<UserProfileData, UserUid> {
    constructor(
        userUid: UserUid,
        private readonly userStorage: UserStorage,
        currentUserService: CurrentUserService,
        logger: Logger,
    ) {
        super(userUid, currentUserService, logger);
    }

    protected override async performUpdate(queuedUpdates: Partial<UserProfileData>): Promise<void> {
        if (!this.canCurrentUserEditProfile()) {
            throw new Error("Current user cannot edit profile");
        }

        return await this.userStorage.updateUser(this.profileUid, queuedUpdates);
    }

    public canCurrentUserEditProfile(): boolean {
        const currentUser = this.getCurrentUser();

        if (!currentUser) {
            return false;
        }

        return currentUser.uid === this.profileUid;
    }
}

export class OrganizationProfileUpdater extends DebouncedProfileUpdater<OrgProfileData, OrganizationUid> {
    private roleCache: Record<UserUid, Record<OrganizationUid, OrganizationRole | null>> = {};

    constructor(
        organizationUid: OrganizationUid,
        private readonly organizationRepository: OrganizationRepository,
        currentUserService: CurrentUserService,
        logger: Logger,
    ) {
        super(organizationUid, currentUserService, logger);
    }

    protected async performUpdate(queuedUpdates: Partial<OrgProfileData>): Promise<void> {
        const canEdit = await this.canCurrentUserEditOrganizationProfile();

        if (!canEdit) {
            throw new Error("Cannot edit organization profile");
        }

        return await this.organizationRepository.update(this.profileUid, queuedUpdates);
    }

    protected async getCurrentUserRole(): Promise<OrganizationRole | undefined> {
        const currentUser = this.getCurrentUser();

        if (!currentUser) {
            return undefined;
        }

        const cachedRole = get(this.roleCache, [currentUser.uid, this.profileUid], undefined);

        if (typeof cachedRole !== "undefined") {
            return cachedRole ?? undefined;
        }

        const role = await this.organizationRepository.getUserRole(this.profileUid, currentUser.uid);
        set(this.roleCache, [currentUser.uid, this.profileUid], role ?? null);
        return role;
    }

    public async canCurrentUserEditOrganizationProfile(): Promise<boolean> {
        const currentUser = this.getCurrentUser();

        if (!currentUser) {
            return false;
        }

        return (await this.getCurrentUserRole()) === "owner";
    }
}
