import {DataModelSchemaVersion} from "@buildwithflux/constants";
import {ClientFirestoreAdapter} from "@buildwithflux/firestore-compatibility-layer";
import {ClientIdProvider} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";
import type firebase from "firebase/compat";

import AlgoliaConnector from "./connectors/AlgoliaConnector";

/**
 * @deprecated Do not make further implementations - use repositories (which can also be used by the backend)
 */
export abstract class BaseStorage {
    private static _schemaVersion = DataModelSchemaVersion;

    protected storageId: string;

    constructor(
        protected readonly firestore: firebase.firestore.Firestore,
        protected readonly firestoreAdapter: ClientFirestoreAdapter,
        protected readonly logger: Logger,
        clientIdProvider: ClientIdProvider,
    ) {
        if (AlgoliaConnector.getAppConfig().partsSearchPath.length === 0) {
            this.logger.error("Algolia search path is missing, check if the environment variable set");
        }

        /**
         * A unique client id that can be used to determine that it was responsible for writing some
         * data to the backend.  For example, when the storageId matches that of an action records
         * metadata, we know that we can ignore it.
         *
         * {@see WithWriteMetaData}
         * {@see FirestoreActionRecordRepository}
         */
        this.storageId = clientIdProvider.clientId;
    }

    static get schemaVersion(): number {
        return this._schemaVersion;
    }
}
