import {
    createShapeFromDxfString,
    createShapeFromSvgString,
    createSvgShape,
    IPcbBoardLayerBase,
    isDxfFileName,
    isSvgFileName,
    PcbBakedNode,
    PcbLayoutFootprintPadShape,
    PcbNodeTypes,
} from "@buildwithflux/core";
import {staticLayoutLayerUids} from "@buildwithflux/models";
import {Matrix3, Shape as ThreeShape} from "three";

import {isConvexFromPoints} from "../math/isConvex";
import {Shape, ShapeRole, ShapeType} from "../types";
import {matrix3MakeScale, toThreeMatrix3} from "../utils";

/** NOTE: For zones we don't spawn a shape in each layer, but we spawn a single on as metadata instead.
 * It will be consumer responsability to associate them to layers using the connected layers property. */
export function createShapesForZone(node: PcbBakedNode<PcbNodeTypes.zone>, stackup: IPcbBoardLayerBase[]): Shape[] {
    const metaLayer = stackup.find((l) => l.uid === staticLayoutLayerUids.metaLayerUid);
    if (!metaLayer) return [];

    const scale = node.bakedRules.scale;
    const transform = matrix3MakeScale(new Matrix3(), scale ? scale.x : 1, scale ? scale.y : 1).multiply(
        toThreeMatrix3(node.bakedRules.transformRelativeToShapesLayer),
    );
    const transformInverse = transform.clone().invert();

    // If valid asset data is contained in bakedRules then attempt shape creation from that data - asset data takes
    // precedence in all cases
    if (node.bakedRules.asset?.payloadFileName && node.bakedRules.asset?.payloadAsBase64) {
        let threeShape: ThreeShape | undefined = undefined;
        if (isSvgFileName(node.bakedRules.asset.payloadFileName)) {
            threeShape = createShapeFromSvgString(atob(node.bakedRules.asset.payloadAsBase64));
        } else if (isDxfFileName(node.bakedRules.asset.payloadFileName)) {
            threeShape = createShapeFromDxfString(atob(node.bakedRules.asset.payloadAsBase64), {
                center: false,
            });
        }
        if (!threeShape) return [];

        const points = threeShape.getPoints();
        return [
            {
                shapeRole: ShapeRole.zone,
                shapeType: ShapeType.ApproxPolygon,
                points,
                layerId: metaLayer.uid,
                transform,
                transformInverse,
                convex: isConvexFromPoints(points),
            },
        ];
    }

    if (node.bakedRules.zoneShape === PcbLayoutFootprintPadShape.circular) {
        const isSquare = node.bakedRules.size.x === node.bakedRules.size.y;
        if (isSquare) {
            return [
                {
                    shapeRole: ShapeRole.zone,
                    shapeType: ShapeType.Circle,
                    radius: node.bakedRules.size.x / 2,
                    layerId: metaLayer.uid,
                    transform,
                    transformInverse,
                },
            ];
        } else {
            return [
                {
                    shapeRole: ShapeRole.zone,
                    shapeType: ShapeType.Oblong,
                    width: node.bakedRules.size.x,
                    height: node.bakedRules.size.y,
                    layerId: metaLayer.uid,
                    transform,
                    transformInverse,
                },
            ];
        }
    }

    if (node.bakedRules.zoneShape === PcbLayoutFootprintPadShape.rectangle) {
        const hasCornerRadius =
            node.bakedRules.cornerRadius &&
            (node.bakedRules.cornerRadius.topLeft > 0 ||
                node.bakedRules.cornerRadius.bottomLeft > 0 ||
                node.bakedRules.cornerRadius.topRight > 0 ||
                node.bakedRules.cornerRadius.bottomRight > 0);
        if (hasCornerRadius && node.bakedRules.cornerRadius) {
            return [
                {
                    shapeRole: ShapeRole.zone,
                    shapeType: ShapeType.RoundedRectangle,
                    width: node.bakedRules.size.x,
                    height: node.bakedRules.size.y,
                    radiusTopLeft: node.bakedRules.cornerRadius.topLeft,
                    radiusBottomLeft: node.bakedRules.cornerRadius.bottomLeft,
                    radiusTopRight: node.bakedRules.cornerRadius.topRight,
                    radiusBottomRight: node.bakedRules.cornerRadius.bottomRight,
                    layerId: metaLayer.uid,
                    transform,
                    transformInverse,
                },
            ];
        } else {
            return [
                {
                    shapeRole: ShapeRole.zone,
                    shapeType: ShapeType.Rectangle,
                    width: node.bakedRules.size.x,
                    height: node.bakedRules.size.y,
                    layerId: metaLayer.uid,
                    transform,
                    transformInverse,
                },
            ];
        }
    }

    const customPadShapeThree = createSvgShape(node.bakedRules.zoneShape);
    if (!customPadShapeThree) return [];

    const points = customPadShapeThree.getPoints();
    return [
        {
            shapeRole: ShapeRole.zone,
            shapeType: ShapeType.ApproxPolygon,
            points,
            layerId: metaLayer.uid,
            transform,
            transformInverse,
            convex: isConvexFromPoints(points),
        },
    ];
}
