import {useEffect} from "react";

import {useFluxServices} from "../../../injection/hooks";
import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";

export function useFluxLogger() {
    const {currentUserService} = useFluxServices();

    useEffect(() => {
        const currentUser = currentUserService.getCurrentUser();
        if (currentUser) {
            FluxLogger.setUser(currentUser.uid, currentUser.handle);
        }

        return currentUserService.subscribeToUserChanges((currentUser) => {
            const user = currentUser.user;
            if (user) {
                FluxLogger.setUser(user.uid, user.handle);
            }
        });
    }, []);
}
