import {
    AnyPcbBakedNode,
    getConnectedCopperLayers,
    IPcbBoardLayerBase,
    PcbBakedNode,
    PcbNodesMap,
    PcbNodeTypes,
} from "@buildwithflux/core";
import {Matrix3} from "three";

import {CircleShape, DifferenceShape, OblongShape, Shape, ShapeRole, ShapeType} from "../types";
import {matrix3MakeScale, matrix3MakeTranslation, toThreeMatrix3} from "../utils";

// TODO: This implementation is currently missing the layout hole and solder mask (and expansion)
// This means that it will work fine for copper DRC but not for rendering or other stuff

export function createShapesForVia(
    node: PcbBakedNode<PcbNodeTypes.via>,
    allNodes: PcbNodesMap<AnyPcbBakedNode>,
    stackup: IPcbBoardLayerBase[],
): Shape[] {
    const outerCopperShape = getCopperOuterShape(node);
    const baseHoleShape = getHoleShape(node);

    const copperLayers = getConnectedCopperLayers(node, allNodes, stackup);

    const transform = toThreeMatrix3(node.bakedRules.transformRelativeToShapesLayer);
    const transformInverse = transform.clone().invert();

    const copperShapes: DifferenceShape[] = copperLayers.map((layer) => ({
        shapeRole: ShapeRole.copper,
        shapeType: ShapeType.Difference,
        shapeA: {...outerCopperShape, layerId: layer.uid},
        shapeB: {...baseHoleShape, shapeRole: ShapeRole.copper, layerId: layer.uid},
        layerId: layer.uid,
        transform,
        transformInverse,
    }));

    // Removed since it's inaccurate with microvias
    // const holeShape = {
    //     ...baseHoleShape,
    //     transform: transform.clone().multiply(baseHoleShape.transform),
    //     transformInverse: baseHoleShape.transformInverse.clone().multiply(transformInverse),
    //     layerId: null,
    //     shapeRole: ShapeRole.layoutHole,
    // };

    return [
        ...copperShapes,
        //holeShape
    ];
}

function getCopperOuterShape(node: PcbBakedNode<PcbNodeTypes.via>): CircleShape | OblongShape {
    // Will be added by the caller as we need to replicate it for every layer
    const layerId = null;

    // Will be multiplied by the caller, sets only scale
    const scale = node.bakedRules.scale;
    const transform = matrix3MakeScale(new Matrix3(), scale ? scale.x : 1, scale ? scale.y : 1);
    const transformInverse = transform.clone().invert();

    const isSquare = node.bakedRules.size.x === node.bakedRules.size.y;
    if (isSquare) {
        return {
            shapeRole: ShapeRole.copper,
            shapeType: ShapeType.Circle,
            radius: node.bakedRules.size.x / 2,
            layerId,
            transform,
            transformInverse,
        };
    } else {
        return {
            shapeRole: ShapeRole.copper,
            shapeType: ShapeType.Oblong,
            width: node.bakedRules.size.x,
            height: node.bakedRules.size.y,
            layerId,
            transform,
            transformInverse,
        };
    }
}

function getHoleShape(
    node: PcbBakedNode<PcbNodeTypes.via>,
): Omit<CircleShape, "layerId" | "shapeRole"> | Omit<OblongShape, "layerId" | "shapeRole"> {
    const transform = matrix3MakeTranslation(
        new Matrix3(),
        node.bakedRules.holePosition?.x ?? 0,
        node.bakedRules.holePosition?.y ?? 0,
    );
    const transformInverse = transform.clone().invert();

    const isSquare = node.bakedRules.holeSize.x === node.bakedRules.holeSize.y;
    if (isSquare) {
        return {
            shapeType: ShapeType.Circle,
            radius: node.bakedRules.holeSize.x / 2,
            transform,
            transformInverse,
        };
    } else {
        return {
            shapeType: ShapeType.Oblong,
            width: node.bakedRules.holeSize.x,
            height: node.bakedRules.holeSize.y,
            transform,
            transformInverse,
        };
    }
}
