import {AccountUid, CreditReport, getPaidCreditsAtSpendingLimit, SpendingLimit} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {areWeInStorybook, isDevEnv, Logger, silentLogger, wait} from "@buildwithflux/shared";
import type {FirebaseError} from "@firebase/util";
import {produce} from "immer";
import {create, StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";

import {useFluxServices} from "../../../injection/hooks";
import {CurrentUserService} from "../../auth";

import {useExistingPaymentInformation} from "./common";

const creditReportCacheTtl = 60_000; // Credit reports are cached for 60s
const creditReportRefreshPoll = 30_000; // But refreshed every 30s when displayed

export type SetSpendingLimitResult = {
    success: boolean;
    isLatestPendingSet: boolean;
    spendingLimit: SpendingLimit | undefined;
};

export type SpendingLimitSetter = (attemptedSpendingLimitToSet: SpendingLimit) => Promise<SetSpendingLimitResult>;

interface CreditReportStoreApi {
    getCreditReport(accountUid: AccountUid, once: boolean): CreditReport | undefined;
    getSpendingLimitSetter(accountUid: AccountUid): SpendingLimitSetter;
}

type CreditReportCacheEntry = {
    fetch?: Promise<CreditReport>;
    fetchError?: Error;
    creditReport?: CreditReport;
    expiresAt?: number;
    needsRefresh?: boolean;
    refreshTimeoutHandle?: ReturnType<typeof globalThis.setTimeout>;
    limitSetter?: SpendingLimitSetter;
    limitSetterPromise?: Promise<SpendingLimit>;
};

type CreditReportStoreState = CreditReportStoreApi & {
    creditReportCache: {
        [key: AccountUid]: CreditReportCacheEntry;
    };
};

function needsRefetch(cacheEntry: CreditReportCacheEntry | undefined): boolean {
    // Fetch is already in progress
    if (cacheEntry?.fetch) {
        return false;
    }

    return !cacheEntry || isExpired(cacheEntry) || !!cacheEntry?.needsRefresh;
}

function isExpired(cacheEntry: CreditReportCacheEntry): boolean {
    return !cacheEntry || !cacheEntry.expiresAt || cacheEntry.expiresAt < Date.now();
}

export type UseCreditReportStore = UseBoundStore<StoreApi<CreditReportStoreState>>;

export const createCreditReportStoreHook = (
    functionsAdapter: FunctionsAdapter,
    currentUserService: CurrentUserService,
    errorLogger: Logger,
    debugLogger: Logger = silentLogger,
): UseCreditReportStore => {
    return create<CreditReportStoreState>()(
        devtools(
            (set, get) => {
                async function handleFetch(
                    accountUid: AccountUid,
                    fetch: () => Promise<CreditReport>,
                    attempt = 0,
                ): Promise<CreditReport> {
                    try {
                        const creditReport = await fetch();

                        if (!creditReport) {
                            throw new Error(`Failed to fetch credit report for ${accountUid}`);
                        }

                        return creditReport;
                    } catch (error) {
                        if ((error as FirebaseError)?.code === "functions/internal" && attempt < 5) {
                            // This is some sort of CORS error, perhaps due to a lack of Access-Control-Allow-Credentials in the CORS response
                            // But as this is an onCall, Firebase is supposed to be handling the CORS response for us, and we don't really have control
                            // over it. TODO: upgrading to firebase-functions v2 in backend may help, or upgrading Firebase in general

                            // All we can do is retry, which actually usually succeeds after some delay
                            errorLogger.warn("Retrying failed credit report fetch, due to internal error", error);
                            await wait(2_000 * attempt); // 0s to 8s delay
                            return await handleFetch(accountUid, fetch, attempt + 1);
                        }

                        if ((error as FirebaseError)?.code === "functions/unauthenticated") {
                            errorLogger.warn("Failed to fetch credit report because unauthenticated");
                            throw error;
                        }

                        errorLogger.error("Failed to fetch credit report", {accountUid, error});
                        throw error;
                    }
                }

                async function fetchCreditReport(accountUid: AccountUid): Promise<void> {
                    debugLogger.debug("Started fetch of credit report", {accountUid});
                    const fetch = handleFetch(accountUid, () =>
                        functionsAdapter.getCreditReport({
                            accountUid,
                        }),
                    );

                    set(
                        produce<CreditReportStoreState>((draft) => {
                            (draft.creditReportCache[accountUid] = draft.creditReportCache[accountUid] ?? {}).fetch =
                                fetch;
                        }),
                        false,
                        "fetchCreditReportBeforeFetch",
                    );

                    try {
                        const creditReport = await fetch;
                        debugLogger.debug("Updated credit report", {accountUid, creditReport});

                        set(
                            produce<CreditReportStoreState>((draft) => {
                                const cache = (draft.creditReportCache[accountUid] =
                                    draft.creditReportCache[accountUid] ?? {});
                                cache.fetch = undefined;
                                cache.needsRefresh = false;
                                cache.creditReport = creditReport;
                                cache.expiresAt = Date.now() + creditReportCacheTtl;
                            }),
                            false,
                            "fetchCreditReportAfterFetch",
                        );
                    } catch (error) {
                        // Mark fetch promise no longer in progress, record error, but don't throw further
                        set(
                            produce<CreditReportStoreState>((draft) => {
                                const cache = (draft.creditReportCache[accountUid] =
                                    draft.creditReportCache[accountUid] ?? {});
                                cache.fetch = undefined;
                                cache.fetchError = error as Error;
                                cache.needsRefresh = false;
                                cache.creditReport = undefined;
                                cache.expiresAt = Date.now() + creditReportCacheTtl;
                            }),
                            false,
                            "fetchCreditReportAfterError",
                        );
                    }
                }

                async function setCreditLimit(
                    accountUid: AccountUid,
                    attemptedSpendingLimitToSet: SpendingLimit,
                ): Promise<SetSpendingLimitResult> {
                    let limitSetterPromise: Promise<SpendingLimit> | undefined = undefined;

                    try {
                        limitSetterPromise = functionsAdapter.setCreditSpendingLimit({
                            accountUid,
                            spendingLimit: attemptedSpendingLimitToSet,
                        });

                        set(
                            produce<CreditReportStoreState>((draft) => {
                                (draft.creditReportCache[accountUid] =
                                    draft.creditReportCache[accountUid] ?? {}).limitSetterPromise = limitSetterPromise;
                            }),
                            false,
                            "setLimitSetterPromise",
                        );

                        const newEffectiveSpendingLimit: SpendingLimit = await limitSetterPromise;

                        /*
                         * Now, we must be careful: a second limit setter may have been queued while the first was in progress,
                         * and it should "win", even if we awaited longer
                         */
                        const isLatestPendingSet =
                            limitSetterPromise === get().creditReportCache[accountUid]?.limitSetterPromise;

                        if (isLatestPendingSet) {
                            set(
                                produce<CreditReportStoreState>((draft) => {
                                    const cache = (draft.creditReportCache[accountUid] =
                                        draft.creditReportCache[accountUid] ?? {});

                                    delete cache.limitSetterPromise;

                                    if (cache.creditReport) {
                                        /*
                                         * This performs a parallel optimistic update to what happens on the backend - if the user
                                         * refreshes at this point (or the cache entry expires) they should see the same values
                                         */
                                        cache.creditReport.spendingLimit = attemptedSpendingLimitToSet;
                                        cache.creditReport.effectiveSpendingLimit = newEffectiveSpendingLimit;
                                        cache.creditReport.paidCreditsAtSpendingLimit = getPaidCreditsAtSpendingLimit(
                                            newEffectiveSpendingLimit,
                                            cache.creditReport.paidCreditPriceCents,
                                        );
                                        cache.creditReport.hasRemainingPaidCredits =
                                            cache.creditReport.paidCreditsAtSpendingLimit >
                                            cache.creditReport.paidCreditsUsed;
                                    }

                                    /*
                                     * But we also request an update from the backend
                                     */
                                    cache.needsRefresh = true;
                                }),
                                false,
                                "setSpendingLimitFinished",
                            );
                        }

                        return {
                            success: true,
                            isLatestPendingSet,
                            spendingLimit: attemptedSpendingLimitToSet,
                        };
                    } catch (error) {
                        const existingSpendingLimit = get().creditReportCache[accountUid]?.creditReport?.spendingLimit;

                        errorLogger.error("Failed to set spending limit", {
                            accountUid,
                            attemptedSpendingLimitToSet,
                            existingSpendingLimit,
                            error,
                        });

                        return {
                            success: false,
                            isLatestPendingSet:
                                limitSetterPromise === get().creditReportCache[accountUid]?.limitSetterPromise,
                            spendingLimit: existingSpendingLimit,
                        };
                    }
                }

                function setUpNextPoll(accountUid: AccountUid): void {
                    if (get().creditReportCache[accountUid]?.refreshTimeoutHandle) {
                        // There's already a waiting refresh
                        return;
                    }

                    debugLogger.debug("Setting up refresh timeout handle", {accountUid});

                    /*
                     * Proactively cause rerender when the cache entry is about to expire (effectively polling)
                     *
                     * If this credit report is no longer being displayed, this won't actually cause a refetch (the
                     * cache entry will expire and be refetched when next retrieved), and if the credit report is being
                     * displayed already, we won't rerender until the new cache entry arrives
                     */
                    set(
                        produce<CreditReportStoreState>((draft) => {
                            (draft.creditReportCache[accountUid] =
                                draft.creditReportCache[accountUid] ?? {}).refreshTimeoutHandle = setTimeout(() => {
                                debugLogger.debug(`Refreshing ${accountUid} after timeout hit`);

                                set(
                                    produce<CreditReportStoreState>((draft) => {
                                        const cache = (draft.creditReportCache[accountUid] =
                                            draft.creditReportCache[accountUid] ?? {});
                                        cache.needsRefresh = true;
                                        delete cache.refreshTimeoutHandle;
                                    }),
                                    false,
                                    "setNeedsRefreshOnTimeout",
                                );
                            }, creditReportRefreshPoll);
                        }),
                        false,
                        "setNeedsRefreshOnTimeoutHandle",
                    );
                }

                return {
                    getCreditReport(accountUid: AccountUid, once: boolean): CreditReport | undefined {
                        const currentUser = currentUserService.getCurrentUser();

                        if (!currentUser || currentUser.isAnonymous) {
                            return undefined;
                        }

                        const entry = get().creditReportCache[accountUid];
                        const creditReport = entry && !isExpired(entry) ? entry?.creditReport : undefined;

                        if (needsRefetch(entry)) {
                            void fetchCreditReport(accountUid);
                        }

                        if (!once) {
                            setUpNextPoll(accountUid);
                        }

                        return creditReport;
                    },

                    // Caches the limit setter so the same one is returned each time, for each account
                    getSpendingLimitSetter(accountUid: AccountUid): SpendingLimitSetter {
                        const currentUser = currentUserService.getCurrentUser();

                        if (!currentUser || currentUser.isAnonymous) {
                            return () =>
                                Promise.resolve({success: false, isLatestPendingSet: false, spendingLimit: undefined});
                        }

                        const cached = get().creditReportCache[accountUid]?.limitSetter;

                        const limitSetter: SpendingLimitSetter =
                            cached ??
                            (async (attemptedSpendingLimitToSet: SpendingLimit) => {
                                return setCreditLimit(accountUid, attemptedSpendingLimitToSet);
                            });

                        if (!cached) {
                            set(
                                produce<CreditReportStoreState>((draft) => {
                                    (draft.creditReportCache[accountUid] =
                                        draft.creditReportCache[accountUid] ?? {}).limitSetter = limitSetter;
                                }),
                                false,
                                "setLimitSetter",
                            );
                        }

                        return limitSetter;
                    },

                    // State - update deeply with immer
                    creditReportCache: {},
                };
            },
            {name: "CreditReportStore", enabled: isDevEnv()},
        ),
    );
};

export function useCreditReportPrefetch(accountUid: AccountUid | undefined): void {
    const {useCreditReportStore} = useFluxServices();

    return useCreditReportStore((state) => {
        if (areWeInStorybook()) {
            return;
        }

        if (!accountUid) {
            return;
        }

        state.getCreditReport(accountUid, true);
    });
}

export function useCreditReport(accountUid: AccountUid | undefined): CreditReport | undefined {
    const {useCreditReportStore} = useFluxServices();

    return useCreditReportStore((state) => {
        if (areWeInStorybook()) {
            return undefined;
        }

        if (!accountUid) {
            return undefined;
        }

        return state.getCreditReport(accountUid, false);
    });
}

export function useSetCreditSpendingLimit(accountUid: AccountUid): SpendingLimitSetter {
    const {useCreditReportStore} = useFluxServices();
    const {hasPermissionToManagePayments, hasPaidActiveSubscription} = useExistingPaymentInformation({accountUid});

    return useCreditReportStore((state) => {
        const noop: SpendingLimitSetter = () =>
            Promise.resolve({
                success: false,
                isLatestPendingSet: false,
                spendingLimit: state.getCreditReport(accountUid, false)?.spendingLimit,
            });

        if (areWeInStorybook()) {
            return noop;
        }

        if (!accountUid) {
            return noop;
        }

        return hasPermissionToManagePayments && hasPaidActiveSubscription
            ? state.getSpendingLimitSetter(accountUid)
            : noop;
    });
}

export function useIsSetCreditSpendingLimitInProgress(accountUid: AccountUid): boolean {
    const {useCreditReportStore} = useFluxServices();

    return useCreditReportStore((state) => {
        if (areWeInStorybook()) {
            return false;
        }

        if (!accountUid) {
            return false;
        }

        return !!state.creditReportCache[accountUid]?.limitSetterPromise;
    });
}
