import {createAction} from "@reduxjs/toolkit";

import {DialogType, IDialogProps} from "./index";

export const openDialog = createAction("openDialog", <T extends DialogType>(type: T, props?: IDialogProps<T>) => {
    return {
        payload: {
            type,
            props,
        },
    };
});

export const modifyDialog = createAction("modifyDialog", <T extends DialogType>(type: T, props?: IDialogProps<T>) => {
    return {
        payload: {
            type,
            props,
        },
    };
});

export const closeDialog = createAction("closeDialog", (type: DialogType) => {
    return {
        payload: {
            type,
        },
    };
});

export const closeAllDialogs = createAction("closeAllDialogs", () => {
    return {
        payload: {},
    };
});
