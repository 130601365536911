import {EditorModes, ElementHelper, getFootprints, getTopLevelLayouts, isSystemPart} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

export class MissingFootprintsValidator implements IDrcValidator {
    problemTypeKey = "missing_footprints";
    problemLabel = "Missing Footprints";
    problemDescription = "Reports parts that have no footprint.";

    checkForProblems({documentUid, elements}: IDrcInputs) {
        const needsFootprint = Object.entries(elements || {}).filter(([_key, element]) => {
            // Special case for system parts
            if (isSystemPart(element.part_uid)) return false;

            // Ignore invalid elements
            if (!ElementHelper.isValidLayoutElement(element, documentUid)) return false;

            // Without layout nodes
            const nodes = element.part_version_data_cache.pcbLayoutNodes;
            if (!nodes) return true;

            // General case
            const footprints = getFootprints(nodes);
            const layouts = getTopLevelLayouts(nodes);
            return footprints.length === 0 && layouts.length === 0;
        });

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: needsFootprint.map((el) => ({
                problemTypeKey: this.problemTypeKey,
                key: `${this.problemTypeKey}_${el[0]}`,
                affectedItems: [{type: "element" as const, uid: el[0]}],
                affectedViews: [EditorModes.pcb, EditorModes.schematic],
            })),
        };
    }
}
