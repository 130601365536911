import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const startReceivingLatestDraftsForPartUid = createActionRecordAction(
    "startReceivingLatestDraftsForPartUid",
    (partUid: string) => {
        return {
            payload: {
                partUid: partUid,
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: false,
            },
        };
    },
    "start receiving latest drafts for a part",
);

export const stopReceivingLatestDraftsForPartUid = createActionRecordAction(
    "stopReceivingLatestDraftsForPartUid",
    (partUid: string) => {
        return {
            payload: {
                partUid: partUid,
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: false,
            },
        };
    },
    "stop receiving latest drafts for a part",
);
