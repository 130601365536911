import {ClipperShapeFactory} from "@buildwithflux/core";
import {Vector2} from "three";

import {CircleShape, RectangleShape, Shape, ShapeType} from "../types";
import {
    chainCompoundShapeTransforms,
    DecomposedMatrix3,
    matrix3AffineSanityCheck,
    matrix3Decompose,
    polygonDistance,
    polygonPointDistance,
} from "../utils";

import {approxFittingPolygon} from "./approxFittingPolygon";
import {isConvex} from "./isConvex";
import {pointDistance} from "./pointDistance";

/**
 * @remarks Do not rely on this function to compute accurate distance between shapes if one of the shapes
 * is a "difference shape". See notes in implementation.
 *
 * @privateRemarks As of writing, this function is only used for the measurement helper.
 */
export function shortestSegmentBetweenShapes(
    shapeA: Shape,
    shapeB: Shape,
    segments: number,
    clipperShapeFactory: ClipperShapeFactory,
): ReturnType<typeof polygonDistance> | null {
    //
    // Before actually using the brute force polygon-polygon distance we test for special easier cases, which will be common

    const transformDecomposedA = matrix3Decompose(shapeA.transform);
    const transformDecomposedB = matrix3Decompose(shapeB.transform);
    const matrixIsSimpleA =
        matrix3AffineSanityCheck(shapeA.transform) &&
        transformDecomposedA.scaleIsEqual &&
        transformDecomposedA.xyDot === 0;
    const matrixIsSimpleB =
        matrix3AffineSanityCheck(shapeB.transform) &&
        transformDecomposedB.scaleIsEqual &&
        transformDecomposedB.xyDot === 0;

    // Easier case: equal scaling circle to circle
    if (
        shapeA.shapeType === ShapeType.Circle &&
        shapeB.shapeType === ShapeType.Circle &&
        matrixIsSimpleA &&
        matrixIsSimpleB
    ) {
        return specialCaseCircleCircle(shapeA, shapeB, transformDecomposedA, transformDecomposedB, clipperShapeFactory);
    }

    // Easier case: rectangle to circle
    if (shapeA.shapeType === ShapeType.Rectangle && shapeB.shapeType === ShapeType.Circle && matrixIsSimpleB) {
        return specialCaseRectangleCircle(shapeA, shapeB, segments, transformDecomposedB, clipperShapeFactory);
    }
    if (shapeB.shapeType === ShapeType.Rectangle && shapeA.shapeType === ShapeType.Circle && matrixIsSimpleA) {
        return specialCaseRectangleCircle(shapeB, shapeA, segments, transformDecomposedA, clipperShapeFactory);
    }

    // TODO: Other special cases, such as oblongs, lines and rounded rectangles
    // Now more and more shape support the closestPoint parameter in the pointDistance function, so refactor soon

    // 1. Recurses over compound shapes
    if (shapeA.shapeType === ShapeType.Union || shapeA.shapeType === ShapeType.Difference) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeA);
        const resultA = shortestSegmentBetweenShapes(transformedA, shapeB, segments, clipperShapeFactory);
        const resultB = shortestSegmentBetweenShapes(transformedB, shapeB, segments, clipperShapeFactory);
        if (!resultA || !resultB) return null;
        if (shapeA.shapeType === ShapeType.Union) {
            if (resultA?.minDist < resultB?.minDist) return resultA;
            else return resultB;
        } else if (shapeA.shapeType === ShapeType.Difference) {
            // TODO: THIS IS PROBABLY WRONG!
            if (resultA?.minDist > -resultB?.minDist) return resultA;
            else return resultB;
        }
    }
    if (shapeB.shapeType === ShapeType.Union || shapeB.shapeType === ShapeType.Difference) {
        const [transformedA, transformedB] = chainCompoundShapeTransforms(shapeB);
        const resultA = shortestSegmentBetweenShapes(transformedA, shapeA, segments, clipperShapeFactory);
        const resultB = shortestSegmentBetweenShapes(transformedB, shapeA, segments, clipperShapeFactory);
        if (!resultA || !resultB) return null;
        if (shapeB.shapeType === ShapeType.Union) {
            if (resultA?.minDist < resultB?.minDist) return resultA;
            else return resultB;
        } else if (shapeB.shapeType === ShapeType.Difference) {
            // TODO: THIS IS PROBABLY WRONG!
            if (resultA?.minDist > -resultB?.minDist) return resultA;
            else return resultB;
        }
    }

    // 2. Non-convex shapes are much harder to compute (TODO)
    if (!isConvex(shapeA) || !isConvex(shapeB)) {
        // Not supported yet!
        return null;
    }

    // 3. Approximate the two shapes into polygons
    const polygonA = approxFittingPolygon(shapeA, segments, clipperShapeFactory);
    const polygonB = approxFittingPolygon(shapeB, segments, clipperShapeFactory);

    // 4. Compute the min distance between the polygons
    return polygonDistance(polygonA, polygonB);
}

function specialCaseCircleCircle(
    shapeA: CircleShape,
    shapeB: CircleShape,
    transformDecomposedA: DecomposedMatrix3,
    transformDecomposedB: DecomposedMatrix3,
    clipperShapeFactory: ClipperShapeFactory,
) {
    const pointA = new Vector2();
    const pointB = new Vector2();
    const distAB = pointDistance(
        shapeA,
        transformDecomposedB.translationX,
        transformDecomposedB.translationY,
        pointA,
        clipperShapeFactory,
    );
    pointDistance(
        shapeB,
        transformDecomposedA.translationX,
        transformDecomposedA.translationY,
        pointB,
        clipperShapeFactory,
    );
    const minDist = distAB - shapeB.radius * transformDecomposedB.scaleX;
    return {minDist, pointA, pointB, minA: pointA, minB: pointB, maxA: pointA, maxB: pointB};
}

function specialCaseRectangleCircle(
    shapeA: RectangleShape,
    shapeB: CircleShape,
    segments: number,
    transformDecomposedB: DecomposedMatrix3,
    clipperShapeFactory: ClipperShapeFactory,
) {
    const polygonA = approxFittingPolygon(shapeA, segments, clipperShapeFactory);
    const pointA = new Vector2();
    const centerB = new Vector2(transformDecomposedB.translationX, transformDecomposedB.translationY);
    const radiusB = shapeB.radius * transformDecomposedB.scaleX;
    const distFromCircleCenter = polygonPointDistance(centerB, polygonA, pointA);
    const minDist = distFromCircleCenter - radiusB;
    const pointB = pointA.clone().sub(centerB).normalize().multiplyScalar(shapeB.radius).applyMatrix3(shapeB.transform);
    return {minDist, pointA, pointB, minA: pointA, minB: pointB, maxA: pointA, maxB: pointB};
}
