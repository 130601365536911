import type {IApplicationState} from "../../state";

// @todo - this selector needs to be further optimized.
const selectChangeHistoryState = (state: IApplicationState) => state.changeHistory;
const selectChangeHistoryList = (state: IApplicationState) => state.changeHistory.changeHistoryListItems;
const selectCurrentChangeId = (state: IApplicationState) => state.changeHistory.currentChangeId;
const selectRequestedChangeId = (state: IApplicationState) => state.changeHistory.requestedChangeId;
const selectTopChangeId = (state: IApplicationState) =>
    state.changeHistory.changeHistoryCacheMetadata.topActionRecordUid;

const changeHistorySelectors = {
    selectChangeHistoryState,
    selectChangeHistoryList,
    selectCurrentChangeId,
    selectRequestedChangeId,
    selectTopChangeId,
};

export default changeHistorySelectors;
