import {Matrix3, Vector2} from "three";

import {
    ApproxBoundingBox,
    ApproxPolygonShape,
    CircleShape,
    DifferenceShape,
    ExpansionShape,
    LineShape,
    OblongShape,
    RectangleShape,
    RoundedRectangleShape,
    Shape,
    ShapeType,
    UnionShape,
} from "../types";

/** Returns an absolute-coordinates overly-approximated bounding box of that shape useful for building BSPs */
export function approxAABoundingBox(shape: Shape): ApproxBoundingBox {
    switch (shape.shapeType) {
        case ShapeType.Circle:
            return circleApproxBoundingBox(shape);
        case ShapeType.Oblong:
            return oblongApproxBoundingBox(shape);
        case ShapeType.Rectangle:
            return rectangleApproxBoundingBox(shape);
        case ShapeType.RoundedRectangle:
            return roundedRectangleApproxBoundingBox(shape);
        case ShapeType.Line:
            return lineApproxBoundingBox(shape);
        case ShapeType.Union:
            return unionApproxBoundingBox(shape);
        case ShapeType.Difference:
            return differenceApproxBoundingBox(shape);
        case ShapeType.ApproxPolygon:
            return approxPolygonApproxBoundingBox(shape);
        case ShapeType.Expansion:
            return expansionApproxBoundingBox(shape);
    }
}

function circleApproxBoundingBox(shape: CircleShape): ApproxBoundingBox {
    return transformApproxBoundingBox(
        {minX: -shape.radius, minY: -shape.radius, maxX: shape.radius, maxY: shape.radius},
        shape.transform,
    );
}

function oblongApproxBoundingBox(shape: OblongShape): ApproxBoundingBox {
    return transformApproxBoundingBox(
        {minX: -shape.width / 2, minY: -shape.height / 2, maxX: shape.width / 2, maxY: shape.height / 2},
        shape.transform,
    );
}

function rectangleApproxBoundingBox(shape: RectangleShape): ApproxBoundingBox {
    return transformApproxBoundingBox(
        {minX: -shape.width / 2, minY: -shape.height / 2, maxX: shape.width / 2, maxY: shape.height / 2},
        shape.transform,
    );
}

function roundedRectangleApproxBoundingBox(shape: RoundedRectangleShape): ApproxBoundingBox {
    return transformApproxBoundingBox(
        {minX: -shape.width / 2, minY: -shape.height / 2, maxX: shape.width / 2, maxY: shape.height / 2},
        shape.transform,
    );
}

function lineApproxBoundingBox(shape: LineShape): ApproxBoundingBox {
    const minPointX = Math.min(shape.startX, shape.endX);
    const minPointY = Math.min(shape.startY, shape.endY);
    const maxPointX = Math.max(shape.startX, shape.endX);
    const maxPointY = Math.max(shape.startY, shape.endY);
    return transformApproxBoundingBox(
        {
            minX: minPointX - shape.thickness / 2,
            minY: minPointY - shape.thickness / 2,
            maxX: maxPointX + shape.thickness / 2,
            maxY: maxPointY + shape.thickness / 2,
        },
        shape.transform,
    );
}

function unionApproxBoundingBox(shape: Omit<UnionShape, "shapeType">): ApproxBoundingBox {
    const bbA = approxAABoundingBox(shape.shapeA);
    const bbB = approxAABoundingBox(shape.shapeB);
    return transformApproxBoundingBox(mergeApproxBoundingBoxes([bbA, bbB]), shape.transform);
}

function differenceApproxBoundingBox(shape: DifferenceShape): ApproxBoundingBox {
    // TODO:
    // Since we are removing the shapeB, it can't be bigger than shapeA.
    // This is, though, an approximation, as the final result could be smaller.
    return transformApproxBoundingBox(approxAABoundingBox(shape.shapeA), shape.transform);
}

function approxPolygonApproxBoundingBox(shape: ApproxPolygonShape): ApproxBoundingBox {
    if (shape.points.length === 0) {
        // Is this the correct behavior?
        return transformApproxBoundingBox({minX: 0, minY: 0, maxX: 0, maxY: 0}, shape.transform);
    }

    const minPoint = shape.points[0]!.clone();
    const maxPoint = shape.points[0]!.clone();
    shape.points.forEach((p) => {
        minPoint.min(p);
        maxPoint.max(p);
    });
    return transformApproxBoundingBox(
        {minX: minPoint.x, minY: minPoint.y, maxX: maxPoint.x, maxY: maxPoint.y},
        shape.transform,
    );
}

function expansionApproxBoundingBox(shape: ExpansionShape): ApproxBoundingBox {
    const bb = approxAABoundingBox(shape.shape);
    return transformApproxBoundingBox(
        {
            minX: bb.minX - shape.expansion,
            minY: bb.minY - shape.expansion,
            maxX: bb.maxX + shape.expansion,
            maxY: bb.maxY + shape.expansion,
        },
        shape.transform,
    );
}

const tempVector = new Vector2();
function transformApproxBoundingBox(boundingBox: ApproxBoundingBox, transform: Matrix3) {
    tempVector.set(boundingBox.minX, boundingBox.minY).applyMatrix3(transform);
    let minX = tempVector.x;
    let minY = tempVector.y;
    let maxX = tempVector.x;
    let maxY = tempVector.y;

    tempVector.set(boundingBox.minX, boundingBox.maxY).applyMatrix3(transform);
    minX = Math.min(minX, tempVector.x);
    minY = Math.min(minY, tempVector.y);
    maxX = Math.max(maxX, tempVector.x);
    maxY = Math.max(maxY, tempVector.y);

    tempVector.set(boundingBox.maxX, boundingBox.minY).applyMatrix3(transform);
    minX = Math.min(minX, tempVector.x);
    minY = Math.min(minY, tempVector.y);
    maxX = Math.max(maxX, tempVector.x);
    maxY = Math.max(maxY, tempVector.y);

    tempVector.set(boundingBox.maxX, boundingBox.maxY).applyMatrix3(transform);
    minX = Math.min(minX, tempVector.x);
    minY = Math.min(minY, tempVector.y);
    maxX = Math.max(maxX, tempVector.x);
    maxY = Math.max(maxY, tempVector.y);

    return {minX, minY, maxX, maxY};
}

/** WARNING: Please check that the input array has at least one element */
export function mergeApproxBoundingBoxes(boundingBoxes: ApproxBoundingBox[]) {
    // NOTE: This spread could break if there are a lot of bounding boxes
    const minX = Math.min(...boundingBoxes.map((bb) => bb.minX));
    const minY = Math.min(...boundingBoxes.map((bb) => bb.minY));
    const maxX = Math.max(...boundingBoxes.map((bb) => bb.maxX));
    const maxY = Math.max(...boundingBoxes.map((bb) => bb.maxY));

    return {minX, minY, maxX, maxY};
}

// Will return 0 in case of overlap
export function boundingBoxDistance(bcA: ApproxBoundingBox, bcB: ApproxBoundingBox) {
    // https://stackoverflow.com/a/65107290
    const uX = Math.max(0, bcA.minX - bcB.maxX);
    const uY = Math.max(0, bcA.minY - bcB.maxY);
    const u = new Vector2(uX, uY);
    const vX = Math.max(0, bcB.minX - bcA.maxX);
    const vY = Math.max(0, bcB.minY - bcA.maxY);
    const v = new Vector2(vX, vY);
    return Math.sqrt(u.length() ** 2 + v.length() ** 2);
}

export function boundingBoxToPolygon(bb: ApproxBoundingBox) {
    return [
        new Vector2(bb.minX, bb.minY),
        new Vector2(bb.maxX, bb.minY),
        new Vector2(bb.maxX, bb.maxY),
        new Vector2(bb.minX, bb.maxY),
    ];
}
