import {IPcbLayoutNodesRuleSetsMap, PcbRule, hasSomeLayoutRuleConfigInMap} from "@buildwithflux/models";
import {merge, transform} from "lodash";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";

import {addRouteSegment} from "./addRouteSegment";

interface IParams {
    updatedNodesRulesSetsMap: IPcbLayoutNodesRuleSetsMap;
    addRouteSegmentParams: Parameters<typeof addRouteSegment>[];
    removeNodeUids?: string[];
}

export const commitMultiRoutingUpdate = createActionRecordAction(
    "commitMultiRoutingUpdate",
    (params: IParams) => {
        const hasInheritedRules = hasSomeLayoutRuleConfigInMap(
            params.updatedNodesRulesSetsMap,
            "allChildNodesWillInheritThisRule",
        );
        const hasInheritedCalculations = hasSomeLayoutRuleConfigInMap(
            params.updatedNodesRulesSetsMap,
            "affectsDescendantCalculations",
        );
        const requiresDescendantRebake = hasSomeLayoutRuleConfigInMap(
            params.updatedNodesRulesSetsMap,
            "requiresDescendantRebake",
        );

        const addRouteSegmentResults = params.addRouteSegmentParams.map((params) => addRouteSegment(...params));

        // Flatten updatedNodePositions and convert to
        // IPcbLayoutNodesRuleSetsMap for merging with
        // params.updatedNodesRulesSetsMap
        const updatedNodePositions: Record<string, PcbRule> = merge(
            {},
            ...addRouteSegmentResults.map((n) => n.payload.updatedNodePositions),
        );
        const updatedNodePositionsRuleSetsMap = transform(
            updatedNodePositions,
            (result, position, nodeUid) => {
                result[nodeUid] = {nodeUid, updatedPcbLayoutRules: {position}};
            },
            {} as IPcbLayoutNodesRuleSetsMap,
        );

        return {
            payload: {
                addedNodes: addRouteSegmentResults.flatMap((n) => n.payload.addedNodes),
                deletedNodes: [
                    ...addRouteSegmentResults.flatMap((nf) => nf.payload.deletedNodes.map((n) => n.uid)),
                    ...(params.removeNodeUids ?? []),
                ],
                updatedNodes: merge(params.updatedNodesRulesSetsMap, updatedNodePositionsRuleSetsMap),
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,

                hasInheritedRules,
                hasInheritedCalculations,
                requiresDescendantRebake,
            },
        };
    },
    "performed multi routing",
);
