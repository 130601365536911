import {createActionRecordAction} from "../../../../../helpers/actionCreator";

export const removeGlobalPcbRuleSet = createActionRecordAction(
    "removeGlobalPcbRuleSet",
    (pcbLayoutRuleUids: string[]) => {
        return {
            payload: {
                pcbLayoutRuleUids,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed document pcb layout rules",
);
