import type {Functions} from "firebase/functions";

import {FluxServices} from ".";

export function connectToFirebaseFunctions(
    services: FluxServices,
    region: "us-west1" | "us-west2" | "us-central1",
    useEmulator: boolean,
): Functions {
    const {getFunctions} = require("firebase/functions");
    const functions = getFunctions(
        useEmulator ? services.firebaseAppForEmulator : services.firebaseAppForCurrentEnvironment,
        region,
    );

    if (useEmulator) {
        const {connectFunctionsEmulator} = require("firebase/functions");
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    return functions;
}
