import {INetsMap} from "@buildwithflux/core";
import {useMemo} from "react";

import type {IApplicationState} from "../../../../state";
import {pickValues} from "@buildwithflux/shared";

const useSelectedNet = (netUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.nets?.[netUid];
        },
        [netUid],
    );
};

const useNetLabel = (selectedObjectUids: string[]) => {
    return useMemo(
        () => (state: IApplicationState) => {
            let netLabel = "";
            selectedObjectUids.some((uid) => {
                const net = state.document?.nets[uid];
                if (net) {
                    netLabel = net.label;
                    return true;
                }
            });
            return netLabel;
        },
        [selectedObjectUids],
    );
};

const useNetProperties = (netUid: string) => {
    return useMemo(
        () => (state: IApplicationState) => {
            return state.document?.nets[netUid]?.properties;
        },
        [netUid],
    );
};

const useSelectedNetsCount = (selectedObjectUids?: string[]) => {
    return useMemo(
        () => (state: IApplicationState) => {
            if (state.document?.nets && selectedObjectUids) {
                return pickValues(state.document?.nets, selectedObjectUids).length;
            }
            return 0;
        },
        [selectedObjectUids],
    );
};

const selectNetsCount = (state: IApplicationState) => {
    const nets = state.document?.nets;
    if (nets) {
        return Object.keys(nets).length;
    } else {
        return 0;
    }
};

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to memoize it
// TODO: this seems to need shallow comparison
const selectNets = (state: IApplicationState): INetsMap => state.document?.nets!;

const netSelectors = {
    selectNets,
    selectNetsCount,
    useNetLabel,
    useNetProperties,
    useSelectedNet,
    useSelectedNetsCount,
};

export default netSelectors;
