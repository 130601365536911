import type {GetObjectCommandOutput} from "@aws-sdk/client-s3";

/**
 * This is to address this long-standing SDK complaint
 *
 * - https://github.com/aws/aws-sdk-js/issues/1553
 * - https://github.com/aws/aws-sdk-js-v3/issues/1613
 *
 * In short, AWS make everything optional for bad reasons. Practically, the Body of a GetObject output is not optional,
 * and will always be present
 */
export type GetObjectCommandOutputWithBody = GetObjectCommandOutput & Required<Pick<GetObjectCommandOutput, "Body">>;

/**
 * AWS SDK now uses standard web-native types, like Blob, ReadableStream, Uint8Array, etc.
 */
export type FileStorageBody = Blob | ReadableStream<any>;

export enum bucketSubFolders {
    documentAssets = "document_assets",
    userAvatars = "user_avatars",
    organizationAvatars = "organization_avatars",
}

export type ImageFit = "cover" | "contain" | "fill" | "inside" | "outside";

export enum ImageSizes {
    smallThumb = 12,
    thumb = 20,
    largeThumb = 24,
    extraExtraSmall = 26,
    extraSmall = 32,
    small = 40,
    extraMedium = 64,
    superMedium = 124,
    superDuperMedium = 136,
    medium = 320,
    large = 640,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface FileStorage {
    getProcessedImageUrl(key: string, size: ImageSizes, fit: ImageFit): string;
    getProcessed3dModelUrl(key: string): string;
    getFileURL(key: string, cacheBlock?: boolean | undefined): string;
    getGlbUrlFromSvgKey(key: string): string;
    uploadFile(
        key: string,
        fileBody: FileStorageBody,
        contentType?: string,
        makePublic?: boolean | undefined,
    ): Promise<void>;
}
