import {areWeTestingWithJest} from "@buildwithflux/shared";

import type {IApplicationStore} from "../../store";

export type ReduxStoreService = {
    getStore(): IApplicationStore;
};

export type MutableReduxStoreService = ReduxStoreService & {
    setStore(store: IApplicationStore): void;
};

/**
 * The point of this service is to provide lazy instantiation of the store for consumers that don't need it immediately
 *
 * In the current state of the app, when you load the definition of the store, it gets automatically instantiated for you,
 * a side-effecting operation that can cause cyclic dependencies (because instantiating the store requires creating the
 * DI container, in order to create necessary middleware.
 *
 * If you're a React component or hook, you can use the Redux context provider, and that's the recommended way of getting
 * at the store. But if you're a pure functional component, or e.g. a Zustand store method or a "logTimeToNextIdle"
 * style static-utility, you'll still want to be able to declare a dependency on the store without causing it to be created.
 *
 * To do so, depend on this services, and call getStore() whenever you're ready
 */
export class ReduxStoreServiceImpl implements ReduxStoreService {
    protected store: IApplicationStore | undefined = undefined;

    constructor(
        /**
         * @todo invert the store dependency
         */
        private readonly getStoreFromGlobalState: () => IApplicationStore = () => require("../../store").store,
    ) {}

    public getStore(): IApplicationStore {
        if (!this.store) {
            this.store = this.getStoreFromGlobalState();
        }
        return this.store;
    }
}

export class MockReduxStoreService extends ReduxStoreServiceImpl implements MutableReduxStoreService {
    public setStore(store: IApplicationStore): void {
        if (!areWeTestingWithJest()) {
            throw new Error("Setting state directly only supported in Jest tests");
        }
        if (this.store) {
            throw new Error("Cannot set state: state already set on service");
        }
        this.store = store;
    }
}
